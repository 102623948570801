import {
    AutoStoriesIcon,
    BuildIcon,
    ChatIcon,
    CodeIcon,
    CreditCardIcon,
    EditCalendarIcon,
    GroupAddIcon,
    HeadphonesIcon,
    LibraryMusicIcon,
    LoginIcon,
    MicIcon,
    MonitorHeartIcon,
    PersonIcon,
    PolicyIcon,
    PublicIcon,
    QueryStatsIcon,
    SatelliteAltIcon,
    ScheduleIcon,
    SettingsInputComponentIcon,
    SettingsSharpIcon,
    ShuffleIcon,
    SpeedIcon,
    UploadIcon,
    ViewListIcon,
    WidgetsIcon,
    WifiIcon
} from '../components/mui';
import {
    AudioSettingsProviderLoader,
    BillingProviderLoader,
    ChatProviderLoader,
    ChatWidgetPreviewLoader,
    DJManagementProviderLoader,
    GeoblockingProviderLoader,
    HealthNotificationProviderLoader,
    IntegrationProviderLoader,
    LibraryPageLoader,
    ListenLinkLoader,
    ListenerStatsEventsLoader,
    LiveControlProviderLoader,
    PageLoadingLoader,
    PageLoginLoader,
    PlayblockProviderLoader,
    ProfileLoader,
    RequestPolicyProviderLoader,
    ScheduledEventsProviderLoader,
    ScheduledMaintenanceWindowLoader,
    SeparationRulesProviderLoader,
    SettingsLoader,
    StationDetailsProviderLoader,
    StationSelectProviderLoader,
    StreamingProviderLoader,
    UploadProviderLoader,
    WebToolsAndWidgetsLoader,
    WebWidgetsLoader
} from '../components/page-loader';
import { RootNavigation } from '../components/root-navigation';
import { SamWidgetDto, ServiceRequest } from './dto';
import { IPictureUploadDefaults } from './interfaces';
import Lang from './language';
import { IRoute } from './routes';
import { defaultLibraryId } from './table-data';

const widgetsChildren: Array<IRoute> = [
    {
        allowedEnvironments: 'all',
        allowedLink: 'station-selected',
        icon: BuildIcon,
        page: WebToolsAndWidgetsLoader,
        protected: true,
        path: 'web-tools-and-widgets',
        parentPath: '/station/:stationId/widgets/',
        title: Lang.rWebToolsAndWidgets,
        showNavMenuAndBtns: true
    },
    {
        allowedEnvironments: 'all',
        allowedLink: 'station-selected',
        icon: CodeIcon,
        page: WebWidgetsLoader,
        protected: true,
        path: 'web-widgets',
        parentPath: '/station/:stationId/widgets/',
        title: Lang.rWebWidgets,
        showNavMenuAndBtns: true
    },
    {
        allowedEnvironments: 'all',
        allowedLink: 'station-selected',
        icon: HeadphonesIcon,
        page: ListenLinkLoader,
        protected: true,
        path: 'listen-link',
        parentPath: '/station/:stationId/widgets/',
        title: Lang.rListenLink,
        showNavMenuAndBtns: true
    }
];

const eventsChildren: Array<IRoute> = [
    {
        allowedEnvironments: 'all',
        allowedLink: 'station-selected',
        icon: MonitorHeartIcon,
        page: HealthNotificationProviderLoader,
        protected: true,
        path: 'health',
        parentPath: '/station/:stationId/events/',
        title: Lang.rHealth,
        showNavMenuAndBtns: true
    },
    {
        allowedEnvironments: 'all',
        allowedLink: 'station-selected',
        icon: QueryStatsIcon,
        page: ListenerStatsEventsLoader,
        protected: true,
        path: 'listener-stats',
        parentPath: '/station/:stationId/events/',
        title: Lang.rListenerStats,
        showNavMenuAndBtns: true,
        routeTag: 'Beta'
    }
];

const scheduleChildren: Array<IRoute> = [
    {
        allowedLink: 'station-selected',
        icon: ShuffleIcon,
        page: PlayblockProviderLoader,
        protected: true,
        path: 'playblocks',
        parentPath: '/station/:stationId/schedule/',
        title: Lang.rPlayblocks,
        showNavMenuAndBtns: true,
        allowedEnvironments: 'all'
    },
    {
        allowedLink: 'station-selected',
        icon: AutoStoriesIcon,
        page: SeparationRulesProviderLoader,
        protected: true,
        path: 'separation-rules',
        parentPath: '/station/:stationId/schedule/',
        title: Lang.rSeparationRules,
        showNavMenuAndBtns: true,
        allowedEnvironments: 'all'
    },
    {
        allowedLink: 'station-selected',
        icon: EditCalendarIcon,
        page: ScheduledEventsProviderLoader,
        protected: true,
        path: 'scheduled-events',
        parentPath: '/station/:stationId/schedule/',
        title: Lang.rScheduledEvents,
        showNavMenuAndBtns: true,
        allowedEnvironments: 'all'
    }
];

const settingsChildren: Array<IRoute> = [
    {
        allowedEnvironments: 'all',
        allowedLink: 'station-selected',
        icon: BuildIcon,
        page: StationDetailsProviderLoader,
        protected: true,
        path: 'station-details',
        parentPath: '/station/:stationId/settings/',
        title: Lang.rStationDetails,
        showNavMenuAndBtns: true
    },
    {
        allowedEnvironments: 'all',
        allowedLink: 'station-selected',
        icon: SatelliteAltIcon,
        page: IntegrationProviderLoader,
        protected: true,
        path: 'integration',
        parentPath: '/station/:stationId/settings/',
        title: Lang.rIntegration,
        showNavMenuAndBtns: true
    },
    {
        allowedEnvironments: 'all',
        allowedLink: 'station-selected',
        icon: GroupAddIcon,
        page: DJManagementProviderLoader,
        protected: true,
        path: 'dj-management',
        parentPath: '/station/:stationId/settings/',
        title: Lang.rDJManagement,
        showNavMenuAndBtns: true
    },
    {
        allowedEnvironments: 'all',
        allowedLink: 'station-selected',
        icon: PolicyIcon,
        page: RequestPolicyProviderLoader,
        protected: true,
        path: 'request-policy',
        parentPath: '/station/:stationId/settings/',
        title: Lang.rRequestPolicy,
        showNavMenuAndBtns: true
    },
    {
        allowedEnvironments: 'all',
        allowedLink: 'station-selected',
        icon: PublicIcon,
        page: GeoblockingProviderLoader,
        protected: true,
        path: 'geo-blocking',
        parentPath: '/station/:stationId/settings/',
        title: Lang.rGeoblocking,
        showNavMenuAndBtns: true
    },
    {
        allowedEnvironments: 'all',
        allowedLink: 'station-selected',
        icon: WifiIcon,
        page: StreamingProviderLoader,
        protected: true,
        path: 'streaming',
        parentPath: '/station/:stationId/settings/',
        title: Lang.rStreaming,
        showNavMenuAndBtns: true
    },
    {
        allowedEnvironments: 'all',
        allowedLink: 'station-selected',
        icon: SettingsInputComponentIcon,
        page: AudioSettingsProviderLoader,
        protected: true,
        path: 'audio',
        parentPath: '/station/:stationId/settings/',
        title: Lang.rAudioSettings,
        showNavMenuAndBtns: true
    }
];

export const SAMCloudRoutes: Array<IRoute> = [
    // Base routes:
    {
        allowedEnvironments: 'all',
        allowedLink: 'station-not-selected',
        icon: ViewListIcon,
        page: StationSelectProviderLoader,
        protected: true,
        path: 'stations',
        parentPath: '/',
        title: Lang.rStationSelect,
        showNavMenuAndBtns: true
    },

    // Station routes:
    {
        allowedEnvironments: 'all',
        allowedLink: 'station-selected',
        defaultUrlParams: `?libraryId=${defaultLibraryId}`,
        icon: LibraryMusicIcon,
        page: LibraryPageLoader,
        protected: true,
        path: 'library',
        parentPath: '/station/:stationId/',
        title: Lang.rLibrary,
        showNavMenuAndBtns: true
    },
    {
        allowedEnvironments: 'all',
        allowedLink: 'station-selected',
        icon: MicIcon,
        page: LiveControlProviderLoader,
        protected: true,
        path: 'live-control',
        parentPath: '/station/:stationId/',
        title: Lang.rLiveControl,
        showNavMenuAndBtns: true
    },
    {
        allowedEnvironments: 'all',
        allowedLink: 'station-selected',
        icon: ChatIcon,
        page: ChatProviderLoader,
        protected: true,
        path: 'chat',
        parentPath: '/station/:stationId/',
        title: Lang.rChat,
        showNavMenuAndBtns: true
    },
    {
        allowedEnvironments: 'all',
        allowedLink: 'never',
        icon: ChatIcon,
        page: ChatWidgetPreviewLoader,
        protected: true,
        path: 'standalone-chat-preview',
        parentPath: '/station/:stationId/',
        title: Lang.rChatPreview,
        showNavMenuAndBtns: false
    },
    {
        allowedEnvironments: 'all',
        allowedLink: 'station-selected',
        icon: ScheduleIcon,
        page: PlayblockProviderLoader,
        protected: true,
        path: 'schedule',
        parentPath: '/station/:stationId/',
        title: Lang.rSchedule,
        showNavMenuAndBtns: true,
        children: scheduleChildren
    },
    {
        allowedEnvironments: 'all',
        allowedLink: 'station-selected',
        icon: SpeedIcon,
        page: HealthNotificationProviderLoader,
        protected: true,
        path: 'events',
        parentPath: '/station/:stationId/',
        title: Lang.rEvents,
        showNavMenuAndBtns: true,
        children: eventsChildren
    },
    {
        allowedEnvironments: 'all',
        allowedLink: 'station-selected',
        icon: SettingsSharpIcon,
        page: StationDetailsProviderLoader,
        protected: true,
        path: 'settings',
        parentPath: '/station/:stationId/',
        title: Lang.rStationSettings,
        showNavMenuAndBtns: true,
        children: settingsChildren
    },
    {
        allowedEnvironments: 'all',
        allowedLink: 'station-selected',
        icon: WidgetsIcon,
        page: WebWidgetsLoader,
        protected: true,
        path: 'widgets',
        parentPath: '/station/:stationId/',
        title: Lang.rWidgets,
        showNavMenuAndBtns: true,
        children: widgetsChildren
    },

    // General routes:
    {
        allowedEnvironments: 'all',
        allowedLink: 'never',
        icon: PersonIcon,
        page: ProfileLoader,
        protected: true,
        path: 'profile',
        parentPath: '/',
        title: Lang.rProfile,
        showNavMenuAndBtns: true
    },
    {
        allowedEnvironments: 'all',
        allowedLink: 'station-not-selected',
        icon: CreditCardIcon,
        page: BillingProviderLoader,
        protected: true,
        path: 'billing',
        parentPath: '/',
        title: Lang.rBilling,
        showNavMenuAndBtns: true
    },
    {
        allowedEnvironments: 'all',
        allowedLink: 'station-not-selected',
        icon: WifiIcon,
        page: StreamingProviderLoader,
        protected: true,
        path: 'streaming',
        parentPath: '/',
        title: Lang.rStreaming,
        showNavMenuAndBtns: true
    },
    {
        allowedEnvironments: 'development-staging',
        allowedLink: 'station-not-selected',
        icon: SettingsSharpIcon,
        page: SettingsLoader,
        protected: false,
        path: 'settings',
        parentPath: '/',
        title: Lang.rSettings,
        showNavMenuAndBtns: true
    },

    // Virtual routes:
    {
        allowedEnvironments: 'all',
        allowedLink: 'never',
        icon: ViewListIcon,
        page: RootNavigation,
        protected: true,
        path: '/',
        parentPath: '/',
        title: Lang.rStationSelect,
        showNavMenuAndBtns: true
    },
    {
        allowedEnvironments: 'all',
        allowedLink: 'never',
        icon: LoginIcon,
        page: PageLoadingLoader,
        protected: false,
        path: 'loading',
        parentPath: '/',
        title: Lang.rLoading,
        showNavMenuAndBtns: true
    },
    {
        allowedEnvironments: 'all',
        allowedLink: 'never',
        icon: LoginIcon,
        page: PageLoginLoader,
        protected: false,
        path: 'login',
        parentPath: '/',
        title: Lang.rLogin,
        showNavMenuAndBtns: true
    },
    {
        allowedEnvironments: 'all',
        allowedLink: 'never',
        icon: UploadIcon,
        page: UploadProviderLoader,
        protected: true,
        path: 'upload',
        parentPath: '/station/:stationId/',
        title: Lang.rUpload,
        showNavMenuAndBtns: false
    },
    {
        allowedEnvironments: 'all',
        allowedLink: 'station-not-selected',
        icon: PersonIcon,
        page: ProfileLoader,
        protected: true,
        path: 'profile',
        parentPath: '/',
        title: Lang.rProfile,
        showNavMenuAndBtns: true
    },
    {
        allowedEnvironments: 'all',
        allowedLink: 'never',
        icon: BuildIcon,
        page: ScheduledMaintenanceWindowLoader,
        protected: false,
        path: 'maintenance',
        parentPath: '/',
        title: Lang.rMaintenance,
        showNavMenuAndBtns: true
    }
];

export const emptyGuid = '00000000-0000-0000-0000-000000000000';
export const scrollBarWidth = 6;
export const scrollBarHeight = 10;

/**
 * Enough time for user to read and click on the link to download.
 * But they can also now click on the user notifications to interact with it.
 */
export const notificationItemDisplayTime = 10;
export const pictureStoragePlaceholderKey = 'PLACEHOLDER_KEY';
export const spacialIconFilter = 'drop-shadow(2px 2px 3px rgba(37, 60, 72, 0.25)) invert(10%)';
export declare type SamwidgetBorderRadius = 'square' | 'rounded';

export const thickBorder = 2.3;

/**
 * Use with util/style/getMaxBorderRadius
 */
export const maxBorderRadius = 10;

export const rangeCountFetch = 35;

export const defaultDebounceTime = 1000;

/**
 * This is used as a timeout, it's too difficult to keep track of all the loaders,
 * rather just keep it loading for a couple of seconds.
 * This won't always be the case.
 */
export const debounceTimeInevitableStop = 2000;

export const samWidgetDefaults: SamWidgetDto = {
    type: 'player',
    stationId: '',
    token: '',
    animType: 'focus-in-expand',
    easing: 'ease-in-out-back',
    refreshInterval: '30s',
    themeBorderRadius: 'rounded',
    imageBorderRadius: 'rounded',
    theme: '{"backgroundTop":"#474747","backgroundBottom":"#474747","widgetBorder":"#5f5f5f","dividers":"#5f5f5f","buttons":"#bebebe","text":"#bebebe"}'
};

export const defaultServiceRequest: ServiceRequest = {
    ownerFilter: 'All',
    serviceId: '0',
    sort: [{ inc: '+', name: 'StationName' }]
};

export const PictureUploadDefaults: IPictureUploadDefaults = {
    maxWidth: 300,
    maxHeight: 150,
    minWidth: 150,
    minHeight: 80,
    maxFileSizeMB: 2097152
};

/**
 * Allowing to search lists if there are more items than this.
 */
export const defaultAllowSearchThreshold = 8;

export enum TaskType {
    AddUrlTaskConfig = 'MediaLoader.Model.AddUrlTaskConfig, MediaLoader.Model',
    ChangeRequestTaskConfig = 'MediaLoader.Model.ChangeRequestTaskConfig, MediaLoader.Model',
    ChangeSocialIntegrationSettingsTaskConfig = 'MediaLoader.Model.ChangeSocialIntegrationSettingsTaskConfig, MediaLoader.Model',
    ClearQueueTaskConfig = 'MediaLoader.Model.ClearQueueTaskConfig, MediaLoader.Model',
    EnqueueUsingLogicConfig = 'MediaLoader.Model.EnqueueUsingLogicConfig, MediaLoader.Model',
    PlaylistTaskConfig = 'MediaLoader.Model.PlaylistTaskConfig, MediaLoader.Model',
    QueueSettingTaskConfig = 'MediaLoader.Model.QueueSettingTaskConfig, MediaLoader.Model',
    ScheduleShowTaskConfig = 'MediaLoader.Model.ScheduleShowTaskConfig, MediaLoader.Model',
    ShareSocialMessageTaskConfig = 'MediaLoader.Model.ShareSocialMessageTaskConfig, MediaLoader.Model',
    ShuffleQueueTaskConfig = 'MediaLoader.Model.ShuffleQueueTaskConfig, MediaLoader.Model',
    SkipToNextTrackTaskConfig = 'MediaLoader.Model.SkipToNextTrackTaskConfig, MediaLoader.Model',
    StartStationTaskConfig = 'MediaLoader.Model.StartStationTaskConfig, MediaLoader.Model',
    StopStationTaskConfig = 'MediaLoader.Model.StopStationTaskConfig, MediaLoader.Model',
    SwitchPlayblockTaskConfig = 'MediaLoader.Model.SwitchPlayblockTaskConfig, MediaLoader.Model',
    SwitchSourceTaskConfig = 'MediaLoader.Model.SwitchSourceTaskConfig, MediaLoader.Model',
    PickTrackTaskConfig = 'MediaLoader.Model.PickTrackTaskConfig, MediaLoader.Model',
    GeoblockingTaskConfig = 'MediaLoader.Model.GeoblockingTaskConfig, MediaLoader.Model',
    PickTrackFromPlaylistConfig = 'MediaLoader.Model.PickTrackFromPlaylistConfig, MediaLoader.Model',
    PickTrackFromFilterConfig = 'MediaLoader.Model.PickTrackFromFilterConfig, MediaLoader.Model',
    RecycleTaskConfig = 'MediaLoader.Model.RecycleTaskConfig, MediaLoader.Model',
    ExternalRSSFeedTaskConfig = 'MediaLoader.Model.ExternalRSSFeedTaskConfig, MediaLoader.Model',
    EmptyRecycleBinTaskConfig = 'MediaLoader.Model.EmptyRecycleBinTaskConfig, MediaLoader.Model',
    EmptyRecycleBinByAgeTaskConfig = 'MediaLoader.Model.EmptyRecycleBinByAgeTaskConfig, MediaLoader.Model'
}

/**
 * Look at FeatureFlagEnum on medialoaderservices to update.
 */
export enum FeatureFlag {
    TestFlag1 = 'TestFlag1',
    TestFlag2 = 'TestFlag2',
    AutomaticGain = 'AutomaticGain',
    EnableChainerSupport = 'EnableChainerSupport',
    UseUserSettingTableEULA = 'UseUserSettingTableEULA',
    Live365RelayEnabled = 'Live365RelayEnabled',
    AllowRemoveBranding = 'AllowRemoveBranding',
    VoiceTrackingEnabled = 'VoiceTrackingEnabled',
    VoiceTrackEditEnabled = 'VoiceTrackEditEnabled',
    DebugRotationLogic = 'DebugRotationLogic',
    URLImportingEnabled = 'URLImportingEnabled',
    MobileLiveStreaming = 'MobileLiveStreaming',
    BackupPlaylistFeatureEnabled = 'BackupPlaylistFeatureEnabled',
    MediaItemIntegrationEnabled = 'MediaItemIntegrationEnabled',
    TFAEnabled = 'TFAEnabled',
    Geoblocking = 'Geoblocking',
    RSSImportingEnabled = 'RSSImportingEnabled',
    V6WidgetGeneration = 'V6WidgetGeneration',
    V6Microsite = 'V6Microsite',
    WidgetSignalR = 'WidgetSignalR',
    ChatFeature = 'ChatFeature',
    ListenerStatsEvent = 'ListenerStatsEvent',
    DiscordIntegrationFeature = 'DiscordIntegrationFeature',
    RadioGPTFeature = 'RadioGPTFeature',
    TwitterIntegration = 'TwitterIntegration'
}

export const backsellCountMax = 9999999;

/**
 * Discord page is styled specifically.
 */
export const discordBtnIconWidth = 26;

export declare type FeedbackType = 'Feature Request' | 'Feedback';

//////// TD Player SDK:

/**
 * The default volume that the player widget is.
 */
export const defaultVolume = 0.6;

/**
 * When the volume is at its lowest, and the user presses play, the volume can go to this amount.
 */
export const playMutedVolume = 0.1;

/**
 * Only for the slider.
 */
export const minVolume = 0.3;

export const zIndex1 = 1300;
export const zIndex2 = 1200;
export const zIndex3 = 1000;
export const zIndex4 = 100;
export const zIndex5 = 2;
export const zIndex6 = -1;
