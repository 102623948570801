import { pictureStoragePlaceholderKey } from '../../../models/consts';
import { AccountState } from '../../../providers/account';
import { isValidUrl } from '../../../utils/general';
import { PersonDetails } from '../models/interfaces';

export function getLogoutHoursMinutes(logoutMinutes: number): { hours: number; minutes: number } {
    return { hours: Math.floor(logoutMinutes / 60), minutes: logoutMinutes % 60 };
}

export function getTotalLogoutMinutes(hours: number, minutes: number) {
    return hours * 60 + minutes;
}

export function getLogoutMinutesErrorMessage(hours: number, minutes: number) {
    const totalMinutes = getTotalLogoutMinutes(hours, minutes);
    if (totalMinutes > 1440) {
        return 'Max value is 24hours.';
    }
    if (totalMinutes < 30) {
        return 'Min value is 30 Minutes.';
    }
    return '';
}

export function getProfileDisplayImage(personDetails: PersonDetails, accountState: AccountState) {
    if (
        personDetails.profileImage &&
        !isValidUrl(personDetails.profileImage) &&
        accountState.pictureStorageBase &&
        personDetails.profileImage
    ) {
        return accountState.pictureStorageBase.replace(pictureStoragePlaceholderKey, personDetails.profileImage);
    }
    if (personDetails.profileImage && isValidUrl(personDetails.profileImage)) {
        return personDetails.profileImage;
    }
}
