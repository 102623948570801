import React, { PureComponent } from 'react';
import Lang from '../models/language';
import { DialogConfirmationProps } from '../models/props';
import { DialogDraggableTitle, getDialogPaperComponent } from './draggable-paper';
import LoadingBtn from './loading-btn';
import { Dialog, DialogActions, DialogContent, Paper } from './mui';
import { Btn } from './styled-components';

const dragTitleId = 'draggable-dialog-title';

export default class DialogConfirmation extends PureComponent<DialogConfirmationProps, { loading: boolean }> {
    constructor(props: DialogConfirmationProps) {
        super(props);
        this.state = { loading: false };
    }

    render() {
        const {
            dialogTitle,
            children,
            draggable,
            closable,
            positiveTitle,
            negativeTitle,
            open,
            onClose,
            onPositiveEvent,
            onNegativeEvent,
            onExternalPositiveEvent,
            isFullWidth = false,
            maxWidth = false,
            theme
        } = this.props;
        const { loading } = this.state;
        const tryClose = () => onClose && onClose();

        const positiveEventFunction = async () => {
            this.setState({ loading: true });
            onPositiveEvent && (await onPositiveEvent());
            this.setState({ loading: false });
        };

        return (
            <Dialog
                open={open}
                fullWidth={isFullWidth}
                maxWidth={maxWidth ? maxWidth : 'md'}
                onClose={() => {
                    closable && tryClose();
                }}
                PaperComponent={draggable ? getDialogPaperComponent(dragTitleId) : Paper}
                aria-labelledby={dragTitleId}
            >
                <DialogDraggableTitle componentId={dragTitleId} dialogTitle={dialogTitle} draggable={draggable} />
                <DialogContent style={{ paddingBottom: theme.spacing(1) }}>{children}</DialogContent>
                <DialogActions>
                    <Btn size="small" variant="text" onClick={onNegativeEvent}>
                        {negativeTitle ? negativeTitle : Lang.btnCancel}
                    </Btn>

                    {(onPositiveEvent || onExternalPositiveEvent) && (
                        <LoadingBtn
                            buttonProps={{
                                size: 'small',
                                variant: 'contained',
                                type: 'submit',
                                onClick: onPositiveEvent ? positiveEventFunction : onExternalPositiveEvent
                            }}
                            loading={loading}
                        >
                            {positiveTitle ? positiveTitle : Lang.btnOk}
                        </LoadingBtn>
                    )}
                </DialogActions>
            </Dialog>
        );
    }
}
