import React from 'react';
import Draggable from 'react-draggable';
import { DialogTitle, Paper } from './mui';

export const DialogDraggableTitle = ({ componentId, draggable, dialogTitle }) => {
    return (
        <DialogTitle sx={{ ...(draggable && { cursor: 'move' }) }} id={componentId}>
            {dialogTitle}
        </DialogTitle>
    );
};

/**
 * Special case: Dialogs with textfields lose focus on text input(re-render)
 * Usage: const dialogPaperComponent = useRef(getDialogPaperComponent(dragTitleId)).current;
 */
export function getDialogPaperComponent(draggableDialogTitle: string) {
    const DialogPaperComponent = (props) => {
        return (
            <Draggable handle={`#${draggableDialogTitle}`} cancel={'[class*="MuiDialogContent-root"]'}>
                <Paper {...props} />
            </Draggable>
        );
    };
    return DialogPaperComponent;
}
