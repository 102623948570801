import React, { FC, useEffect, useState } from 'react';
import { useTreeView } from '..';
import BtnIconTooltip from '../../../../components/btn-icon-tooltip';
import DialogCustomComponent from '../../../../components/dialog-custom-component';
import {
    Collapse,
    Divider,
    SearchIcon,
    SettingsSharpIcon,
    Stack,
    WindowIcon,
    useMediaQuery,
    useTheme
} from '../../../../components/mui';
import { useSearch } from '../../../../components/search-bar';
import SearchBar from '../../../../components/search-bar/search-bar';
import { LibTblData, LibTreeData, TblColType } from '../../../../models/interfaces';
import Lang from '../../../../models/language';
import { BaseStationProps } from '../../../../models/props';
import { libComponentData, libTreeData } from '../../../../models/table-data';
import TreeContainer from '../library-tree';
import { TreeDefinition, TreeEntity } from '../models/interfaces';
import { isDisplayableTable, toggleTableShow } from '../utils';
import DialogLayoutSelectInput from './dialog-layout-select';

const buttonSpacing = 1;

export const LibraryControls: FC<BaseStationProps> = ({ stationId }) => {
    const theme = useTheme();
    const { searchValue, searchLoading, search, searchRequest } = useSearch();
    const { libLayout, setLibLayoutHighlight, setLibLayoutState, setTreeLayoutState, treeViewData } = useTreeView();
    const [dialogLayoutOptionsOpen, setDialogLayoutOptionsOpen] = useState(false);
    const [dialogLibTreeOpen, setDialogLibTreeOpen] = useState(false);
    const [dialogPlaylistTreeOpen, setDialogPlaylistTreeOpen] = useState(false);
    const [openSettingDialog, setOpenSettingsDialog] = useState(false);

    const sm = useMediaQuery(theme.breakpoints.up('md'));
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        if (openSettingDialog) {
            setOpenSettingsDialog(false);
        }
    }, [isSmallScreen]);

    const currentComponentData =
        !treeViewData.resolvedNode || treeViewData.resolvedNode?.type === 'LibraryItem'
            ? libComponentData.library
            : libComponentData.playlist;

    const getTreeState = (treeEntity: TreeEntity) => {
        const treeNode = libLayout.treeComponents.find((x) => x?.treeEntity === treeEntity);
        let newPossibleTreeNode = treeNode;
        if (!treeNode) {
            let treeIndex = libLayout.treeComponents.findIndex((x) => !x);
            if (treeIndex < 0) {
                // This could happen if 2 of the same tree entities are busy showing:
                treeIndex = libLayout.treeComponents.length - 1;
            }
            newPossibleTreeNode = { treeEntity, treeIndex };
        }
        if (treeEntity === 'library-tree') {
            return {
                dialogOpenEvent: setDialogLibTreeOpen,
                hidden: treeNode ? false : true,
                open: dialogLibTreeOpen,
                treeNode: (treeNode ? treeNode : newPossibleTreeNode) as TreeDefinition
            };
        } else {
            return {
                dialogOpenEvent: setDialogPlaylistTreeOpen,
                hidden: treeNode ? false : true,
                open: dialogPlaylistTreeOpen,
                treeNode: (treeNode ? treeNode : newPossibleTreeNode) as TreeDefinition
            };
        }
    };

    const renderLibraryButton = (item: LibTblData<TblColType>) => {
        const { tableEntity } = item;
        const tableIndex = libLayout.tableComponents.findIndex((comp) => comp?.tableEntity === item.tableEntity);
        const showTable = tableIndex >= 0;
        const btnTitle = `${showTable ? Lang.btnHide : Lang.btnShow} ${item.title}`;
        const tableDisplayable = isDisplayableTable(item.tableEntity);

        return (
            <BtnIconTooltip
                displayMode="tooltip"
                icon={<item.icon />}
                iconButtonProps={{
                    color: 'primary',
                    highlight: showTable,
                    size: 'small'
                }}
                onClick={() => {
                    toggleTableShow(tableEntity, tableDisplayable, showTable, libLayout, setLibLayoutState);
                }}
                onMouseEnter={() => {
                    setLibLayoutHighlight(tableEntity, true);
                }}
                onMouseLeave={() => {
                    setLibLayoutHighlight(tableEntity, false);
                }}
            >
                {btnTitle}
            </BtnIconTooltip>
        );
    };

    const renderLibraryOrPlaylist = (libTreeData: LibTreeData) => {
        const { dialogOpenEvent, hidden, open, treeNode } = getTreeState(libTreeData.treeEntity);
        // If the item was hidden by the user, then the button should inevitably show in order for the user to bring it back:
        const showButtonToggle = !sm || hidden;

        return (
            <Collapse key={libTreeData.treeEntity} in={showButtonToggle} unmountOnExit>
                <BtnIconTooltip
                    displayMode="tooltip"
                    icon={<libTreeData.icon />}
                    iconButtonProps={{
                        color: 'primary',
                        highlight: open,
                        size: 'small',
                        onClick: () => {
                            if (hidden) {
                                setTreeLayoutState(treeNode, true);
                            }
                            if (!sm) {
                                dialogOpenEvent(true);
                            }
                        }
                    }}
                >
                    {`Show ${libTreeData.title}`}
                </BtnIconTooltip>
            </Collapse>
        );
    };

    const renderLibOrPlaylistDialog = (libTreeData: LibTreeData) => {
        const { dialogOpenEvent, open } = getTreeState(libTreeData.treeEntity);
        // Note, treeIndex doesn't matter in the dialog because it can't be toggled in anyway:
        const treeDef = { treeEntity: libTreeData.treeEntity, treeIndex: 0 } as TreeDefinition;

        return (
            <DialogCustomComponent closable onClose={() => dialogOpenEvent(false)} open={open}>
                <TreeContainer key={`tree-${treeDef.treeEntity}`} stationId={stationId} treeDef={treeDef} dialogDisplay={true} />
            </DialogCustomComponent>
        );
    };

    const renderSearch = () => {
        return (
            <Stack direction="row" alignItems="center" spacing={0.5} sx={{ flex: 1 }}>
                <SearchBar />
                <BtnIconTooltip
                    displayMode="tooltip"
                    icon={<SearchIcon />}
                    iconButtonProps={{
                        color: 'primary',
                        highlight: !!searchValue,
                        size: 'small',
                        onClick: () => search(searchValue),
                        sx: { p: 0.85 }
                    }}
                    loading={searchLoading}
                >
                    {searchRequest.value ? 'Search active' : 'Search'}
                </BtnIconTooltip>
            </Stack>
        );
    };

    return (
        <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
            {!isSmallScreen && (
                <Stack direction="row" spacing={buttonSpacing} alignItems="center">
                    {renderLibraryOrPlaylist(libTreeData.libraryTree)}
                    {renderLibraryOrPlaylist(libTreeData.playlist)}
                </Stack>
            )}

            {renderSearch()}

            {!isSmallScreen && (
                <>
                    <Divider orientation="vertical" />
                    <Stack direction="row" spacing={buttonSpacing} alignItems="center">
                        {renderLibraryButton(currentComponentData)}
                        {renderLibraryButton(libComponentData.queue)}
                        {renderLibraryButton(libComponentData.history)}
                    </Stack>
                </>
            )}

            <Divider orientation="vertical" />
            <BtnIconTooltip
                displayMode="tooltip"
                icon={<WindowIcon />}
                iconButtonProps={{
                    color: 'primary',
                    highlight: dialogLayoutOptionsOpen,
                    size: 'small',
                    onClick: () => {
                        setDialogLayoutOptionsOpen(true);
                    }
                }}
            >
                {Lang.btnLibLayout}
            </BtnIconTooltip>
            {isSmallScreen && (
                <BtnIconTooltip
                    displayMode="tooltip"
                    icon={<SettingsSharpIcon />}
                    iconButtonProps={{
                        color: 'primary',
                        highlight: openSettingDialog,
                        size: 'small',
                        onClick: () => {
                            setOpenSettingsDialog(true);
                        }
                    }}
                >
                    {Lang.btnLibLayout}
                </BtnIconTooltip>
            )}

            <DialogLayoutSelectInput
                closable
                dialogTextContent="Select the library layout you prefer."
                dialogTitle="Library Layout"
                draggable
                onClose={() => setDialogLayoutOptionsOpen(false)}
                open={dialogLayoutOptionsOpen}
            />
            <DialogCustomComponent
                closable
                draggable
                onClose={() => setOpenSettingsDialog(false)}
                open={openSettingDialog}
                dialogTitle="Library settings"
            >
                <Stack direction="row" spacing={1} alignItems="center">
                    {renderLibraryOrPlaylist(libTreeData.libraryTree)}
                    {renderLibraryOrPlaylist(libTreeData.playlist)}

                    {<Divider orientation="vertical" />}
                    {renderLibraryButton(currentComponentData)}
                    {renderLibraryButton(libComponentData.queue)}
                    {renderLibraryButton(libComponentData.history)}
                </Stack>
            </DialogCustomComponent>
            {renderLibOrPlaylistDialog(libTreeData.libraryTree)}
            {renderLibOrPlaylistDialog(libTreeData.playlist)}
        </Stack>
    );
};

export default LibraryControls;
