import moment from 'moment';
import React from 'react';
import { getPrevItems, itemIsPlaceholderItem } from '../components/dynamic-table/utils';
import {
    CheckBoxIcon,
    CheckCircleIcon,
    HistoryIcon,
    MicIcon,
    PersonIcon,
    QueueMusicIcon,
    Stack,
    SubscriptionsIcon,
    TaskIcon,
    Tooltip,
    VideoLibraryIcon,
    VisibilityIcon
} from '../components/mui';
import { ResolvedTreeNode } from '../pages/station/library/models/interfaces';
import { getMediaItem } from '../utils/general';
import { LocalStorageType } from '../utils/local-storage';
import { attachZ, formatDurationDHM } from '../utils/time';
import { DynamicTableRequest, HistoryItemDto, LibraryItemDto, QueueItemDto } from './dto';
import { ITblCol, LibraryTableData, LibraryTreeData, LibTblData, MenuItemData, TableSettings, TblColType } from './interfaces';
import Lang from './language';
import { historyMenuItems, libraryMenuItems, playlistMenuItems, queueMenuItems } from './menu-item-data';
import { formatDateTimeFull, formatTimeOnly } from './time';

/**
 * This refers to All Media in the library which is the default to be selected.
 */
export const defaultLibraryId = '00000000-0000-0000-0000-0000000a0500';

/**
 * LocalStorageType: 'TABLE-HISTORY'
 */
export const historyCols: Array<ITblCol<HistoryItemDto>> = [
    {
        label: 'Played',
        dataKey: 'DatePlayed',
        width: 1,
        labelFormatter: (label, row) => {
            let played: string;
            label = attachZ(label);

            const localDate = new Date(label);
            const current = moment(localDate);
            const today = moment();
            if (current.isSame(today, 'day')) {
                // Only show time if it's today (saves space)
                played = current.format(formatTimeOnly);
            } else {
                played = current.format(formatDateTimeFull);
            }

            if (row && row.Requested) {
                return (
                    <>
                        {played} <span title="Requested Track">(R)</span>
                    </>
                );
            }
            return played;
        },
        style: { textAlign: 'center' }
    },
    {
        label: 'Title',
        dataKey: 'Title',
        width: 1.4
    },
    {
        label: 'Artist',
        dataKey: 'Artist',
        width: 1.4
    },
    {
        label: 'Album',
        dataKey: 'Album',
        width: 1
    },
    {
        label: 'Duration',
        dataKey: 'Duration',
        width: 1,
        labelFormatter: (label) => {
            return formatDurationDHM(label);
        },
        style: { textAlign: 'end' }
    },
    {
        label: <TaskIcon sx={{ width: 'auto' }} />,
        dataKey: 'Requested',
        width: 0.4,
        labelFormatter: (_, row) => {
            const style = { display: 'flex', alignItems: 'center' };

            const arr: JSX.Element[] = [];

            if (row && row.UserId && row.UserId.toString() != '0') {
                arr.push(
                    <div style={style} title="Live Stream" key="key-live-stream">
                        <MicIcon />
                    </div>
                );
            }

            if (row && row.Requested) {
                arr.push(
                    <div style={style} title="Track requested" key="key-track-requested">
                        <CheckBoxIcon />
                    </div>
                );
            }
            return (
                arr.length > 0 && (
                    <Stack
                        direction="row"
                        justifyContent="center"
                        sx={{
                            borderStyle: 'solid',
                            borderWidth: 1,
                            borderColor: '#8181818c'
                        }}
                    >
                        {arr}
                    </Stack>
                )
            );
        },
        style: { textAlign: 'center' }
    },
    {
        label: <PersonIcon sx={{ width: 'auto' }} />,
        dataKey: 'Performances',
        width: 0.2,
        style: { textAlign: 'center' }
    }
];

/**
 * LocalStorageType: 'TABLE-QUEUE'.
 * Type never is chosen because the package isn't made for getting a sub parameter (e.g. queue.MediaItem.Title).
 */
export const queueCols: Array<ITblCol<never>> = [
    {
        label: 'ETA',
        dataKey: 'MediaItem.DateAdded',
        width: 1,
        labelFormatter: (_, row, nowPlayingInfo, listItems, etas) => {
            if (row && listItems && etas) {
                const index = row['Index'];
                const { prevEta, prevQueueItem } = getPrevItems(index, etas, listItems);
                if (prevEta) {
                    etas[index] = prevEta.clone();
                    const mediaItem = getMediaItem(prevQueueItem ? prevQueueItem : nowPlayingInfo);
                    etas[index].add(moment.duration(mediaItem?.Duration ?? '0'));

                    return etas[index].format(formatTimeOnly);
                } else {
                    let datePlayed: Date | string;
                    if (nowPlayingInfo) {
                        datePlayed = attachZ(nowPlayingInfo.DatePlayed);
                    } else {
                        datePlayed = new Date();
                    }
                    const historyDuration = moment.duration(nowPlayingInfo?.Duration ?? '0');
                    etas[index] = moment(datePlayed).add(historyDuration);

                    return etas[index].format(formatTimeOnly);
                }
            }

            return '...';
        }
    },
    {
        label: 'Title',
        dataKey: 'MediaItem.Title',
        width: 1.4
    },
    {
        label: 'Artist',
        dataKey: 'MediaItem.Artist',
        width: 1.4
    },
    {
        label: 'Album',
        dataKey: 'MediaItem.Album',
        width: 1
    },
    {
        label: 'Duration',
        dataKey: 'MediaItem.Duration',
        width: 1,
        labelFormatter: (label) => {
            return formatDurationDHM(label);
        },
        style: { textAlign: 'center' }
    },
    {
        label: <TaskIcon sx={{ width: 'auto' }} />,
        dataKey: 'Requested',
        width: 0.4,
        labelFormatter: (_, row) => {
            const style = { display: 'flex', alignItems: 'center' };

            const queueItem = row as never as QueueItemDto;
            const mediaItem = getMediaItem(queueItem);
            const arr: JSX.Element[] = [];
            if (mediaItem && queueItem.Requested) {
                arr.push(
                    <div style={style} title="Track requested" key="key-track-requested">
                        <CheckBoxIcon />
                    </div>
                );
            }
            return (
                arr.length > 0 && (
                    <Stack
                        direction="row"
                        justifyContent="center"
                        sx={{
                            borderStyle: 'solid',
                            borderWidth: 1,
                            borderColor: '#8181818c'
                        }}
                    >
                        {arr}
                    </Stack>
                )
            );
        },
        style: { textAlign: 'center' }
    }
];

/**
 * LocalStorageType: 'TABLE-LIBRARY'.
 */
export const libraryCols: Array<ITblCol<LibraryItemDto>> = [
    {
        label: (
            <Tooltip title="Browsable">
                <VisibilityIcon sx={{ width: 'auto' }} />
            </Tooltip>
        ),
        dataKey: 'Browsable',
        width: 1,
        labelFormatter: (label) => {
            return <VisibilityIcon sx={{ opacity: label ? 1 : 0.2 }} />;
        },
        style: { textAlign: 'center' }
    },
    {
        label: 'Title',
        dataKey: 'Title',
        width: 1
    },
    {
        label: 'Artist',
        dataKey: 'Artist',
        width: 1
    },
    {
        label: 'Album',
        dataKey: 'Album',
        width: 1
    },
    {
        label: 'Duration',
        dataKey: 'Duration',
        width: 0.8,
        labelFormatter: (label) => {
            return formatDurationDHM(label);
        },
        style: { textAlign: 'center' }
    },
    {
        label: 'Added',
        dataKey: 'DateAdded',
        width: 1,
        labelFormatter: (label) => {
            return moment(label).format(formatDateTimeFull);
        },
        style: { textAlign: 'center' }
    }
];

export const playlistCols: Array<ITblCol<never>> = [
    {
        label: 'Browsable',
        dataKey: 'MediaItem.Browsable',
        width: 1,
        style: { textAlign: 'center' }
    },
    {
        label: 'Title',
        dataKey: 'MediaItem.Title',
        width: 1
    },
    {
        label: 'Artist',
        dataKey: 'MediaItem.Artist',
        width: 1
    },
    {
        label: 'Album',
        dataKey: 'MediaItem.Album',
        width: 1
    },
    {
        label: 'Duration',
        dataKey: 'MediaItem.Duration',
        width: 0.8,
        labelFormatter: (label) => {
            return formatDurationDHM(label);
        },
        style: { textAlign: 'center' }
    },
    {
        label: 'Added',
        dataKey: 'MediaItem.DateAdded',
        width: 1,
        labelFormatter: (label) => {
            return moment(label).format(formatDateTimeFull);
        },
        style: { textAlign: 'center' }
    }
];

const initTableHistorySettings: TableSettings = {
    mediaItemMediaTypeColor: 'Display 1'
};
const initTableLibrarySettings: TableSettings = {
    mediaItemMediaTypeColor: 'None'
};
const initTablePlaylistSettings: TableSettings = {
    mediaItemMediaTypeColor: 'None'
};
const initTableQueueSettings: TableSettings = {
    mediaItemMediaTypeColor: 'None'
};

export const initHistoryColsEnabled: Array<keyof HistoryItemDto> = [
    'DatePlayed',
    'Title',
    'Artist',
    'Duration',
    'Requested',
    'Performances'
];
export const initLibraryColsEnabled: Array<keyof LibraryItemDto> = ['Title', 'Artist', 'Album', 'Duration'];
export const initPlaylistColsEnabled: Array<keyof never> = [
    'MediaItem.Title',
    'MediaItem.Artist',
    'MediaItem.Album',
    'MediaItem.Duration'
];
export const initQueueColsEnabled: Array<keyof never> = [
    'MediaItem.DateAdded',
    'MediaItem.Title',
    'MediaItem.Artist',
    'MediaItem.Duration',
    'Requested'
];

export const libTreeData: LibraryTreeData = {
    libraryTree: {
        icon: VideoLibraryIcon,
        menuItems: [],
        title: Lang.tLibraryTree,
        treeEntity: 'library-tree'
    },
    playlist: {
        icon: SubscriptionsIcon,
        menuItems: [],
        title: Lang.tPlaylists,
        treeEntity: 'playlist'
    }
};

export const libComponentData: LibraryTableData = {
    history: {
        cols: historyCols as unknown as Array<ITblCol<TblColType>>,
        icon: HistoryIcon,
        initColsEnabled: initHistoryColsEnabled as Array<keyof TblColType>,
        initTableSettings: initTableHistorySettings,
        menuItems: historyMenuItems,
        movable: ['PlaylistItem', 'QueueItem', 'Playlist', 'MediaItem'],
        tableEntity: 'HistoryItem',
        tableStorageKey: LocalStorageType.TABLE_HISTORY,
        tableSettingsStorageKey: LocalStorageType.TABLE_HISTORY_SETTINGS,
        title: Lang.tHistory,
        footerLabel: (request: DynamicTableRequest) => {
            if (request.range?.total) {
                return request.range.total >= 0 ? `${request.range.total} items` : 'Loading';
            } else {
                return 'No Items';
            }
        }
    },
    library: {
        cols: libraryCols as unknown as Array<ITblCol<TblColType>>,
        icon: VideoLibraryIcon,
        initColsEnabled: initLibraryColsEnabled as Array<keyof TblColType>,
        initTableSettings: initTableLibrarySettings,
        menuItems: libraryMenuItems,
        // Note, Dragging to PlaylistItem from LibraryItem is not possible in theory because only one can be chosen, but in the future this might change:
        movable: ['PlaylistItem', 'QueueItem', 'Playlist', 'MediaItem'],
        tableEntity: 'LibraryItem',
        tableStorageKey: LocalStorageType.TABLE_LIBRARY,
        tableSettingsStorageKey: LocalStorageType.TABLE_LIBRARY_SETTINGS,
        title: Lang.tLibrary,
        headerLabel: (_tableData: LibTblData<TblColType>, resolvedNode?: ResolvedTreeNode) => {
            return resolvedNode?.filterDisplayName ?? Lang.tLibrary;
        },
        footerLabel: (request: DynamicTableRequest) => {
            if (request.range?.total) {
                return request.range.total >= 0 ? `${request.range.total} tracks` : 'Loading';
            } else {
                return 'No Tracks';
            }
        },
        footerSubLabel: (request: DynamicTableRequest) => {
            return request.range?.totalDuration && `(${request.range?.totalDuration})`;
        }
    },
    playlist: {
        cols: playlistCols as unknown as Array<ITblCol<TblColType>>,
        icon: SubscriptionsIcon,
        initColsEnabled: initPlaylistColsEnabled as Array<keyof TblColType>,
        initTableSettings: initTablePlaylistSettings,
        menuItems: playlistMenuItems,
        movable: ['PlaylistItem', 'QueueItem', 'Playlist', 'MediaItem'],
        tableEntity: 'PlaylistItem',
        tableStorageKey: LocalStorageType.TABLE_PLAYLIST,
        tableSettingsStorageKey: LocalStorageType.TABLE_PLAYLIST_SETTINGS,
        title: Lang.tPlaylist,
        headerLabel: (_tableData: LibTblData<TblColType>, resolvedNode?: ResolvedTreeNode) => {
            return resolvedNode?.filterDisplayName ?? Lang.tPlaylist;
        },
        footerLabel: (request: DynamicTableRequest) => {
            return request.range?.total ? `${request.range?.total} tracks` : undefined;
        },
        footerSubLabel: (request: DynamicTableRequest) => {
            return request.range?.totalDuration && `(${request.range?.totalDuration})`;
        }
    },
    queue: {
        cols: queueCols as unknown as Array<ITblCol<TblColType>>,
        icon: QueueMusicIcon,
        initColsEnabled: initQueueColsEnabled as Array<keyof TblColType>,
        initTableSettings: initTableQueueSettings,
        menuItems: queueMenuItems,
        movable: ['PlaylistItem', 'QueueItem', 'Playlist', 'MediaItem'],
        tableEntity: 'QueueItem',
        tableStorageKey: LocalStorageType.TABLE_QUEUE,
        tableSettingsStorageKey: LocalStorageType.TABLE_QUEUE_SETTINGS,
        title: Lang.tQueue,
        footerLabel: (request: DynamicTableRequest, listData?: TblColType[]) => {
            if (request.range || listData) {
                const rangeTotal = request.range?.total ? request.range.total : 0;
                const listTotal = listData?.length ? listData.length : 0;
                return `${rangeTotal > listTotal ? rangeTotal : listTotal} Tracks`;
            } else {
                return 'No Tracks';
            }
        },
        footerSubLabel: (request: DynamicTableRequest, listData?: TblColType[]) => {
            if (listData) {
                const items = listData.filter((item) => itemIsPlaceholderItem(item));
                if (items?.length > 0) {
                    return `(${items?.length} loading)`;
                }
            }
            return request.range?.totalDuration && `(${request.range?.totalDuration})`;
        }
    }
};

export const libSelectMenuItems: Array<MenuItemData> = [
    {
        action: libComponentData.history.tableEntity,
        title: libComponentData.history.title,
        icon: libComponentData.history.icon
    },
    {
        action: 'DisplayableTable', // Varies between 'LibraryItem' and 'PlaylistItem', whichever is selected at that time.
        title: Lang.tUserSelected,
        icon: CheckCircleIcon
    },
    { action: libComponentData.queue.tableEntity, title: libComponentData.queue.title, icon: libComponentData.queue.icon }
];
