import React, { FC } from 'react';
import { LoadingBtnProps } from '../models/props';
import { useKeyPress } from '../utils/key-press-handler';
import { Box, CircularProgress, Collapse } from './mui';
import { Btn } from './styled-components';

const timeout = 400;
export const LoadingBtn: FC<LoadingBtnProps> = ({
    buttonProps,
    children,
    circularProgressProps,
    keyShortcuts = [],
    loading,
    sx,
    shortcutEvent
}) => {
    const loadingProp = loading && { disabled: true };

    // Invoke shortcut if keyShortcuts & shortcutEvent is defined.
    useKeyPress(
        () => {
            if (shortcutEvent) {
                shortcutEvent();
            }
        },
        keyShortcuts && shortcutEvent ? true : false,
        keyShortcuts
    );

    return (
        <Box sx={{ m: 1, position: 'relative', ...sx }}>
            <Btn {...buttonProps} {...loadingProp}>
                <Collapse in={!loading} orientation="horizontal" timeout={timeout}>
                    <div style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>{children}</div>
                </Collapse>
                <Collapse in={loading} orientation="horizontal" timeout={timeout} sx={{ height: '24px' }}>
                    <CircularProgress size={24} color={buttonProps.color} {...circularProgressProps} />
                </Collapse>
            </Btn>
        </Box>
    );
};

export default LoadingBtn;
