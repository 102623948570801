import moment from 'moment';
import { MediaItemDto } from '../models/dto';
import { formatDateTimeFull } from '../models/time';

export function timeToMilliseconds(time) {
    if (time.split('.').length > 1 && time.split('.')[1].length > 3) {
        time = time.substring(0, time.length - time.split('.')[1].length + 3);
    }
    let b;
    return (
        (time = time.split('.')),
        (b = time[1] * 1 || 0),
        (time = time[0].split(':')),
        b + (time[2] ? time[0] * 3600 + time[1] * 60 + time[2] * 1 : time[1] ? time[0] * 60 + time[1] * 1 : time[0] * 1) * 1e3
    );
}

export function millisecondsToTime(duration) {
    const durationDisplay = '';
    if (duration != null) {
        let seconds = Math.floor(parseInt(duration) / 1000);
        let days = 0,
            hours = 0,
            minutes = 0;

        while (seconds > 3599) {
            hours++;
            seconds = seconds - 3600;
        }

        while (hours > 23) {
            days++;
            hours = hours - 24;
        }

        while (seconds > 59) {
            minutes++;
            seconds = seconds - 60;
        }

        return (
            (days > 0 ? days + 'd, ' : '') +
            (hours > 0 ? (hours <= 9 ? '0' + hours : hours) + ':' : '') +
            (minutes <= 9 ? '0' + minutes : minutes) +
            ':' +
            (seconds <= 9 ? '0' + seconds : seconds)
        );
    }
    return durationDisplay;
}

/**
 * FROM SAM Cloud `TDPlayerControls.FormatTime`:
 * @returns e.g. "01:22"
 */
export function formatTimeMMSS(duration: number) {
    let hours = 0;
    let minutes = 0;
    let seconds = Math.round(duration / 1000);

    while (seconds > 3599) {
        hours++;
        seconds = seconds - 3600;
    }

    while (seconds > 59) {
        minutes++;
        seconds = seconds - 60;
    }

    const displayDuration =
        (hours > 0 ? (hours <= 9 ? '0' + hours : hours) + ':' : '') +
        (minutes <= 9 ? '0' + minutes : minutes) +
        ':' +
        (seconds <= 9 ? '0' + seconds : seconds);
    return displayDuration;
}

/**
 * From SAM Cloud TDPlayerControls.TimeToMilliseconds
 * @returns e.g. 1000
 */
export function timeToMillisecondsPlayer(time) {
    const timeArray = time.split(':'); // split it at the colons
    let hours = 0;
    let minutes = 0;
    let seconds = 0;

    if (timeArray.length < 1) {
        seconds = +timeArray[0];
    } else if (timeArray.length > 2) {
        hours = +timeArray[0];
        minutes = +timeArray[1];
        seconds = +timeArray[2];
    } else {
        minutes = +timeArray[0];
        seconds = +timeArray[1];
    }

    return (hours * 60 * 60 + minutes * 60 + seconds) * 1000;
}

export function formatDurationHM(value: string) {
    let result = '';
    if (value !== null) {
        const r = /^(-)?(\d*)\.?(\d{2}):(\d{2}):(\d{2}(\.\d{1,7})?)$/.exec(value);

        if (r !== null && r.length > 0) {
            const hours = r[3],
                minutes = r[4];
            let seconds = r[5];
            if (seconds.indexOf('.') > -1) {
                seconds = seconds.substring(0, seconds.indexOf('.'));
            }
            if (parseInt(hours) > 0) {
                result = parseInt(hours) + ':' + minutes + ':' + seconds;
            } else {
                result = minutes + ':' + seconds;
            }
        }
    }

    return result;
}

export function formatDurationDHM(duration) {
    if (duration != null) {
        const r = /^(-)?(\d*)\.?(\d{2}):(\d{2}):(\d{2}(\.\d{1,7})?)$/.exec(duration) ?? '';
        if (r.length > 0) {
            const days = r[2],
                minutes = r[4];
            let hours = r[3],
                seconds = r[5];
            if (seconds.indexOf('.') > -1) {
                seconds = seconds.substring(0, seconds.indexOf('.'));
            }
            if (parseInt(hours) > 0 || parseInt(days) > 0) {
                if (parseInt(days) > 0) {
                    hours = (parseInt(hours) + parseInt(days) * 24).toString();
                }
                return `${hours}:${minutes}:${seconds}`;
            } else {
                return `${minutes}:${seconds}`;
            }
        }
    }
    return '';
}

export function isValidIntroTrack(selectedMediaItem: MediaItemDto) {
    const millDuration = timeToMilliseconds(formatDurationHM(selectedMediaItem.Duration));
    if ((selectedMediaItem.MediaStatus != null && selectedMediaItem.MediaStatus != 200) || millDuration > 60 * 1000) {
        return false;
    }
    return true;
}

export function createTimeSpanString(duration: moment.Duration): string {
    return `${duration.get('hours')}:${duration.get('minutes')}:${duration.get('seconds')}`;
}

export function getMinutesBetweenDates(start: Date, end: Date) {
    const duration = moment.duration(moment(end).diff(moment(start)));
    return duration.asMinutes();
}

export function getTimeRange(startDate: Date, endDate: Date) {
    const start = moment(startDate);
    const end = moment(endDate);
    return `${start.format('HH:mm')} - ${end.format('HH:mm')}`;
}

export function getFormattedTimestamp(utcSeconds) {
    const formattedDate = moment(utcSeconds * 1000).format(formatDateTimeFull);
    return formattedDate;
}

export function getConnectionDuration(utcSeconds) {
    const difference = moment().diff(moment(utcSeconds * 1000));
    const duration = moment.duration(difference);
    if (duration.asDays() >= 1) {
        return moment.utc(duration.asMilliseconds()).format('D[d] H[h] m[m] s[s]');
    } else {
        return moment.utc(duration.asMilliseconds()).format('H[h] m[m] s[s]');
    }
}

/**
 * Get time for now (used in player time).
 */
export function getNow() {
    return new Date().getTime();
}

/**
 * @param seconds
 * @returns Duration in the format 'hh:mm:ss'
 */
export function secondsToHMS(seconds: number) {
    const duration = moment.duration(seconds, 'seconds');
    const hours = duration.hours();
    const minutes = duration.minutes();
    const secondsRemaining = duration.seconds();
    const minSecDuration = `${minutes.toString().padStart(2, '0')}:${secondsRemaining.toString().padStart(2, '0')}`;
    return hours ? `${hours}:${minSecDuration}` : minSecDuration;
}

/**
 *
 * @param seconds
 * @returns  Duration in the format '** days, hh:mm:ss'
 */
export function secondsToDHMS(seconds: number) {
    const days = Math.floor(seconds / (24 * 60 * 60));
    const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((seconds % (60 * 60)) / 60);
    const remainingSeconds = seconds % 60;
    return `${days} days, ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
}

/**
 * Dates from the API don't always come in correctly, using the legacy SAM Cloud, everywhere a 'Z' was added, this should be added.
 * For some or other reason, 'Z' doesn't come from API, append it yourself.
 * This is how the old SAM Cloud did it:
 */
export function attachZ(datePlayed: string): string {
    if (datePlayed.indexOf('Z') == -1) {
        datePlayed += 'Z';
    }
    return datePlayed;
}
