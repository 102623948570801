import React, { DragEvent, FC, useState } from 'react';
import { useTreeView } from '..';
import DialogAddToPlaylist from '../../../../components/dialog-add-to-playlist';
import DialogClearHistory from '../../../../components/dialog-clear-history';
import DialogConfirmation from '../../../../components/dialog-confirmation';
import DialogCreateGptMediaItemInput from '../../../../components/dialog-create-gpt-media-item';
import DialogCreatePlaylistInput from '../../../../components/dialog-create-playlist';
import DialogEditMediaProvider from '../../../../components/dialog-edit-media-item';
import DialogExportHistory from '../../../../components/dialog-export-history';
import DialogImportPlaylist from '../../../../components/dialog-import-playlist';
import { DraggableBox } from '../../../../components/draggable-box';
import DynamicTable from '../../../../components/dynamic-table';
import { Stack, useMediaQuery, useTheme } from '../../../../components/mui';
import { LibraryTreeNode, PlaylistResponseDto } from '../../../../models/dto';
import { BaseStationProps } from '../../../../models/props';
import DragProvider from '../../../../providers/drag';
import { TableEntity } from '../../../../utils/signalr/models';
import { maxWidthHeight } from '../../../../utils/style';
import TreeContainer from '../library-tree';
import { ResizingData, TableDefinition, defaultTDMainSize, defaultTDSize } from '../models/interfaces';
import {
    getAllContainerProps,
    getPlaylistTypes,
    getPrevCompSize,
    getTotalResizableSize,
    getUniqueTblKey,
    shouldDisplayTableContainer
} from '../utils';
import DragDisplay from './drag-display';
import LibraryControls from './library-controls';

const colSpacing = 0.5;
const maxTblSizeRatio = 0.9;
const minTblSizeRatio = 0.1;

export const LibraryLayout: FC<BaseStationProps> = ({ stationId }) => {
    const theme = useTheme();
    const {
        libLayout,
        sharedDialogsState,
        playlistTree,
        treeViewData,
        setLibLayoutState,
        setLibSwapLayoutState,
        setSharedDialogs,
        setPlaylistTree
    } = useTreeView();
    const sm = useMediaQuery(theme.breakpoints.up('md'));
    const [resizingData, setResizingData] = useState<ResizingData>();

    const showTreeContainer = libLayout.treeComponents.findIndex((x) => x) >= 0 ? true : false;

    const handleTableSwap = (swapTable1: TableEntity, swapTable2: TableEntity) => {
        const tableComponents1 = libLayout.tableComponents.find((x) => x !== null && x.tableEntity === swapTable1);
        const tableComponents2 = libLayout.tableComponents.find((x) => x !== null && x.tableEntity === swapTable2);

        if (tableComponents1 && tableComponents2) {
            setLibSwapLayoutState(tableComponents1, tableComponents2);
        }
    };

    const handleCreatePlaylist = async (playlistType: LibraryTreeNode, playlistName: string) => {
        const res = (await setPlaylistTree(playlistType, 'new', playlistName)) as PlaylistResponseDto;
        // Initiate Intent Callback:
        sharedDialogsState.createPlaylist && sharedDialogsState.createPlaylist(res);
        return res;
    };

    const renderResizeBoxInternalControl = (index1: number, renderColumn: boolean) => {
        const dragProps = {
            renderColumn,
            onDragStart: () => setResizingData({ index1: index1, index2: index1 }), // Only the first index will be used.
            onDragEnd: () => setResizingData(undefined),
            onDoubleClick: () => {
                const tableDef1 = libLayout.tableComponents[index1] as TableDefinition;
                tableDef1.size = defaultTDMainSize;
                setLibLayoutState(tableDef1, true);
            }
        };
        return <DraggableBox {...dragProps} />;
    };

    const renderGenericResizeBox = (index1: number, index2: number, renderColumn: boolean) => {
        const dragProps = {
            renderColumn,
            onDragStart: () => setResizingData({ index1, index2 }),
            onDragEnd: () => setResizingData(undefined),
            onDoubleClick: () => {
                const tableDef1 = libLayout.tableComponents[index1] as TableDefinition;
                const tableDef2 = libLayout.tableComponents[index2] as TableDefinition;
                tableDef1.size = tableDef2.size = defaultTDSize;
                setLibLayoutState(tableDef1, true);
                setLibLayoutState(tableDef2, true);
            }
        };
        return <DraggableBox {...dragProps} />;
    };

    const renderDynamicTable = (tableIndex: number, tableDefIndexesPerStack: number[]) => {
        const tableDef = libLayout.tableComponents[tableIndex];
        // Note, if the key changes, the entire dynamic table will rerender which is what we want:
        const tableKey = getUniqueTblKey(tableIndex, treeViewData, tableDef);
        return (
            tableDef && (
                <DynamicTable
                    key={tableKey}
                    stationId={stationId}
                    tableDef={tableDef}
                    onTableSwap={handleTableSwap}
                    tableDefIndexesPerStack={tableDefIndexesPerStack}
                />
            )
        );
    };

    const renderTree = (treeIndex: number) => {
        const treeDef = libLayout.treeComponents[treeIndex];
        // Note, if the key changes, the entire dynamic table will rerender which is what we want:
        const treeKey = `tree-${treeIndex}-${treeDef?.treeEntity}`;
        return treeDef ? <TreeContainer key={treeKey} stationId={stationId} treeDef={treeDef} dialogDisplay={false} /> : null;
    };

    const onDragOverResize = (e: DragEvent<HTMLDivElement>, tableDefIndexesPerStack?: number[]) => {
        if (resizingData) {
            if (e?.currentTarget && tableDefIndexesPerStack && resizingData && resizingData.index1 !== resizingData.index2) {
                const renderColumn = libLayout.layoutGridType !== 'cols';
                // This only goes 2 items back, if you want to add more items, make this dynamic:
                const compPrev = getPrevCompSize(libLayout, resizingData.index1 - 1, tableDefIndexesPerStack);
                const compSize1 = libLayout.tableComponents[resizingData.index1]?.size ?? 0;

                const parentRect = e.currentTarget.getBoundingClientRect();
                const { height, width, top, left } = parentRect;
                const mouseRelativeToParent = renderColumn ? e.clientY - top : e.clientX - left;
                const newSizeRatio = mouseRelativeToParent / (renderColumn ? height : width) - compPrev;

                if (newSizeRatio !== compSize1) {
                    const ultimateNewSize1 =
                        newSizeRatio > maxTblSizeRatio
                            ? maxTblSizeRatio
                            : newSizeRatio < minTblSizeRatio
                              ? minTblSizeRatio
                              : newSizeRatio;

                    const tableDef1 = libLayout.tableComponents[resizingData.index1] as TableDefinition;
                    const prevTotal = getTotalResizableSize(libLayout, tableDefIndexesPerStack);
                    tableDef1.size = ultimateNewSize1 * prevTotal;

                    setLibLayoutState(tableDef1, true);

                    const tableDef2 = libLayout.tableComponents[resizingData.index2] as TableDefinition;
                    // Take out the second one to calculate it's new total since tableDef1 changed:
                    const allExcept2 = [...tableDefIndexesPerStack];
                    allExcept2.splice(allExcept2.indexOf(tableDef2.tableIndex), 1);
                    tableDef2.size = prevTotal - getTotalResizableSize(libLayout, allExcept2);
                    setLibLayoutState(tableDef2, true);

                    // Note, stopping propagation is intended because there's 2 onDragOverResize events from 2 different stacks that can fire:
                    e.stopPropagation();
                    e.preventDefault();
                }
            } else {
                const renderColumn = false;
                const compSize1 = libLayout.tableComponents[resizingData.index1]?.size ?? 0;

                const parentRect = e.currentTarget.getBoundingClientRect();
                const { height, width, top, left } = parentRect;
                const mouseRelativeToParent = renderColumn ? e.clientY - top : e.clientX - left;
                const newSizeRatio = mouseRelativeToParent / (renderColumn ? height : width);

                if (newSizeRatio !== compSize1) {
                    const ultimateNewSize1 =
                        newSizeRatio > maxTblSizeRatio
                            ? maxTblSizeRatio
                            : newSizeRatio < minTblSizeRatio
                              ? minTblSizeRatio
                              : newSizeRatio;

                    const tableDef1 = libLayout.tableComponents[resizingData.index1] as TableDefinition;
                    tableDef1.size = ultimateNewSize1 * 1;

                    setLibLayoutState(tableDef1, true);

                    // Note, stopping propagation is intended because there's 2 onDragOverResize events from 2 different stacks that can fire:
                    e.stopPropagation();
                    e.preventDefault();
                }
            }
        }
    };

    const renderLibLayout = () => {
        let dynamicTables;
        const {
            dragger0,
            dragger0_1,
            dragger1_2,
            dragger0_2,
            showContainer1,
            showContainer2,
            dragger0Dragging,
            dragger1_2Dragging
        } = getAllContainerProps(libLayout, resizingData);
        if (libLayout.layoutGridType === 'grid') {
            const dragger0Props = !dragger1_2Dragging && libLayout.resizable && { onDragOver: (e) => onDragOverResize(e) };
            const dragger1_2Props = !dragger0Dragging &&
                libLayout.resizable && { onDragOver: (e) => onDragOverResize(e, [1, 2]) };
            const leftContainerSize = showContainer2
                ? dragger0
                    ? libLayout.tableComponents[0]?.size ?? 0
                    : defaultTDMainSize
                : 1;
            dynamicTables = (
                <Stack flex={8} direction="row" spacing={colSpacing} {...dragger0Props}>
                    {showContainer1 && (
                        <Stack direction="column" sx={{ flex: leftContainerSize }} spacing={colSpacing}>
                            {renderDynamicTable(0, [0])}
                        </Stack>
                    )}
                    {dragger0 && renderResizeBoxInternalControl(0, false)}
                    {showContainer2 && (
                        <Stack
                            direction="column"
                            sx={{ flex: showContainer1 ? 1 - leftContainerSize : 1 }}
                            spacing={colSpacing}
                            {...dragger1_2Props}
                        >
                            {renderDynamicTable(1, [1, 2])}
                            {dragger1_2 && renderGenericResizeBox(1, 2, true)}
                            {renderDynamicTable(2, [1, 2])}
                        </Stack>
                    )}
                </Stack>
            );
        } else {
            const renderColumn = libLayout.layoutGridType !== 'cols';
            const tableDefIndexesPerStack = [0, 1, 2];
            const dragOverProps = libLayout.resizable && { onDragOver: (e) => onDragOverResize(e, tableDefIndexesPerStack) };
            dynamicTables = shouldDisplayTableContainer(libLayout, tableDefIndexesPerStack) && (
                <Stack direction={renderColumn ? 'column' : 'row'} sx={{ flex: 8 }} spacing={colSpacing} {...dragOverProps}>
                    {renderDynamicTable(0, tableDefIndexesPerStack)}
                    {dragger0_1 && renderGenericResizeBox(0, 1, renderColumn)}
                    {renderDynamicTable(1, tableDefIndexesPerStack)}
                    {dragger1_2 && renderGenericResizeBox(1, 2, renderColumn)}
                    {dragger0_2 && renderGenericResizeBox(0, 2, renderColumn)}
                    {renderDynamicTable(2, tableDefIndexesPerStack)}
                </Stack>
            );
        }
        return (
            <DragProvider>
                <>
                    <Stack direction="row" sx={{ flex: 1 }} spacing={colSpacing}>
                        {sm && showTreeContainer && (
                            <Stack direction="column" sx={{ flex: 2, maxWidth: '260px' }} spacing={colSpacing}>
                                {renderTree(0)}
                                {renderTree(1)}
                            </Stack>
                        )}
                        <Stack spacing={1} sx={{ flex: 1 }}>
                            <LibraryControls stationId={stationId} />
                            {dynamicTables}
                        </Stack>
                    </Stack>
                    <DragDisplay></DragDisplay>
                </>
            </DragProvider>
        );
    };

    const renderSharedDialogs = () => {
        const {
            addToPlaylistMediaItems,
            clearHistory,
            createGptMediaItem,
            createPlaylist,
            confirmation,
            editMediaItems,
            exportHistory,
            importPlaylist
        } = sharedDialogsState;
        return [
            <DialogAddToPlaylist
                key={DialogAddToPlaylist.name}
                stationId={stationId}
                open={!!addToPlaylistMediaItems}
                playlistTree={playlistTree as LibraryTreeNode}
                onOpenCreatePlaylist={(onCreate, open) =>
                    setSharedDialogs({
                        createPlaylist: open ? onCreate : false
                    })
                }
                addToPlaylistMediaItems={sharedDialogsState.addToPlaylistMediaItems as string[]}
                onClose={() => setSharedDialogs({ addToPlaylistMediaItems: false })}
            />,
            <DialogCreateGptMediaItemInput
                key={DialogCreateGptMediaItemInput.name}
                stationId={stationId}
                open={!!createGptMediaItem}
                onClose={() => setSharedDialogs({ createGptMediaItem: false })}
            ></DialogCreateGptMediaItemInput>,
            <DialogCreatePlaylistInput
                key={DialogCreatePlaylistInput.name}
                stationId={stationId}
                open={!!createPlaylist}
                onClose={() => setSharedDialogs({ createPlaylist: false })}
                playlistTypes={getPlaylistTypes([], playlistTree)}
                onCreatePlaylist={handleCreatePlaylist}
            ></DialogCreatePlaylistInput>,
            <DialogEditMediaProvider
                key={DialogEditMediaProvider.name}
                stationId={stationId}
                open={!!editMediaItems}
                onClose={() => setSharedDialogs({ editMediaItems: false })}
            ></DialogEditMediaProvider>,
            <DialogExportHistory
                key={DialogExportHistory.name}
                stationId={stationId}
                open={exportHistory}
                onClose={() => setSharedDialogs({ exportHistory: false })}
            ></DialogExportHistory>,
            <DialogClearHistory
                key={DialogClearHistory.name}
                stationId={stationId}
                open={clearHistory}
                onClose={() => setSharedDialogs({ clearHistory: false })}
            ></DialogClearHistory>,
            <DialogConfirmation
                key={DialogConfirmation.name}
                dialogTitle={confirmation ? confirmation.title : ''}
                open={!!confirmation}
                onNegativeEvent={() => setSharedDialogs({ confirmation: false })}
                onPositiveEvent={async () => {
                    if (confirmation) {
                        await confirmation.positiveCallback();
                    }
                    setSharedDialogs({ confirmation: false });
                }}
                theme={theme}
            >
                {confirmation && confirmation?.description}
            </DialogConfirmation>,
            <DialogImportPlaylist
                key={DialogImportPlaylist.name}
                stationId={stationId}
                open={!!importPlaylist}
                playlistId={importPlaylist.toString()}
                onClose={() => setSharedDialogs({ importPlaylist: false })}
            ></DialogImportPlaylist>
        ];
    };

    return (
        <Stack direction="row" sx={maxWidthHeight} spacing={colSpacing}>
            <DragProvider>
                <>
                    {renderLibLayout()}
                    {renderSharedDialogs()}
                    <DragDisplay></DragDisplay>
                </>
            </DragProvider>
        </Stack>
    );
};

export default LibraryLayout;
