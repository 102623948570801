import React, { CSSProperties, FC, useState } from 'react';
import { H6, StyledImage, allowCustomProps } from '../components/styled-components';
import { PictureUploadDefaults, zIndex4 } from '../models/consts';
import { PictureUploadProps } from '../models/props';
import { Notification, useNotification } from '../providers/notifications';
import { getMaxBorderRadius } from '../utils/style';
import BtnIconTooltip from './btn-icon-tooltip';
import CenteredCircularLoader from './centered-circular-loader';
import DialogCustomComponent from './dialog-custom-component';
import { Box, CancelIcon, Stack, alpha, styled, useTheme } from './mui';

const StyledLabel = styled('label')(({ theme }) => ({
    background: alpha(theme.palette.primary.main, 0.85),
    color: theme.palette.primary.contrastText,
    cursor: 'pointer',
    textAlign: 'center',
    opacity: 0,
    height: 30,
    [`&:hover`]: { background: theme.palette.action.active }
}));

const StyledUploadContainer = styled(
    Stack,
    allowCustomProps(['width', 'height'])
)<{ width: number; height: number }>(({ width, height, theme }) => ({
    overflow: 'hidden',
    position: 'relative',
    textAlign: 'center',
    height: height,
    width: width,
    [`&:hover`]: {
        opacity: 0.85,
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: `${getMaxBorderRadius(theme)}px`,
        [`& .deleteBox `]: {
            opacity: 1
        },
        [`& .uploadLabel`]: {
            opacity: 1
        }
    }
}));

const PictureUpload: FC<PictureUploadProps> = ({
    image,
    uploadTitle,
    deleteTitle,
    height,
    width,
    loading,
    onChange,
    onDelete,
    onError,
    disabled,
    hideDeleteBtn
}) => {
    const [openImageDialog, setOpenImageDialog] = useState(false);
    const [dragActive, setDragActive] = useState(false);
    const { addNotification } = useNotification();
    const theme = useTheme();

    const getValidHeight = () => {
        return height < PictureUploadDefaults.minHeight
            ? PictureUploadDefaults.minHeight
            : height > PictureUploadDefaults.maxHeight
              ? PictureUploadDefaults.maxHeight
              : height;
    };

    const getValidWidth = () => {
        return width < PictureUploadDefaults.minWidth
            ? PictureUploadDefaults.minWidth
            : width > PictureUploadDefaults.maxWidth
              ? PictureUploadDefaults.maxWidth
              : width;
    };

    const validWidth = getValidWidth();
    const validHeight = getValidHeight();

    const handleChange = (image: File) => {
        if (image.size && image.size > PictureUploadDefaults.maxFileSizeMB) {
            const errorMessage = `Maximum file size of ${PictureUploadDefaults.maxFileSizeMB} MB allowed`;
            addNotification(
                new Notification({
                    message: errorMessage,
                    severity: 'error'
                })
            );
            onError && onError(errorMessage);
        } else {
            onChange(image);
            onError && onError(undefined);
        }
    };

    const handleUpload = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!e.target.files || !e.target.files[0]) {
            return;
        }
        handleChange(e.target.files[0]);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (!e.dataTransfer.files || !e.dataTransfer.files[0]) {
            return;
        }
        handleChange(e.dataTransfer.files[0]);
    };

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === 'dragenter' || e.type === 'dragover') {
            setDragActive(true);
        } else if (e.type === 'dragleave') {
            setDragActive(false);
        }
    };

    const containerSx = (): CSSProperties | undefined => {
        const borderSx: CSSProperties = {
            border: `1px solid ${theme.palette.primary.main}`,
            borderRadius: `${getMaxBorderRadius(theme)}px`
        };
        if (dragActive) {
            return { backgroundColor: theme.palette.primary.contrastText, ...borderSx, borderStyle: 'dashed solid' };
        }
        return borderSx;
    };

    return (
        <>
            <StyledUploadContainer
                alignItems="space-between"
                justifyContent="space-between"
                height={validHeight}
                width={validWidth}
                sx={containerSx()}
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}
            >
                {loading ? (
                    <CenteredCircularLoader />
                ) : (
                    <>
                        <Box
                            sx={{ alignSelf: 'flex-end', opacity: 0, zIndex: zIndex4, position: 'absolute' }}
                            className="deleteBox"
                        >
                            <BtnIconTooltip
                                displayMode="tooltip"
                                icon={<CancelIcon />}
                                iconButtonProps={{
                                    onClick: onDelete,
                                    disabled: disabled || hideDeleteBtn || !image,
                                    size: 'small',
                                    sx: {
                                        border: 'none'
                                    }
                                }}
                            >
                                {deleteTitle}
                            </BtnIconTooltip>
                        </Box>

                        {image && (
                            <StyledImage
                                style={{ cursor: 'zoom-in', width: '100%' }}
                                onClick={() => setOpenImageDialog(true)}
                                src={(() => {
                                    return typeof image === 'string' ? image : URL.createObjectURL(image);
                                })()}
                            />
                        )}
                        {!image && <H6 p={2}>No Image</H6>}

                        <StyledLabel
                            htmlFor="uploadBtn"
                            className="uploadLabel"
                            style={{ position: 'absolute', bottom: 0, width: '100%' }}
                        >
                            <input
                                id="uploadBtn"
                                disabled={disabled}
                                accept="image/*"
                                type="file"
                                onChange={(e) => handleUpload(e)}
                                style={{ display: 'none' }}
                            />
                            {uploadTitle}
                        </StyledLabel>
                    </>
                )}
            </StyledUploadContainer>
            {image && (
                <DialogCustomComponent
                    closable
                    draggable
                    isFullWidth={true}
                    maxWidth="sm"
                    open={openImageDialog}
                    onClose={() => setOpenImageDialog(false)}
                >
                    <Box
                        component="img"
                        alt="No image"
                        src={typeof image === 'string' ? image : URL.createObjectURL(image)}
                        sx={{ width: '100%', overflow: 'hidden' }}
                    />
                </DialogCustomComponent>
            )}
        </>
    );
};

export default PictureUpload;
