import { alpha, SxProps, Theme } from '../components/mui';
import { maxBorderRadius, scrollBarHeight, scrollBarWidth } from '../models/consts';
import { AnchorPosition } from '../models/interfaces';
import { getColorFromPalette } from './colors';

/**
 * The arrow of the tooltip sometimes shows a space between the tooltip and the arrow when border radius is too much, this prevents it.
 */
export const popperStyle: SxProps<Theme> = {
    '& .MuiTooltip-tooltipPlacementRight .MuiTooltip-arrow': {
        left: '1.4px'
    },
    '& .MuiTooltip-tooltipPlacementLeft .MuiTooltip-arrow': {
        right: '1.4px'
    }
};

export function getBorderRadiusFromAnchor(styleAttribute: string, theme: Theme, anchor: AnchorPosition = 'top') {
    const borderRadius = theme.shape.borderRadius;
    const zeroBorderRadius = '0px';
    return {
        [styleAttribute]: {
            borderTopLeftRadius: anchor === 'bottom' || anchor === 'right' ? borderRadius : zeroBorderRadius,
            borderTopRightRadius: anchor === 'bottom' || anchor === 'left' ? borderRadius : zeroBorderRadius,
            borderBottomLeftRadius: anchor === 'top' || anchor === 'right' ? borderRadius : zeroBorderRadius,
            borderBottomRightRadius: anchor === 'top' || anchor === 'left' ? borderRadius : zeroBorderRadius
        }
    };
}

export function getPositionStyleFromAnchor(anchorPosition: string): SxProps<Theme> {
    switch (anchorPosition) {
        case 'left':
            return { left: 0 };
        case 'right':
            return { right: 0 };
        case 'bottom':
            return { bottom: 0 };
        default:
            return { top: 0 };
    }
}

export const maxWidthHeight: SxProps<Theme> = { width: '100%', height: '100%' };
export const autoWidthHeight: SxProps<Theme> = { width: 'auto', height: 'auto' };

/**
 * Ads spacing at the bottom of the scollable table, don't add it if the footer is disabled.
 * @param theme Theme is needed, can't go without this.
 */
export const tableBottomScrollSpace = (theme, bottomMargin = true) => {
    const bottomMarginProps = bottomMargin && {
        '.ReactVirtualized__Grid__innerScrollContainer': { mb: `calc(26px + ${theme.spacing(3)})` }
    };
    return {
        ...bottomMarginProps,
        // Note, there's a bug where overflow is not scroll when it should be, for that reason, use !important:
        '.ReactVirtualized__Table__Grid': { overflow: 'hidden scroll !important' }
    };
};

export function getGlobalScrollStyle(theme) {
    const alphaColor = 0.15;

    const { main } = getColorFromPalette('primary', theme.palette);
    return {
        ['&::-webkit-scrollbar']: {
            width: scrollBarWidth,
            height: scrollBarHeight
        },
        ['&::-webkit-scrollbar-track']: {
            background: alpha(main, alphaColor),
            borderRadius: theme.shape.borderRadius
        },
        ['&::-webkit-scrollbar-thumb']: {
            background: main,
            borderRadius: theme.shape.borderRadius
        }
    };
}

/**
 * Used to limit the border radius of some components.
 * Sometimes a circle is too round to be a square, so limit it if there is use to sides.
 * Should append unit onto it otherwise it would be too much e.g.: `${getMaxBorderRadius(theme)}px`
 */
export function getMaxBorderRadius(theme: Theme) {
    return theme.shape.borderRadius > maxBorderRadius ? maxBorderRadius : theme.shape.borderRadius;
}

/**
 * Viritualized table default styling
 */

export function getVirtualizedTableStyle(theme: Theme, interactable = true, bottomMargin = true) {
    const cursorProps = interactable && { cursor: 'pointer' };
    return {
        ...maxWidthHeight,
        ...tableBottomScrollSpace(theme, bottomMargin),
        background: theme.palette.background.default,
        '.ReactVirtualized__Table__row': {
            ...cursorProps,
            transition: theme.transitions.create(['background'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.short
            }),
            '.ReactVirtualized__Table__rowColumn': {
                ml: '2px',
                mr: '2px'
            }
        },
        '.ReactVirtualized__Table__headerColumn': {
            '&:first-of-type': {
                ml: '2px'
            }
        }
    };
}

/**
 * Column width for the checked items. Special case.
 */
export const checkedColumnWidth = 58;
