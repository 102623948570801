import moment from 'moment-timezone';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import CountDownTimer from '../../components/count-down-timer';
import { Alert, CancelIcon, Chip, CloseIcon, IconButton, Stack } from '../../components/mui';
import { Caption, RecordingIcon } from '../../components/styled-components';
import { useAccount } from '../../providers/account';
import useLocalStorage, { LocalStorageType } from '../../utils/local-storage';
import { useStationId } from '../../utils/router-util';

const ScheduledMaintenanceAlert: FC = () => {
    const [maintenanceAlertEnabled, setMaintenanceAlertEnabled] = useState(true);
    const [isMaintenanceDue, setIsMaintenanceDue] = useState(false);
    const [ignoreMaintenanceMode, setIgnoreMaintenanceMode] = useLocalStorage<boolean | null>(
        LocalStorageType.IGNORE_MAINTENANCE_MODE,
        null
    );

    const location = useLocation();
    const account = useAccount();
    const stationId = useStationId(location);

    useEffect(() => {
        setMaintenanceAlertEnabled(true);
    }, [stationId]);

    const getMaintenanceDetails = (): { localDate: string; countDownToMaintenance: number } | undefined => {
        const { isDue, date } = account.accountState.maintenanceMode;
        setIsMaintenanceDue(isDue);
        if (date) {
            if (ignoreMaintenanceMode === null) {
                setIgnoreMaintenanceMode(false);
            }
            const utcMaintenanceDate = moment.tz(date, 'UTC');
            return {
                localDate: utcMaintenanceDate.local().format('dddd, MMMM Do, h:mm:ss'),
                countDownToMaintenance: Math.round(moment.duration(utcMaintenanceDate.diff(moment.utc())).asSeconds())
            };
        }
    };

    const onMaintenaceInProgess = async () => {
        setIsMaintenanceDue(true);
        await account.logout();
    };

    const maintenanceDetails = useMemo(
        () => getMaintenanceDetails(),
        [account.accountState.maintenanceMode.date, account.accountState.maintenanceMode.isDue]
    );

    const renderMaintenanceAlert = () => {
        if (!maintenanceDetails) {
            return <></>;
        }

        if (isMaintenanceDue || maintenanceDetails.countDownToMaintenance <= 0) {
            return (
                <Alert severity="error" sx={{ pt: 0, pb: 0 }}>
                    <Caption>SCHEDULED MAINTENANCE IN PROGRESS!!</Caption>
                </Alert>
            );
        }

        if (maintenanceDetails.countDownToMaintenance > 0) {
            if (ignoreMaintenanceMode || !maintenanceAlertEnabled) {
                return (
                    <Alert severity="info" sx={{ pt: 0, pb: 0 }}>
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Caption>Scheduled maintenace in</Caption>
                            <CountDownTimer
                                includeDays={true}
                                duration={maintenanceDetails.countDownToMaintenance}
                                start={maintenanceDetails.countDownToMaintenance > 0}
                                onComplete={onMaintenaceInProgess}
                                sx={{ fontSize: 15, fontWeight: 'bold', mt: 0, pt: 0 }}
                            />
                            <RecordingIcon color="info" />
                        </Stack>
                    </Alert>
                );
            }

            if (maintenanceAlertEnabled) {
                return (
                    <Alert
                        severity="info"
                        sx={{ pt: 0, pb: 0 }}
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => setMaintenanceAlertEnabled(false)}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                    >
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 0, sm: 2 }} justifyContent="space-between">
                            <Caption>
                                The {account.accountState.brand?.ProductName ?? 'SAMCLOUD'} user interface will be unavailable for
                                a short period of time due to maintenance starting on {maintenanceDetails.localDate}.
                            </Caption>
                            <Chip
                                icon={<CancelIcon fontSize="small" />}
                                variant="outlined"
                                color={maintenanceDetails.countDownToMaintenance <= 0 ? 'error' : 'info'}
                                label={<Caption>Don't show message again</Caption>}
                                onClick={() => setIgnoreMaintenanceMode(true)}
                                size="small"
                                sx={{ width: 200 }}
                            />
                        </Stack>
                    </Alert>
                );
            }
        }
    };

    return renderMaintenanceAlert() ?? <></>;
};

export default ScheduledMaintenanceAlert;
