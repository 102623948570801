import React, { createContext, FC, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
    getListenerStatsAnalyticsCloudVsLive,
    getListenerStatsAnalyticsListener,
    getListenerStatsAnalyticsTrack,
    getListenerStatsGraph
} from '../../middleware/listener-stats';
import { getStorageInfo } from '../../middleware/upload';
import {
    HistoricListener,
    HistoryItemDto,
    ListenerStatsAnalyticsCloudVsLiveDto,
    ListenerStatsAnalyticsListenerDto,
    ListenerStatsAnalyticsTrackDto,
    ListenerStatsDataDto
} from '../../models/dto';
import { EmptyFunc, Void } from '../../models/interfaces';
import { IStorageUsedInfo } from '../../pages/station/upload/models/interfaces';
import { Notification, useNotification } from '../../providers/notifications';
import useLocalStorage, { LocalStorageType } from '../../utils/local-storage';
import { useEffectAsync } from '../../utils/react-util';
import { useStationId } from '../../utils/router-util';
import { EntityMessageType, SignalRMessage, TableEntity } from '../../utils/signalr/models';
import { AnalyticsInterval, defaultAnalyticsInterval, graphFetchIntervalDelay, storageFetchIntervalDelay } from './consts';
import ListenerStatus from './listener-status';
import { getAnalyticsIntervalSelection } from './utils';
import { useSignalRMultipleEntities } from '../../utils/signalr/utils';

interface IListenerStatusContext {
    analyticsInterval: AnalyticsInterval;
    analyticsIntervalSelection: AnalyticsInterval[];
    isDialogOpen: boolean;
    isLoading: boolean;
    listenerStatCount: number;
    peakListenerStatCount: number;
    storageInfo: IStorageUsedInfo;

    // For the stats in the dialog:
    statsAnalyticsTrack?: ListenerStatsAnalyticsTrackDto;
    statsAnalyticsListener?: ListenerStatsAnalyticsListenerDto;
    statsAnalyticsCloudVsLive?: ListenerStatsAnalyticsCloudVsLiveDto;

    setAnalyticsInterval: Void<AnalyticsInterval>;
    setIsDialogOpen: Void<boolean>;
}

const initListenerStatusContext: Partial<IListenerStatusContext> = {
    analyticsIntervalSelection: [],
    analyticsInterval: defaultAnalyticsInterval,
    isLoading: false,
    listenerStatCount: 0,
    peakListenerStatCount: 0,
    storageInfo: { storageLimit: 10737418240, storageUsed: 0 }, //10737418240 is 10GB.

    setAnalyticsInterval: EmptyFunc,
    setIsDialogOpen: EmptyFunc
};

const ListenerStatusContext = createContext(initListenerStatusContext as IListenerStatusContext);

export function useListenerStatus() {
    return useContext(ListenerStatusContext);
}

const historicGraphInterval = 0;
const listenerStatsEntities: TableEntity[] = ['ListenerStats', 'HistoryItem'];

export const ListenerStatusProvider: FC = () => {
    const { addNotification } = useNotification();
    const location = useLocation();
    const stationId = useStationId(location);
    const [isLoadingPrimary, setIsLoadingPrimary] = useState(false);
    const [isLoadingSecondary, setIsLoadingSecondary] = useState(false);
    const [listenerStatCount, setListenerStatCount] = useState(0);
    const [peakListenerStatCount, setPeakListenerStatCount] = useState(0);

    const [, setHistoricListeners] = useState<HistoricListener[]>();
    const [storageInfo, setStorageInfo] = useState<IStorageUsedInfo>(initListenerStatusContext.storageInfo as IStorageUsedInfo);
    const [statsAnalyticsTrack, setStatsAnalyticsTrack] = useState<ListenerStatsAnalyticsTrackDto>();
    const [statsAnalyticsListener, setStatsAnalyticsListener] = useState<ListenerStatsAnalyticsListenerDto>();
    const [statsAnalyticsCloudVsLive, setStatsAnalyticsCloudVsLive] = useState<ListenerStatsAnalyticsCloudVsLiveDto>();
    const analyticsIntervalSelection = useMemo(() => getAnalyticsIntervalSelection(), []);

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [analyticsInterval, setAnalyticsInterval] = useLocalStorage<AnalyticsInterval>(
        LocalStorageType.ANALYTICS_INTERVAL,
        defaultAnalyticsInterval
    );

    // Edge case:
    useEffect(() => {
        if (analyticsInterval && analyticsIntervalSelection.length > 0) {
            const itemInSelection = analyticsIntervalSelection.find((x) => x.id === analyticsInterval.id);
            if (!itemInSelection) {
                // If the item isn't in the selection, set it to the first item.
                // This can only happen if a user doesn't have that option anymore (e.g. a year has passed).
                setAnalyticsInterval(analyticsIntervalSelection[0]);
            }
        }
    }, [analyticsInterval, analyticsIntervalSelection]);

    const fetchGraph = async (_stationId: string) => {
        const res = await getListenerStatsGraph(_stationId, historicGraphInterval);
        if (res.success) {
            setListenerStatCount(res.CurrentListenerCount);
            setHistoricListeners(res.HistoricListenerCount);
            const peak = res.HistoricListenerCount.reduce((maxObject, item) => {
                return item.Listeners > maxObject ? item.Listeners : maxObject;
            }, 0);
            setPeakListenerStatCount(peak);
        } else {
            addNotification(
                new Notification({
                    message: res.message,
                    severity: 'error'
                })
            );
        }
    };

    const fetchStorage = async (_stationId: string) => {
        const res = await getStorageInfo(_stationId);
        if (res.success) {
            setStorageInfo({ storageLimit: res.StorageLimit, storageUsed: res.StorageUsed });
        } else {
            addNotification(
                new Notification({
                    message: res.message,
                    severity: 'error'
                })
            );
        }
    };

    const fetchGraphAndStorage = async (_stationId: string, fetchWhat: 'all' | 'graph' | 'storage') => {
        setIsLoadingPrimary(true);
        switch (fetchWhat) {
            case 'all':
                await Promise.all([fetchGraph(_stationId), fetchStorage(_stationId)]);
                break;
            case 'graph':
                await fetchGraph(_stationId);
                break;
            case 'storage':
                await fetchStorage(_stationId);
                break;
            default:
                break;
        }
        setIsLoadingPrimary(false);
    };

    useEffectAsync(async () => {
        let graphFetchInterval, storageFetchInterval;
        if (stationId) {
            // First time fetch all data:
            await fetchGraphAndStorage(stationId, 'all');
            graphFetchInterval = setInterval(async () => await fetchGraphAndStorage(stationId, 'graph'), graphFetchIntervalDelay);
            storageFetchInterval = setInterval(
                async () => await fetchGraphAndStorage(stationId, 'storage'),
                storageFetchIntervalDelay
            );
        }

        return () => {
            clearInterval(graphFetchInterval);
            clearInterval(storageFetchInterval);
        };
    }, [stationId]);

    useEffectAsync(async () => {
        // Only needed when dialog is open:
        if (isDialogOpen && stationId) {
            let error = '';
            setIsLoadingSecondary(true);
            const [res1, res2, res3] = await Promise.all([
                getListenerStatsAnalyticsTrack(stationId, analyticsInterval),
                getListenerStatsAnalyticsListener(stationId, analyticsInterval),
                getListenerStatsAnalyticsCloudVsLive(stationId, analyticsInterval)
            ]);
            setIsLoadingSecondary(false);
            if (res1.success) {
                setStatsAnalyticsTrack(res1);
            } else {
                error = `${error}-${res1.message}`;
            }
            if (res2.success) {
                setStatsAnalyticsListener(res2);
            } else {
                error = `${error}-${res2.message}`;
            }
            if (res3.success) {
                setStatsAnalyticsCloudVsLive(res3);
            } else {
                error = `${error}-${res3.message}`;
            }

            if (error) {
                addNotification(
                    new Notification({
                        message: error,
                        severity: 'error'
                    })
                );
            }
        }
    }, [isDialogOpen, stationId, analyticsInterval]);

    useSignalRMultipleEntities(stationId, listenerStatsEntities, (messageType: EntityMessageType, message: SignalRMessage) => {
        if (messageType === 'EntityInsertedMessage' && message.Table === 'ListenerStats') {
            if (message.InsertedItems && message.InsertedItems.length > 0) {
                const listenerStatsData = message.InsertedItems[0].Data as ListenerStatsDataDto;
                setListenerStatCount(listenerStatsData.Listeners);
            }
        } else if (messageType === 'EntityInsertedMessage' && message.Table === 'HistoryItem') {
            if (message.InsertedItems && message.InsertedItems.length > 0) {
                const historyItem = message.InsertedItems[0].Data as HistoryItemDto;
                // Not sure if this is needed but it was done as the Dojo's SAM Cloud:
                setListenerStatCount(historyItem.Performances);
            }
        }
    });

    const value: IListenerStatusContext = {
        analyticsInterval,
        analyticsIntervalSelection,
        isDialogOpen,
        isLoading: isLoadingPrimary || isLoadingSecondary,
        listenerStatCount,
        peakListenerStatCount,
        storageInfo,

        // Used in the graph in the dialog:
        statsAnalyticsTrack,
        statsAnalyticsListener,
        statsAnalyticsCloudVsLive,

        setIsDialogOpen: (open) => setIsDialogOpen(open),
        setAnalyticsInterval: (interval) => setAnalyticsInterval(interval)
    };

    return <ListenerStatusContext.Provider value={value}>{<ListenerStatus />}</ListenerStatusContext.Provider>;
};
