import { urlVerifySession } from '../middleware/account';
import { BaseResponseDto, XRange } from '../models/dto';
import { Exception } from '../models/interfaces';
import Lang from '../models/language';
import { GetEnv } from './env';
import { ConsoleLog, ConsoleLogError } from './log';

export async function Fetch<T extends BaseResponseDto>(url: string, requestInit: RequestInit, range?: XRange): Promise<T> {
    const reqInit = range
        ? {
              ...requestInit,
              ...{ headers: { ...requestInit.headers, 'X-Range': range.toPayload() } }
          }
        : requestInit;

    reqInit.headers = { ...reqInit.headers, 'UI-Version': GetEnv().version };

    let stringResponse = '';
    try {
        const response = await fetch(url, reqInit);
        stringResponse = await response.text(); //response.json() truncates big numbers
        const data = stringResponse ? JSON.parse(stringResponse.replace(/("[^"]*"\s*:\s*)(\d{16,})/g, '$1"$2"')) : ''; //makes all strings with 16+ digits strings
        const isSuccessStatusCode =
            response.status === 200 || response.status === 204 || response.status === 201 || response.status === 202;
        if (!isSuccessStatusCode) {
            // Special case: By this time, the verify might time out, this will dispatch a logout:
            if (response.status === 401 && url.indexOf(urlVerifySession)) {
                ConsoleLog(`Logging out. ${response.status} was received in request: ${reqInit.method} - (${url})`, response);
                return ERROR<T>(Lang.msgLogoutVerificationError, response.status);
            }
            ConsoleLogError(`Error ${response.status} was received in request: ${reqInit.method} - (${url})`, response);
            return ERROR<T>(data.Message ?? Lang.msgGenericRequestError, response.status);
        }
        if (response.ok && (response.status === 204 || response.status === 201 || response.status === 202)) {
            return OK<T>();
        }

        if (response.ok) {
            if (data === null || data === undefined) {
                //Special case for a response in which the data is null
                //Cannot use if(!data), fails if data = 0
                return OK<T>({ message: 'success', success: true } as unknown as T, response.headers);
            } else if (!data.hasErrors) {
                if (typeof data === 'number' || typeof data === 'string' || typeof data === 'boolean') {
                    return OK<T>({ value: data, message: 'success', success: true } as unknown as T, response.headers);
                }
                return OK<T>(data, response.headers, response.status);
            } else {
                return ERROR<T>(data.errorReason ?? 'An error occurred', response.status);
            }
        }
    } catch (e) {
        if (e instanceof SyntaxError) {
            const isHTMLErrorResponse = stringResponse.startsWith('<!DOCTYPE html');
            return ERROR<T>(isHTMLErrorResponse || stringResponse.length > 300 ? 'An error occurred.' : stringResponse);
        }
        return ERROR<T>((e as Exception).message);
    }

    return ERROR<T>("Api didn't fetch");
}

function ERROR<T extends BaseResponseDto>(errorReason: string, status?: number): T {
    return {
        success: false,
        message: errorReason,
        statusCode: status
    } as T;
}

function OK<T extends BaseResponseDto>(data?: T, headers?: Headers, status?: number): T {
    const contentRange = headers && headers.get('content-range');
    const totalTrackDuration = headers && (headers.get('total-track-duration') as string);

    // If the data is not an array, merge it with the current data object.
    const dataObject = Array.isArray(data) ? { data: data } : data;
    return {
        ...dataObject,
        success: true,
        headers: headers,
        statusCode: status,
        message: '',
        ...(contentRange && { range: XRange.fromResponseString(contentRange, totalTrackDuration) })
    } as T;
}
