import React, { FC } from 'react';
import { useEditMedia } from '..';
import { MediaItemDto } from '../../../models/dto';
import { Stack, TextField } from '../../mui';
import { maxRowsDisplay } from '../consts';

/**
 * Used for Comments Page and Lyrics Page.
 */
const MetadataPage: FC = () => {
    const { getCurrentMediaItem, updateItem } = useEditMedia();
    const currentMediaItem = getCurrentMediaItem();

    const handleChange = (event, key: keyof MediaItemDto) => {
        updateItem(currentMediaItem.MediaItemId, key, event.target.value as never);
    };

    return (
        <Stack direction="row" spacing={1}>
            <TextField
                multiline
                rows={maxRowsDisplay}
                sx={{ flex: 1 }}
                label={currentMediaItem?.Comment ? 'Comments' : 'Type a comment'}
                value={currentMediaItem?.Comment ?? ''}
                onChange={(e) => handleChange(e, 'Comment')}
            />
            <TextField
                multiline
                rows={maxRowsDisplay}
                sx={{ flex: 1 }}
                label={currentMediaItem?.Lyrics ? 'Lyrics' : 'Type the lyrics'}
                value={currentMediaItem?.Lyrics ?? ''}
                onChange={(e) => handleChange(e, 'Lyrics')}
            />
        </Stack>
    );
};

export default MetadataPage;
