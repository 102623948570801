import deepEqual from 'deep-equal';
import React, { DragEvent, FC, MouseEvent, useEffect, useMemo, useState } from 'react';
import AutoSizer from 'react-virtualized/dist/es/AutoSizer';
import InfiniteLoader from 'react-virtualized/dist/es/InfiniteLoader';
import Table, { Column, HeaderMouseEventHandlerParams, TableHeaderProps, TableRowProps } from 'react-virtualized/dist/es/Table';
import 'react-virtualized/styles.css';
import { fetchLibraryList, fetchPlaylistList, genericFetchList } from '../../middleware/dynamic-list';
import { rangeCountFetch, zIndex3 } from '../../models/consts';
import {
    DoneAbortController,
    DynamicListDto,
    DynamicTableRequest,
    ISort,
    LibraryDynamicTableRequest,
    SortNames,
    SortRequest,
    XRange
} from '../../models/dto';
import { DropPosition, MenuAnchorPosition, MenuItemData, MouseClickEvent, SelectItem, TblColType } from '../../models/interfaces';
import Lang from '../../models/language';
import { TableProps } from '../../models/props';
import { libSelectMenuItems } from '../../models/table-data';
import { useRoutingData } from '../../pages/routing/provider';
import { useTreeView } from '../../pages/station/library';
import { defaultTDSize } from '../../pages/station/library/models/interfaces';
import { getRelativeSize, isActiveTab, isDisplayableTable } from '../../pages/station/library/utils';
import { useDrag } from '../../providers/drag';
import { Notification, useNotification } from '../../providers/notifications';
import { useStation } from '../../providers/station';
import { extractItemData, getRandomId } from '../../utils/general';
import useLocalStorage from '../../utils/local-storage';
import { removeKeyFromProps } from '../../utils/props';
import { useEffectAsync } from '../../utils/react-util';
import { EntityMessageType, SignalRMessage, TableEntity } from '../../utils/signalr/models';
import { useSignalRSingleEntity } from '../../utils/signalr/utils';
import { checkedColumnWidth, getVirtualizedTableStyle, maxWidthHeight } from '../../utils/style';
import { rowClassName } from '../../utils/table-row-styling';
import { QueueLoopBtn } from '../btn-queue-queue-loop';
import { DynamicListHeader } from '../dynamic-list-header';
import { CircularProgress, IconButton, RemoveCircleIcon, Stack, Tooltip, alpha, useTheme } from '../mui';
import { useSearch } from '../search-bar';
import { ListContainer, TblContainer } from '../styled-components';
import Wrap from '../wrap';
import {
    CellDataRenderer,
    CheckedCellRenderer,
    CheckedHeaderRenderer,
    HeaderRenderer,
    TableSettingsPopup,
    renderAlertPopup
} from './components';
import DynamicPlaceholderTableRow from './dynamic-placeholder-table-row';
import DynamicTableRow from './dynamic-table-row';
import Footer from './footer';
import MenuOptions from './menu';
import {
    getInitRequest,
    getListMenuItems,
    getTableColumns,
    isRowChecked,
    isTableSortable as isTableSortableFn,
    useCleanupPlaceholders,
    useMenuShortcuts
} from './table-util';
import { addItems, getApiSortValue, itemIsPlaceholderItem, moveOrAddItems, onSignalRItemReceived, toggleSelected } from './utils';
import { menuItemSelected } from './utils/menu-item-context';

const headerHeight = 30;
const persistCheckbox = false;

/**
 * Keep track of the ETA list, changes while rerendering.
 */
const etas: moment.Moment[] = [];

const DynamicTable: FC<TableProps> = ({ stationId, tableDef, tableDefIndexesPerStack, onTableSwap }) => {
    const { highlight, tableIndex, tableEntity } = tableDef;
    const { tableData } = getTableColumns<TblColType>(tableEntity);
    const { stationData } = useRoutingData();

    const theme = useTheme();
    const { searchRequest, setSearchLoading, setSearchRequestType } = useSearch();
    const {
        libLayout,
        nowPlayingInfo,
        treeViewData,
        sharedDialogsState,
        setLibLayoutState,
        clearLibLayoutHighlight,
        setSharedDialogs,
        setActiveTab
    } = useTreeView();
    const { togglePlayPreviewMediaItem } = useStation();

    const initRequest = getInitRequest(stationId, tableEntity, treeViewData.resolvedNode);

    const { dragState, onItemsDrop, setDragActive, setSelectedItems, setTableEntityTo } = useDrag();
    const { addNotification } = useNotification();
    const [colsEnabled, setColsEnabled] = useLocalStorage<Array<string>>(tableData.tableStorageKey, tableData.initColsEnabled);
    const [tableSettingsOpen, setTableSettingsOpen] = useState(false);
    const [menuDialogOpen, setMenuDialogOpen] = useState(false);
    const [listData, setListData] = useState<Array<TblColType>>();
    const [listChecked, setListChecked] = useState<Array<TblColType>>([]);
    const [checkedLastIndex, setListCheckedLastIndex] = useState<number>(-1);
    const [request, setRequest] = useState<DynamicTableRequest>(initRequest);
    const [currentSortRequest, setCurrentSortRequest] = useState<SortRequest>({ sort: [...initRequest.sort] });
    const [isLoading, setIsLoading] = useState(false);
    const [loadingMoreItems, setLoadingMoreItems] = useState(false);
    const [menuAnchorPosition, setSelectedElement] = useState<MenuAnchorPosition | null>(null);

    const resNodeChangeProp =
        tableEntity !== 'LibraryItem' && tableEntity !== 'PlaylistItem' ? tableEntity : treeViewData.resolvedNode;

    const droppableHere = dragState.tableEntityTo === tableEntity;
    const hasItems = listData && listData.length > 0;

    // Signal R for current Table Entity:
    useSignalRSingleEntity(
        stationId,
        tableEntity,
        async (messageType: EntityMessageType, message: SignalRMessage) => {
            await onSignalRItemReceived(
                tableEntity,
                messageType,
                message,
                stationId,
                setListData,
                setRequest,
                loadMoreRows,
                setListChecked,
                treeViewData.resolvedNode
            );
        },
        treeViewData.resolvedNode,
        searchRequest
    );

    useCleanupPlaceholders(listData, setListData);

    //Prevent searching while page is loading
    useEffect(() => {
        const shouldLoad = isLoading || loadingMoreItems;
        setSearchLoading(shouldLoad);
    }, [isLoading, loadingMoreItems]);

    // Keeps track of the items that can be dragged (everything that's selected).
    useEffect(() => {
        setSelectedItems(tableEntity, [...listChecked]);
    }, [listChecked]);

    useEffectAsync(async () => {
        if (request.range) {
            const restart =
                searchRequest.requestType === 'refresh'
                    ? true
                    : currentSortRequest.range && !deepEqual(currentSortRequest.sort, request.sort);
            await loadMoreRows({ startIndex: request.range.from, stopIndex: request.range.to }, restart);
        }
    }, [stationId, tableEntity, resNodeChangeProp, request, searchRequest.value, searchRequest.requestType]);

    useEffect(() => {
        return () => {
            resetAllSelected();
        };
    }, [stationId, tableEntity, resNodeChangeProp]);

    const activeMenuItems = useMemo(
        () => getListMenuItems(tableData, treeViewData.resolvedNode, stationData.manageStationData),
        [tableData, treeViewData.resolvedNode, stationData.manageStationData]
    );

    const initiateReqController = (req: DynamicTableRequest): boolean => {
        if (req.abortController.signal.aborted) {
            req.abortController = new AbortController() as DoneAbortController;
            req.abortController.state = 'busy';
            return true;
        }
        switch (req.abortController.state) {
            case 'busy': {
                const sameRequest = deepEqual(req, request);
                if (sameRequest) {
                    return false;
                } else {
                    // Abort, not needed anymore:
                    req.abortController.abort();
                    req.abortController = new AbortController() as DoneAbortController;
                    req.abortController.state = 'busy';
                    return true;
                }
            }
            case 'idle':
            case 'done':
            default: {
                req.abortController.state = 'busy';
                return true;
            }
        }
    };

    const loadMoreRows = async ({ startIndex, stopIndex }, restart = false) => {
        if (restart) {
            startIndex = 0;
            stopIndex = rangeCountFetch;
        }
        if (
            !restart &&
            currentSortRequest.range &&
            currentSortRequest.range.from === startIndex &&
            currentSortRequest.range.to === stopIndex
        ) {
            return;
        } else if (
            (tableEntity !== 'LibraryItem' && tableEntity !== 'PlaylistItem') ||
            ((tableEntity === 'LibraryItem' || tableEntity === 'PlaylistItem') && treeViewData.resolvedNode)
        ) {
            setCurrentSortRequest((prevState) => {
                return { ...prevState, range: new XRange(startIndex, stopIndex), sort: [...request.sort] };
            });
        }
        if (restart || !listData) {
            setIsLoading(true);
            onCheckedAll(false);
        } else if (listData && listData.length === 0) {
            // Only show loader if there are currently no items:
            setIsLoading(true);
        } else {
            setLoadingMoreItems(true);
        }
        let res: DynamicListDto<TblColType> | undefined = undefined;
        let continueRequest;

        if (tableEntity === 'LibraryItem' || tableEntity === 'PlaylistItem') {
            if (!treeViewData.resolvedNode) {
                setIsLoading(false);
                setLoadingMoreItems(false);
                // Waiting for treeView to actually have something picked.
                return;
            }

            const newRequest = { ...request, resolvedTreeNode: treeViewData.resolvedNode } as LibraryDynamicTableRequest;
            if (restart) {
                newRequest.range = new XRange(startIndex, stopIndex, newRequest.range?.total, newRequest.range?.totalDuration);
            }
            continueRequest = initiateReqController(newRequest);
            if (continueRequest) {
                if (tableEntity === 'LibraryItem') {
                    res = await fetchLibraryList(newRequest, searchRequest);
                } else {
                    res = await fetchPlaylistList(newRequest, searchRequest);
                }
            }
        } else {
            const newRequest = { ...request };
            if (restart) {
                newRequest.range = new XRange(startIndex, stopIndex, newRequest.range?.total, newRequest.range?.totalDuration);
            }
            continueRequest = initiateReqController(newRequest);
            if (continueRequest) {
                res = await genericFetchList<DynamicTableRequest, TblColType>(newRequest, searchRequest);
            }
        }

        if (continueRequest) {
            request.abortController.state = 'done';
            setIsLoading(false);
            setLoadingMoreItems(false);
        }
        if (res && res.success) {
            if (searchRequest) {
                setSearchRequestType('load-more');
            }
            // Range can get updated in the headers:
            request.range?.merge(res.range);
            if (restart && request.range) {
                request.range.from = startIndex;
            }
            // Accumulates the data as it gets fetched:
            setListData((prevState) => {
                if (res) {
                    return restart ? res.data && [...res.data] : addItems(prevState, res.data);
                } else {
                    return prevState;
                }
            });
        } else if (res) {
            if (!request.abortController.signal.aborted) {
                addNotification(
                    new Notification({
                        error: res.message,
                        message: res.message,
                        severity: 'error'
                    })
                );
            }
        }
    };

    const refreshAllRows = async () => {
        await loadMoreRows({ startIndex: 0, stopIndex: rangeCountFetch }, true);
    };

    const onColEnableChange = (value) => {
        setColsEnabled((prevState) => {
            const currentIndex = prevState.indexOf(value);
            if (currentIndex === -1) {
                prevState.push(value);
            } else {
                prevState.splice(currentIndex, 1);
            }
            return [...prevState];
        });
    };

    const onRowRightClick = ({ event, index, rowData }: MouseClickEvent) => {
        event.preventDefault();
        event.stopPropagation();
        const rect = event.currentTarget.getBoundingClientRect();
        setSelectedElement({
            selectedEvent: event,
            selectedElement: event.currentTarget,
            mouseX: event.clientX - rect.left
        });

        toggleSelected(event, true, index, listData ? listData : [], rowData, setListChecked, setListCheckedLastIndex);
    };

    const onRowRightClickEmptyList = (event: MouseEvent<HTMLElement>) => {
        event.preventDefault();
        setSelectedElement({
            anchorReference: 'anchorPosition',
            selectedEvent: event,
            selectedElement: event.currentTarget,
            mouseX: event.clientX,
            mouseY: event.clientY
        });
    };

    const onRowClick = ({ event, index, rowData }: MouseClickEvent) => {
        toggleSelected(event, false, index, listData ? listData : [], rowData, setListChecked, setListCheckedLastIndex);
    };

    const onCheckedAll = (checked: boolean) => {
        if (checked) {
            setListChecked(listData ? [...listData] : []);
        } else {
            setListChecked([]);
            setListCheckedLastIndex(-1);
        }
    };

    const onMouseDownProps = !menuDialogOpen && {
        onMouseDown: () => {
            // Strictly for setting the active tab for now:
            setActiveTab(tableDef.tableIndex);
        }
    };

    /**
     * This will reset the entire list.
     */
    const resetAllSelected = () => {
        onCheckedAll(false);
        setRequest(initRequest);
        setListData([]);
    };

    const onSelectHeaderListItem = (item: MenuItemData) => {
        let tblEntity: TableEntity;
        if (item.action === 'DisplayableTable') {
            // Revert to selected resolved node:
            tblEntity = treeViewData.resolvedNode?.type ?? 'LibraryItem';
        } else {
            tblEntity = item.action as TableEntity;
        }

        const tableDisplayable = isDisplayableTable(tblEntity);
        // Just to take on the new size:
        const size =
            libLayout.tableComponents && libLayout.tableComponents.length > 0 && libLayout.tableComponents[tableIndex]?.size;
        setLibLayoutState({ tableDisplayable, tableIndex, tableEntity: tblEntity, size: size ? size : defaultTDSize }, true);
    };

    const onMinimize = () => {
        setLibLayoutState(tableDef, false);
    };

    const onDragRowItem = (index: number, rowData: TblColType) => {
        toggleSelected(null, true, index, listData ? listData : [], rowData, setListChecked, setListCheckedLastIndex);
    };

    /**
     * Drop will only occur when it was a valid drag & drop according to LibraryTableData.movable attribute.
     */
    const onDropItem = async (
        dropPosition: DropPosition,
        sourceTableEntity: TableEntity,
        sourceTableItems: TblColType[],
        targetRowData?: TblColType
    ) => {
        if (sourceTableEntity === tableEntity && targetRowData === null) {
            // Nothing to do if sorting but no target was given to sort to
            return;
        }
        await moveOrAddItems(
            stationId,
            listData,
            tableEntity,
            addNotification,
            setListData,
            dropPosition,
            sourceTableEntity,
            sourceTableItems,
            targetRowData,
            treeViewData.resolvedNode
        );
    };

    const onDropOnTable = () => {
        if (dragState.isTableSwap) {
            if (dragState.tableEntityTo != dragState.tableEntityFrom) {
                onTableSwap(dragState.tableEntityFrom as TableEntity, dragState.tableEntityTo as TableEntity);
            }
        } else {
            if (dragState.tableEntityTo) {
                onItemsDrop(tableEntity, tableData, undefined, 'TOP', onDropItem);
            }
        }
        setDragActive(tableEntity, tableData, false);
        clearLibLayoutHighlight();
    };

    const onDragOver = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        if (dragState.isTableSwap) {
            setTableEntityTo(tableEntity);
        } else {
            if (!libLayout.resizable) {
                // Needed otherwise "drop" events don't fire:
                e.stopPropagation();
            }
            const isMovable = tableData.movable.indexOf(tableEntity) > -1;
            const tableEntityTo = isMovable ? tableEntity : false;
            setTableEntityTo(tableEntityTo);
        }
    };

    const onDragLeave = () => {
        setTableEntityTo(false);
    };

    const onMenuItemClicked = async (item: MenuItemData) => {
        setIsLoading(true);
        // Signal R will update the list's state after this call if successful:
        const res = await menuItemSelected(
            stationId,
            tableEntity,
            item,
            listChecked,
            checkedLastIndex,
            listData ?? [],
            addNotification,
            setListData,
            setSharedDialogs,
            refreshAllRows,
            togglePlayPreviewMediaItem,
            treeViewData.resolvedNode
        );
        setIsLoading(false);

        if (!res.success) {
            addNotification(
                new Notification({
                    error: res.message,
                    message: res.message,
                    severity: 'error'
                })
            );
        }
    };

    const shortcutsDisabled =
        !menuAnchorPosition &&
        !isLoading &&
        !sharedDialogsState.editMediaItems &&
        !menuDialogOpen &&
        isActiveTab(libLayout, tableDef);

    useMenuShortcuts(onMenuItemClicked, shortcutsDisabled, activeMenuItems, hasItems ? true : false, listChecked.length > 0);

    const onHeaderClick = (params: HeaderMouseEventHandlerParams) => {
        if (params.dataKey === 'selectList') {
            // When clicking "Select All" checkbox, onHeaderClick will also fire, to prevent this:
            return;
        }
        const newSortName = getApiSortValue(params.dataKey) as SortNames;
        const newSortItem: ISort = { inc: '+', name: newSortName };

        setRequest((prevState) => {
            const { sort } = prevState;
            const oldSort = sort.find((item) => item.name.toLowerCase() === newSortName.toLowerCase());
            newSortItem.inc = oldSort?.inc === '+' ? '-' : '+';
            return { ...prevState, sort: [newSortItem] };
        });
    };

    // Only show columns that are enabled:
    const colsDisplayed = tableData.cols.filter((item) => colsEnabled.indexOf(item.dataKey) !== -1);
    const { palette, transitions } = theme;
    const { action } = palette;

    const multipleSelected = listChecked.length > 1;
    const actionSelectAll = listData?.length === 1 ? false : !multipleSelected;

    const highlightSx = highlight && {
        background: droppableHere ? palette.secondary.light : action.focus
    };

    const isTableSortable = isTableSortableFn(tableEntity);

    const loader = isLoading && (
        <Stack
            justifyContent="center"
            alignItems="center"
            sx={{
                ...maxWidthHeight,
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: zIndex3,
                background: alpha(theme.palette.background.paper, 0.2)
            }}
            onClick={() => {
                // TODO: Remove this once issue is solved, it's only for developmental purposes:
                const logList: Array<SelectItem<string, unknown>> = [
                    { id: 'highlight', value: highlight },
                    { id: 'tableIndex', value: tableIndex },
                    { id: 'tableEntity', value: tableEntity },
                    { id: 'tableData', value: tableData },
                    { id: 'initRequest', value: initRequest },
                    { id: 'treeViewData', value: treeViewData },
                    { id: 'colsEnabled', value: colsEnabled },
                    { id: 'menuDialogOpen', value: menuDialogOpen },
                    { id: 'listData', value: listData },
                    { id: 'listChecked', value: listChecked },
                    { id: 'request', value: request },
                    { id: 'isLoading', value: isLoading },
                    { id: 'menuAnchorPosition', value: menuAnchorPosition },
                    { id: 'resNodeChangeProp', value: resNodeChangeProp }
                ];
                for (let i = 0; i < logList.length; i++) {
                    const { id, value } = logList[i];
                    // eslint-disable-next-line no-console
                    console.log(`ListStateSummary-${id}`, value);
                }
            }}
        >
            <CircularProgress />
        </Stack>
    );

    const noData = [
        renderAlertPopup(!droppableHere, 'info', searchRequest.value ? Lang.msgNoListDataFiltered : Lang.msgNoListData),
        renderAlertPopup(droppableHere, 'success', Lang.msgAddItemsHere)
    ];

    /**
     * Some items ignore the From and To fields data ranges like RECYCLE BIN.
     * {@link maxRowCount} has to be set. It's only an issue with the RECYCLE BIN as it duplicates the items in the list.
     * Setting the row count to request?.range?.total means it's not going to try to fetch again afterwards.
     */
    const maxRowCount = (request?.range?.total ?? -1 <= 0) ? 1000000 : (request?.range?.total ?? 0);

    const table = (
        <TblContainer
            sx={getVirtualizedTableStyle(theme)}
            style={{ overflow: 'hidden scroll !important', position: 'relative' }}
            onContextMenu={onRowRightClickEmptyList}
        >
            {loader}
            {!hasItems && noData}
            {hasItems && (
                <InfiniteLoader
                    isRowLoaded={({ index }) => {
                        return listData[index] ? true : false;
                    }}
                    loadMoreRows={async (params) => {
                        return await setRequest((prevState) => {
                            return {
                                ...prevState,
                                range: new XRange(params.startIndex, params.stopIndex)
                            };
                        });
                    }}
                    rowCount={maxRowCount}
                    minimumBatchSize={rangeCountFetch}
                >
                    {({ onRowsRendered, registerChild }) => (
                        <AutoSizer>
                            {({ height, width }) => {
                                // Sum up the width:
                                const totalWidth = colsDisplayed.reduce((accumulator, object) => accumulator + object.width, 0);

                                return (
                                    <Table
                                        // ref={registerChild}
                                        ref={(element): void => {
                                            if (element && registerChild) {
                                                registerChild(element);
                                            }
                                        }}
                                        onRowsRendered={onRowsRendered}
                                        rowClassName={rowClassName}
                                        headerHeight={headerHeight}
                                        sortBy={request.sort[0].name}
                                        sortDirection={request.sort[0].inc === '-' ? 'DESC' : 'ASC'}
                                        headerStyle={{
                                            width: 'auto',
                                            marginRight: '0px',
                                            ...(isTableSortable && { cursor: 'pointer' })
                                        }}
                                        {...(isTableSortable && { onHeaderClick: onHeaderClick })}
                                        width={width}
                                        height={height}
                                        rowHeight={40}
                                        rowCount={listData.length}
                                        rowGetter={({ index }) => {
                                            const listItem = listData[index];
                                            // Attaches the index onto the row to render for convenience:
                                            listItem['Index'] = index;
                                            return listItem;
                                        }}
                                        rowRenderer={(props: TableRowProps) => {
                                            const { key, partialProps } = removeKeyFromProps(props);
                                            if (itemIsPlaceholderItem(props.rowData)) {
                                                return (
                                                    <DynamicPlaceholderTableRow {...(partialProps as TableRowProps)} key={key} />
                                                );
                                            }
                                            return (
                                                <DynamicTableRow
                                                    tableData={tableData}
                                                    tableEntity={tableEntity}
                                                    triggerOnDrag={onDragRowItem}
                                                    triggerOnDrop={onDropItem}
                                                    triggerTableSwap={onTableSwap}
                                                    rowChecked={isRowChecked(props.index, listData, listChecked)}
                                                    {...(partialProps as TableRowProps)}
                                                    key={key}
                                                />
                                            );
                                        }}
                                        onRowRightClick={onRowRightClick}
                                        onRowClick={onRowClick}
                                    >
                                        {(persistCheckbox || listChecked.length > 0) && (
                                            <Column
                                                key={getRandomId('col-')}
                                                dataKey="selectList"
                                                width={checkedColumnWidth}
                                                headerRenderer={(props: TableHeaderProps) => (
                                                    <CheckedHeaderRenderer
                                                        {...props}
                                                        checked={listChecked.length === listData.length}
                                                        onChecked={onCheckedAll}
                                                    />
                                                )}
                                                cellRenderer={CheckedCellRenderer}
                                                cellDataGetter={({ rowData }) => {
                                                    const itemIndex = listChecked.indexOf(rowData);
                                                    return itemIndex > -1;
                                                }}
                                                headerStyle={{ textAlign: 'left' }}
                                                style={{ textAlign: 'left' }}
                                            />
                                        )}
                                        {colsDisplayed.map((item) => {
                                            return (
                                                <Column
                                                    key={getRandomId('col-')}
                                                    label={item.label}
                                                    dataKey={item.dataKey}
                                                    width={width * (item.width / totalWidth)}
                                                    headerRenderer={HeaderRenderer}
                                                    cellDataGetter={({ dataKey, rowData }) => {
                                                        const itemData = extractItemData(dataKey, rowData);
                                                        return item.labelFormatter
                                                            ? item.labelFormatter(
                                                                  itemData,
                                                                  rowData,
                                                                  nowPlayingInfo,
                                                                  listData,
                                                                  etas
                                                              )
                                                            : itemData;
                                                    }}
                                                    cellRenderer={CellDataRenderer}
                                                    headerStyle={{ textAlign: 'center', ...item.headerStyle }}
                                                    style={{ ...item.style }}
                                                />
                                            );
                                        })}
                                    </Table>
                                );
                            }}
                        </AutoSizer>
                    )}
                </InfiniteLoader>
            )}
            {hasItems && (
                <Footer
                    label={tableData.footerLabel ? tableData.footerLabel(request, listData) : ''}
                    isLoading={loadingMoreItems}
                    subLabel={tableData.footerSubLabel ? tableData.footerSubLabel(request, listData) : ''}
                    range={request.range}
                    listChecked={listChecked}
                    setListChecked={setListChecked}
                    itemsButtonClick={() => {
                        setTableSettingsOpen(true);
                    }}
                />
            )}
            <TableSettingsPopup
                tableSettingsOpen={tableSettingsOpen}
                colsEnabled={colsEnabled}
                tableData={tableData}
                menuDialogOpen={menuDialogOpen}
                onColEnableChange={onColEnableChange}
                setTableSettingsOpen={setTableSettingsOpen}
                setMenuDialogOpen={setMenuDialogOpen}
            />
        </TblContainer>
    );

    return (
        <Wrap
            isWrapped={!!tableData.title}
            wrapper={(children) => (
                <ListContainer
                    {...onMouseDownProps}
                    draggable={!!hasItems || !!dragState.isTableSwap}
                    onDrop={onDropOnTable}
                    onDragOver={onDragOver}
                    onDragLeave={onDragLeave}
                    style={{ flex: getRelativeSize(tableDef, libLayout, tableDefIndexesPerStack) }}
                    sx={{
                        p: 1,
                        ...maxWidthHeight,
                        transition: transitions.create(['background'], {
                            duration: transitions.duration.shorter,
                            easing: transitions.easing.sharp
                        }),
                        ...highlightSx
                    }}
                >
                    <DynamicListHeader
                        toggle={true}
                        icon={tableData.icon}
                        menuItems={libSelectMenuItems}
                        tableEntity={tableEntity}
                        tableData={tableData}
                        tableDef={tableDef}
                        onSelectHeaderListItem={onSelectHeaderListItem}
                        selectedItemId={isDisplayableTable(tableData.tableEntity) ? 'DisplayableTable' : tableData.tableEntity}
                        title={
                            tableData.headerLabel ? tableData.headerLabel(tableData, treeViewData.resolvedNode) : tableData.title
                        }
                        actionChild={
                            <Stack direction="row" alignItems="center">
                                {tableEntity === 'QueueItem' && listData && (
                                    <QueueLoopBtn listData={listData} stationId={stationId} />
                                )}
                                <Tooltip title={Lang.btnMinimize}>
                                    <IconButton onClick={onMinimize} size="small">
                                        <RemoveCircleIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        }
                    />
                    <MenuOptions
                        hasItems={hasItems}
                        itemsSelected={listChecked.length > 0}
                        menuAnchorPosition={menuAnchorPosition}
                        menuItems={activeMenuItems}
                        actionTitle={actionSelectAll ? Lang.btnSelectAll : Lang.btnDeselectAll}
                        onActionClicked={() => (actionSelectAll ? onCheckedAll(true) : onCheckedAll(false))}
                        menuItemClicked={onMenuItemClicked}
                        setSelectedElement={setSelectedElement}
                    />
                    {children}
                </ListContainer>
            )}
        >
            {table}
        </Wrap>
    );
};

export default DynamicTable;
