import React, { FC } from 'react';
import { Menu, MenuItem } from '../mui';
import { DurationModeData, TimeSelectionMenuProps } from './models';
import { getDurationMode } from './util';

const timeSelectionModes: DurationModeData[] = [
    getDurationMode('countDown'),
    getDurationMode('extended'),
    getDurationMode('playTime'),
    getDurationMode('total')
];

const TimeSelectionMenu: FC<TimeSelectionMenuProps> = ({ anchorEl, selected, handleClose, handleSelect }) => {
    const open = Boolean(anchorEl);

    return (
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
            {timeSelectionModes.map((durationMode) => (
                <MenuItem
                    key={durationMode.type}
                    selected={durationMode.type === selected}
                    onClick={() => handleSelect(durationMode)}
                >
                    {durationMode.title}
                </MenuItem>
            ))}
        </Menu>
    );
};

export default TimeSelectionMenu;
