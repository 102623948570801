import '@fontsource/exo/300.css';
import '@fontsource/exo/400.css';
import '@fontsource/exo/500.css';
import '@fontsource/exo/600.css';
import '@fontsource/exo/700.css';
import '@fontsource/roboto';
import React from 'react';
import { CssBaseline } from './components/mui';
import { BaseContainer } from './components/styled-components';
import ThemeSelector from './components/theme-selector';
import Router from './pages/routing';
import { Providers } from './providers/providers';
import './styles/styles.css';

const SAMCloudApp = () => {
    return (
        <Providers>
            <CssBaseline enableColorScheme />
            <BaseContainer className="fullHeight">
                <ThemeSelector></ThemeSelector>
                <Router />
            </BaseContainer>
        </Providers>
    );
};

export default SAMCloudApp;
