import { FnVoid, Void } from '../../models/interfaces';

export type Type = 'countDown' | 'extended' | 'playTime' | 'total';

export interface DurationModeData {
    type: Type;
    title: string;
}

export const durationModeDefault: Type = 'total';

export interface TimeSelectionMenuProps {
    anchorEl?: HTMLElement;
    selected: Type;
    handleClose: FnVoid;
    handleSelect: Void<DurationModeData>;
}
