import { useEffect, useMemo } from 'react';
import { FilterName } from '../../middleware/library';
import { FeatureFlag, rangeCountFetch } from '../../models/consts';
import { DoneAbortController, DynamicTableRequest, ISort, ManageStationDto, XRange } from '../../models/dto';
import {
    Fn,
    LibTblData,
    MenuItemAction,
    MenuItemData,
    PlaceholderItem,
    TableSettings,
    TblColType
} from '../../models/interfaces';
import Lang from '../../models/language';
import { libComponentData } from '../../models/table-data';
import { placeholderRemovalThreshold } from '../../models/time';
import { StationData, useRoutingData } from '../../pages/routing/provider';
import { ResolvedTreeNode, TableSettingsControl } from '../../pages/station/library/models/interfaces';
import getDeepClonedObject from '../../utils/deep-clone';
import { isFeatureFlagEnabled } from '../../utils/general';
import { KeyboardDef, useKeyPress } from '../../utils/key-press-handler';
import { LocalStorageType } from '../../utils/local-storage';
import { ConsoleLogError } from '../../utils/log';
import { ResourcePermissions } from '../../utils/resource-permissions';
import { TableEntity } from '../../utils/signalr/models';
import { itemIsPlaceholderItem } from './utils';

/**
 * Note casting to unknown is done for type safety.
 * @param tableKey Identifies the table currently in use.
 */
export function getTableColumns<T>(tableKey: TableEntity): {
    tableData: LibTblData<T>;
} {
    switch (tableKey) {
        case 'HistoryItem':
            return {
                tableData: libComponentData.history as LibTblData<T>
            };
        case 'LibraryItem':
            return {
                tableData: libComponentData.library as LibTblData<T>
            };
        case 'PlaylistItem':
            return {
                tableData: libComponentData.playlist as LibTblData<T>
            };
        case 'QueueItem':
            return {
                tableData: libComponentData.queue as LibTblData<T>
            };
        default:
            return {
                tableData: libComponentData.queue as LibTblData<T>
            };
    }
}

export function getTableSettingsItems(
    tableSettingsStorageKey: LocalStorageType,
    tableSettingsControl: TableSettingsControl
): { tableSettings: TableSettings; setTableSettings: (value: TableSettings | Fn<TableSettings>) => void } {
    switch (tableSettingsStorageKey) {
        case LocalStorageType.TABLE_HISTORY_SETTINGS:
            return {
                tableSettings: tableSettingsControl.tableSettingsHistory,
                setTableSettings: tableSettingsControl.setTableSettingsHistory
            };
        case LocalStorageType.TABLE_LIBRARY_SETTINGS:
            return {
                tableSettings: tableSettingsControl.tableSettingsLibrary,
                setTableSettings: tableSettingsControl.setTableSettingsLibrary
            };
        case LocalStorageType.TABLE_PLAYLIST_SETTINGS:
            return {
                tableSettings: tableSettingsControl.tableSettingsPlaylist,
                setTableSettings: tableSettingsControl.setTableSettingsPlaylist
            };
        case LocalStorageType.TABLE_QUEUE_SETTINGS:
        default:
            return {
                tableSettings: tableSettingsControl.tableSettingsQueue,
                setTableSettings: tableSettingsControl.setTableSettingsQueue
            };
    }
}

export function removeItemByAction(menuItems: MenuItemData[], action: MenuItemAction) {
    const parentItems = menuItems.filter((x) => {
        const menuItemData = x.action === 'more-items' && x.menuItems && x.menuItems.find((i) => i.action === action);
        return menuItemData;
    });
    if (parentItems && parentItems.length > 0) {
        const parentItemCount = parentItems.length;
        for (let i = 0; i < parentItemCount; i++) {
            const element = parentItems[i];
            if (element.menuItems) {
                element.menuItems = element.menuItems.filter((x) => x.action !== action);
                if (element.menuItems && element.menuItems.length === 0) {
                    // Remove item if it doesn't have any children to display (which is what 'more-items' is for):
                    const index = menuItems.findIndex((x) => x === element);
                    if (index >= 0) {
                        menuItems.splice(index, 1);
                    }
                }
            }
        }
    } else {
        // No parent, remove Action:
        const index = menuItems.findIndex((item) => item.action === action);
        if (index >= 0) {
            menuItems.splice(index, 1);
        }
    }
}

/**
 * Remove menu item according to action and title. Title must be exact as specified in {@link MenuItemData}.
 */
function removeItemByActionAndTitle(menuItems: MenuItemData[], action: MenuItemAction, title: string): MenuItemData[] {
    const index = menuItems.findIndex((x) => x.action === action && x.title === title);
    if (index >= 0) {
        menuItems.splice(index, 1);
    } else {
        for (let i = 0; i < menuItems.length; i++) {
            const menuItem = menuItems[i];
            const length = menuItem.menuItems?.length ?? 0;
            if (menuItem.menuItems && length > 0) {
                menuItem.menuItems = removeItemByActionAndTitle(menuItem.menuItems, action, title);
                if (length > menuItem.menuItems.length) {
                    // Break the for-loop because it's already been removed:
                    i = menuItems.length;
                }
            }
        }
    }
    return menuItems;
}

/**
 * Some tables have different menu items, e.g. All Media displays different ones than Requests or Recycle Bin.
 * This function just helps filter them out.
 */
export function getListMenuItems<T>(
    tableData: LibTblData<T>,
    resolvedNode?: ResolvedTreeNode,
    manageStationData?: ManageStationDto
): MenuItemData[] {
    let menuItems = getDeepClonedObject(tableData.menuItems);

    const radioGptFeature = manageStationData ? isFeatureFlagEnabled(FeatureFlag.RadioGPTFeature, manageStationData) : false;
    if (!radioGptFeature) {
        removeItemByAction(menuItems, 'create-gpt-media-item');
    }

    switch (tableData.tableEntity) {
        case 'LibraryItem': {
            if (resolvedNode?.filterName === FilterName.RECYCLEBIN) {
                // Filter out move-to-recyclebin:
                removeItemByAction(menuItems, 'move-to-recyclebin');
                removeItemByActionAndTitle(menuItems, 'more-items', Lang.aCreate);
                removeItemByActionAndTitle(menuItems, 'more-items', Lang.aAddTo);
                removeItemByActionAndTitle(menuItems, 'more-items', Lang.aSetAs);
            } else {
                menuItems = menuItems.filter((x) => x.action !== 'restore');
            }
            break;
        }
        default:
            break;
    }
    return menuItems;
}

/**
 * Get the initial sort and request items.
 * @param tableEntity Table it's busy with.
 * @param playlist Is a playlist item picked, else, library.
 */
export function getInitRequest(
    stationId: string,
    tableEntity: TableEntity,
    resolvedTreeNode?: ResolvedTreeNode
): DynamicTableRequest {
    let initSort: ISort[] = [{ inc: '-', name: 'DatePlayed' }];

    switch (tableEntity) {
        case 'HistoryItem':
            initSort = [{ inc: '-', name: 'DatePlayed' }];
            break;
        case 'LibraryItem':
            switch (resolvedTreeNode?.filterName) {
                case FilterName.REQUESTS:
                    initSort = [{ inc: '-', name: 'NumberOfRequests' }];
                    break;
                default:
                    initSort = [
                        { inc: '+', name: 'Album' },
                        { inc: '+', name: 'Title' },
                        { inc: '+', name: 'Artist' }
                    ];
            }
            break;
        case 'QueueItem':
        case 'PlaylistItem':
        default:
            // Playlist initial sort value:
            initSort = [{ inc: '+', name: 'SortValue' }];
            break;
    }

    const initAbortController = new AbortController() as DoneAbortController;
    initAbortController.state = 'idle';

    return {
        abortController: initAbortController,
        range: new XRange(0, rangeCountFetch),
        stationId,
        sort: initSort,
        tableEntity
    };
}

export function isRowChecked(index: number, listData: TblColType[], listChecked: TblColType[]) {
    const rowData = listData[index];
    const itemIndex = listChecked.indexOf(rowData);
    if (itemIndex > -1) {
        return true;
    }
    return false;
}

/**
 * Look for placeholders that are outdated and remove them from the list.
 * @param setListData Set function
 */
export function useCleanupPlaceholders(
    listData: TblColType[] | undefined,
    setListData: React.Dispatch<React.SetStateAction<TblColType[] | undefined>>
) {
    useEffect(() => {
        const timerInterval: NodeJS.Timeout = setInterval(() => {
            const currentDateUTC = Date.now();
            setListData((prevState) => {
                if (prevState) {
                    // Get all non-placeholder-items as well as valid placeholder items:
                    const newItems = prevState.filter((x) => {
                        if (!itemIsPlaceholderItem(x)) {
                            return true;
                        }
                        // Still valid:
                        return currentDateUTC - (x as PlaceholderItem).dateAddedUTC < placeholderRemovalThreshold;
                    });
                    if (prevState.length === newItems.length) {
                        return prevState;
                    }
                    ConsoleLogError('PlaceholderItem(s) Removed', { list: prevState, removedUTC: Date.now() });
                    return newItems;
                }
                return prevState;
            });
        }, 1000);
        return () => {
            clearInterval(timerInterval);
        };
    }, [listData]);
}

export function getKeyboardShortcuts(listMenuItems: MenuItemData[], shortcuts?: KeyboardDef[]): KeyboardDef[] {
    shortcuts = shortcuts ? shortcuts : [];
    for (let i = 0; i < listMenuItems.length; i++) {
        const element = listMenuItems[i];
        if (element.shortcut) {
            const prevShortcut = shortcuts.find((x) => x === element.shortcut);
            if (!prevShortcut) {
                // Prevents adding a duplicate shortcut:
                shortcuts.push(element.shortcut);
            } else {
                ConsoleLogError(
                    'DuplicateShortcut',
                    `Some shortcut might be ignored for ${element.shortcut.code}-CTRL-${element.shortcut.ctrlKey}`
                );
            }
        }
        if (element.menuItems) {
            getKeyboardShortcuts(element.menuItems, shortcuts);
        }
    }

    return shortcuts;
}

/**
 * Recursive function to find item by shortcut.
 */
function getMenuItemByShortcut(listMenuItems: MenuItemData[], shortcut: KeyboardDef): MenuItemData | undefined {
    for (let i = 0; i < listMenuItems.length; i++) {
        const element = listMenuItems[i];
        if (element.shortcut === shortcut) {
            return element;
        }
        if (element.menuItems) {
            const foundElement = getMenuItemByShortcut(element.menuItems, shortcut);
            if (foundElement) {
                return foundElement;
            }
        }
    }
    return undefined;
}

/**
 * Assign shortcuts to the menu items in a table.
 * @param listMenuItems
 * @param onMenuItemClicked
 */
export function useMenuShortcuts(
    onMenuItemClicked: (item: MenuItemData) => Promise<void>,
    active: boolean,
    listMenuItems: MenuItemData[],
    hasItems: boolean,
    itemsSelected: boolean
) {
    const { stationData } = useRoutingData();
    const keyDefs = useMemo(() => getKeyboardShortcuts(listMenuItems), [listMenuItems]);

    useKeyPress(
        async (shortcut: KeyboardDef) => {
            const item = getMenuItemByShortcut(listMenuItems, shortcut);

            if (item && !isMenuItemDisabled(stationData, item, hasItems, itemsSelected)) {
                await onMenuItemClicked(item);
            }
        },
        active,
        keyDefs,
        [stationData, hasItems, itemsSelected]
    );
}

/**
 * In order to disable menu item functionality, these prerequisites should persist.
 */
export function isMenuItemDisabled(
    stationData: StationData,
    menuItemData: MenuItemData,
    hasItems: boolean,
    itemsSelected: boolean
): boolean {
    const { action, hasItemsDependent = false, itemsSelectedDependent = false } = menuItemData;
    if (hasItemsDependent) {
        if (!hasItems) {
            return true;
        }
    }
    if (itemsSelectedDependent) {
        if (!itemsSelected) {
            return true;
        }
    }
    if (action === 'edit') {
        const hasEditMetadataPermission = stationData.manageStationData?.security?.hasPermissions(
            ResourcePermissions.editMetaData
        );
        if (!hasEditMetadataPermission) {
            return true;
        }
    }
    return false;
}

export function isTableSortable(tableEntity: string) {
    return tableEntity !== 'QueueItem' && tableEntity !== 'PlaylistItem';
}
