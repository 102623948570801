import React, { useRef, useState } from 'react';
import Lang from '../models/language';
import { DialogSelectInputProps } from '../models/props';
import { getGlobalScrollStyle } from '../utils/style';
import { DialogDraggableTitle, getDialogPaperComponent } from './draggable-paper';
import LoadingBtn from './loading-btn';
import { ClearIcon, Dialog, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, TextField, useTheme } from './mui';
import BtnIconTooltip from './btn-icon-tooltip';
import { defaultAllowSearchThreshold } from '../models/consts';

const dragTitleId = 'draggable-select-dialog-title';

const DialogSelectInput = <T, U>(props: DialogSelectInputProps<T, U>) => {
    const {
        allowSearchThreshold = defaultAllowSearchThreshold,
        closable,
        dialogTitle,
        draggable,
        negativeTitle,
        open,
        loading,
        onSearch,
        onClose,
        onSelect,
        items,
        id
    } = props;
    const [searchText, setSearchText] = useState('');
    const theme = useTheme();
    const dialogPaperComponent = useRef(getDialogPaperComponent(dragTitleId)).current;
    const isDraggable = draggable === undefined || draggable;
    const isClosable = closable === undefined || closable;
    const searchAllowed = items.length > allowSearchThreshold;

    const dialogListSx = (selected: boolean) =>
        selected && {
            background: theme.palette.primary.light,
            color: theme.palette.primary.contrastText
        };

    const onTypeSearch = (e, clear = false) => {
        if (searchAllowed) {
            if (clear) {
                onSearch && onSearch('');
                setSearchText('');
                return;
            }
            if (e) {
                const val = e.target.value as string;
                onSearch && onSearch(val);
                setSearchText(val);
            }
        }
    };

    const searchTextUpper = searchText.toUpperCase();
    const filteredItems = !searchAllowed
        ? items
        : items.filter((item) => {
              return (item.value as string).toUpperCase().includes(searchTextUpper);
          });

    return (
        <Dialog
            open={open}
            onClose={() => {
                isClosable && onClose && onClose();
            }}
            PaperComponent={isDraggable ? dialogPaperComponent : Paper}
            aria-labelledby={dragTitleId}
        >
            {dialogTitle && <DialogDraggableTitle componentId={dragTitleId} dialogTitle={dialogTitle} draggable={isDraggable} />}

            <>
                <List
                    sx={{
                        maxWidth: 400,
                        minWidth: 250,
                        overflowY: 'auto',
                        maxHeight: 300,
                        p: 0,
                        ...getGlobalScrollStyle(theme)
                    }}
                >
                    {searchAllowed && (
                        <ListItem>
                            <TextField
                                fullWidth
                                autoFocus
                                size="small"
                                autoComplete="search criteria"
                                label={Lang.lblSearchBox}
                                type="text"
                                value={searchText}
                                onChange={onTypeSearch}
                                InputProps={{
                                    sx: { fontSize: { xs: 12, md: 15 }, pr: 0 },
                                    endAdornment: (
                                        <BtnIconTooltip
                                            displayMode="tooltip"
                                            icon={<ClearIcon />}
                                            onClick={() => {
                                                onTypeSearch(null, true);
                                            }}
                                            iconButtonProps={{
                                                color: 'secondary',
                                                size: 'small',
                                                sx: { border: 'none', p: 1 }
                                            }}
                                        >
                                            {Lang.btnClear}
                                        </BtnIconTooltip>
                                    )
                                }}
                            />
                        </ListItem>
                    )}
                    {filteredItems.map((item) => (
                        <ListItem
                            divider
                            key={item.id as string}
                            sx={{
                                overflowWrap: 'break-word',
                                p: 0,
                                ...dialogListSx(item.id === id)
                            }}
                        >
                            <ListItemButton onClick={() => onSelect(item.id)} sx={{ pt: 0.7, pb: 0.7 }}>
                                {item.icon && (
                                    <ListItemIcon>
                                        <item.icon color="primary" />
                                    </ListItemIcon>
                                )}
                                <ListItemText primary={item.value as string} sx={{ mr: 1 }} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
                <LoadingBtn
                    sx={{ alignSelf: 'flex-end' }}
                    buttonProps={{
                        size: 'small',
                        variant: 'contained',
                        type: 'submit',
                        onClick: onClose,
                        sx: { height: 27, fontWeight: 100 }
                    }}
                    loading={loading ?? false}
                >
                    {negativeTitle ? negativeTitle : Lang.btnClose}
                </LoadingBtn>
            </>
        </Dialog>
    );
};

export default DialogSelectInput;
