import React, { FC } from 'react';
import Lang from '../../models/language';
import { DialogDraggableTitle, getDialogPaperComponent } from '../draggable-paper';
import {
    CachedIcon,
    CheckBoxIcon,
    DeleteIcon,
    Dialog,
    DialogActions,
    DialogContent,
    DownloadIcon,
    IconButton,
    List,
    ListItem,
    Paper,
    RadioButtonCheckedIcon,
    Stack,
    Tooltip,
    UploadIcon,
    alpha,
    useTheme
} from '../mui';
import { Body2, Btn, RecordingIcon } from '../styled-components';

import { SelectItem } from '../../models/interfaces';
import { getGlobalScrollStyle } from '../../utils/style';
import { secondsToHMS } from '../../utils/time';
import BtnIconTooltip from '../btn-icon-tooltip';
import CountDownTimer from '../count-down-timer';
import Dropdown from '../dropdown';
import MiniPlayer from '../mini-player';
import { DialogVoiceTrackingProps } from './interfaces';
import { useVoiceTracking } from './use-voice-tracking';

const dragTitleId = 'draggable-voice-tracking-dialog-title';

const DialogVoiceTracking: FC<DialogVoiceTrackingProps> = ({
    dialogTitle,
    label,
    open,
    draggable,
    closable,
    positiveTitle,
    onClose,
    onPositiveEvent,
    isSingleFileUpload = false
}) => {
    const isDraggable = draggable === undefined || draggable;
    const isClosable = closable === undefined || closable;

    const theme = useTheme();

    const {
        devices,
        loading,
        inputDevice,
        isRecording,
        uploadItems,
        recordingTime,
        handleDelete,
        handleFileUpload,
        handleChange,
        startRecording,
        handleStopRecording,
        updateMediaDevices
    } = useVoiceTracking(open);

    const getSelectItems = () => devices.map((item) => ({ id: item.deviceId, value: item.label }) as SelectItem<string, string>);

    return (
        <Dialog
            open={open}
            onClose={() => {
                isClosable && onClose && onClose();
            }}
            PaperComponent={isDraggable ? getDialogPaperComponent(dragTitleId) : Paper}
            aria-labelledby={dragTitleId}
            fullWidth
            maxWidth="xs"
        >
            <DialogDraggableTitle componentId={dragTitleId} dialogTitle={dialogTitle} draggable={isDraggable} />

            <DialogContent sx={{ p: 2, ...getGlobalScrollStyle(theme) }}>
                {devices.length > 0 && (
                    <>
                        <Stack direction="row" alignItems="center" justifyContent="center" pt={1}>
                            <Dropdown
                                items={getSelectItems()}
                                label={label}
                                value={inputDevice ? inputDevice : devices[0].deviceId}
                                disabled={isRecording}
                                onChange={handleChange}
                                size="small"
                            />
                            <BtnIconTooltip
                                loading={loading}
                                displayMode="tooltip"
                                icon={<CachedIcon />}
                                iconButtonProps={{
                                    color: 'primary',
                                    onClick: updateMediaDevices
                                }}
                            >
                                {Lang.btnRefresh}
                            </BtnIconTooltip>
                        </Stack>
                        <Stack justifyContent="center" alignItems="center" mt={0.5}>
                            {!isRecording && (
                                <Tooltip title="Click to start recording" placement="top">
                                    <IconButton onClick={startRecording}>
                                        <RadioButtonCheckedIcon sx={{ fontSize: 170 }} color="disabled" />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {isRecording && (
                                <Tooltip title="Click to stop recording" placement="top">
                                    <IconButton onClick={handleStopRecording}>
                                        <RecordingIcon sx={{ fontSize: 170 }} color="error" />
                                    </IconButton>
                                </Tooltip>
                            )}
                            <CountDownTimer
                                recordingTime={recordingTime}
                                duration={600}
                                start={isRecording}
                                onComplete={handleStopRecording}
                            />
                        </Stack>

                        <List
                            sx={{
                                overflowY: 'auto',
                                maxHeight: 300,
                                ...getGlobalScrollStyle(theme)
                            }}
                        >
                            {uploadItems.map((uploadItem, index) => {
                                const { file, statusColor, isLoading, message, blobUrl, duration } = uploadItem;

                                return (
                                    <ListItem
                                        key={index}
                                        sx={{
                                            overflowWrap: 'break-word',
                                            p: 0.5,
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            background: index % 2 === 0 ? alpha(theme.palette.action.disabled, 0.05) : 'none'
                                        }}
                                    >
                                        <MiniPlayer url={blobUrl} sx={{ p: 0.3 }} />
                                        <Body2 sx={{ ml: 2, mr: 2 }}>
                                            {file.name} - {secondsToHMS(duration)}
                                        </Body2>
                                        <Stack direction="row" spacing={0.5} justifyContent="flex-end" alignItems="center">
                                            {isSingleFileUpload && (
                                                <BtnIconTooltip
                                                    displayMode="tooltip"
                                                    icon={statusColor === 'primary' ? <UploadIcon /> : <CheckBoxIcon />}
                                                    loading={isLoading}
                                                    iconButtonProps={{
                                                        color: statusColor,
                                                        onClick: () =>
                                                            statusColor === 'primary' ? handleFileUpload(uploadItem) : null,
                                                        size: 'small',
                                                        sx: {
                                                            p: 0.3
                                                        },
                                                        disabled: isLoading
                                                    }}
                                                >
                                                    {message ?? Lang.btnUpload}
                                                </BtnIconTooltip>
                                            )}
                                            <a href={blobUrl} download={file.name}>
                                                <BtnIconTooltip
                                                    displayMode="tooltip"
                                                    icon={<DownloadIcon />}
                                                    iconButtonProps={{
                                                        color: 'primary',
                                                        size: 'small',
                                                        sx: {
                                                            p: 0.3
                                                        }
                                                    }}
                                                >
                                                    {Lang.btnDownload}
                                                </BtnIconTooltip>
                                            </a>

                                            {(!isSingleFileUpload || statusColor === 'primary') && (
                                                <BtnIconTooltip
                                                    displayMode="tooltip"
                                                    icon={<DeleteIcon />}
                                                    iconButtonProps={{
                                                        color: 'primary',
                                                        onClick: () => handleDelete(index),
                                                        size: 'small',
                                                        sx: {
                                                            p: 0.3
                                                        }
                                                    }}
                                                >
                                                    {Lang.btnDelete}
                                                </BtnIconTooltip>
                                            )}
                                        </Stack>
                                    </ListItem>
                                );
                            })}
                        </List>
                    </>
                )}
            </DialogContent>

            <DialogActions>
                <Btn size="small" variant="text" onClick={onClose}>
                    {Lang.btnClose}
                </Btn>
                {!isSingleFileUpload && (
                    <Btn
                        size="small"
                        variant="contained"
                        onClick={() => onPositiveEvent && onPositiveEvent(uploadItems.map((item) => item.file))}
                        disabled={devices.length === 0 || uploadItems.length == 0}
                    >
                        {positiveTitle ?? Lang.btnSave}
                    </Btn>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default DialogVoiceTracking;
