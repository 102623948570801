import { PaletteOptions, ThemeOptions } from '../components/mui';
import { AnchorPosition } from '../models/interfaces';
import {
    aquaMarineCl,
    chelseaGemCl,
    citronCl,
    funBlueCl,
    indigoCl,
    leafCl,
    napierGreenCl,
    oliveDrabCl,
    raspberryCl,
    samOrangeCl,
    skyBlueCl,
    warmRedCl
} from './colors';

interface ICustomThemeOptions {
    themeDrawer: {
        anchor: AnchorPosition;
    };
}

declare module '@mui/material/styles' {
    // fix the type error when referencing the Theme object in your styled component
    interface Theme {
        customThemeOptions?: ICustomThemeOptions;
    }
    // fix the type error when calling `createTheme()` with a custom theme option
    interface ThemeOptions {
        customThemeOptions?: ICustomThemeOptions;
    }
}

const headingTypography = {
    fontFamily: 'Exo',
    fontWeightLight: 300,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700
};
const buttonTypography = {
    ...headingTypography,
    fontFamily: 'Roboto',
    letterSpacing: 1,
    wordSpacing: 1
};

export const defaultTheme: ThemeOptions = {
    components: {
        MuiTooltip: {
            defaultProps: {
                arrow: true
            }
        }
    },
    customThemeOptions: {
        themeDrawer: {
            anchor: 'top'
        }
    },
    shape: {
        borderRadius: 4
    },
    typography: {
        fontFamily: 'Verdana, Roboto, Sans Serif',
        h1: headingTypography,
        h2: headingTypography,
        h3: headingTypography,
        h4: headingTypography,
        h5: headingTypography,
        h6: headingTypography,
        button: buttonTypography
    }
};
export const lightPalette: PaletteOptions = {
    primary: samOrangeCl,
    secondary: oliveDrabCl,
    text: {
        primary: '#546491'
    },
    error: {
        main: '#f12828'
    },
    warning: {
        main: '#FF9800'
    },
    info: {
        main: '#288df1'
    },
    success: leafCl,
    divider: 'rgba(0,0,0,0.14)'
};
export const darkPalette: PaletteOptions = {
    background: {
        default: '#292929'
        //paper: '#515151'// This colour might be used if the default's too dark, but I find it clashes a bit.
    }
};

export interface ColorPalette {
    name: string;
    paletteOptions: PaletteOptions;
}

/**
 * All Theme palettes.
 */
export const ColorPalettes: Array<ColorPalette> = [
    {
        name: 'SAM Cloud (default)',
        paletteOptions: {
            primary: samOrangeCl,
            secondary: oliveDrabCl
        }
    },
    {
        name: 'Olive Drab',
        paletteOptions: {
            primary: oliveDrabCl,
            secondary: samOrangeCl
        }
    },
    {
        name: 'Napier Green',
        paletteOptions: {
            primary: napierGreenCl,
            secondary: indigoCl
        }
    },
    {
        name: 'Indigo',
        paletteOptions: {
            primary: indigoCl,
            secondary: napierGreenCl
        }
    },
    {
        name: 'Leaf Green',
        paletteOptions: {
            primary: leafCl,
            secondary: citronCl
        }
    },
    {
        name: 'Citron Green',
        paletteOptions: {
            primary: citronCl,
            secondary: leafCl
        }
    },
    {
        name: 'Fun Blue',
        paletteOptions: {
            primary: funBlueCl,
            secondary: chelseaGemCl
        }
    },
    {
        name: 'Chelsea Gem',
        paletteOptions: {
            primary: chelseaGemCl,
            secondary: funBlueCl
        }
    },
    {
        name: 'Raspberry',
        paletteOptions: {
            primary: raspberryCl,
            secondary: aquaMarineCl
        }
    },
    {
        name: 'Aqua Marine',
        paletteOptions: {
            primary: aquaMarineCl,
            secondary: raspberryCl
        }
    },
    {
        name: 'Warm Red',
        paletteOptions: {
            primary: warmRedCl,
            secondary: skyBlueCl
        }
    },
    {
        name: 'Sky Blue',
        paletteOptions: {
            primary: skyBlueCl,
            secondary: warmRedCl
        }
    }
];

export interface SavedThemeOptions {
    borderRadius: number;
    colorMode: PaletteMode;
    colorPalette: number;
    spacing: number;
    drawerAnchor: AnchorPosition;
}

export const defaultThemOptions: SavedThemeOptions = {
    borderRadius: 4,
    colorMode: 'light',
    colorPalette: 0,
    spacing: 8,
    drawerAnchor: 'bottom'
};

export const borderRadiusStep = 2;
export const borderRadiusMin = 0;
export const borderRadiusMax = 28;
export const borderMarks = [{ value: 0 }, { value: 4 }, { value: 10 }, { value: 16 }, { value: 22 }, { value: borderRadiusMax }];

export const spacingStep = 1;
export const spacingMin = 1;
export const spacingMax = 16;
export const spacingMarks = [{ value: spacingMin }, { value: 4 }, { value: 8 }, { value: 12 }, { value: spacingMax }];
export const spacingSupportedMin = 4;
export const spacingSupportedMax = 12;

export function isSupportedSpacing(spacing: number) {
    if (spacing >= spacingSupportedMin && spacing <= spacingSupportedMax) {
        return true;
    }
    return false;
}
export function getClosestSpacingRecommended(spacing: number) {
    if (spacing < spacingSupportedMin) {
        return spacingSupportedMin;
    } else if (spacing > spacingSupportedMax) {
        return spacingSupportedMax;
    }

    return defaultThemOptions.spacing;
}

/**
 * The default amount to darken or lighten when user interaction happens e.g. hover.
 */
export const darkenOrLighten = 0.1;

export const alertNotificationPosition = 166;
export const mainDrawerWidthSmall = 20;
export const mainDrawerWidth = 240;
export const customizeDrawerWidth = 340;

export type PaletteMode = 'light' | 'dark';
