import React, { createContext, FC, useContext, useState } from 'react';
import { DropPosition, LibTblData, SelectItem, TblColType, Void } from '../models/interfaces';
import { ParentDom, TriggerOnDropFunc } from '../models/props';
import { useTreeView } from '../pages/station/library';
import { TableEntity } from '../utils/signalr/models';

/**
 * If you want drag functionality, rig it up with this provider.
 */

export interface DragState {
    isTableSwap?: boolean;
    tableEntityFrom: TableEntity | false;
    tableEntityTo: TableEntity | false;
    selectedItems: Array<SelectItem<TableEntity, TblColType[]>> | string[];
}

interface IDragContext {
    dragState: DragState;
    setSelectedItems: (tableEntity: TableEntity, newSelectedIds: TblColType[]) => void;
    setTableEntityTo: Void<TableEntity | false>;
    setDragActive: (
        tableEntity: TableEntity,
        tableData: LibTblData<TblColType>,
        dragActive: boolean,
        isTableSwap?: boolean
    ) => void;
    onItemsDrop: (
        tableEntity: TableEntity,
        tableData: LibTblData<TblColType>,
        targetRowData: TblColType | undefined,
        index: DropPosition,
        triggerOnDrop: TriggerOnDropFunc
    ) => void;
}

const initDragContext: Partial<IDragContext> = {
    dragState: { tableEntityTo: false, tableEntityFrom: false, selectedItems: [] }
};

const DragContext = createContext(initDragContext as IDragContext);

export function useDrag() {
    return useContext(DragContext);
}

export const DragProvider: FC<ParentDom> = ({ children }) => {
    const [dragState, setDragState] = useState(initDragContext.dragState as DragState);
    const { setLibLayoutHighlight, libLayout } = useTreeView();

    const value = {
        dragState,
        setTableEntityTo: (tableEntityTo: TableEntity | false) => {
            setDragState((prevState) => {
                return prevState.tableEntityTo !== tableEntityTo ? { ...prevState, tableEntityTo } : prevState;
            });
        },
        setDragActive: (
            tableEntity: TableEntity,
            tableData: LibTblData<TblColType>,
            dragActive: boolean,
            isTableSwap?: boolean
        ) => {
            let moveableEntities = tableData.movable;
            if (isTableSwap) {
                moveableEntities = libLayout.tableComponents
                    .filter((tbl) => tbl?.tableEntity && tbl.tableEntity !== tableEntity)
                    .map((tbl) => tbl?.tableEntity) as TableEntity[];
            }

            for (let i = 0; i < moveableEntities.length; i++) {
                const element = moveableEntities[i];
                setLibLayoutHighlight(element, dragActive);
            }

            setDragState((prevState) => {
                return { ...prevState, tableEntityFrom: dragActive ? tableEntity : false, isTableSwap };
            });
        },
        setSelectedItems: (tableEntity: TableEntity, newSelectedIds: TblColType[]) => {
            const newItems = [...newSelectedIds];
            setDragState((prevState) => {
                const selectedItems = prevState.selectedItems as Array<SelectItem<TableEntity, TblColType[]>>;
                const selectedItemSet = selectedItems.find((item) => item.id === tableEntity);
                if (selectedItemSet) {
                    selectedItemSet.value = newItems;
                } else {
                    selectedItems.push({ id: tableEntity, value: newItems });
                }
                return { ...prevState, selectedItems: [...selectedItems] };
            });
        },
        onItemsDrop: (
            targetTableEntity: TableEntity,
            tableData: LibTblData<TblColType>,
            targetRowData: TblColType | undefined,
            dropPosition: DropPosition,
            triggerOnDrop: TriggerOnDropFunc
        ) => {
            const { tableEntityFrom } = dragState;
            const selectedItems = dragState.selectedItems as Array<SelectItem<TableEntity, TblColType[]>>;
            const selectedItemSet = selectedItems.find((item) => item.id === tableEntityFrom) as SelectItem<
                TableEntity,
                TblColType[]
            >;
            const isMovable = tableData.movable.indexOf(targetTableEntity) > -1;

            if (isMovable && tableEntityFrom) {
                triggerOnDrop(dropPosition, tableEntityFrom, selectedItemSet.value, targetRowData);
            }
        }
    };

    return <DragContext.Provider value={value}>{children}</DragContext.Provider>;
};

export default DragProvider;
