import React, { FC } from 'react';
import { useEditMedia } from '../..';
import LabelSlider from '../../../label-slider';
import { Box, Stack, Typography, useTheme } from '../../../mui';
import { gainAltKey, gainKey } from './consts';

export const GainInputItem: FC = () => {
    const { palette } = useTheme();
    const { currentAudioSettingsItem, onSettingChange } = useEditMedia();

    const audioSettings = currentAudioSettingsItem?.audioSettings;

    if (!audioSettings) {
        return <></>;
    }

    return (
        <Stack direction="row" justifyContent="space-between" flex={1} sx={{ m: 1 }} alignItems="center">
            <Box sx={{ m: 1, ml: 0, mr: 1 }}>
                <Typography variant="body1">Gain</Typography>
                <Typography variant="body2" color={palette.text.secondary}>
                    Set the gain value
                </Typography>
            </Box>
            <LabelSlider
                step={0.1}
                min={-6}
                max={6}
                unit="dB"
                name={gainAltKey}
                value={audioSettings.Gain}
                onChange={(value) => onSettingChange(value, gainKey)}
                round={1}
            />
        </Stack>
    );
};
