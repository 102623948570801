import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';
import { PlayerTimerProps } from '../../models/props';
import useLocalStorage, { LocalStorageType } from '../../utils/local-storage';
import { formatTimeMMSS, getNow } from '../../utils/time';
import { Tooltip } from '../mui';
import { Body2, Btn } from '../styled-components';
import Wrap from '../wrap';
import { DurationModeData, durationModeDefault } from './models';
import TimeSelectionMenu from './time-selection-menu';
import { getDurationMode, getPlayerDataValue, setDuration } from './util';

const oneSecond = 1000;

/**
 * Dependent on the player-bar.
 */
const PlayerTime: FC<PlayerTimerProps> = ({ isLoading, isPlaying, playerData, setIsLoading }) => {
    const { palette } = useTheme();
    const [, setLastLiveTimestamp] = useState(getNow());
    const [lastDuration, setLastDuration] = useState('00:00');
    const [displayTime, setDisplayTime] = useState('00:00');

    const [timeMenuAnchor, setTimeMenuAnchor] = useState<HTMLElement>();
    const [durationType, saveDurationType] = useLocalStorage(LocalStorageType.PLAYER_DURATION_TYPE, durationModeDefault);
    // This has to be a memo because it's used in the useEffect:
    const durationMode = useMemo(() => getDurationMode(durationType), [durationType]);

    const handleSelectTimeMenu = (durationModeData: DurationModeData) => {
        handleCloseTimeMenu();
        setIsLoading(true);
        saveDurationType(durationModeData.type);
    };

    const handleCloseTimeMenu = () => {
        setTimeMenuAnchor(undefined);
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setTimeMenuAnchor(event.currentTarget);
    };

    const mediaItemIdToLookAt = useMemo(() => getPlayerDataValue('MediaItemId', playerData), [playerData]);

    // PlayerData will change when cuePointChanged executes:
    useEffect(() => {
        const _lastDuration = getPlayerDataValue('Duration', playerData);
        // Reset both timestamps:
        setLastDuration(_lastDuration);

        const now = getNow();
        const _lastHistoryTimestamp = !isPlaying ? (playerData ? getPlayerDataValue('DatePlayedUnix', playerData) : now) : now;
        setLastLiveTimestamp(_lastHistoryTimestamp);
    }, [isPlaying, mediaItemIdToLookAt]);

    const onDuration = () => {
        setIsLoading(false);
        // This was the only way to get the correct lastLiveTimestamp or lastHistoryTimestamp:
        setLastLiveTimestamp((prevState) => {
            const newPosition = getNow() - prevState;
            const newPos = formatTimeMMSS(newPosition);
            setDuration(lastDuration, newPos, durationMode, setLastDuration, setDisplayTime);
            return prevState;
        });
    };

    useEffect(() => {
        const timerInterval: NodeJS.Timeout = setInterval(onDuration, oneSecond);
        return () => {
            clearInterval(timerInterval);
        };
    }, [lastDuration, durationMode]);

    const onClickProps = !isLoading && { onClick: handleClick };

    return (
        <div style={{ cursor: 'pointer' }}>
            <Wrap
                isWrapped={!isLoading}
                wrapper={(child) => (
                    <Tooltip title={durationMode.title} placement="right-end">
                        {child}
                    </Tooltip>
                )}
            >
                <Btn
                    size="small"
                    variant="outline"
                    {...onClickProps}
                    disabled={isLoading}
                    sx={{ '&:hover': { background: palette.secondary.main } }}
                >
                    <Body2 dangerouslySetInnerHTML={{ __html: displayTime }} />
                </Btn>
            </Wrap>

            <TimeSelectionMenu
                anchorEl={timeMenuAnchor}
                selected={durationMode.type}
                handleClose={handleCloseTimeMenu}
                handleSelect={handleSelectTimeMenu}
            />
        </div>
    );
};

export default PlayerTime;
