import { BaseResponseDto, BooleanResponseDto, NumberResponseDto, UserPermissions, UserPermissionsDto } from '../models/dto';
import { IProfileImageDto, PersonDetails, PersonDetailsDto } from '../pages/profile/models/interfaces';
import { DJDto, UserRequest } from '../pages/station/settings/dj-management/models/interfaces';

import { BaseUrl } from '../utils/env';
import { Fetch } from '../utils/middleware';
import { getRequestInitDELETE, getRequestInitGET, getRequestInitPOST, getRequestInitPUT } from './headers';

const userApi = 'api/user';

const lblStationId = '{stationId}';
const lblUserId = '{userId}';
const lblServiceId = '{serviceId}';
const lblStationOnly = '{stationOnly}';
const lblUpdateAllStations = '{updateAllStations}';

const urlPermissions = `${userApi}/${lblServiceId}/${lblStationId}/permissions`;
const urlDeletePermissions = `${userApi}/${lblServiceId}/${lblStationId}/${lblUserId}/permissions?stationOnly=${lblStationOnly}`;
const urlCreateUser = `${userApi}/${lblServiceId}/${lblStationId}`;
const urlGetDjs = `${userApi}/${lblServiceId}/${lblStationId}/djs`;
const urlUpdatePermissions = `${userApi}/${lblServiceId}/${lblStationId}/permissions?updateAllStations=${lblUpdateAllStations}`;
const urlGetPersonDetails = `${userApi}/personDetails`;
const urlUploadImage = `${userApi}/profileImage`;

export async function getPermissions(stationId: string, serviceId: string): Promise<UserPermissionsDto> {
    const url = `${BaseUrl()}${urlPermissions}`.replace(lblServiceId, serviceId).replace(lblStationId, stationId);
    return await Fetch(url, getRequestInitGET());
}

export async function updatePermissions(
    stationId: string,
    serviceId: string,
    updateAllStations: boolean,
    userPermissions: UserPermissions[]
): Promise<BaseResponseDto> {
    const url = `${BaseUrl()}${urlUpdatePermissions}`
        .replace(lblServiceId, serviceId)
        .replace(lblStationId, stationId)
        .replace(lblUpdateAllStations, updateAllStations.toString());
    return await Fetch(url, { body: JSON.stringify(userPermissions), ...getRequestInitPUT() });
}

export async function createUser(stationId: string, serviceId: string, user: UserRequest): Promise<NumberResponseDto> {
    const url = `${BaseUrl()}${urlCreateUser}`.replace(lblServiceId, serviceId).replace(lblStationId, stationId);
    return await Fetch(url, { body: JSON.stringify(user), ...getRequestInitPOST() });
}

export async function deletePermissions(
    stationId: string,
    serviceId: string,
    userId: string,
    stationOnly: string
): Promise<UserPermissionsDto> {
    const url = `${BaseUrl()}${urlDeletePermissions}`
        .replace(lblServiceId, serviceId)
        .replace(lblStationId, stationId)
        .replace(lblUserId, userId)
        .replace(lblStationOnly, stationOnly);
    return await Fetch(url, getRequestInitDELETE());
}

export async function getDjs(stationId: string, serviceId: string): Promise<DJDto> {
    const url = `${BaseUrl()}${urlGetDjs}`.replace(lblServiceId, serviceId).replace(lblStationId, stationId);
    return await Fetch(url, getRequestInitGET());
}

export async function getPersonDetails(): Promise<PersonDetailsDto> {
    const url = `${BaseUrl()}${urlGetPersonDetails}`;
    return await Fetch(url, getRequestInitGET());
}

export async function updatePersonDetails(data: PersonDetails): Promise<BooleanResponseDto> {
    const url = `${BaseUrl()}${urlGetPersonDetails}`;
    return await Fetch(url, { ...getRequestInitPUT(), body: JSON.stringify(data) });
}

export async function uploadProfileImage(data: FormData): Promise<IProfileImageDto> {
    const url = `${BaseUrl()}${urlUploadImage}`;
    return await Fetch(url, {
        ...getRequestInitPOST(false),
        body: data
    });
}
