import React, { FC } from 'react';
import { DraggableBoxProps } from '../models/props';
import { Box, FormatLineSpacingIcon, Tooltip, alpha, useTheme } from './mui';

const startPx = '14px';
const endPx = '36px';
const boxThickness = '6px';
const borderRadius = '0px';
const boxAlpha = 0.1;
const initAlpha = 0.7;
const boxHandleName = 'box-handle';

// Vertical = 8 + 6 + 6 = 20;
// Horizontal = 8 + 4 + 6 = 18;

/**
 * Vertical Draggable Box (drag left or right with Flex Row).
 */
export const DraggableBox: FC<DraggableBoxProps> = ({ renderColumn, onDoubleClick, onDragStart, onDragEnd }) => {
    const theme = useTheme();
    const style = renderColumn
        ? {
              marginTop: '8px',
              marginBottom: '4px'
          }
        : {
              marginLeft: '8px',
              marginRight: '6px'
          };

    const sx = renderColumn
        ? {
              height: boxThickness,
              cursor: 'row-resize'
          }
        : {
              width: boxThickness,
              cursor: 'col-resize'
          };
    const boxHandleSx = renderColumn
        ? { left: startPx, right: endPx, height: boxThickness }
        : { top: startPx, bottom: endPx, width: boxThickness };
    const iconSx = renderColumn
        ? { top: '-8px', right: 4 }
        : {
              transform: 'rotate(90deg)',
              bottom: 4,
              right: '-10px'
          };
    return (
        <Box
            draggable
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            style={style}
            sx={{
                border: 'none',
                position: 'relative',
                ...sx,
                '&:hover': {
                    ['div, svg']: {
                        transition: theme.transitions.create(['opacity', 'color', 'background'], {
                            duration: theme.transitions.duration.shortest,
                            easing: theme.transitions.easing.easeIn
                        }),
                        color: theme.palette.primary.main,
                        opacity: 1,
                        [`&.${boxHandleName}`]: {
                            background: alpha(theme.palette.secondary.main, 0.5)
                        }
                    }
                }
            }}
            onDoubleClick={(e) => {
                // Reset functionality most likely:
                onDoubleClick && onDoubleClick(e);
            }}
        >
            <Box
                draggable={false}
                className={boxHandleName}
                style={{ borderRadius }}
                sx={{
                    position: 'absolute',
                    opacity: initAlpha,
                    background: alpha(theme.palette.divider, boxAlpha),
                    ...boxHandleSx
                }}
            />
            <Tooltip title="Drag to grow; Double click to reset to default">
                <FormatLineSpacingIcon
                    sx={{
                        position: 'absolute',
                        opacity: initAlpha,
                        fontSize: '24px',
                        color: theme.palette.divider,
                        ...iconSx
                    }}
                />
            </Tooltip>
        </Box>
    );
};

export default DraggableBox;
