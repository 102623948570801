import React, { FC, useState } from 'react';
import {
    acknowledgeAllUserNotifications,
    acknowledgeUserNotifications,
    dismissAllUserNotifications,
    dismissUserNotifications,
    getUserNotifications,
    getUserNotificationsCount
} from '../middleware/account';
import { NotificationItem, NotificationStatus } from '../models/dto';
import Lang from '../models/language';
import { UserNotificationsProps } from '../models/props';
import { useAccount } from '../providers/account';
import { Notification, useNotification } from '../providers/notifications';
import { useEffectAsync } from '../utils/react-util';
import { getGlobalScrollStyle, getMaxBorderRadius } from '../utils/style';
import BtnIconTooltip from './btn-icon-tooltip';
import {
    Badge,
    CircularProgress,
    ClearAllIcon,
    ClearIcon,
    DoneAllIcon,
    DoneIcon,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    NotificationsActiveIcon,
    NotificationsIcon,
    Popover,
    Stack,
    alpha,
    useTheme
} from './mui';
import { Body2, H6 } from './styled-components';

const UserNotifications: FC<UserNotificationsProps> = ({ persistNotificationButton = true }) => {
    const { accountState, userNotifications, userNotificationCount, setUserNotifications, setUserNotificationCount } =
        useAccount();
    const { addNotification } = useNotification();
    const [isLoading, setIsLoading] = useState<string | false>(false);
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement>();
    const open = Boolean(anchorEl);

    const fetchUserNotifications = async () => {
        setIsLoading(Lang.rLoading);
        const res = await getUserNotifications();
        if (res.success) {
            setUserNotifications(res.data);
        } else {
            addNotification(
                new Notification({
                    message: res.message,
                    severity: 'error'
                })
            );
        }
        setIsLoading(false);
    };

    const fetchUserNotificationsCount = async () => {
        setIsLoading(Lang.rLoading);
        const res = await getUserNotificationsCount();
        if (res.success) {
            setUserNotificationCount(res.value);
        } else {
            addNotification(
                new Notification({
                    message: res.message,
                    severity: 'error'
                })
            );
        }
        setIsLoading(false);
    };

    /**
     * If item is undefined, acknowledge all.
     */
    const onAcknowledge = async (item?: NotificationItem) => {
        setIsLoading(Lang.lblAcknowleging);
        const res = await (item ? acknowledgeUserNotifications(item.NotificationItemId) : acknowledgeAllUserNotifications());
        if (res.success) {
            await fetchUserNotificationsCount();
            addNotification(
                new Notification({
                    message: Lang.msgAcknowledgeNotifications,
                    severity: 'success'
                })
            );
            if (!item) {
                onClose();
            }
        } else {
            addNotification(
                new Notification({
                    message: res.message,
                    severity: 'error'
                })
            );
        }
        setIsLoading(false);
    };

    /**
     * If item is undefined, dismiss all.
     */
    const onDismiss = async (item?: NotificationItem) => {
        setIsLoading(Lang.lblDismissing);
        const res = await (item ? dismissUserNotifications(item.NotificationItemId) : dismissAllUserNotifications());
        if (res.success) {
            addNotification(
                new Notification({
                    message: Lang.msgDismissNotifications,
                    severity: 'success'
                })
            );
            if (!item) {
                onClose();
            }
        } else {
            addNotification(
                new Notification({
                    message: res.message,
                    severity: 'error'
                })
            );
        }
        setIsLoading(false);
    };

    useEffectAsync(async () => {
        if (open) {
            await fetchUserNotifications();
        } else if (accountState.loggedIn) {
            await fetchUserNotificationsCount();
        }
    }, [accountState.loggedIn, open]);

    const onOpen = (event) => {
        setAnchorEl(event.currentTarget.firstElementChild);
    };

    const onClose = () => {
        setAnchorEl(undefined);
    };

    const borderRadius = getMaxBorderRadius(theme);

    const hasUserNotifications = !isLoading && userNotifications.length > 0;
    const userNotificationsTitle = isLoading
        ? isLoading
        : hasUserNotifications
          ? Lang.lblUserNotifications
          : Lang.lblUserNotificationsNone;
    const showAcknoledgeAllBtn = Boolean(userNotifications?.some((item) => item.Status === NotificationStatus.New));
    const showDismissAllBtn = Boolean(
        userNotifications?.some(
            (item) => item.Status === NotificationStatus.New || item.Status === NotificationStatus.Acknowledged
        )
    );

    // To declutter the buttons on main-app-bar:
    const showNotificationsButton = persistNotificationButton || userNotificationCount > 0;
    const disabled = Boolean(isLoading);

    return (
        <>
            {showNotificationsButton && (
                <BtnIconTooltip
                    displayMode="tooltip"
                    icon={
                        <Badge
                            badgeContent={userNotificationCount}
                            variant="standard"
                            color="secondary"
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                            sx={{
                                '& .MuiBadge-badge': {
                                    right: 4,
                                    top: 4,
                                    borderRadius: `${borderRadius < 8 ? 8 : borderRadius}px`,
                                    border: `1px solid ${theme.palette.background.paper}`,
                                    padding: '0'
                                }
                            }}
                        >
                            <NotificationsIcon />
                        </Badge>
                    }
                    iconButtonProps={{
                        highlight: open,
                        onClick: onOpen,
                        color: 'primary',
                        size: 'small',
                        sx: { m: theme.spacing(1), ml: 0 }
                    }}
                >
                    {Lang.btnUserNotifications}
                </BtnIconTooltip>
            )}
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={onClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                sx={{ top: 36 }}
            >
                <List
                    sx={{
                        maxWidth: 400,
                        minWidth: 400,
                        overflowY: 'auto',
                        maxHeight: 300,
                        ...getGlobalScrollStyle(theme)
                    }}
                >
                    <ListItem sx={{ background: alpha(theme.palette.primary.main, 0.1) }}>
                        <ListItemIcon>
                            {isLoading ? (
                                <CircularProgress size={24} color="primary" />
                            ) : (
                                <NotificationsIcon color={hasUserNotifications ? 'primary' : 'inherit'} />
                            )}
                        </ListItemIcon>
                        <ListItemText primary={<H6>{userNotificationsTitle}</H6>} />
                        <Stack direction="row" spacing={1}>
                            {showAcknoledgeAllBtn && (
                                <BtnIconTooltip
                                    displayMode="tooltip"
                                    icon={<DoneAllIcon />}
                                    iconButtonProps={{
                                        disabled,
                                        onClick: () => onAcknowledge(),
                                        outlined: false,
                                        size: 'small'
                                    }}
                                >
                                    {Lang.btnAcknowledgeAll}
                                </BtnIconTooltip>
                            )}
                            {showDismissAllBtn && (
                                <BtnIconTooltip
                                    displayMode="tooltip"
                                    icon={<ClearAllIcon />}
                                    iconButtonProps={{
                                        disabled,
                                        onClick: () => onDismiss(),
                                        outlined: false,
                                        size: 'small'
                                    }}
                                >
                                    {Lang.btnDismissAll}
                                </BtnIconTooltip>
                            )}
                        </Stack>
                    </ListItem>
                    {userNotifications.map((item, index) => {
                        return (
                            <ListItem key={item.NotificationItemId} divider={index !== userNotifications.length - 1}>
                                <ListItemIcon>
                                    {item.Status === NotificationStatus.New ? (
                                        <NotificationsActiveIcon color="primary" />
                                    ) : (
                                        <NotificationsIcon
                                            color={item.Status === NotificationStatus.Acknowledged ? 'inherit' : 'disabled'}
                                        />
                                    )}
                                </ListItemIcon>
                                <Body2 dangerouslySetInnerHTML={{ __html: item.NotificationMessage }}></Body2>
                                <Stack direction="row" spacing={1}>
                                    <BtnIconTooltip
                                        displayMode="tooltip"
                                        icon={<DoneIcon />}
                                        iconButtonProps={{
                                            disabled:
                                                disabled ||
                                                item.Status === NotificationStatus.Acknowledged ||
                                                item.Status === NotificationStatus.Dismissed,
                                            onClick: () => onAcknowledge(item),
                                            outlined: false,
                                            size: 'small'
                                        }}
                                    >
                                        {Lang.btnAcknowledge}
                                    </BtnIconTooltip>
                                    <BtnIconTooltip
                                        displayMode="tooltip"
                                        icon={<ClearIcon />}
                                        iconButtonProps={{
                                            disabled: disabled || item.Status === NotificationStatus.Dismissed,
                                            onClick: () => onDismiss(item),
                                            outlined: false,
                                            size: 'small'
                                        }}
                                    >
                                        {Lang.btnDismiss}
                                    </BtnIconTooltip>
                                </Stack>
                            </ListItem>
                        );
                    })}
                </List>
            </Popover>
        </>
    );
};

export default UserNotifications;
