import React, { FC, useState } from 'react';
import AutoSizer from 'react-virtualized/dist/es/AutoSizer';
import Table, { Column, SortDirectionType } from 'react-virtualized/dist/es/Table';
import { useEditMedia } from '..';
import { HistoryItemDto } from '../../../models/dto';
import Lang from '../../../models/language';
import { extractItemData } from '../../../utils/general';
import { useEffectAsync } from '../../../utils/react-util';
import { getVirtualizedTableStyle } from '../../../utils/style';
import CenteredCircularLoader from '../../centered-circular-loader';
import { TableRowStyling } from '../../../utils/table-row-styling';
import { Alert, Stack, useTheme } from '../../mui';
import { initHistorySortState } from '../consts';
import { SortState } from '../interfaces';
import { historyItemCols } from '../table-data';
import { sortList } from '../utils';
import { TblContainer } from '../../styled-components';

const HistoryPage: FC = () => {
    const theme = useTheme();
    const [isLoading, setIsLoading] = useState(false);
    const [sortState, setSortState] = useState<SortState<HistoryItemDto>>(initHistorySortState);
    const { currentHistoryItem, setItemHistoryItems } = useEditMedia();
    const sortedList = sortList(sortState, currentHistoryItem?.value).list;

    useEffectAsync(async () => {
        if (!(currentHistoryItem && currentHistoryItem.value)) {
            setIsLoading(true);
            await setItemHistoryItems();
            setIsLoading(false);
        }
    }, [currentHistoryItem?.value]);

    const onSort = (info: { sortBy: string; sortDirection: SortDirectionType }) => {
        setSortState((prevState) => ({
            ...prevState,
            sortBy: info.sortBy as keyof HistoryItemDto,
            sortDirection: info.sortDirection
        }));
    };

    const totalWidth = historyItemCols.reduce((accumulator, object) => accumulator + object.width, 0);

    return isLoading ? (
        <CenteredCircularLoader />
    ) : sortedList && sortedList.length > 0 ? (
        <Stack direction="column" spacing={1}>
            <TblContainer
                sx={getVirtualizedTableStyle(theme, false, false)}
                style={{ overflow: 'hidden scroll !important', height: 300 }}
            >
                <AutoSizer>
                    {({ height, width }) => (
                        <Table
                            width={width}
                            height={height}
                            headerHeight={20}
                            rowHeight={30}
                            sort={onSort}
                            sortBy={sortState.sortBy}
                            sortDirection={sortState.sortDirection}
                            rowCount={sortedList?.length ?? 0}
                            rowGetter={({ index }) => sortedList && sortedList[index]}
                            rowStyle={({ index }) => {
                                const rowCSS = new TableRowStyling({
                                    index,
                                    theme,
                                    rowChecked: false,
                                    rowDragging: false,
                                    dragAboveItemActive: false,
                                    dragBelowItemActive: false
                                });
                                return rowCSS.getFinalStyling();
                            }}
                        >
                            {historyItemCols.map((item) => {
                                return (
                                    <Column
                                        key={item.dataKey}
                                        label={item.label}
                                        dataKey={item.dataKey}
                                        width={width * (item.width / totalWidth)}
                                        cellDataGetter={({ dataKey, rowData }) => {
                                            const itemData = extractItemData(dataKey, rowData);
                                            return item.labelFormatter ? item.labelFormatter(itemData, rowData) : itemData;
                                        }}
                                        headerStyle={{ textAlign: 'center', ...item.headerStyle }}
                                        style={{ ...item.style }}
                                    />
                                );
                            })}
                        </Table>
                    )}
                </AutoSizer>
            </TblContainer>
            <Alert variant="outlined" severity="info">
                {Lang.msgHistoryItemsInfo}
            </Alert>
        </Stack>
    ) : (
        <Alert variant="outlined" severity="warning">
            {Lang.msgNoListData}
        </Alert>
    );
};

export default HistoryPage;
