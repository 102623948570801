import React, { FC, useState } from 'react';
import Icon from '../images/image';
import { startStation, stopStation } from '../middleware/stations';
import { SamVibeStationDto } from '../models/dto';
import Lang from '../models/language';
import { StationDataProps } from '../models/props';
import { Notification, useNotification } from '../providers/notifications';
import BtnIconTooltip from './btn-icon-tooltip';
import DialogConfirmation from './dialog-confirmation';
import { PodcastsIcon, PowerSettingsNewIcon, Stack, Tooltip, Typography, alpha, useTheme } from './mui';

const maxStationWidthXs = '120px';
const maxStationWidthMd = '250px';
const maxStationWidthLg = '322px';
const maxHeightIcon = '60px';

const StationStatus: FC<StationDataProps> = ({ stationIsStarted: isStarted, stationData }) => {
    const theme = useTheme();
    const { palette, shape } = theme;
    const { addNotification } = useNotification();
    const [onAirLoading, setOnAirLoading] = useState<string | false>(false);
    const [offAirDialogOpen, setOffAirDialogOpen] = useState(false);

    const isLoading = Boolean(onAirLoading) || !(stationData && stationData.stationInfo);

    const grayedOut = palette.grey[300];
    const iconColour = isLoading ? 'primary' : isStarted ? 'success' : 'warning';
    const txtColour = isLoading ? 'primary' : isStarted ? 'success' : 'warning';

    const startStopStation = async (station: SamVibeStationDto, start: boolean) => {
        const response = await (start ? startStation : stopStation)(station.StationId.toString());

        if (response.success) {
            const { Name } = station;
            addNotification(
                new Notification({
                    message: Lang.msgStationStartedStopped
                        .replace('{stationName}', Name)
                        .replace('{stationStatus}', start ? 'started' : 'stopped'),
                    severity: 'success'
                })
            );
        } else {
            addNotification(
                new Notification({
                    message: response.message,
                    severity: 'error'
                })
            );
        }
    };

    const handleOnAirToggle = async (start: boolean) => {
        if (stationData?.stationInfo) {
            if (!start && !offAirDialogOpen) {
                // Allow the user to stop the station via the dialog:
                setOffAirDialogOpen(true);
                return;
            }
            setOnAirLoading(start ? Lang.lblStarting : Lang.lblStopping);

            await startStopStation(stationData.stationInfo, start);
            setOnAirLoading(false);
            setOffAirDialogOpen(false);
        }
    };

    const onClickOnAirToggle = async () => {
        await handleOnAirToggle(!isStarted);
    };

    const renderStationStopDialog = () => {
        return (
            stationData?.stationInfo && (
                <DialogConfirmation
                    dialogTitle="Station stop confirmation"
                    open={offAirDialogOpen}
                    onNegativeEvent={() => setOffAirDialogOpen(false)}
                    onPositiveEvent={async () => await handleOnAirToggle(false)}
                    positiveTitle={Lang.btnStopStation}
                    theme={theme}
                >
                    {Lang.msgConfirmationStationStop.replace('{stationName}', stationData.stationInfo.Name)}
                </DialogConfirmation>
            )
        );
    };

    const renderStatusIcon = () => {
        const sxProp = isLoading && { sx: { color: grayedOut } };
        const logoUrl = stationData?.stationInfo?.Logo;
        if (logoUrl) {
            return (
                <Icon
                    name="station-logo"
                    style={{
                        marginLeft: theme.spacing(0.2),
                        marginRight: theme.spacing(0.2),
                        height: '100%',
                        maxHeight: maxHeightIcon,
                        width: 'auto',
                        objectFit: 'contain',
                        ...(!isStarted && { filter: 'grayscale(60%)' })
                    }}
                    externalSrc={logoUrl}
                />
            );
        } else {
            return <PodcastsIcon color={isLoading ? 'inherit' : iconColour} {...sxProp} />;
        }
    };

    const airStatus = isStarted ? Lang.btnOnAir : Lang.btnOffAir;

    return (
        <Stack
            direction="row"
            spacing={0.5}
            alignItems="center"
            sx={{
                borderColor: alpha(grayedOut, 0.5),
                borderWidth: '1px',
                borderStyle: 'solid',
                p: 0.6,
                borderRadius: `${shape.borderRadius}px`
            }}
        >
            {renderStationStopDialog()}
            {renderStatusIcon()}
            <Tooltip title={airStatus}>
                <Typography
                    variant="body1"
                    color={txtColour}
                    sx={{
                        maxWidth: { xs: maxStationWidthXs, md: maxStationWidthMd, lg: maxStationWidthLg },
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap'
                    }}
                >
                    {isLoading ? (onAirLoading ? onAirLoading : Lang.rLoading) : stationData.stationInfo?.Name ?? airStatus}
                </Typography>
            </Tooltip>

            <BtnIconTooltip
                displayMode="tooltip"
                icon={<PowerSettingsNewIcon />}
                loading={isLoading}
                iconButtonProps={{
                    onClick: onClickOnAirToggle,
                    highlight: offAirDialogOpen,
                    color: iconColour,
                    size: 'small'
                }}
            >
                {isStarted ? Lang.btnStopStation : Lang.btnStartStation}
            </BtnIconTooltip>
        </Stack>
    );
};

export default StationStatus;
