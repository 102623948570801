import React, { FC } from 'react';
import Lang from '../models/language';
import { DialogCustomComponentProps } from '../models/props';
import { getGlobalScrollStyle } from '../utils/style';
import { DialogDraggableTitle } from './draggable-paper';
import { Dialog, DialogActions, DialogContent, Paper, useTheme } from './mui';
import { Btn } from './styled-components';

// Note, draggable doesn't work on this since it messes up the child component sent through.
const draggable = false;
const dragTitleId = 'draggable-custom-component-dialog-title';

const DialogCustomComponent: FC<DialogCustomComponentProps> = ({
    children,
    closable,
    dialogTitle,
    open,
    positiveTitle,
    isFullWidth = false,
    maxWidth = false,
    positiveEventDisabled = false,
    onClose,
    onPositiveEvent
}) => {
    const theme = useTheme();
    const tryClose = () => onClose && onClose();

    return (
        <Dialog
            open={open}
            onClose={() => {
                closable && tryClose();
            }}
            PaperComponent={Paper}
            aria-labelledby={dragTitleId}
            fullWidth={isFullWidth}
            maxWidth={maxWidth}
        >
            <DialogDraggableTitle componentId={dragTitleId} dialogTitle={dialogTitle} draggable={draggable} />
            <DialogContent
                sx={{
                    ['&.MuiDialogContent-root']: { ...getGlobalScrollStyle(theme) }
                }}
                style={{ paddingBottom: theme.spacing(1) }}
            >
                {children}
            </DialogContent>
            <DialogActions>
                <Btn size="small" variant="text" onClick={tryClose}>
                    {Lang.btnClose}
                </Btn>
                {onPositiveEvent && (
                    <Btn size="small" variant="contained" onClick={onPositiveEvent} disabled={positiveEventDisabled}>
                        {positiveTitle ?? Lang.btnOk}
                    </Btn>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default DialogCustomComponent;
