import { AudioSettingDto, SamVibeStationDto } from '../../../../models/dto';
import {
    AudioSettingInputName,
    defaultDuration,
    defaultLevel,
    defaultLevelXfade,
    defaultMaxCross,
    maxDuration,
    maxLevel,
    minDuration,
    minLevel
} from '../../../../pages/station/settings/audio-settings/models/consts';
import { FadeType } from '../../../../pages/station/settings/audio-settings/models/interfaces';
import { ItemAudioSettingsItem } from '../../interfaces';
import { ActiveToggleState, SettingType } from './consts';

export function getFadeDescription(fadeInState: ActiveToggleState, selectedValue: string) {
    switch (fadeInState) {
        case 'yes':
            return 'Fade will apply.';
        case 'no':
            return 'Fade will not apply.';
        case 'default':
        default:
            return `${selectedValue}: No changes`;
    }
}

export function getFadeData(settingType: SettingType): {
    fadeDurationKey: AudioSettingInputName;
    fadeStateTitle: string;
    fadeTypeKey: keyof AudioSettingDto;
} {
    switch (settingType) {
        case SettingType.FADE_IN:
            return {
                fadeDurationKey: 'FadeInDuration',
                fadeStateTitle: 'Fade In',
                fadeTypeKey: 'FadeInType'
            };
        case SettingType.FADE_OUT:
        default:
            return {
                fadeDurationKey: 'FadeOutDuration',
                fadeStateTitle: 'Fade Out',
                fadeTypeKey: 'FadeOutType'
            };
    }
}

interface IInputData {
    default?: number;
    description: string;
    key: keyof AudioSettingDto;
    min?: number;
    max?: number;
    title: string;
    unit?: string;
}

export function getInputData(
    settingType: 'TrimSilence' | 'Xfade',
    stationInfo?: SamVibeStationDto
): {
    input1: IInputData;
    input2: IInputData;
    input3: IInputData;
} {
    switch (settingType) {
        case 'TrimSilence':
            return {
                input1: {
                    description: 'Trim the silence',
                    key: 'TrimSilence',
                    title: 'Trim Silence'
                },
                input2: {
                    default: stationInfo?.LevelStart ?? defaultLevel,
                    description: 'Change silence at start',
                    key: 'LevelStart',
                    min: minLevel,
                    max: maxLevel,
                    title: 'Silence at start',
                    unit: 'dB'
                },
                input3: {
                    default: stationInfo?.LevelEnd ?? defaultLevel,
                    description: 'Change silence at end',
                    key: 'LevelEnd',
                    min: minLevel,
                    max: maxLevel,
                    title: 'Silence at end',
                    unit: 'dB'
                }
            };
        case 'Xfade':
        default:
            return {
                input1: {
                    default: defaultLevel,
                    description: 'Cross intersections',
                    key: 'Xfade',
                    title: 'Cross',
                    unit: 'dB'
                },
                input2: {
                    default: stationInfo?.LevelXFade ?? defaultLevelXfade,
                    description: 'Change where intersection crosses',
                    key: 'LevelXfade',
                    min: minLevel,
                    max: maxLevel,
                    title: 'Intersect cross at',
                    unit: 'dB'
                },
                input3: {
                    default: stationInfo?.MaxCross ?? defaultMaxCross,
                    description: 'Change cross duration',
                    key: 'MaxCross',
                    min: minDuration,
                    max: maxDuration / 1000,
                    title: 'Cross duration',
                    unit: 'sec'
                }
            };
    }
}

export function getEditItemDescription(currentAudioSettingsItem: ItemAudioSettingsItem, key: keyof AudioSettingDto): string {
    const { audioSettings } = currentAudioSettingsItem;

    switch (key) {
        case 'FadeInType':
        case 'FadeOutType': {
            const fadeType = audioSettings[key];
            const duration = audioSettings[key === 'FadeInType' ? 'FadeInDuration' : 'FadeOutDuration'] as number;
            if (fadeType === FadeType.Default || fadeType === FadeType.None) {
                return `Fade Type: ${getFadeTypeName(fadeType)}`;
            }
            return `Fade Type: ${getFadeTypeName(fadeType)} & Duration: ${duration ? duration : defaultDuration / 1000}`;
        }
        case 'TrimSilence': {
            const { LevelStart, LevelEnd, TrimSilence } = audioSettings;
            if (TrimSilence) {
                return `Silence at: Start (dB): ${LevelStart ? LevelStart : defaultLevel} & End: ${
                    LevelEnd ? LevelEnd : defaultLevel
                }`;
            }
            return TrimSilence === false ? 'No' : 'Default';
        }
        case 'Xfade': {
            const { MaxCross, LevelXfade, Xfade } = audioSettings;
            if (Xfade) {
                return `Silence at: Start (dB): ${MaxCross ? MaxCross : defaultMaxCross} & End: ${
                    LevelXfade ? LevelXfade : defaultLevelXfade
                }`;
            }
            return Xfade === false ? 'No' : 'Default';
        }
        default:
            return 'Template description';
    }
}

function getFadeTypeName(fadeType: FadeType): string {
    switch (fadeType) {
        case FadeType.None:
            return 'None';
        case FadeType.Linear:
            return 'Linear';
        case FadeType.Sinusoidal:
            return 'Sinusoidal';
        case FadeType.Logarithmic:
            return 'Logarithmic';
        case FadeType.Default:
            return 'Default';
        default:
            return 'None';
    }
}
