import { Dispatch, SetStateAction } from 'react';
import { TblDataIdentifiers, getTableColTypeId, populatePlaceholder } from '.';
import {
    clearPlaylist,
    clearQueue,
    movePlaylistQueueItems,
    playlistRemoveDuplicates,
    postAddQueueItemsPosition,
    queueRemoveDuplicates,
    removePlaylistDeleteUnselectedItems,
    removePlaylistItems,
    removeQueueDeleteUnselectedItems,
    removeQueueItems
} from '../../../middleware/dynamic-list';
import {
    exportPlaylistFileFromLibrary,
    postBulkUpdateBrowsableField,
    postLibraryBulkDelete,
    postLibraryRecycle,
    postLibraryRecycleRestore
} from '../../../middleware/library';
import { exportPlaylistFileFromPlaylist, putShufflePlaylist } from '../../../middleware/playlist';
import { exportPlaylistFileFromQueue, putShuffleQueue } from '../../../middleware/queue';
import {
    getAlternateContentTrack,
    getIntroTrack,
    postAlternateContentTrack,
    postCurrentIntroTrack
} from '../../../middleware/stations';
import { BaseResponseDto, ExportPlaylistRequest, MediaItemDto, RemovePlaylistQueueItemsRequest } from '../../../models/dto';
import { FnAsync, MenuItemData, PlaceholderItem, TblColType, Void } from '../../../models/interfaces';
import Lang from '../../../models/language';
import { ResolvedTreeNode, SharedDialogsState } from '../../../pages/station/library/models/interfaces';
import { Notification } from '../../../providers/notifications';
import { getMediaItem } from '../../../utils/general';
import { createLinkUrl, navigateWindowExternal } from '../../../utils/router-util';
import { TableEntity } from '../../../utils/signalr/models';
import { formatDurationHM, isValidIntroTrack } from '../../../utils/time';

/**
 * Decide what to do when an item was selected in the context menu.
 * @param tableEntity Table entity context.
 * @param menuItemData Picked item.
 */
export async function menuItemSelected(
    stationId: string,
    tableEntity: TableEntity,
    menuItemData: MenuItemData,
    listChecked: TblColType[],
    checkedLastIndex: number,
    listData: TblColType[],
    addNotification: Void<Notification>,
    setListData: Dispatch<SetStateAction<TblColType[] | undefined>>,
    setSharedDialogs: (sharedDialogsState: Partial<SharedDialogsState>) => void,
    refreshAllRows: FnAsync<void>,
    togglePlayPreviewMediaItem: Void<MediaItemDto>,
    resolvedNode?: ResolvedTreeNode
): Promise<BaseResponseDto> {
    const { action } = menuItemData;
    // If there's not needed to make an api call:
    const plainSuccessRes: BaseResponseDto = { success: true, message: '' };

    // TableEntity-specific:
    if (tableEntity === 'HistoryItem') {
        switch (action) {
            case 'export-history': {
                setSharedDialogs({ exportHistory: true });
                return plainSuccessRes;
            }
            case 'clear': {
                setSharedDialogs({ clearHistory: true });
                return plainSuccessRes;
            }
        }
    } else if (tableEntity === 'QueueItem') {
        if (action === 'shuffle') {
            return await putShuffleQueue(stationId);
        }
    } else if (tableEntity === 'PlaylistItem') {
        if (action === 'shuffle') {
            return await putShufflePlaylist(stationId, resolvedNode?.categoryId);
        }
    } else if (tableEntity === 'LibraryItem') {
        if (action === 'restore') {
            const ids = listChecked.map((item) => getMediaItem(item)[TblDataIdentifiers.MediaItemId]);
            return await postLibraryRecycleRestore(stationId, ids);
        }
    }

    switch (action) {
        case 'preview in player': {
            if (checkedLastIndex < listChecked.length && checkedLastIndex >= 0) {
                const mediaItem = getMediaItem(listChecked[checkedLastIndex]);
                if (mediaItem) {
                    togglePlayPreviewMediaItem(mediaItem);
                }
            }
            return plainSuccessRes;
        }
        case 'create-gpt-media-item': {
            setSharedDialogs({ createGptMediaItem: true });
            return plainSuccessRes;
        }
        case 'remove-selected':
        case 'remove-unselected':
        case 'clear':
        case 'remove-duplicates':
            return await removePlaylistOrQueueItems(action, listChecked, resolvedNode, stationId, tableEntity, setListData);
        case 'move-to-recyclebin':
        case 'remove-permanently': {
            const description =
                action === 'move-to-recyclebin'
                    ? Lang.msgMoveQueuePlaylistItemsToRecycleBin
                    : Lang.msgDeletePermanentlyQueuePlaylistItems;
            setSharedDialogs({
                confirmation: {
                    description: description.replace('{plural}', listChecked.length > 1 ? 's' : ''),
                    title:
                        action === 'move-to-recyclebin' ? 'Media item recycle confirmation' : 'Permanently delete media item(s)',
                    positiveCallback: async () => {
                        const ids = listChecked.map((item) => getMediaItem(item)[TblDataIdentifiers.MediaItemId]);
                        const res =
                            action === 'move-to-recyclebin'
                                ? await postLibraryRecycle(stationId, ids)
                                : await postLibraryBulkDelete(stationId, ids);
                        if (res.success) {
                            addNotification(
                                new Notification({
                                    message: action === 'move-to-recyclebin' ? 'Item(s) recycled' : 'Item(s) deleted',
                                    severity: 'success'
                                })
                            );
                        } else {
                            addNotification(
                                new Notification({
                                    message: res.message,
                                    severity: 'error'
                                })
                            );
                        }
                    }
                }
            });
            return plainSuccessRes;
        }
        case 'intro-track':
        case 'alternate-content': {
            if (checkedLastIndex < listChecked.length && checkedLastIndex >= 0) {
                return await introAlternateContentChange(
                    action,
                    stationId,
                    listChecked[checkedLastIndex],
                    plainSuccessRes,
                    addNotification,
                    setSharedDialogs
                );
            }
            return plainSuccessRes;
        }
        case 'upload-files': {
            const uploadLink = createLinkUrl('/station/:stationId/upload', stationId);
            navigateWindowExternal(uploadLink);
            return plainSuccessRes;
        }
        case 'import-playlist': {
            if (resolvedNode?.categoryId) {
                setSharedDialogs({ importPlaylist: resolvedNode?.categoryId });
            }
            return plainSuccessRes;
        }
        case 'export-MIL':
        case 'export-M3U':
        case 'export-M3U8':
        case 'export-CSV': {
            exportPlaylistFromFile(action, stationId, tableEntity, addNotification, resolvedNode);
            return plainSuccessRes;
        }
        case 'refresh': {
            await refreshAllRows();
            return plainSuccessRes;
        }
        case 'enable-browsing':
        case 'disable-browsing': {
            const ids = listChecked.map((item) => getMediaItem(item)[TblDataIdentifiers.MediaItemId]);
            return await postBulkUpdateBrowsableField({ browsable: !!(action === 'enable-browsing'), stationId, ids });
        }
        case 'add-top-queue':
        case 'add-bottom-queue': {
            const items = listChecked.map((item) => getMediaItem(item)[TblDataIdentifiers.MediaItemId]);
            return await postAddQueueItemsPosition(stationId, items, action === 'add-top-queue' ? 'TOP' : 'BOTTOM');
        }
        case 'add-to-playlist': {
            const ids = listChecked.map((item) => getMediaItem(item)[TblDataIdentifiers.MediaItemId]);
            setSharedDialogs({ addToPlaylistMediaItems: ids });
            return plainSuccessRes;
        }
        case 'edit': {
            setSharedDialogs({ editMediaItems: { tableEntity, checkedItems: listChecked } });
            return plainSuccessRes;
        }
        case 'move-top':
        case 'move-bottom':
        case 'move-up':
        case 'move-down': {
            const movePosition = action === 'move-up' || action === 'move-top' ? 'TOP' : 'BOTTOM';
            const items = listChecked.map((item) => getTableColTypeId(tableEntity, item));

            const playlistRequestProps = tableEntity === 'PlaylistItem' && { playlistCategoryId: resolvedNode?.categoryId };
            const moveUpDownProps = (action === 'move-up' || action === 'move-down') && {
                itemPositionId: (action === 'move-up' ? findMoveUpId : findMoveDownId)(tableEntity, listChecked, listData)
            };

            return await movePlaylistQueueItems({
                ...playlistRequestProps,
                ...moveUpDownProps,
                items,
                movePosition,
                stationId,
                tableEntity
            });
        }
        default:
    }

    return {
        message: Lang.msgNotYetImplemented,
        success: false
    };
}

function findMoveUpId(tableEntity: TableEntity, listChecked: TblColType[], listData: TblColType[]): string {
    if (listChecked.length === 0) {
        return getTableColTypeId(tableEntity, listData[0]);
    }
    const listCheckedId = getTableColTypeId(tableEntity, listChecked[0]);
    const indexOfFirstItemInList = listData.findIndex((item) => listCheckedId === getTableColTypeId(tableEntity, item));
    if (indexOfFirstItemInList > 0) {
        return getTableColTypeId(tableEntity, listData[indexOfFirstItemInList - 1]);
    }
    return getTableColTypeId(tableEntity, listData[0]);
}

function findMoveDownId(tableEntity: TableEntity, listChecked: TblColType[], listData: TblColType[]): string {
    if (listChecked.length === 0) {
        return getTableColTypeId(tableEntity, listData[0]);
    }
    const listCheckedId = getTableColTypeId(tableEntity, listChecked[listChecked.length - 1]);
    const indexOfFirstItemInList = listData.findIndex((item) => listCheckedId === getTableColTypeId(tableEntity, item));
    if (indexOfFirstItemInList >= 0 && indexOfFirstItemInList + 1 < listData.length) {
        return getTableColTypeId(tableEntity, listData[indexOfFirstItemInList + 1]);
    }
    return getTableColTypeId(tableEntity, listData[listData.length - 1]);
}

/**
 * Will export in the background.
 */
function exportPlaylistFromFile(
    action: string,
    stationId: string,
    tableEntity: string,
    addNotification: Void<Notification>,
    resolvedNode?: ResolvedTreeNode
) {
    const exportFormat = (() => {
        switch (action) {
            case 'export-MIL':
                return 'MIL';
            case 'export-M3U':
                return 'M3U';
            case 'export-M3U8':
                return 'M3U8';
            case 'export-CSV':
            default:
                return 'CSV';
        }
    })();
    addNotification(
        new Notification({
            message: Lang.msgExportingPlaylistFile,
            severity: 'info'
        })
    );
    const params: ExportPlaylistRequest = { stationId, exportFormat, resolvedTreeNode: resolvedNode };
    const exportRequest = (() => {
        switch (tableEntity) {
            case 'LibraryItem':
                return exportPlaylistFileFromLibrary;
            case 'PlaylistItem':
                return exportPlaylistFileFromPlaylist;
            case 'QueueItem':
            default:
                return exportPlaylistFileFromQueue;
        }
    })();
    exportRequest(params).catch((msg) => {
        addNotification(
            new Notification({
                message: msg,
                severity: 'error'
            })
        );
    });
}

async function introAlternateContentChange(
    action: 'intro-track' | 'alternate-content',
    stationId: string,
    item: TblColType,
    plainSuccessRes: BaseResponseDto,
    addNotification: Void<Notification>,
    setSharedDialogs: (sharedDialogsState: Partial<SharedDialogsState>) => void
): Promise<BaseResponseDto> {
    const mediaItem = getMediaItem(item);
    const newMediaItemId = mediaItem[TblDataIdentifiers.MediaItemId];
    let getRequest: (stationId: string) => Promise<MediaItemDto>;
    let postRequest: (stationId: string, medidaItemId: string) => Promise<MediaItemDto>;
    let msgSuccess: string;
    let msgReplaceExisting: string;

    if (action === 'intro-track') {
        getRequest = getIntroTrack;
        postRequest = postCurrentIntroTrack;
        msgSuccess = Lang.msgIntroTrackChanged;
        msgReplaceExisting = Lang.msgReplaceExistingIntroTrack;
        if (!isValidIntroTrack(mediaItem)) {
            addNotification(
                new Notification({
                    message: Lang.msgMediaItemNotEligible,
                    severity: 'warning'
                })
            );
            return plainSuccessRes;
        }
    } else {
        getRequest = getAlternateContentTrack;
        postRequest = postAlternateContentTrack;
        msgSuccess = Lang.msgAlternateTrackChanged;
        msgReplaceExisting = Lang.msgReplaceExistingAlternateContent;
    }

    const changeRequest = async () => {
        const res = await postRequest(stationId, newMediaItemId);
        if (res.success) {
            addNotification(
                new Notification({
                    message: msgSuccess,
                    severity: 'info'
                })
            );
        }
        return res;
    };
    const res = await getRequest(stationId);

    if (res.success && res.MediaItemId && res.MediaItemId !== newMediaItemId) {
        setSharedDialogs({
            confirmation: {
                description: msgReplaceExisting
                    .replace('{artist}', res.Artist)
                    .replace('{title}', res.Title)
                    .replace('{duration}', formatDurationHM(res.Duration)),
                title: 'Replace current intro track',
                positiveCallback: async () => {
                    await changeRequest();
                }
            }
        });
    } else {
        return await changeRequest();
    }
    return plainSuccessRes;
}

async function removePlaylistOrQueueItems(
    action: string,
    listChecked: TblColType[],
    resolvedNode: ResolvedTreeNode | undefined,
    stationId: string,
    tableEntity: TableEntity,
    setListData: Dispatch<SetStateAction<TblColType[] | undefined>>
): Promise<BaseResponseDto> {
    if (action === 'remove-selected' || action === 'remove-unselected' || action === 'clear') {
        setListData((prevState) => {
            // Convert Items to placeholder loader in order for it to be deleted:
            prevState?.forEach((item, index) => {
                const id = getTableColTypeId(tableEntity, item);
                const itemFound = action === 'clear' ? true : listChecked.find((x) => getTableColTypeId(tableEntity, x) === id);
                if (
                    action === 'clear' ||
                    (action === 'remove-selected' && itemFound) ||
                    (action === 'remove-unselected' && !itemFound)
                ) {
                    const placeholderItem = new PlaceholderItem('remove', id);
                    prevState[index] = populatePlaceholder(tableEntity, item, placeholderItem);
                }
            });
            return prevState ? [...prevState] : [];
        });
    }
    const ids = listChecked.map((item) => getTableColTypeId(tableEntity, item));

    let queueFunc;
    let playlistFunc;
    const request: RemovePlaylistQueueItemsRequest = { stationId, ids, playlistCategoryId: resolvedNode?.categoryId };
    switch (action) {
        case 'remove-selected':
            queueFunc = removeQueueItems;
            playlistFunc = removePlaylistItems;
            break;
        case 'remove-unselected':
            queueFunc = removeQueueDeleteUnselectedItems;
            playlistFunc = removePlaylistDeleteUnselectedItems;
            break;
        case 'remove-duplicates':
            queueFunc = queueRemoveDuplicates;
            playlistFunc = playlistRemoveDuplicates;
            break;
        case 'clear':
            queueFunc = clearQueue;
            playlistFunc = clearPlaylist;
            break;
        default:
            break;
    }
    return await (tableEntity === 'QueueItem' ? queueFunc : playlistFunc)(request);
}
