import React, { PureComponent } from 'react';
import Lang from '../models/language';
import { DialogListInputProps } from '../models/props';
import CheckboxList from './checklist';
import { DialogDraggableTitle, getDialogPaperComponent } from './draggable-paper';
import { Dialog, DialogActions, DialogContent, DialogContentText, Paper } from './mui';
import { Btn } from './styled-components';

const dragTitleId = 'draggable-dialog-list-title';

export default class DialogListInput extends PureComponent<DialogListInputProps> {
    render() {
        const {
            colsEnabled,
            dialogTextContent,
            dialogTitle,
            draggable,
            closable,
            items,
            onClose,
            onColEnableChange,
            open,
            positiveTitle
        } = this.props;
        const closeDialog = () => onClose && onClose();
        const isDraggable = draggable === undefined || draggable;
        const isClosable = closable === undefined || closable;

        return (
            <Dialog
                open={open}
                onClose={() => {
                    isClosable && onClose && onClose();
                }}
                PaperComponent={isDraggable ? getDialogPaperComponent(dragTitleId) : Paper}
                aria-labelledby={dragTitleId}
            >
                <DialogDraggableTitle componentId={dragTitleId} dialogTitle={dialogTitle} draggable={isDraggable} />
                <DialogContent>
                    {dialogTextContent && <DialogContentText>{dialogTextContent}</DialogContentText>}
                    <CheckboxList colsEnabled={colsEnabled} items={items} onColEnableChange={onColEnableChange} />
                </DialogContent>
                <DialogActions>
                    <Btn size="small" variant="contained" onClick={closeDialog}>
                        {positiveTitle ? positiveTitle : Lang.btnOk}
                    </Btn>
                </DialogActions>
            </Dialog>
        );
    }
}
