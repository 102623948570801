import {
    AudioFileIcon,
    ClearAllIcon,
    ClearIcon,
    CreateIcon,
    DeleteForeverIcon,
    DeleteIcon,
    DeleteOutlineIcon,
    EditIcon,
    EditNoteIcon,
    ExitToAppIcon,
    ExpandIcon,
    FileCopyIcon,
    ImportExportIcon,
    KeyboardArrowDownIcon,
    KeyboardArrowUpIcon,
    MusicNoteIcon,
    PlayCircleFilledIcon,
    PublicIcon,
    QueueMusicIcon,
    RecyclingIcon,
    RefreshIcon,
    RestoreIcon,
    SearchIcon,
    SearchOffIcon,
    ShuffleIcon,
    SubscriptionsIcon,
    UploadIcon,
    VerticalAlignBottomIcon,
    VerticalAlignTopIcon
} from '../components/mui';
import { shortcutSpacePress } from '../utils/key-press-handler';
import { MenuItemData } from './interfaces';
import Lang from './language';

const addToPlaylistMenuItemData: MenuItemData = {
    order: 0,
    action: 'add-to-playlist',
    icon: QueueMusicIcon,
    title: `${Lang.aAddTo} ${Lang.tPlaylist}`,
    itemsSelectedDependent: true,
    shortcut: { code: 'KeyC', altKey: true }
};

const shuffleMenuItemData: MenuItemData = {
    order: 1,
    action: 'shuffle',
    icon: ShuffleIcon,
    title: Lang.tShuffleQueue
};

const exportPlaylistFile: MenuItemData = {
    order: 0,
    action: 'more-items',
    icon: ExitToAppIcon,
    title: Lang.aExportPlaylistFile,
    menuItems: [
        {
            action: 'export-MIL',
            icon: AudioFileIcon,
            title: 'MIL'
        },
        {
            action: 'export-M3U',
            icon: AudioFileIcon,
            title: 'M3U'
        },
        {
            action: 'export-M3U8',
            icon: AudioFileIcon,
            title: 'M3U8'
        },
        {
            action: 'export-CSV',
            icon: AudioFileIcon,
            title: 'CSV'
        }
    ]
};

const moveToMenu: MenuItemData = {
    order: 0,
    action: 'more-items',
    icon: ExpandIcon,
    title: Lang.aMoveTo,
    itemsSelectedDependent: true,
    menuItems: [
        {
            order: 0,
            action: 'move-top',
            icon: VerticalAlignTopIcon,
            title: Lang.aTop,
            shortcut: { code: 'ArrowUp', altKey: true, ctrlKey: true }
        },
        {
            order: 0,
            action: 'move-bottom',
            icon: VerticalAlignBottomIcon,
            title: Lang.aBottom,
            shortcut: { code: 'ArrowDown', altKey: true, ctrlKey: true }
        },
        {
            order: 1,
            action: 'move-up',
            icon: KeyboardArrowUpIcon,
            title: Lang.aUp,
            shortcut: { code: 'ArrowUp', altKey: true }
        },
        {
            order: 1,
            action: 'move-down',
            icon: KeyboardArrowDownIcon,
            title: Lang.aDown,
            shortcut: { code: 'ArrowDown', altKey: true }
        }
    ]
};

const enableBrowsing: MenuItemData = {
    order: 0,
    action: 'more-items',
    icon: SearchIcon,
    title: Lang.aBrowsable,
    itemsSelectedDependent: true,
    menuItems: [
        {
            action: 'enable-browsing',
            icon: SearchIcon,
            title: Lang.aBrowsableEnable
        },
        {
            action: 'disable-browsing',
            icon: SearchOffIcon,
            title: Lang.aBrowsableDisable
        }
    ]
};

const importUploadFilesLibrary: MenuItemData = {
    order: 0,
    action: 'more-items',
    icon: ImportExportIcon,
    title: Lang.aImport,
    menuItems: [
        {
            action: 'upload-files',
            icon: UploadIcon,
            title: Lang.aUploadFiles,
            shortcut: { code: 'KeyO', ctrlKey: true }
        }
    ]
};

const importUploadFiles: MenuItemData = { ...importUploadFilesLibrary };
if (importUploadFiles.menuItems) {
    importUploadFiles.menuItems = [
        ...importUploadFiles.menuItems,
        {
            action: 'import-playlist',
            icon: ImportExportIcon,
            title: Lang.aImportPlaylist,
            shortcut: { code: 'KeyP', ctrlKey: true }
        }
    ];
}

const refreshMenuItemData: MenuItemData = {
    order: 1,
    action: 'refresh',
    icon: RefreshIcon,
    title: Lang.aRefresh,
    shortcut: { code: 'KeyR', ctrlKey: true }
};

const setAsMenuItemData: MenuItemData = {
    order: 2,
    action: 'more-items',
    icon: CreateIcon,
    title: Lang.aSetAs,
    itemsSelectedDependent: true,
    menuItems: [
        {
            action: 'intro-track',
            icon: MusicNoteIcon,
            title: Lang.aIntroTrack
        },
        {
            action: 'alternate-content',
            icon: PublicIcon,
            title: Lang.aAlternateContent
        }
    ]
};

const previewMenuItemData: MenuItemData = {
    order: 2,
    action: 'preview in player',
    icon: PlayCircleFilledIcon,
    title: Lang.aPreviewInPlayer,
    itemsSelectedDependent: true,
    shortcut: shortcutSpacePress
};

const removeMenuItemData: MenuItemData = {
    order: 0,
    action: 'more-items',
    icon: DeleteIcon,
    title: Lang.aRemove,
    menuItems: [
        {
            order: 0,
            action: 'remove-selected',
            icon: DeleteIcon,
            title: Lang.aRemoveSelected,
            itemsSelectedDependent: true,
            shortcut: { code: 'Delete' }
        },
        {
            order: 0,
            action: 'remove-unselected',
            icon: DeleteOutlineIcon,
            title: Lang.aRemoveUnSelected,
            itemsSelectedDependent: true,
            shortcut: { code: 'Delete', shiftKey: true }
        },
        {
            order: 1,
            action: 'clear',
            icon: ClearIcon,
            title: Lang.btnClear,
            hasItemsDependent: true,
            shortcut: { code: 'Delete', ctrlKey: true }
        },
        {
            order: 1,
            action: 'remove-duplicates',
            icon: FileCopyIcon,
            title: Lang.aRemoveDuplicates,
            itemsSelectedDependent: true
        },
        {
            order: 2,
            action: 'move-to-recyclebin',
            icon: RecyclingIcon,
            title: Lang.aRecycleBin,
            itemsSelectedDependent: true,
            shortcut: { code: 'KeyD', ctrlKey: true }
        },
        {
            order: 2,
            action: 'remove-permanently',
            icon: DeleteForeverIcon,
            title: Lang.aRemovePermanently,
            itemsSelectedDependent: true,
            shortcut: { code: 'Delete', ctrlKey: true, shiftKey: true }
        }
    ]
};

const libRemoveMenuItemData: MenuItemData = {
    order: 0,
    action: 'more-items',
    icon: DeleteIcon,
    title: Lang.aRemove,
    menuItems: [
        {
            order: 2,
            action: 'move-to-recyclebin',
            icon: RecyclingIcon,
            title: Lang.aRecycleBin,
            itemsSelectedDependent: true,
            shortcut: { code: 'KeyD', ctrlKey: true }
        },
        {
            order: 2,
            action: 'remove-permanently',
            icon: DeleteForeverIcon,
            title: Lang.aRemovePermanently,
            itemsSelectedDependent: true,
            shortcut: { code: 'Delete', ctrlKey: true, shiftKey: true }
        }
    ]
};

/**
 * RECYCLE BIN option.
 */
const restoreMenuItemData: MenuItemData = {
    order: 0,
    action: 'restore',
    icon: RestoreIcon,
    title: Lang.aRestore,
    itemsSelectedDependent: true
};

const editMenuItemData: MenuItemData = {
    order: 0,
    action: 'edit',
    icon: EditNoteIcon,
    title: Lang.aEdit,
    itemsSelectedDependent: true,
    shortcut: { code: 'KeyE', ctrlKey: true }
};

const createGptMenuItemData: MenuItemData = {
    order: 0,
    action: 'more-items',
    icon: EditIcon,
    title: Lang.aCreate,
    menuItems: [
        {
            action: 'create-gpt-media-item',
            icon: MusicNoteIcon,
            title: Lang.aGptMediaItem
        }
    ]
};

const addToQueuePlaylist: MenuItemData = {
    order: 0,
    action: 'more-items',
    icon: QueueMusicIcon,
    title: Lang.aAddTo,
    itemsSelectedDependent: true,
    menuItems: [
        {
            order: 0,
            action: 'add-top-queue',
            icon: VerticalAlignTopIcon,
            title: `${Lang.tQueue} ${Lang.aTop}`,
            itemsSelectedDependent: true,
            shortcut: { code: 'Enter', ctrlKey: true }
        },
        {
            order: 0,
            action: 'add-bottom-queue',
            icon: VerticalAlignBottomIcon,
            title: `${Lang.tQueue} ${Lang.aBottom}`,
            itemsSelectedDependent: true,
            shortcut: { code: 'Enter' }
        },
        { ...addToPlaylistMenuItemData, order: 1, icon: SubscriptionsIcon, title: Lang.tPlaylist }
    ]
};

export const historyMenuItems: Array<MenuItemData> = [
    addToQueuePlaylist,
    editMenuItemData,
    enableBrowsing,
    {
        order: 1,
        action: 'export-history',
        icon: ExitToAppIcon,
        title: Lang.aExport
    },
    refreshMenuItemData,
    {
        order: 2,
        action: 'clear',
        icon: ClearAllIcon,
        title: Lang.aClearHistory
    }
];

export const libraryMenuItems: Array<MenuItemData> = [
    restoreMenuItemData,
    addToQueuePlaylist,
    editMenuItemData,
    libRemoveMenuItemData,
    exportPlaylistFile,
    createGptMenuItemData,
    enableBrowsing,
    importUploadFilesLibrary,
    refreshMenuItemData,
    setAsMenuItemData,
    previewMenuItemData
];

export const playlistMenuItems: Array<MenuItemData> = [
    addToQueuePlaylist,
    editMenuItemData,
    moveToMenu,
    removeMenuItemData,
    exportPlaylistFile,
    enableBrowsing,
    importUploadFiles,
    { ...shuffleMenuItemData, title: Lang.tShufflePlaylist },
    refreshMenuItemData,
    setAsMenuItemData,
    previewMenuItemData
];

export const queueMenuItems: Array<MenuItemData> = [
    addToPlaylistMenuItemData,
    editMenuItemData,
    moveToMenu,
    removeMenuItemData,
    exportPlaylistFile,
    enableBrowsing,
    importUploadFiles,
    shuffleMenuItemData,
    refreshMenuItemData,
    setAsMenuItemData,
    previewMenuItemData
];
