import { lblStationId } from '../../middleware/integration/integration';
import { apiLibrary } from '../../middleware/library';
import { MediaItemDto } from '../../models/dto';
import { BaseUrl } from '../../utils/env';

export interface PreviewAudioData {
    url: string;
    codec: string;
    mediaItem: MediaItemDto;
}

/**
 * Don't ask me why the codec reverts to m4a if it's aac, refer to OLD SAM Cloud, new SAM Cloud doesn't need it.
 */
export function createPreviewAudioData(stationId: string, mediaItem: MediaItemDto): PreviewAudioData {
    const codec =
        !(/OPR/.test(navigator.userAgent) || /Opera/.test(navigator.userAgent)) && !/Firefox/.test(navigator.userAgent)
            ? 'aac'
            : 'mp3';
    const url = `${BaseUrl()}${apiLibrary.replace(lblStationId, stationId)}/RetrieveAudioFile/${mediaItem.MediaItemId}/${codec}`;
    return { url, codec: codec == 'aac' ? 'm4a' : codec, mediaItem };
}
