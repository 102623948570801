import React, { FC, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getIcon } from '../../images/image';
import { putStationSkipTrack } from '../../middleware/stations';
import { defaultVolume, playMutedVolume } from '../../models/consts';
import { HistoryItemDto, MediaItemDto, PlayerDataDto } from '../../models/dto';
import Lang from '../../models/language';
import { StationDataPropsNN } from '../../models/props';
import { useAccount } from '../../providers/account';
import { Notification, useNotification } from '../../providers/notifications';
import { useSettings } from '../../providers/settings';
import { useStation } from '../../providers/station';
import { convertToNumber, delay, hasSkipTrackPermission, resolveMediaItemPicture } from '../../utils/general';
import { ConsoleLogError } from '../../utils/log';
import { useEffectAsync } from '../../utils/react-util';
import { shouldDisplayPlayerBar, useStationId } from '../../utils/router-util';
import { EntityMessageType, SignalRMessage, TableEntityCallbacks } from '../../utils/signalr/models';
import { useSignalRCallback } from '../../utils/signalr/utils';
import { getMaxBorderRadius } from '../../utils/style';
import { TDSdkWrapper } from '../../utils/tdplayer/TDSdkWrapper';
import { getNowPlaying } from '../../utils/tdplayer/helper';
import { CuePointChanged, PlayerStateEvent } from '../../utils/tdplayer/models';
import { formatTimeMMSS } from '../../utils/time';
import BtnIconTooltip from '../btn-icon-tooltip';
import {
    Box,
    CircularProgress,
    Collapse,
    EjectIcon,
    LinearProgress,
    OpenInNewIcon,
    PlayArrowIcon,
    ShareIcon,
    SkipNextIcon,
    Stack,
    StopIcon,
    Tooltip,
    styled,
    useTheme
} from '../mui';
import { PlayerBtn } from '../player-btn';
import PreviewAudio from '../preview-audio';
import { Body2, Caption } from '../styled-components';
import TdSdkScript from '../td-sdk-script';
import VolumeSlider from '../vol-slider';
import DialogShareNP from './dialog-share-np';
import PlayerTime from './player-time';
import { convertHistoryItemToMediaItem, getPlayerDataValue } from './util';

const largeFontSize = '1.5rem';
const largeIconSize = '55px';
const smallFontSize = '1.25rem';
const smallIconSize = '40px';
const stackSpacing = 1;

/**
 * open - Animate to open then stay open.
 * hidden - Animate to closed, then stay closed.
 * transition - busy either closing or opening. Will only be in this phase during the animTransition.duration.
 */
type AnimState = 'transitioning' | 'hidden' | 'open';

const HoverStack = styled(Stack)(({ theme }) => {
    const { palette, spacing, transitions } = theme;
    const animTransition = {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.shorter // Should be the same as animDelay to remove component after hiding.
    };

    return {
        '& > .MuiCollapse-vertical > .MuiCollapse-vertical': {
            display: 'flex',
            flexDirection: 'row'
        },
        position: 'relative',
        justifyContent: 'space-between',
        color: palette.primary.contrastText,
        background: palette.primary.dark,
        overflow: 'hidden',
        alignItems: 'center',
        padding: spacing(1),
        // height is animated in local sx when AnimState changes:
        transition: transitions.create(['background', 'padding', 'height'], animTransition),
        '&:hover': {
            background: palette.primary.main,
            padding: spacing(1.2)
        }
    };
});

const tableEntityCallback: keyof TableEntityCallbacks = 'HistoryItem';

const PlayerBar: FC<StationDataPropsNN> = ({ stationIsStarted, stationData }) => {
    const theme = useTheme();
    const { spacing, transitions } = theme;
    const { accountState } = useAccount();
    const { environment, settings } = useSettings();
    const { addNotification } = useNotification();
    const location = useLocation();
    const stationId = useStationId(location);

    const shouldDisplayPlayer = useMemo(() => shouldDisplayPlayerBar(location), [location]);

    const [openDialogShareNP, setOpenDialogShareNP] = useState(false);
    const [expand, setExpand] = useState(false);
    const [mediaItemPicture, setMediaItemPicture] = useState<string>();
    const [playerData, setPlayerData] = useState<PlayerDataDto>();
    const [playerWrapper, setPlayerWrapper] = useState<TDSdkWrapper>();
    const [fetchIsLoading, setFetchIsLoading] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [sdkIsLoading, setSdkIsLoading] = useState(false);
    const [loadedTDPlayer, setLoadedSdk] = useState(false);
    // animState binds to settings.stationPlayerBar loosely:
    const [animState, setAnimState] = useState<AnimState>(settings.stationPlayerBar ? 'transitioning' : 'hidden');
    const animDelay = transitions.duration.shorter;

    const [playerVolume, setPlayerVolume] = useState(playerWrapper?.Volume ?? defaultVolume * 100);
    const [playerState, setPlayerState] = useState<PlayerStateEvent>();
    const [playerStatusTimer, setPlayerStatusTimer] = useState<NodeJS.Timeout>();

    // Toggle between TDSdkWrapper and PreviewAudio
    const { previewMediaItem, togglePlayPreviewMediaItem } = useStation();

    const mediaOrHistoryItem = previewMediaItem
        ? previewMediaItem
        : playerData?.mediaItem
          ? playerData.mediaItem
          : playerData?.historyItem;

    const animTransition = {
        easing: transitions.easing.sharp,
        duration: transitions.duration.leavingScreen
    };

    const isPreviewing = previewMediaItem ? true : false;
    const showPlayButton = isPreviewing ? !isPlaying : !fetchIsLoading && !sdkIsLoading && !isPlaying;
    const showStopButton = isPreviewing ? isPlaying : !fetchIsLoading && !sdkIsLoading && isPlaying;

    const setMediaItemFields = (
        mediaItem?: Partial<MediaItemDto>,
        historyItem?: Partial<HistoryItemDto>,
        previewMediaItem?: Partial<MediaItemDto>
    ) => {
        setPlayerData((prevState) => {
            if (prevState) {
                if (mediaItem) {
                    prevState.mediaItem = prevState.mediaItem
                        ? { ...prevState.mediaItem, ...mediaItem }
                        : (mediaItem as MediaItemDto);
                }
                if (historyItem) {
                    prevState.historyItem = (
                        prevState.historyItem ? { ...prevState.historyItem, ...historyItem } : historyItem
                    ) as HistoryItemDto;
                }
                // previewMediaItem can actually be set to false (if it's not previewing anymore):
                prevState.previewMediaItem = previewMediaItem as MediaItemDto;
            }
            return prevState ? { ...prevState } : prevState;
        });
    };

    useSignalRCallback(
        stationId ?? '',
        tableEntityCallback,
        (messageType: EntityMessageType, message: SignalRMessage) => {
            if (messageType === 'EntityInsertedMessage' && message.Table === 'HistoryItem' && message.InsertedItems) {
                for (let i = 0; i < message.InsertedItems.length; i++) {
                    const historyItem = message.InsertedItems[i].Data as HistoryItemDto;
                    const mediaItem = convertHistoryItemToMediaItem(historyItem);
                    setMediaItemFields(mediaItem, historyItem, previewMediaItem);
                }
            }
            // Note: No need to subscribe to 'SourceStateRefreshMessage' because the router does that.
        },
        [previewMediaItem]
    );

    useEffectAsync(async () => {
        if (previewMediaItem) {
            if (playerWrapper && playerWrapper.IsPlaying) {
                playerWrapper.stop();
                // Give some for player wrapper to emit stopped event before starting preview:
                await delay(400);
            }
            setIsPlaying(true);
        }
        setMediaItemFields(playerData?.mediaItem, playerData?.historyItem, previewMediaItem);
    }, [previewMediaItem]);

    useEffect(() => {
        const show = settings.stationPlayerBar && stationIsStarted;
        setAnimState('transitioning');
        const timeout = setTimeout(() => {
            setAnimState(show ? 'open' : 'hidden');
        }, animDelay);

        return () => {
            clearTimeout(timeout);
        };
    }, [settings.stationPlayerBar, stationIsStarted]);

    useEffectAsync(async () => {
        if (!isPreviewing) {
            setFetchIsLoading(true);
            if (stationId && stationData.stationInfo) {
                const res = await getNowPlaying(stationId, isPlaying, playerData);
                if (res.success) {
                    setPlayerData(res);
                } else {
                    addNotification(
                        new Notification({
                            message: res.message,
                            severity: 'error'
                        })
                    );
                }
            }
        }
        setFetchIsLoading(false);
    }, [stationId, stationData.manageStationData, isPlaying, isPreviewing]);

    useEffect(() => {
        if (playerData && stationData.manageStationData) {
            const coverArtBase = stationData.manageStationData.coverArtBase || stationData.manageStationData.pictureStorageBase;
            const picture = getPlayerDataValue('Picture', playerData);
            // Check if the item actually has a picture first:
            if (picture) {
                const mediaItemId = getPlayerDataValue('MediaItemId', playerData);
                const imgSrc = resolveMediaItemPicture(coverArtBase, mediaItemId);
                setMediaItemPicture(imgSrc);
            } else {
                // Do a no-album here:
                setMediaItemPicture(getIcon('no-album-icon').src);
            }
        }
    }, [playerData]);

    useEffect(() => {
        return () => {
            // Stop the player if it's busy playing:
            if (playerWrapper) {
                playerWrapper.stop();
            }
        };
    }, [playerWrapper]);

    useEffect(() => {
        if (stationId && playerData && loadedTDPlayer) {
            setPlayerWrapper((prevState) => {
                // Only set the wrapper once:
                return prevState
                    ? prevState
                    : new TDSdkWrapper(playerData, playChangedSdk, statusChangedSdk, loadingChangedSdk, cuePointChangedSdk);
            });
        }
    }, [stationId, playerData, loadedTDPlayer]);

    const playChangedSdk = (isPlaying: boolean) => {
        setIsPlaying(isPlaying);
    };

    const statusChangedSdk = (status: PlayerStateEvent) => {
        if (playerState?.data.code !== status.data.code && status.data.code === 'STREAM_GEO_BLOCKED') {
            clearTimeout(playerStatusTimer);
            const playerStatusTimerNew = setTimeout(() => {
                status.data.code = 'STREAM_GEO_BLOCKED_MESSAGE_RECEIVED';
                setPlayerState(status);
            }, 2000);
            setPlayerStatusTimer(playerStatusTimerNew);
        }
        setPlayerState(status);
    };

    const loadingChangedSdk = (_isLoading: boolean) => {
        setSdkIsLoading(_isLoading);
    };

    const cuePointChangedSdk = (e: CuePointChanged) => {
        const { albumName, artistName, cueTimeDuration, cueTitle } = e.data.cuePoint;

        const mediaItem = {
            Title: cueTitle,
            Artist: artistName,
            Album: albumName,
            Duration: formatTimeMMSS(convertToNumber(cueTimeDuration))
        };

        setMediaItemFields(mediaItem);
    };

    const onLoadSdk = () => {
        setLoadedSdk(true);
    };

    if (!stationId || animState === 'hidden') {
        // The player bar can only be there if a station exists, but even the route should contain the station ID.
        return <></>;
    }

    const onSetVolume = (_volume: number) => {
        if (playerWrapper) {
            playerWrapper.Volume = _volume;
        }
        setPlayerVolume(_volume);
    };

    const onEjectClick = () => {
        togglePlayPreviewMediaItem(undefined);
        setIsPlaying(false);
    };

    const onPlayClick = () => {
        const newPlayVolume = playerVolume < 2 ? playMutedVolume * 100 : playerVolume;

        if (isPreviewing) {
            if (playerWrapper && isPlaying) {
                // Stop if playing
                playerWrapper.stop();
            }
            setIsPlaying(true);
        } else if (playerWrapper) {
            // PreviewAudio not needed to be stopped because it won't be rendered.
            playerWrapper.play(newPlayVolume);
        } else {
            ConsoleLogError('onPlayClick:PlayerWrapper not set');
        }
        setPlayerVolume(newPlayVolume);
    };

    const onStopClick = () => {
        if (isPreviewing) {
            setIsPlaying(false);
        } else if (playerWrapper) {
            // playChangedSdk event will change setIsPlaying.
            playerWrapper.stop();
        }
    };

    const onSkipTrackClick = async () => {
        if (stationId && hasSkipTrackPermission(stationData)) {
            setFetchIsLoading(true);
            const res = await putStationSkipTrack(stationId);
            if (!res.success) {
                addNotification(
                    new Notification({
                        message: res.message,
                        severity: 'error'
                    })
                );
            } else {
                addNotification(
                    new Notification({
                        message: Lang.msgSkipDelay,
                        severity: 'info'
                    })
                );
            }
            setFetchIsLoading(false);
        }
    };

    const onErrorImage = () => {
        if (!mediaItemPicture) {
            return;
        }
        // Cover images don't work on Localhost because there's no bucket hosted for local songs, but the error message drives me mad:
        if (environment.name !== 'development') {
            addNotification(
                new Notification({
                    message: Lang.msgErrorCoverPicture,
                    severity: 'error'
                })
            );
        }
        setMediaItemPicture(getIcon('no-album-icon').src);
    };

    const onPlayerSiteOpen = () => {
        window.open(`${accountState.siteUrl}/${stationData.stationId}`, '_blank');
    };

    const iconProps = {
        sx: {
            transition: transitions.create(['font-size'], animTransition),
            fontSize: expand ? largeFontSize : smallFontSize
        }
    };

    const renderTextTooltip = (title: string, text?: string) => {
        return (
            text && (
                <Tooltip title={title} placement="right">
                    <Body2 variant="body2">{text}</Body2>
                </Tooltip>
            )
        );
    };

    const circularProgressSx = { '& .MuiCollapse-wrapperInner': { display: 'flex' } };
    return (
        shouldDisplayPlayer && (
            <HoverStack
                direction="row"
                onMouseEnter={() => setExpand(true)}
                onMouseLeave={() => setExpand(false)}
                sx={{
                    height: animState === 'open' ? 'auto' : '0px',
                    padding: spacing(animState === 'open' ? 1 : 0)
                }}
            >
                {fetchIsLoading && (
                    <Collapse in={fetchIsLoading} orientation="vertical" unmountOnExit>
                        <Box sx={{ width: '100%', position: 'absolute', top: 0, left: 0, right: 0 }}>
                            <LinearProgress color="primary" />
                        </Box>
                    </Collapse>
                )}
                {animState === 'open' &&
                    (isPreviewing && previewMediaItem ? (
                        <PreviewAudio
                            stationId={stationId}
                            mediaItem={previewMediaItem}
                            isPlaying={isPlaying}
                            playerVolume={playerVolume}
                            setIsPlaying={setIsPlaying}
                        />
                    ) : (
                        <TdSdkScript onLoad={onLoadSdk} />
                    ))}
                <Stack direction="row" spacing={stackSpacing} alignItems="center" justifyContent="start" flex={1}>
                    <Collapse in={isPreviewing} orientation="horizontal" unmountOnExit>
                        <Stack direction="row" spacing={1}>
                            <BtnIconTooltip
                                displayMode="tooltip"
                                icon={<EjectIcon />}
                                onClick={onEjectClick}
                                iconButtonProps={{
                                    color: 'secondary',
                                    size: 'small'
                                }}
                            >
                                {Lang.btnEjectPreview}
                            </BtnIconTooltip>
                            <Caption sx={{ alignContent: 'center', color: theme.palette.secondary.main }}>Previewing</Caption>
                        </Stack>
                    </Collapse>
                    <Collapse in={sdkIsLoading} orientation="horizontal" sx={circularProgressSx}>
                        <CircularProgress size={22} color={expand ? 'info' : 'secondary'} />
                    </Collapse>
                    <Collapse in={showPlayButton} orientation="horizontal" unmountOnExit>
                        <PlayerBtn icon={PlayArrowIcon} iconProps={iconProps} title={Lang.btnPlay} onClick={onPlayClick} />
                    </Collapse>
                    <Collapse in={showStopButton} orientation="horizontal">
                        <PlayerBtn icon={StopIcon} iconProps={iconProps} title={Lang.btnStop} onClick={onStopClick} />
                    </Collapse>
                    <PlayerBtn
                        icon={SkipNextIcon}
                        iconProps={iconProps}
                        title={Lang.btnSkip}
                        disabled={!hasSkipTrackPermission(stationData)}
                        onClick={onSkipTrackClick}
                    />
                    {playerData && (
                        <PlayerTime
                            isLoading={sdkIsLoading || fetchIsLoading}
                            isPlaying={isPlaying}
                            playerData={playerData}
                            setIsLoading={setSdkIsLoading}
                        />
                    )}
                </Stack>
                {playerData && (
                    <Stack direction="row" spacing={stackSpacing} alignItems="center" justifyContent="center" flex={1}>
                        {mediaItemPicture && (
                            <Stack sx={{ overflow: 'hidden', borderRadius: `${getMaxBorderRadius(theme)}px` }}>
                                <img
                                    onError={onErrorImage}
                                    style={{
                                        transition: transitions.create(['height', 'width'], animTransition),
                                        width: expand ? largeIconSize : smallIconSize,
                                        height: expand ? largeIconSize : smallIconSize
                                    }}
                                    src={mediaItemPicture}
                                />
                            </Stack>
                        )}
                        <Stack direction="column">
                            {renderTextTooltip('Title', getPlayerDataValue('Title', playerData))}
                            {renderTextTooltip('Artist', getPlayerDataValue('Artist', playerData))}
                            {getPlayerDataValue('Album', playerData) && (
                                <Collapse in={expand} orientation="vertical">
                                    {renderTextTooltip('Album', getPlayerDataValue('Album', playerData))}
                                </Collapse>
                            )}
                        </Stack>
                    </Stack>
                )}
                <Stack direction="row" spacing={stackSpacing} alignItems="center" justifyContent="end" flex={1}>
                    <VolumeSlider disabled={!expand} volume={playerVolume} onSetVolume={onSetVolume} />
                    <PlayerBtn
                        icon={ShareIcon}
                        iconProps={iconProps}
                        title={Lang.btnOpenShareCurrentInfo}
                        onClick={() => setOpenDialogShareNP(true)}
                    />
                    <PlayerBtn
                        icon={OpenInNewIcon}
                        iconProps={iconProps}
                        title={Lang.btnOpenPlayerSite}
                        onClick={onPlayerSiteOpen}
                    />
                </Stack>
                {stationData.manageStationData && mediaOrHistoryItem && (
                    <DialogShareNP
                        open={openDialogShareNP}
                        onClose={() => setOpenDialogShareNP(false)}
                        manageStationData={stationData.manageStationData}
                        mediaItem={mediaOrHistoryItem}
                    />
                )}
            </HoverStack>
        )
    );
};

export default PlayerBar;
