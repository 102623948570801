import { LiveSource } from '../../../models/dto';
import { stateBit } from './models/consts';
import { SourceState } from './models/interfaces';

export function getIsStarted(liveSource: LiveSource) {
    return liveSource.Status == 1 || liveSource.Status == 3;
}

export function getStateBitArray(sourceState: SourceState, liveSource: LiveSource) {
    const { activeLiveSourceId, broadcastMode, nextLiveSourceId } = sourceState;
    const isStarted = getIsStarted(liveSource);
    const isReady = liveSource.Status == 2 || liveSource.Status == 3;
    const isActiveLiveSourceId = liveSource.LiveSourceId == activeLiveSourceId;
    const isNextLiveSourceId = liveSource.LiveSourceId == nextLiveSourceId;

    /**
     * Bit string to represent state. Bit index meanings are as follows:
     * - 0: Live source type. 0 == SamVibe, 1 == Live
     * - 1-3: Mode. 100 == Cloud, 010 == CloudLive, 001 == LiveBackup
     * - 4: isStarted. 0 == not started, 1 == started
     * - 5: isReady. 0 == not ready, 1 == ready
     * - 6: isActiveLiveSourceId. 0 == no, 1 == yes
     * - 7: isNextLiveSourceId. 0 == no, 1 == yes
     */

    let bits = 0;
    bits |= liveSource.LiveSourceType == 1 ? stateBit.LIVETYPE : 0;
    switch (broadcastMode) {
        case 0:
            bits |= stateBit.CLOUDBROADCASTMODE;
            break;
        case 1:
            bits |= stateBit.CLOUDLIVEBROADCASTMODE;
            break;
        case 2:
            bits |= stateBit.LIVEBACKUPBROADCASTMODE;
            break;
    }
    bits |= isStarted ? stateBit.STARTED : 0;
    bits |= isReady ? stateBit.READY : 0;
    bits |= isActiveLiveSourceId ? stateBit.ACTIVELIVESOURCE : 0;
    bits |= isNextLiveSourceId ? stateBit.NEXTLIVESOURCE : 0;
    return bits;
}

export function hasFlag(state: number, flag: number) {
    return (state & flag) == flag;
}

export function hasFlags(state: number, flags: Array<number>) {
    for (const flag of flags) {
        if (!hasFlag(state, flag)) {
            return false;
        }
    }
    return true;
}

export function hasNoFlags(state: number, flags: Array<number>) {
    for (const flag of flags) {
        if (hasFlag(state, flag)) {
            return false;
        }
    }
    return true;
}
export function parseKey(key: string) {
    let productKey = '';
    if (key) {
        const match = key.match(/(.{1,5})/g);
        match?.map((chunk, p) => {
            if (p == 0) {
                productKey = chunk;
            } else {
                productKey += ' - ' + chunk;
            }
        });
    }
    return productKey;
}

export function changeOrderForBackup(sources: Array<LiveSource>) {
    const sourcesToUpdate = [...sources];
    let samSource;
    const sourcesForBackup: Array<LiveSource> = [];
    for (const liveSource of sourcesToUpdate) {
        if (liveSource.LiveSourceType === 0) {
            samSource = liveSource;
            samSource.OrderIndex = 2;
        } else {
            liveSource.OrderIndex = sourcesForBackup.length;
            sourcesForBackup[sourcesForBackup.length] = liveSource;
        }
    }
    sourcesForBackup[2] = samSource;
    return sourcesForBackup;
}
