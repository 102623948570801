import { AudioSettingDto } from '../../../../../models/dto';
import { SelectItem } from '../../../../../models/interfaces';
import { FadeType, IAudioSettings, ILoading } from './interfaces';

export const fullDB = -10;
export const defaultLevelXfade = -30;
export const defaultMaxCross = 5;
export const maxLevel = -10;
export const minLevel = -60;
export const defaultLevel = -50;
export const minDuration = 1;
export const minMaxCross = 1;
export const maxMaxCross = 10;
export const maxDuration = 10000;
export const defaultDuration = 10000;
export const minXfadeSkipShortDuration = 0;
export const maxXfadeSkipShortDuration = 999;

export const defaultLoading: ILoading = {
    mainDisplayData: false,
    saveBtnData: false
};

export const defaultAudioSettings: IAudioSettings = {
    TrimSilence: true,
    Xfade: true,
    XfadeSkipShortDuration: 70,
    LevelStart: -50,
    LevelEnd: -50,
    LevelXfade: -30,
    MaxCross: 5,
    FadeIn: { Duration: 1, FadeType: 3 },
    FadeOut: { Duration: 3, FadeType: 3 },
    StationGain: 0
};

export const curveOptions: SelectItem<string, string>[] = [
    { value: 'Linear', id: '1' },
    { value: 'Sinusoidal', id: '2' },
    { value: 'Logarithmic', id: '3' },
    { value: 'Exponential', id: '4' }
];

export declare type LoadingTypes = 'mainDisplayData' | 'saveBtnData';
export declare type AudioSettingInputName =
    | 'TrimSilence'
    | 'Xfade'
    | 'XfadeSkipShortDuration'
    | 'LevelStart'
    | 'LevelEnd'
    | 'LevelXfade'
    | 'MaxCross'
    | 'FadeInEnabled'
    | 'FadeInDuration'
    | 'FadeOutEnabled'
    | 'FadeOutDuration'
    | 'StationGain';

/**
 * To be manipulated during editing, just setting a baseline of defined values to correctly detect changes.
 */
export const initAudioSettingsDto: Partial<AudioSettingDto> = {
    FadeInType: FadeType.Default,
    FadeOutType: FadeType.Default,
    Gain: 0,
    MediaItemId: '',
    /**
     * true: defined, false: don't trim, undefined: default.
     */
    TrimSilence: null,
    Xfade: null
};
