import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getLogFilterTotals } from '../../../../../middleware/server-log-entry';
import { LocalStorageType, getLocalStorageItem } from '../../../../../utils/local-storage';
import { useEffectAsync } from '../../../../../utils/react-util';
import { createLinkUrl, getStationId, isOnPage } from '../../../../../utils/router-util';
import { IHealthAlertNotification, LogTotals } from '../models/interfaces';
import { Color } from '../../../../../utils/colors';

export function useHealthAlertNotification(): IHealthAlertNotification {
    const [serverLogTotals, setServerLogTotals] = useState<LogTotals[]>([]);

    const location = useLocation();
    const stationId = getStationId(location);
    const healthAlertEnabled = getLocalStorageItem(LocalStorageType.ENABLEHEALTHALERT);

    useEffectAsync(
        async (signal) => {
            if (healthAlertEnabled !== false && stationId) {
                const lastHealthView = getLocalStorageItem(LocalStorageType.LASTVIEWEDHEALTHALERT);
                const response = await getLogFilterTotals(stationId, true, lastHealthView, signal);
                if (response.success) {
                    setServerLogTotals(response.data);
                }
            }
        },
        [stationId, healthAlertEnabled]
    );

    useEffect(() => {
        if (stationId) {
            const eventsLink = createLinkUrl('/station/:stationId/events', stationId);
            if (isOnPage(location, eventsLink)) {
                setServerLogTotals([]);
            }
        }
    }, [location]);

    if (!healthAlertEnabled) {
        return {
            showAlert: false
        };
    }

    let display = 0;
    let color: Color = 'info';

    const errorTotals = serverLogTotals.find((x) => x.Key === 'error')?.Value;
    const allNotificationTotal = serverLogTotals.map((logTotal) => parseInt(logTotal.Value)).reduce((a, b) => a + b, 0);

    if (errorTotals && parseInt(errorTotals) > 0) {
        color = 'error';
        display = parseInt(errorTotals);
    } else if (allNotificationTotal > 99) {
        display = allNotificationTotal;
    }

    return { showAlert: true, color: color, value: display };
}
