import React, { FC, useState, MouseEvent } from 'react';
import { FunctionSelectItem } from '../models/interfaces';
import { MenuBtnProps } from '../models/props';
import { makeStringIdentifier } from '../pages/station/upload/general';
import LoadingBtn from './loading-btn';
import { Fade, ListItemIcon, Menu, MenuItem } from './mui';

/**
 * Menu popup with actions.
 * Usage:
 * menuItems[0].value(); // To invoke callback.
 */
export const MenuBtn: FC<MenuBtnProps> = ({
    buttonProps,
    children,
    circularProgressProps,
    loading,
    sx,
    menuItems,
    selectedItem
}) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement>();
    const open = Boolean(anchorEl);
    const handleClick = (event: MouseEvent<HTMLElement>) => {
        if (menuItems.length === 1) {
            // If there's only one item, invoke it, no need for a popup:
            menuItems[0].value();
        } else {
            setAnchorEl(event.currentTarget);
        }
    };
    const handleClose = (item?: FunctionSelectItem) => {
        setAnchorEl(undefined);
        if (item) {
            item.value();
        }
    };

    return (
        <div>
            <LoadingBtn
                buttonProps={{
                    ...buttonProps,
                    onClick: handleClick
                }}
                loading={loading}
                circularProgressProps={circularProgressProps}
                sx={sx}
            >
                {children}
            </LoadingBtn>
            <Menu anchorEl={anchorEl} open={open} onClose={() => handleClose()} TransitionComponent={Fade}>
                {menuItems.map((item) => {
                    const isSelected = selectedItem === item;
                    return (
                        <MenuItem key={makeStringIdentifier(item.id)} selected={isSelected} onClick={() => handleClose(item)}>
                            {item.icon && (
                                <ListItemIcon>
                                    <item.icon fontSize="small" />
                                </ListItemIcon>
                            )}
                            {item.id}
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    );
};

export default MenuBtn;
