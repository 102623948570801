import { progressStates, sizes, validTypes } from './models/consts';
import { ClearOption, Destination, IStatusTooltip, IUploadStoreItem, Tab } from './models/interfaces';

export function cleanupUrl(url: string) {
    const indexQuestionMark = url.lastIndexOf('?');
    if (indexQuestionMark > -1) {
        url = url.substring(0, indexQuestionMark);
    }
    return url;
}
/**
 * Use this to remove funny characters to make e.g. an ID.
 */
export function makeStringIdentifier(str: string): string {
    return str.replace(/[^a-zA-Z0-9 ]/, '').replace(/ /g, '');
}

export function encodeUrlParams(value: string) {
    if (value.indexOf('?') != -1) {
        const encodedParms: Array<string> = [];
        const url = new URL(value);
        const baseUrl = `${url.protocol}//${url.host}${url.pathname}?`;

        const parms = url.search.split('?')[1].split('&');

        for (const param of parms) {
            const paramArray = param.split('=');
            encodedParms.push(`${paramArray[0]}=${encodeURIComponent(paramArray[1])}`);
        }
        return baseUrl + encodedParms.join('&');
    }
    return value;
}

export function isValidFileType(filename) {
    const ext = filename.split('.').pop();
    if (ext == filename) {
        return false;
    }
    return validTypes.some((x) => x === ext.toLowerCase());
}

export function generateUID() {
    return ('0000' + ((Math.random() * Math.pow(36, 4)) << 0).toString(36)).slice(-4);
}

export function getFileSize(bytes) {
    if (bytes < Math.pow(2, 10)) {
        return bytes + ' Bytes';
    }
    if (bytes >= Math.pow(2, 10) && bytes < Math.pow(2, 20)) {
        return Math.round(bytes / Math.pow(2, 10)) + ' KB';
    }
    if (bytes >= Math.pow(2, 20) && bytes < Math.pow(2, 30)) {
        return Math.round((bytes / Math.pow(2, 20)) * 10) / 10 + ' MB';
    }
    if (bytes > Math.pow(2, 30)) {
        return Math.round((bytes / Math.pow(2, 30)) * 100) / 100 + ' GB';
    }
    return '';
}

export function retrieveQueueSizeInBytes(items: Array<IUploadStoreItem>) {
    let totalSize = 0;

    for (const item of items) {
        const progressState = item.progressState;
        if (
            progressState == progressStates.QUEUED ||
            progressState == progressStates.UPLOADING ||
            progressState == progressStates.UPLOADED
        ) {
            totalSize += item.size;
        }
    }
    return totalSize;
}

export function retrieveQueueStatus(queueCount: number): IStatusTooltip {
    if (queueCount <= 30) {
        return { title: 'Normal - You can typically expect your files to be ready within a few minutes.', color: 'success' };
    }
    if (queueCount > 30 && queueCount <= 80) {
        return { title: 'Busy - You can typically expect your files to be ready within 30 minutes.', color: 'info' };
    }
    if (queueCount > 80) {
        return {
            title: 'Very busy - You can typically expect your files to be ready in more than 30 minutes.',
            color: 'warning'
        };
    }

    return { title: 'Could not determine the global upload queue status at this time.', color: 'error' };
}

export function idExists(id: string, queue: Array<IUploadStoreItem>) {
    return queue.some((x) => x.id === id);
}

export function isMatchFilenameOnly(type: Tab) {
    switch (type) {
        case 'FILE':
            return true;
        default:
            return false;
    }
}

export function generateRandomNumber() {
    return Math.random().toString(36).slice(2, 9);
}

export function padBlockId(blockId: number, length: number) {
    let str = blockId.toString();
    while (str.length < length) {
        str = `0${str}`;
    }
    return str;
}

export function resolveImportModeType(importMode: number) {
    switch (importMode) {
        case 0:
            return 'IgnoreDuplicates';
        case 1:
            return 'ReplaceFile';
        case 2:
            return 'ReplaceFileAndMetadata';
        default:
            return '';
    }
}

export function resolveImportDestination(destination: Destination) {
    switch (destination) {
        case 'Library':
            return 0;
        case 'Playlist':
            return 1;
        case 'Queue':
            return 2;
        default:
            return 0;
    }
}

export function resolveClearOptionToProgressState(option: ClearOption) {
    switch (option) {
        case 'ALL':
            return Object.values(progressStates).filter((x) => x !== progressStates.UPLOADING);
        case 'COMPLETED':
            return [progressStates.UPLOADED, progressStates.READY, progressStates.SKIPPED];
        case 'FAILED':
            return [progressStates.ERROR, progressStates.INVALID, progressStates.LOCALERROR, progressStates.DELETED];
        default:
            return [];
    }
}

export function removeParamFromURL(key: string, sourceURL: string) {
    let rtn = sourceURL.split('?')[0];
    let param: string;
    let paramsArray: Array<string> = [];
    const queryString = sourceURL.indexOf('?') !== -1 ? sourceURL.split('?')[1] : '';

    if (queryString !== '') {
        paramsArray = queryString.split('&');
        for (let i = paramsArray.length - 1; i >= 0; i -= 1) {
            param = paramsArray[i].split('=')[0];
            if (param === key) {
                paramsArray.splice(i, 1);
            }
        }
        rtn = rtn + '?' + paramsArray.join('&');
    }
    return rtn;
}

export function formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) {
        return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function retrieveItemByFilename(fileName: string, storeItems: Array<IUploadStoreItem>) {
    const items = storeItems.map((item) => {
        const re = new RegExp(item.filename + '*', 'gi');
        const test = re.test(fileName);
        if (test) {
            return item;
        }
    });
    if (items.length > 0) {
        return items[0];
    }
}
