import { SelectItem } from '../../models/interfaces';
import { buildReportPeriods, buildTimeZone } from './utils';

export type Inputs = 'Filter' | 'Report Type' | 'Period' | 'Time Zone';
export const filterInputIndex = 0;
export const reportTypeIndex = 1;
export const periodIndex = 2;
export const timeZoneIndex = 3;
export type SelectItemDataType = SelectItem<string, { title?: string; value: unknown }>;
export type ItemInputs = SelectItem<Inputs, { data: SelectItemDataType[]; description: string; selected?: SelectItemDataType }>[];

// Data should just be populated (via fetch)
const reportTypeData = [
    { id: '0', value: { value: 'Detail Report' } },
    { id: '1', value: { value: 'Aggregate Report' } }
];
const periodData = buildReportPeriods();
const timeZoneData = buildTimeZone();
export const initInputValues: ItemInputs = [
    { id: 'Filter', value: { data: [], description: 'Media type filter' } },
    {
        id: 'Report Type',
        value: {
            data: reportTypeData,
            description: 'Report type',
            selected: reportTypeData[0]
        }
    },
    {
        id: 'Period',
        value: {
            data: periodData,
            description: 'Period',
            selected: periodData[0]
        }
    },
    { id: 'Time Zone', value: { data: timeZoneData, description: 'Time Zone', selected: timeZoneData[11] } } // 11 is "UTC"
];
