import moment from 'moment';
import { HistoryItemDto } from '../../models/dto';
import { ITblCol } from '../../models/interfaces';
import { formatDateTimeFull } from '../../models/time';

export const historyItemCols: Array<ITblCol<HistoryItemDto>> = [
    {
        label: 'Played',
        dataKey: 'DatePlayed',
        width: 1,
        labelFormatter: (label) => {
            return moment(label).format(formatDateTimeFull);
        },
        style: { textAlign: 'left' },
        headerStyle: { textAlign: 'left' }
    },
    {
        label: 'Time ago',
        dataKey: 'Album', // Note, we can't use TimeAgo because it doesn't exist in .
        width: 1,
        labelFormatter: (label, row?: HistoryItemDto) => {
            if (row) {
                const date1 = moment(new Date());
                const date2 = moment(new Date(row.DatePlayed));
                const minutes = date1.diff(date2, 'minutes');
                const daysFloat = minutes / 1440;
                const hoursFloat = (daysFloat - Math.floor(daysFloat)) * 24;
                const minutesFloat = (hoursFloat - Math.floor(hoursFloat)) * 60;

                const daysDisplay = Math.floor(daysFloat);
                const hoursDisplay = Math.floor(hoursFloat);
                const minutesDisplay = Math.floor(minutesFloat);

                return `${daysDisplay ? `${daysDisplay}d` : ''}${daysDisplay && hoursDisplay ? '-' : ''}${
                    hoursDisplay ? `${hoursDisplay}h` : ''
                }${hoursDisplay && minutesDisplay ? '-' : ''}${minutesDisplay ? `${minutesDisplay}m` : ''}`;
            }
            return label;
        },
        style: { textAlign: 'start' },
        headerStyle: { textAlign: 'start' }
    },
    {
        label: 'Performances',
        dataKey: 'Performances',
        width: 0.8,
        style: { textAlign: 'center' },
        headerStyle: { textAlign: 'center' }
    }
];
