import React, { Suspense, lazy } from 'react';
import CenteredCircularLoader from './centered-circular-loader';

const AudioSettingsProvider = lazy(() => import('../pages/station/settings/audio-settings'));
const BillingProvider = lazy(() => import('../pages/billing/billing-services'));
const ChatProvider = lazy(() => import('../pages/station/chat'));
const ChatWidgetPreview = lazy(() => import('../pages/station/chat/components/chat-widget-details'));
const DJManagementProvider = lazy(() => import('../pages/station/settings/dj-management'));
const GeoblockingProvider = lazy(() => import('../pages/station/settings/geo-blocking'));
const HealthNotificationProvider = lazy(() => import('../pages/station/events/health'));
const IntegrationProvider = lazy(() => import('../pages/station/settings/integration'));
const LibraryPage = lazy(() => import('../pages/station/library'));
const ListenerStatsEvents = lazy(() => import('../pages/station/events/listener-stats-events'));
const ListenLink = lazy(() => import('../pages/station/listen-link'));
const LiveControlProvider = lazy(() => import('../pages/station/live-control'));
const PageLoading = lazy(() => import('../pages/loading'));
const PageLogin = lazy(() => import('../pages/login'));
const PlayblockProvider = lazy(() => import('../pages/station/schedule/playblocks'));
const Profile = lazy(() => import('../pages/profile'));
const RequestPolicyProvider = lazy(() => import('../pages/station/settings/request-policy'));
const ScheduledEventsProvider = lazy(() => import('../pages/station/schedule/scheduled-events'));
const ScheduledMaintenanceWindow = lazy(() => import('../pages/maintenance/scheduled-maintenance-window'));
const SeparationRulesProvider = lazy(() => import('../pages/station/schedule/seperation-rules'));
const Settings = lazy(() => import('../pages/settings'));
const StationDetailsProvider = lazy(() => import('../pages/station/settings/station-details'));
const StationSelectProvider = lazy(() => import('../pages/station-select'));
const StreamingProvider = lazy(() => import('../pages/billing/streaming'));
const UploadProvider = lazy(() => import('../pages/station/upload'));
const WebToolsAndWidgets = lazy(() => import('../pages/station/widgets/website-tools-and-widgets'));
const WebWidgets = lazy(() => import('../pages/station/widgets/web-widgets'));

/**
 * Wrap item in Suspense for Lazy Loading.
 */
function withSuspense(item: JSX.Element) {
    return <Suspense fallback={<CenteredCircularLoader />}>{item}</Suspense>;
}

const AudioSettingsProviderLoader = () => {
    return withSuspense(<AudioSettingsProvider />);
};

const BillingProviderLoader = () => {
    return withSuspense(<BillingProvider />);
};

const ChatProviderLoader = () => {
    return withSuspense(<ChatProvider />);
};

const ChatWidgetPreviewLoader = () => {
    return withSuspense(<ChatWidgetPreview />);
};

const DJManagementProviderLoader = () => {
    return withSuspense(<DJManagementProvider />);
};

const GeoblockingProviderLoader = () => {
    return withSuspense(<GeoblockingProvider />);
};

const HealthNotificationProviderLoader = () => {
    return withSuspense(<HealthNotificationProvider />);
};

const IntegrationProviderLoader = () => {
    return withSuspense(<IntegrationProvider />);
};

const LibraryPageLoader = () => {
    return withSuspense(<LibraryPage />);
};

const ListenerStatsEventsLoader = () => {
    return withSuspense(<ListenerStatsEvents />);
};

const ListenLinkLoader = () => {
    return withSuspense(<ListenLink />);
};

const LiveControlProviderLoader = () => {
    return withSuspense(<LiveControlProvider />);
};

const PageLoadingLoader = () => {
    return withSuspense(<PageLoading />);
};

const PageLoginLoader = () => {
    return withSuspense(<PageLogin />);
};

const PlayblockProviderLoader = () => {
    return withSuspense(<PlayblockProvider />);
};

const ProfileLoader = () => {
    return withSuspense(<Profile />);
};

const RequestPolicyProviderLoader = () => {
    return withSuspense(<RequestPolicyProvider />);
};

const ScheduledEventsProviderLoader = () => {
    return withSuspense(<ScheduledEventsProvider />);
};

const ScheduledMaintenanceWindowLoader = () => {
    return withSuspense(<ScheduledMaintenanceWindow />);
};

const SeparationRulesProviderLoader = () => {
    return withSuspense(<SeparationRulesProvider />);
};

const SettingsLoader = () => {
    return withSuspense(<Settings />);
};

const StationDetailsProviderLoader = () => {
    return withSuspense(<StationDetailsProvider />);
};

const StationSelectProviderLoader = () => {
    return withSuspense(<StationSelectProvider />);
};

const StreamingProviderLoader = () => {
    return withSuspense(<StreamingProvider />);
};

const UploadProviderLoader = () => {
    return withSuspense(<UploadProvider />);
};

const WebToolsAndWidgetsLoader = () => {
    return withSuspense(<WebToolsAndWidgets />);
};

const WebWidgetsLoader = () => {
    return withSuspense(<WebWidgets />);
};

export {
    AudioSettingsProviderLoader,
    BillingProviderLoader,
    ChatProviderLoader,
    ChatWidgetPreviewLoader,
    DJManagementProviderLoader,
    GeoblockingProviderLoader,
    HealthNotificationProviderLoader,
    IntegrationProviderLoader,
    LibraryPageLoader,
    ListenLinkLoader,
    ListenerStatsEventsLoader,
    LiveControlProviderLoader,
    PageLoadingLoader,
    PageLoginLoader,
    PlayblockProviderLoader,
    ProfileLoader,
    RequestPolicyProviderLoader,
    ScheduledEventsProviderLoader,
    ScheduledMaintenanceWindowLoader,
    SeparationRulesProviderLoader,
    SettingsLoader,
    StationDetailsProviderLoader,
    StationSelectProviderLoader,
    StreamingProviderLoader,
    UploadProviderLoader,
    WebToolsAndWidgetsLoader,
    WebWidgetsLoader
};
