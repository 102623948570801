import { AccountResponseDto, DynamicListDto, ILoginRequest, NotificationItem, NumberResponseDto } from '../models/dto';
import Lang from '../models/language';
import { AccountState } from '../providers/account';
import { BaseUrl } from '../utils/env';
import { Fetch } from '../utils/middleware';
import { getRequestInitGET, getRequestInitPOST, getRequestInitPUT } from './headers';

const lblNotificationItemId = '{notificationItemId}';

const urlLogin = 'api/AccountV2/Login?ReturnUrl=%2F'; // POST This needs credentials and allow the origin explicitly.
const urlLoginSession = 'api/AccountV2/Login?ReturnUrl=%2F'; // GET
const urlLogoff = 'api/AccountV2/LogOff';
const urlForgotPassword = 'api/AccountV2/ForgotPassword';
export const urlVerifySession = 'api/AccountV2/VerifySession';

// User Notifications:
const notificationApi = 'api/notification/user';
const userNotificationUrl = `${notificationApi}`;
const userNotificationCountUrl = `${notificationApi}/count`;
const userNotificationAcknowledgeAllUrl = `${notificationApi}/acknowledge/all`;
const userNotificationDismissAllUrl = `${notificationApi}/dismiss/all`;
const userNotificationAcknowledgeUrl = `${notificationApi}/acknowledge/${lblNotificationItemId}`;
const userNotificationDismissUrl = `${notificationApi}/dismiss/${lblNotificationItemId}`;

function assessAccountResponse(loginDetails: AccountResponseDto): AccountState {
    const error = loginDetails.message && { error: loginDetails.message };
    const siteUrl = loginDetails.siteUrl ?? '';
    const trackingID = loginDetails.trackingID;

    if (loginDetails.success || !error) {
        if (loginDetails.loggedIn) {
            return {
                brand: loginDetails.brand,
                loading: false,
                isRecaptchaEnabled: loginDetails.isRecaptchaEnabled,
                username: loginDetails.loginModel?.username,
                loggedIn: loginDetails.loggedIn,
                useTritonSso: loginDetails.useTritonSso,
                siteUrl: siteUrl,
                userId: loginDetails.userId,
                trackingID: trackingID,
                maintenanceMode: loginDetails.maintenanceMode,
                pictureStorageBase: loginDetails.pictureStorageBase
            };
        } else {
            // If it comes in here the request was unauthorized:
            return {
                brand: loginDetails.brand,
                loading: false,
                isRecaptchaEnabled: loginDetails.isRecaptchaEnabled,
                loggedIn: false,
                useTritonSso: loginDetails.useTritonSso,
                siteUrl: siteUrl,
                userId: loginDetails.userId,
                trackingID: trackingID,
                maintenanceMode: loginDetails.maintenanceMode,
                pictureStorageBase: loginDetails.pictureStorageBase
            };
        }
    } else if (error && error.error === Lang.msgLogoutVerificationError) {
        // Special case: Only when the validate times out with 401.
        return {
            loading: false,
            isRecaptchaEnabled: loginDetails.isRecaptchaEnabled,
            loggedIn: false,
            useTritonSso: loginDetails.useTritonSso,
            userId: loginDetails.userId,
            siteUrl,
            trackingID: trackingID,
            maintenanceMode: loginDetails.maintenanceMode,
            pictureStorageBase: loginDetails.pictureStorageBase
        };
    } else {
        return {
            loading: false,
            isRecaptchaEnabled: loginDetails.isRecaptchaEnabled,
            loggedIn: false,
            useTritonSso: loginDetails.useTritonSso,
            userId: loginDetails.userId,
            siteUrl: siteUrl,
            trackingID: trackingID,
            maintenanceMode: loginDetails.maintenanceMode,
            pictureStorageBase: loginDetails.pictureStorageBase,
            ...error
        };
    }
}

export async function login(newUser: ILoginRequest): Promise<AccountState> {
    const url = `${BaseUrl()}${urlLogin}`;
    const loginDetails = await Fetch<AccountResponseDto>(url, { ...getRequestInitPOST(), body: JSON.stringify(newUser) });

    return assessAccountResponse(loginDetails);
}

export async function loginSession(): Promise<AccountState> {
    const url = `${BaseUrl()}${urlLoginSession}`;
    const loginDetails = await Fetch<AccountResponseDto>(url, getRequestInitGET());

    return assessAccountResponse(loginDetails);
}

export async function logout(): Promise<AccountState> {
    const url = `${BaseUrl()}${urlLogoff}`;
    const loginDetails = await Fetch<AccountResponseDto>(url, getRequestInitPOST());

    return assessAccountResponse(loginDetails);
}

export async function verifySession(): Promise<AccountState> {
    const url = `${BaseUrl()}${urlVerifySession}`;
    const loginDetails = await Fetch<AccountResponseDto>(url, getRequestInitGET());

    return assessAccountResponse(loginDetails);
}

export async function forgotPassword(email: string): Promise<AccountState> {
    const url = `${BaseUrl()}${urlForgotPassword}`;
    const request: Partial<ILoginRequest> = { username: email };
    const loginDetails = await Fetch<AccountResponseDto>(url, { ...getRequestInitPUT(), body: JSON.stringify(request) });

    return assessAccountResponse(loginDetails);
}

export async function getUserNotifications(): Promise<DynamicListDto<NotificationItem>> {
    const url = `${BaseUrl()}${userNotificationUrl}`;
    return await Fetch<DynamicListDto<NotificationItem>>(url, getRequestInitGET());
}

export async function getUserNotificationsCount(): Promise<NumberResponseDto> {
    const url = `${BaseUrl()}${userNotificationCountUrl}`;
    return await Fetch<NumberResponseDto>(url, getRequestInitGET());
}

export async function acknowledgeAllUserNotifications(): Promise<NumberResponseDto> {
    const url = `${BaseUrl()}${userNotificationAcknowledgeAllUrl}`;
    return await Fetch<NumberResponseDto>(url, getRequestInitPUT());
}

export async function dismissAllUserNotifications(): Promise<NumberResponseDto> {
    const url = `${BaseUrl()}${userNotificationDismissAllUrl}`;
    return await Fetch<NumberResponseDto>(url, getRequestInitPUT());
}

export async function acknowledgeUserNotifications(NotificationItemId): Promise<NumberResponseDto> {
    const url = `${BaseUrl()}${userNotificationAcknowledgeUrl.replace(lblNotificationItemId, NotificationItemId)}`;
    return await Fetch<NumberResponseDto>(url, getRequestInitPUT());
}

export async function dismissUserNotifications(NotificationItemId): Promise<NumberResponseDto> {
    const url = `${BaseUrl()}${userNotificationDismissUrl.replace(lblNotificationItemId, NotificationItemId)}`;
    return await Fetch<NumberResponseDto>(url, getRequestInitPUT());
}
