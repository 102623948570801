export type PlayerStateCode = 'STREAM_GEO_BLOCKED' | 'STREAM_GEO_BLOCKED_MESSAGE_RECEIVED' | number;

export interface PlayerStateEvent {
    data: { code: PlayerStateCode };
}

export enum PlayerEvent {
    STREAM_START = 'stream-start',
    STREAM_STOP = 'stream-stop',
    STREAM_FAIL = 'stream-fail',
    STREAM_ERROR = 'stream-error',
    TRACK_CUE_POINT = 'track-cue-point',
    SPEECH_CUE_POINT = 'speech-cue-point',
    AD_BREAK_CUE_POINT = 'ad-break-cue-point',
    STREAM_STATUS = 'stream-status'
}

export interface CuePoint {
    albumName: string;
    artistName: string;
    coverURL: string;
    cueTimeDuration: string; //"220236"
    cueTimeStart: string; //"1710765869385"
    cueTitle: string;
    mount: string;
    parameters: {
        cue_time_duration: string;
        cue_time_start: string;
        cue_title: string;
        track_album_name: string;
        track_artist_name: string;
        track_cover_url: string;
        track_id: string;
    };
    timestamp: number;
    trackId: string;
    type: string;
}

export interface CuePointChanged {
    data: { cuePoint: CuePoint };
}
