import { includeCors } from '../utils/env';

export const basicHeaders = {
    Accept: '*/*',
    'Content-Type': 'application/json'
};

export function getRequestInitPOST(includeheaders = true): RequestInit {
    const init: RequestInit = {
        credentials: 'include',
        ...(includeCors() && { mode: 'cors' }),
        ...(includeheaders && { headers: basicHeaders }),
        method: 'POST'
    };
    return init;
}

export function getRequestInitGET(includeheaders = true): RequestInit {
    const init: RequestInit = {
        ...{ credentials: 'include' },
        ...(includeheaders && { headers: basicHeaders }),
        method: 'GET'
    };
    return init;
}

export function getRequestInitPUT(includeheaders = true): RequestInit {
    const init: RequestInit = {
        ...getRequestInitGET(includeheaders),
        method: 'PUT'
    };
    return init;
}

export function getRequestInitDELETE(): RequestInit {
    const init: RequestInit = {
        ...getRequestInitGET(),
        method: 'DELETE'
    };
    return init;
}
