import { ThirdPartyIntegrationsDto } from '../../pages/station/settings/integration/models/interfaces';
import { BaseUrl } from '../../utils/env';
import { Fetch } from '../../utils/middleware';
import { getRequestInitGET } from '../headers';

export const integrationApi = 'api/thirdPartyIntegration';
export const lblMediaTypeCodes = '{mediaTypeCodes}';
export const lblStationId = '{stationId}';
export const urlIntegration = `${integrationApi}/${lblStationId}`;

const urlGetThirdPartyIntegrationDetails = `${urlIntegration}/retrieveThirdPartyIntegrationDetails`;

export async function getThirdPartyIntegrationDetails(
    stationId: string,
    requestInit?: RequestInit
): Promise<ThirdPartyIntegrationsDto> {
    const url = `${BaseUrl()}${urlGetThirdPartyIntegrationDetails.replace(lblStationId, stationId)}`;
    return await Fetch(url, requestInit ?? getRequestInitGET());
}
