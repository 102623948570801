/**
 * Deep clone an object.
 * @returns a deep copy of the cloned object
 */
export default function getDeepClonedObject<T>(obj: T): T {
    if (Array.isArray(obj)) {
        const clonedObjs = [];
        for (const item of obj) {
            if (typeof item === 'object') {
                //shldn't try deep clone primitive types
                const clonedItem = getDeepClonedSingleObject(item);
                clonedObjs.push(clonedItem as never);
            } else {
                clonedObjs.push(item as never);
            }
        }
        return clonedObjs as T;
    } else {
        return getDeepClonedSingleObject(obj);
    }

    function getDeepClonedSingleObject<U>(obj: U): U {
        const clonedObj = {};
        for (const [key] of Object.entries(obj as object)) {
            if (obj[key] && typeof obj[key] === 'object') {
                clonedObj[key] = getDeepClonedObject(obj[key]);
            } else {
                clonedObj[key] = obj[key];
            }
        }
        return clonedObj as U;
    }
}
