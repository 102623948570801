import { SelectItem } from '../../models/interfaces';

/**
 * Dojo SAM Cloud also fetched the storage every 30 seconds:
 */
export const storageFetchIntervalDelay = 30000;
/**
 * Dojo SAM Cloud also fetched the graph every 30 seconds:
 */
export const graphFetchIntervalDelay = 60000;

type IntervalType = 'hours' | 'days' | 'months';

export interface AnalyticsInterval {
    amount: number;
    /**
     * Format: `${amount}-${interval}`
     */
    id: string;
    interval: IntervalType;
    /**
     * Format auto populated to: 'MMM YYYY'.
     */
    label: string;
}

export const defaultAnalyticsInterval: AnalyticsInterval = { amount: 24, id: '24-hour', interval: 'hours', label: '24 Hour' };

export const intervalStore: SelectItem<string, string>[] = [
    { id: '0', value: 'Minute Stats' },
    { id: '1', value: 'Hourly Stats' },
    { id: '2', value: 'Daily Stats' }
];

export interface PeriodType {
    index: number;
    description: string;
    date: Date;
}

export interface TimezoneType {
    index: string;
    description: string;
    offset: number;
}

export const allMonths = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];
