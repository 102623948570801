import { CSSProperties, MouseEvent, ReactNode } from 'react';
import { PopoverReference, SvgIcon, SxProps, Theme } from '../components/mui';
import { RelayGridData } from '../pages/billing/streaming/models/interfaces';
import { ResolvedTreeNode, TreeEntity } from '../pages/station/library/models/interfaces';
import { ClockwheelLineItemDto } from '../pages/station/schedule/playblocks/models/interfaces';
import { Color } from '../utils/colors';
import { delay } from '../utils/general';
import { KeyboardDef } from '../utils/key-press-handler';
import { LocalStorageType } from '../utils/local-storage';
import { TableEntity } from '../utils/signalr/models';
import {
    DynamicTableRequest,
    GptDetailDto,
    HistoryItemDto,
    LibraryItemDto,
    PlaylistItemDto,
    QueueItemDto,
    RequestItemDto
} from './dto';

export declare type AnchorPosition = 'left' | 'top' | 'right' | 'bottom';
export declare type DisplayMode = 'expand' | 'tooltip' | 'both';
export declare type ButtonIconPositon = 'right' | 'left';

/**
 * Based on Mui.Alert.AlertColor (severity).
 */
export declare type Severity = 'success' | 'info' | 'warning' | 'error';

/**
 * Basic function that returns nothing.
 */
export declare type Void<T> = (value: T) => void;

/**
 * Basic function that returns something.
 */
export declare type Fn<T> = (item: T) => T;

// Note: As a standard, an empty function should explicitly be defined, otherwise a developer should be warned by the linter:
// eslint-disable-next-line @typescript-eslint/no-empty-function
export const EmptyFunc = () => {};

// Note: As a standard, an empty function should explicitly be defined, otherwise a developer should be warned:
// eslint-disable-next-line @typescript-eslint/no-empty-function
export const EmptyAsyncFunc = async () => {};

export const EmptyAsyncBaseResponse = async () => {
    await delay(1000);
    return { success: true, message: '' };
};

/**
 * Basic function that returns nothing.
 */
export declare type FnVoid = () => void;

/**
 * Asynchronous function.
 */
export declare type FnAsync<T> = () => Promise<T>;
export declare type FnAsyncWithParam<T, U> = (opt?: U) => Promise<T>;

/**
 * Basic event usually to prevent default.
 */
export interface Evt {
    preventDefault: FnVoid;
    target?: EventTarget;
    key?: string;
}

export interface LocationState {
    from: string;
    /**
     * To prevent {@link from} location to go to an unauthorized location,
     * compare {@link prevUsername} with accountState.username.
     */
    prevUsername?: string;
}

export declare type JSXChildren = JSX.Element[] | JSX.Element;

export interface Exception {
    message: string;
}

export interface ITblCol<T> {
    label: ReactNode;
    /**
     * The whole cell can be intercepted by using row, label is basically the value of {@link ITblCol}[{@link dataKey}].
     */
    labelFormatter?: (
        label: string,
        row?: T,
        nowPlayingInfo?: HistoryItemDto,
        listItems?: TblColType[],
        etas?: moment.Moment[]
    ) => ReactNode;
    /**
     * Custom is will have to depend on the labelFormatter to display.
     */
    dataKey: keyof T;
    headerStyle?: CSSProperties;
    style?: CSSProperties;
    width: number;
}

export declare type CustomInputType = 'url' | 'text' | 'select' | 'image' | 'email';

/**
 * This defines an queue item being loaded but hasn't resolved yet. It will resolve into a queue/history item eventually.
 * Look at TblDataIdentifiers.
 */
export class PlaceholderItem {
    /**
     * When adding or removing.
     */
    action: 'add' | 'remove' | 'updating';
    /**
     * If it's pending it can't perform fetch yet. If it's ready, it probably came from Signal R (server) and can fetch soon.
     */
    status: 'pending' | 'fetching';
    /**
     * QueueItem/HistoryItem/MediaItem ID.
     */
    id: string;
    /**
     * Just to append a name to the placeholder item for display.
     */
    description?: string;
    /**
     * Sort value for arranging correctly.
     */
    SortValue: number;
    /**
     * DatePlayed for arranging correctly.
     */
    DatePlayed: string;
    /**
     * DateRequested for arranging correctly.
     */
    DateRequested: string;
    /**
     * Signal R Message.Table.
     * Defined only if it comes from a message.
     */
    messageTable?: TableEntity;
    /**
     * Placeholder items should be cleaned if it hasn't been resolved.
     */
    dateAddedUTC: number;

    constructor(action, id, messageTable?: TableEntity) {
        this.action = action;
        this.status = 'pending';
        this.id = id;
        this.SortValue = 0;
        this.DatePlayed = '';
        this.DateRequested = '';
        this.messageTable = messageTable;
        this.dateAddedUTC = Date.now();
    }
}

// TODO: types need to be changed, for now, only HistoryItemDto and QueueItemDto works:
export declare type TblColType =
    | HistoryItemDto
    | LibraryItemDto
    | PlaylistItemDto
    | QueueItemDto
    | RelayGridData
    | ClockwheelLineItemDto
    | PlaceholderItem
    | RequestItemDto;

export declare type OutputModeType = 'TXT' | 'M3U' | 'ASX' | 'PLS' | 'SC' | 'FLASH' | 'TUNEIN';

export interface CustomInput {
    id: number;
    name: string;
    type: CustomInputType;
    label: string;
    required: boolean;
    value: string | File;
    disabled: boolean;
    error: boolean;
    helperText: string | null;
    fullWidth: boolean;
}

export interface SelectItem<T, U> {
    id: T;
    value: U;
}

export interface SelectItemIcon<T, U> extends SelectItem<T, U> {
    icon?: typeof SvgIcon;
    iconSx?: SxProps<Theme>;
}

export type FunctionSelectItem = SelectItemIcon<string, FnVoid>;

export interface StationDetails {
    Name: string;
    Description: string;
    Website: string;
    Facebook: string;
    Twitter: string;
    Logo: string;
    Genre: string;
    GptDetail?: GptDetailDto;
}

export interface IPictureUploadDefaults {
    maxWidth: number;
    minWidth: number;
    maxHeight: number;
    minHeight: number;
    maxFileSizeMB: number;
}

export type MediaItemMediaTypeColor = 'Display 1' | 'Display 2' | 'Display 3' | 'None';
/**
 * Applies for single table (HistoryItem, LibraryItem, PlaylistItem and QueueItem).
 */
export interface TableSettings {
    /**
     * Display 1 - Color cell on the left of each media item.
     * Display 2 - Transparent color over each media item.
     * Display 3 - Full color over each media item.
     * None - No color will be displayed on a media item.
     */
    mediaItemMediaTypeColor: MediaItemMediaTypeColor;
}

export interface LibraryTableData {
    history: LibTblData<TblColType>;
    library: LibTblData<TblColType>;
    playlist: LibTblData<TblColType>;
    queue: LibTblData<TblColType>;
}

/**
 * Add more types if you want it movable.
 */
export type MovableElementType = TableEntity;

export interface LibTblData<T> {
    icon: typeof SvgIcon;
    cols: ITblCol<T>[];
    initColsEnabled: (keyof T)[];
    initTableSettings: TableSettings;
    menuItems: MenuItemData[];
    /**
     * An item in the table can be moved to another table entity.
     * If it's movable in itsself, it means it's sortable.
     */
    movable: MovableElementType[];
    tableEntity: TableEntity;
    tableStorageKey: LocalStorageType;
    tableSettingsStorageKey: LocalStorageType;
    title: string;
    headerLabel?: (_tableData: LibTblData<TblColType>, resolvedNode?: ResolvedTreeNode) => string;
    footerLabel?: (request: DynamicTableRequest, listData?: TblColType[]) => string | undefined;
    footerSubLabel?: (request: DynamicTableRequest, listData?: TblColType[]) => string | undefined;
}

export interface LibraryTreeData {
    libraryTree: LibTreeData;
    playlist: LibTreeData;
}

export interface LibTreeData {
    icon: typeof SvgIcon;
    menuItems: Array<MenuItemData>;
    title: string;
    treeEntity: TreeEntity;
}

export declare type MenuItemAction =
    | 'remove'
    | 'refresh'
    | 'more-items' // When there is more items in that action.
    | TreeEntity
    | 'DisplayableTable'
    | TableEntity // It can be used as an action for libSelectMenuItems.
    | 'edit'
    | 'create-gpt-media-item'
    // Playlist actions:
    | 'new'
    | 'rename'
    // Library-Tree actions:
    | 'color'
    // Queue actions:
    | 'alternate-content'
    | 'intro-track'
    | 'upload-files'
    | 'move-top'
    | 'move-bottom'
    | 'move-up'
    | 'move-down'
    | 'export-MIL'
    | 'export-M3U'
    | 'export-M3U8'
    | 'export-CSV'
    // History actions:
    | 'add-top-queue'
    | 'add-bottom-queue'
    | 'add-to-playlist'
    | 'enable-browsing'
    | 'disable-browsing'
    | 'export-history' // For History Only
    // Playblock actions:
    | 'reset'
    | 'clear-all'
    | 'clear-selected'
    // TODO From here on, these actions have not been implemented yet:
    | 'sort'
    | 'preview in player'
    // Playlist & Queue:
    | 'remove-selected'
    | 'remove-unselected'
    | 'remove-duplicates'
    | 'clear'
    | 'move-to-recyclebin'
    | 'remove-permanently'
    | 'shuffle'
    | 'import-playlist'
    // HealthNotification
    | 'health-notification-export'
    // RECYCLE BIN:
    | 'restore';

export interface MenuItemData {
    action: MenuItemAction;
    /**
     * Currently selected item from where the right click came from.
     * Can be any type of format, just cast it first.
     */
    data?: unknown;
    icon: typeof SvgIcon;
    /**
     * {@link MenuItemData.action} should be 'more-items' to enable this.
     */
    menuItems?: Array<MenuItemData>;
    /**
     * The group order (display purposes).
     * Undefined means 0 (will be displayed at the top).
     */
    order?: number;
    title: string;
    /**
     * If true, there needs to be item(s) selected to initiate the {@link action}.
     * default: false.
     */
    itemsSelectedDependent?: boolean;
    /**
     * If true, there has to be items (not necessarily even selected) to initiate the {@link action}.
     * default: false
     */
    hasItemsDependent?: boolean;
    /**
     * Shortcut to execute for menu item.
     */
    shortcut?: KeyboardDef;
}

export interface Highlightable {
    highlight?: boolean;
}

export interface MouseClickEvent {
    event: MouseEvent<HTMLElement>;
    index: number;
    rowData: TblColType;
}

/**
 * Use this to propagate data from a child mouse event to the top.
 */
export interface DataMouseEvent<T> extends MouseEvent<HTMLElement> {
    data: T;
}

export interface MenuAnchorPosition {
    /**
     * Should the Menu be positioned at mouseY (mouseY needs to be defined then)?
     */
    anchorReference?: PopoverReference;
    data?: unknown;
    mouseX: number;
    mouseY?: number;
    selectedElement: HTMLElement;
    selectedEvent: MouseEvent<HTMLElement>;
}

export declare type DropPosition = 'TOP' | 'BOTTOM';

export declare type AddToPlaylistAction = 'TOP' | 'BOTTOM' | 'CLEAR';

export declare type InputSize = 'small' | 'medium';

export declare type InputType = 'text' | 'password' | 'number' | 'email';

export interface InputBtn {
    onClick: FnVoid;
    label?: string;
    icon: typeof SvgIcon;
    color: Color;
    loading?: boolean;
    disabled?: boolean;
    isOutlined?: boolean;
    sx?: SxProps<Theme>;
}

/**
 * Helper for creating an interface from another interface with props of type {Type}
 */
export type AllNewType<T, Type> = {
    [K in keyof T]: T[K] extends Array<infer U> ? AllNewType<U, Type>[] : Type;
};

export interface DynamicObject {
    [key: string]: unknown;
}

export interface KeyValue<TKey, TValue> {
    Key: TKey;
    Value: TValue;
}

export interface GptInfo {
    BacksellCount: number;
    BreakNote?: string;
    Minutes: number;
    Seconds: number;
}
/**
 * The type of SAM Widget to pass to sam-widget.
 * If it updates in SAM Widget, update it here too.
 */
export declare type SamWidgetType =
    | 'none'
    | 'chat'
    | 'comments'
    | 'current show'
    | 'dedications'
    | 'history'
    | 'info'
    | 'library'
    | 'playlist'
    | 'playlists'
    | 'line up'
    | 'line up tile'
    | 'now playing'
    | 'player'
    | 'show timeline';

export interface IError {
    id: number;
    msg: string;
    params?: unknown;
    timestamp: number;
}

export interface ICountryStateHierarchy {
    name: string;
    type: string;
    children: {
        name: string;
        type: string;
    }[];
    code?: string;
}
