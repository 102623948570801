import deepEqual from 'deep-equal';
import moment from 'moment-timezone';
import { CSSProperties } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { Theme, alpha } from '../components/mui';
import { FeatureFlag, SAMCloudRoutes, pictureStoragePlaceholderKey } from '../models/consts';
import { ManageStationDto, MediaItemDto, PlaylistItemDto, QueueItemDto, SamVibeStationDto } from '../models/dto';
import { SelectItem, Void } from '../models/interfaces';
import { IRoute, UrlSearchParam } from '../models/routes';
import { StationData } from '../pages/routing/provider';
import { Notification } from '../providers/notifications';
import { ConsoleLogError } from './log';
import { ResourcePermissions } from './resource-permissions';
import { createStationRouteUrl } from './router-util';

/**
 * Quick util function to get a random ID.
 * @param prefix Just a string to add on the ID.
 * @param randomize How random(unique) the ID should be.
 * @returns Unique string.
 */
export function getRandomId(prefix = 'id-', randomize = 10000): string {
    return `${prefix}${Math.floor(Math.random() * randomize).toString()}-${Date.now()}`;
}

export const delay = async (time = 5000) => {
    await new Promise<void>((done) => setTimeout(() => done(), time));
};

/**
 * Extract the data out of the item.
 * @param dataKey Key e.g. MediaItem.Title
 * @param rowData Data Item e.g. QueueItem
 */
export function extractItemData(dataKey: string, rowData) {
    let item = { ...rowData };
    const myArray = dataKey.split('.');
    for (let i = 0; i < myArray.length; i++) {
        item = item ? item[myArray[i]] : null;
    }
    return item;
}

export function isValidUrl(urlString: string): boolean {
    try {
        return Boolean(new URL(urlString));
    } catch (e) {
        return false;
    }
}

export const isValidUrlIgnoreEmpty = (urlString: string): boolean => {
    if (!urlString) {
        return true;
    }
    try {
        return Boolean(new URL(urlString));
    } catch (e) {
        return false;
    }
};

export function textEllipsis(str, maxLength) {
    const ellipsis = '...';
    if (str.length > maxLength) {
        return str.slice(0, maxLength - ellipsis.length) + ellipsis;
    }
    return str;
}

export function padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
}

export function convertMsToTime(milliseconds: number) {
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    minutes = minutes % 60;
    hours = hours % 24;

    return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
}

export function isObject(input) {
    // IE8 will treat undefined and null as object if it wasn't for
    // input != null
    return input != null && Object.prototype.toString.call(input) === '[object Object]';
}

export function isDefaultName(name: string) {
    const re = /^default$/gi;
    return re.test(name);
}

/**
 * Shallow compare all items in object.
 * @param ignoreProperties array of property names to not include in comparison
 * @returns true if it's the same
 */
export function compareObjects(objectA, objectB, ignoreProperties: string[] = []) {
    const aProperties = Object.getOwnPropertyNames(objectA).filter((prop) => !ignoreProperties.includes(prop));
    const bProperties = Object.getOwnPropertyNames(objectB).filter((prop) => !ignoreProperties.includes(prop));

    if (aProperties.length !== bProperties.length) {
        return false;
    }

    for (let i = 0; i < aProperties.length; i++) {
        const propName = aProperties[i];

        if (objectA[propName] !== objectB[propName]) {
            /**
             * NOTE: This extra check allows an object that's null to test unedited against an empty string ''.
             * Because API sends e.g. `Comment: null` even after you set `Comment: ''`.
             * Without it, any unedited comment might be marked as edited.
             */
            if (!(!objectA[propName] && !objectB[propName])) {
                if (isEdited(objectA[propName], objectB[propName])) {
                    return false;
                }
            }
        }
    }

    return true;
}

export function getMaxTableHeight(rowCount: number, maxHeight: number, maxRowHeight: number) {
    const totalRowsHeight = maxRowHeight * (rowCount + 1);
    return totalRowsHeight > maxHeight ? maxHeight : totalRowsHeight;
}

export function getTableRowCss(selected: boolean, isEvenRow: boolean, theme: Theme): CSSProperties {
    return {
        background: selected
            ? alpha(theme.palette.primary.main, 0.3)
            : isEvenRow
              ? alpha(theme.palette.primary.main, 0.06)
              : 'unset',
        borderBottom: `0.2px solid ${alpha(theme.palette.primary.main, 0.3)}`
    };
}

/**
 * Notificaiton for alerting users that billing tab is still in development
 * @param addNotification
 */
export function handleOpenBillingTab(
    serviceId: string,
    navigate: NavigateFunction,
    addNotification: Void<Notification>,
    isLink = false
) {
    const billingRoute = SAMCloudRoutes.find((item) => item.path === 'billing');

    if (billingRoute) {
        const link = createStationRouteUrl(
            billingRoute,
            '',
            serviceId
                ? [
                      {
                          name: 'svc',
                          value: serviceId
                      }
                  ]
                : undefined
        );
        if (isLink) {
            return link;
        }
        navigate(link);
    } else {
        addNotification(
            new Notification({
                message: 'Could not navigate to Library Requests filter. Contact your administrator for assistance.',
                severity: 'error'
            })
        );
    }
}

export function getQueryParamServiceId(urlQuery: URLSearchParams) {
    const searchParam: UrlSearchParam = 'svc';
    return urlQuery.get(searchParam)?.toString();
}

export function getServiceId(stationData: StationData) {
    return stationData.manageStationData?.serviceId?.toString() ?? '0';
}

export function getListenUrl(siteUrl: string, stationId: string) {
    return siteUrl && stationId ? `${siteUrl}/${stationId}` : '';
}

export function toTitleCase(str: string) {
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}

export function removeExtraSpacesAndLineBreaks(str: string) {
    return str.replace(/\s\s+/g, ' ');
}

export function isNumeric(str: string) {
    return /^-?\d+$/.test(str);
}

export async function detailsFormData(image: File | string, optionalData: SelectItem<string, string>[]): Promise<FormData> {
    const formData = new FormData();

    if (!image) {
        return formData;
    } else if (typeof image === 'string' && isValidUrl(image)) {
        const blob = await convertUrltoBlob(image);
        if (blob) {
            formData.append('file', blob);
        }
    } else {
        image = image as File;
        formData.append('file', image, image.name);
    }
    for (let i = 0; i < optionalData.length; i++) {
        const { id, value } = optionalData[i];
        formData.append(id, value);
    }

    return formData;
}

export async function convertUrltoBlob(url: string, addNotification?: Void<Notification>) {
    try {
        const blobResponse = await fetch(url);
        return await blobResponse.blob();
    } catch (e) {
        let errorMessage = `Error processing ${url}.`;
        if (addNotification) {
            addNotification(
                new Notification({
                    message: errorMessage,
                    severity: 'error'
                })
            );
        }
        if (typeof e === 'string') {
            errorMessage = e;
        } else if (e instanceof Error) {
            errorMessage = e.message;
        }
        ConsoleLogError(errorMessage);
    }
}

export function getTimeZone() {
    return moment.tz.guess();
}

export function isEdited<T>(item1: T, item2: T) {
    return !deepEqual(item1, item2);
}

export function resolvePicture(showImage: string, pictureStorageBase?: string) {
    return pictureStorageBase?.replace(pictureStoragePlaceholderKey, showImage) ?? '';
}

export function resolveMediaItemPicture(coverArtBase: string, mediaItemId: string) {
    return coverArtBase.replace(pictureStoragePlaceholderKey, `${mediaItemId}_144x144.jpg`);
}

/**
 * Sometimes you just need a MediaItem.
 */
export function getMediaItem<T>(item: T): MediaItemDto {
    const mediaItem = item as MediaItemDto;
    if (!mediaItem) {
        ConsoleLogError('Invalid: No object to get media item');
        return {} as MediaItemDto;
    }
    if (mediaItem.MediaItemId) {
        return mediaItem;
    }
    const playlistItem = item as PlaylistItemDto;
    if (playlistItem.PlaylistCategoryItemId && playlistItem.MediaItem) {
        return playlistItem.MediaItem;
    }
    const queueItem = item as QueueItemDto;
    if (queueItem.QueueItemId && queueItem.MediaItem) {
        return queueItem.MediaItem;
    }
    return mediaItem;
}

/**
 * Look at Old SAM Cloud FeatureFlagHelper.isFeatureFlagEnabled.
 * Usage: isFeatureFlagEnabled('RadioGPTFeature', stationData.manageStationData);
 */
export function isFeatureFlagEnabled(key: FeatureFlag, manageStationData?: ManageStationDto) {
    if (manageStationData && manageStationData.featureFlagsEnabled && manageStationData.featureFlagsEnabled.length > 0) {
        const flag = manageStationData.featureFlagsEnabled.find((item) => item.Key === key);
        if (flag?.Value === true) {
            return true;
        }
    }
    return false;
}

export function getRandomNumber(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function hasSkipTrackPermission(stationData: StationData | undefined) {
    if (stationData && stationData.manageStationData?.security) {
        return stationData.manageStationData.security.hasPermissions(ResourcePermissions.skipTrack);
    }
    return false;
}

export function convertToNumber(value: number | string): number {
    let numberValue = +value;

    if (isNaN(numberValue)) {
        numberValue = Number(value);
    }

    return isNaN(numberValue) ? 0 : numberValue;
}

/**
 * @param text Initial String
 * @param insertText Text to insert
 * @param index Index where it should be inserted to
 */
export function insertStringAtIndex(text: string, insertText: string, index: number): string {
    if (index < 0) {
        index = 0;
    } else if (index > text.length) {
        index = text.length;
    }

    return text.slice(0, index) + insertText + text.slice(index);
}

/**
 *
 * @returns Read token
 */
export function getReadToken(stationInfo?: SamVibeStationDto) {
    let tokenString: string | undefined;

    if (stationInfo?.ReadToken) {
        if (typeof stationInfo.ReadToken === 'string') {
            tokenString = stationInfo.ReadToken;
        } else if ((stationInfo.ReadToken as { TokenString: string }).TokenString) {
            tokenString = (stationInfo.ReadToken as { TokenString: string }).TokenString;
        }
    }

    return tokenString;
}

/**
 * Round to 2 digits.
 */
export function bytesToGB(bytes: number) {
    return (bytes / (1024 * 1024 * 1024)).toFixed(2);
}

export function formatListenerStatCount(count: number): string {
    if (count >= 1000) {
        return (count / 1000).toFixed(1) + 'K';
    }
    return count.toString();
}

export function shldHideRouteFromTritonUser(route: IRoute, useTritonSso: boolean, showBillingMenuItem?: boolean) {
    if (!useTritonSso) {
        return false;
    }

    if (route.path === 'billing' && showBillingMenuItem === false) {
        return false;
    }

    //Hide Billing and Streaming from triton users
    return route.path === 'billing' || route.path === 'streaming';
}
