import React, { FC, useState } from 'react';
import {
    Collapse,
    Divider,
    ExpandLess,
    ExpandMore,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ReadMoreIcon,
    useTheme
} from './mui';
import { MoreLinksProps } from '../models/props';

const listItemButtonSx = { pt: 0.7, pb: 0.7, mb: 1 };

const MoreLinks: FC<MoreLinksProps> = ({ items }) => {
    const theme = useTheme();
    const [openList, setOpenList] = useState(false);

    const handleClick = () => {
        setOpenList((prevState) => !prevState);
    };

    return (
        <>
            <ListItemButton
                onClick={handleClick}
                sx={{
                    ...listItemButtonSx,
                    backgroundColor: openList ? theme.palette.action.selected : 'inherit'
                }}
                selected={openList}
            >
                <ListItemIcon>
                    <ReadMoreIcon />
                </ListItemIcon>
                <ListItemText primary="More" secondary="View more options" />
                {openList ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openList} timeout="auto" unmountOnExit>
                <Divider />
                <List component="div" sx={listItemButtonSx}>
                    {items.map((item) => (
                        <ListItemButton onClick={item.onItemClick} key={item.id} sx={{ pl: 4 }}>
                            {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                            <ListItemText primary={item.label} />
                        </ListItemButton>
                    ))}
                </List>
            </Collapse>
        </>
    );
};

export default MoreLinks;
