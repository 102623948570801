import getDeepClonedObject from '../../../../utils/deep-clone';
import { ConsoleLog, ConsoleLogError } from '../../../../utils/log';
import {
    FadeDirection,
    FadeType,
    IAnalysis,
    IAnalysisLevel,
    IAudioSettings,
    ILevel,
    IPlotData,
    ISeries,
    MediaItemAudioSetting
} from './models/interfaces';

const analysisLevel60 = -60;
const analysisLevel55 = -55;
const analysisLevelDurationMin = 0;
const fakeAnalysisDuration = 300000;
const analysisLevelDuration999 = 999;
const analysisLevelDuration1000 = 1000;
const fakeAnalysisGain = {
    REPLAYGAIN_REFERENCE_LOUDNESS: '-18.00',
    REPLAYGAIN_TRACK_GAIN: '0',
    REPLAYGAIN_ALGORITHM: 'ITU-R BS.1770',
    REPLAYGAIN_ALBUM_GAIN: '0'
};

function updateTrackSettings(
    updatedTrackSettings: MediaItemAudioSetting,
    globalSettings: IAudioSettings,
    trackSettings: MediaItemAudioSetting
) {
    updatedTrackSettings.Gain = trackSettings.Gain;
    updatedTrackSettings.TrimSilence = !trackSettings.TrimSilence ? globalSettings.TrimSilence : trackSettings.TrimSilence;
    updatedTrackSettings.XFade = !trackSettings.XFade ? globalSettings.Xfade : trackSettings.XFade;
    updatedTrackSettings.FadeInType = trackSettings.FadeInType === 5 ? globalSettings.FadeIn.FadeType : trackSettings.FadeInType;
    updatedTrackSettings.FadeInDuration =
        trackSettings.FadeInType == 5 || !trackSettings.FadeInDuration
            ? globalSettings.FadeIn.Duration
            : trackSettings.FadeInDuration;
    updatedTrackSettings.FadeOutType =
        trackSettings.FadeOutType == 5 ? globalSettings.FadeOut.FadeType : trackSettings.FadeOutType;
    updatedTrackSettings.FadeOutDuration =
        trackSettings.FadeOutType == 5 || !trackSettings.FadeOutDuration
            ? globalSettings.FadeOut.Duration
            : trackSettings.FadeOutDuration;
    updatedTrackSettings.FadeInDuration = trackSettings.FadeInType == 0 ? 0 : updatedTrackSettings.FadeInDuration;
    updatedTrackSettings.FadeOutDuration = trackSettings.FadeOutType == 0 ? 0 : updatedTrackSettings.FadeOutDuration;
}

function updateCueStart(
    updatedTrackSettings: MediaItemAudioSetting,
    globalSettings: IAudioSettings,
    trackSettings: MediaItemAudioSetting,
    updatedMergedAnalyisLevels: IAnalysisLevel[]
) {
    if (!trackSettings.CueStart) {
        if (updatedTrackSettings.TrimSilence == false) {
            updatedTrackSettings.CueStart = 0;
        } else {
            //Detect silence at start level (Set to zero if no point detected - rare edge case)
            updatedTrackSettings.CueStart =
                getTimestampFromDbLevel(
                    updatedMergedAnalyisLevels,
                    !trackSettings.LevelStart ? globalSettings.LevelStart : trackSettings.LevelStart,
                    FadeDirection.IN
                ) || 0;
        }
    } else {
        updatedTrackSettings.CueStart = trackSettings.CueStart;
    }
    updatedTrackSettings.CueStart = Math.max(0, updatedTrackSettings.CueStart);
}

function updateCueEnd(
    updatedTrackSettings: MediaItemAudioSetting,
    globalSettings: IAudioSettings,
    trackSettings: MediaItemAudioSetting,
    updatedMergedAnalyisLevels: IAnalysisLevel[],
    trackAnalysis: IAnalysis
) {
    //Detect silence END point if not specifically set
    if (!trackSettings.CueEnd) {
        if (updatedTrackSettings.TrimSilence == false) {
            updatedTrackSettings.CueEnd = trackAnalysis.duration;
        } else {
            //Detect silence at end level (Use duration if no point detected - rare edge case)
            updatedTrackSettings.CueEnd =
                getTimestampFromDbLevel(
                    updatedMergedAnalyisLevels,
                    !trackSettings.LevelEnd ? globalSettings.LevelEnd : trackSettings.LevelEnd,
                    FadeDirection.OUT
                ) || trackAnalysis.duration;
        }
    } else {
        updatedTrackSettings.CueEnd = trackSettings.CueEnd;
    }
    if (trackAnalysis) updatedTrackSettings.CueEnd = Math.min(trackAnalysis.duration, updatedTrackSettings.CueEnd);
}

function updateCueCrossStartEnd(
    updatedTrackSettings: MediaItemAudioSetting,
    globalSettings: IAudioSettings,
    trackSettings: MediaItemAudioSetting,
    updatedMergedAnalyisLevels: IAnalysisLevel[]
) {
    //Calculate XFade points (Note: These settings are not officially part of TrackSettings object spec. We are just adding our custom fields for convenience)
    updatedTrackSettings.CueCrossAtEnd =
        getTimestampFromDbLevel(
            updatedMergedAnalyisLevels,
            !trackSettings.LevelXfade ? globalSettings.LevelXfade : trackSettings.LevelXfade,
            FadeDirection.OUT
        ) || (updatedTrackSettings?.CueEnd ?? 0) - (!trackSettings.MaxCross ? globalSettings.MaxCross : trackSettings.MaxCross);
    updatedTrackSettings.CueCrossAtStart =
        getTimestampFromDbLevel(
            updatedMergedAnalyisLevels,
            !trackSettings.LevelXfade ? globalSettings.LevelXfade : trackSettings.LevelXfade,
            FadeDirection.IN
        ) || (!trackSettings.MaxCross ? globalSettings.MaxCross : trackSettings.MaxCross);

    //Edge case: What if XFade level is lower than silence trimming? In case Cross points might be outside Start/End cues.
    updatedTrackSettings.CueCrossAtStart = Math.max(
        updatedTrackSettings?.CueStart ?? 0,
        updatedTrackSettings.CueCrossAtStart ?? 0
    );
    updatedTrackSettings.CueCrossAtEnd = Math.min(updatedTrackSettings?.CueEnd ?? 0, updatedTrackSettings.CueCrossAtEnd);
    //We also do not allow Xfade points to move beyond 50% boundary. can really only happen for very short tracks
    const middle =
        (updatedTrackSettings.CueStart as number) +
        ((updatedTrackSettings.CueEnd as number) - (updatedTrackSettings.CueStart as number)) / 2;

    updatedTrackSettings.CueCrossAtStart = Math.min(middle, updatedTrackSettings.CueCrossAtStart);
    updatedTrackSettings.CueCrossAtEnd = Math.max(middle, updatedTrackSettings.CueCrossAtEnd);
}

function updateGlobalSettings(globalSettings: IAudioSettings, trackAnalysis: IAnalysis) {
    const skipFadeOrXFade = trackAnalysis.duration <= globalSettings.XfadeSkipShortDuration;
    if (skipFadeOrXFade) {
        globalSettings.Xfade = false;
        globalSettings.FadeIn.FadeType = FadeType.None;
        globalSettings.FadeOut.FadeType = FadeType.None;
    }
}

function generateFulldBAnalysisLevels(duration: number, fadeDirection: FadeDirection, fullDB: number) {
    if (fadeDirection === FadeDirection.IN) {
        return [
            { key: analysisLevel60, value: analysisLevelDurationMin },
            { key: analysisLevel55, value: analysisLevelDuration999 },
            { key: fullDB, value: analysisLevelDuration1000 }
        ];
    } else {
        return [
            { key: analysisLevel60, value: duration },
            { key: analysisLevel55, value: duration - analysisLevelDuration999 },
            { key: fullDB, value: duration - analysisLevelDuration1000 }
        ];
    }
}

/**
 *
 *  Combine levelsStart and LevelsEnd into one analysis file.
 *  Also fill missing gaps:
 *  Fill in dB value for zero timestamp and duration timestamp (assume -100dB)
 *  Assume fulldB levels for part of track not analysed (unless its a short track and values overlap)
 *  Sort by timestamp
 *  Note: The merge could create duplicates - i.e. two dB values for SAME timestamp. is not a big deal - just use one or the other, or biggest dB value.
 */
function mergeAnalysisLevels(levelsStart: ILevel[], levelsEnd: ILevel[], fulldB: number, duration: number): IAnalysisLevel[] {
    let mergedAnalyisLevels: IAnalysisLevel[] = [];
    let minTimeStampStart = duration + 1;
    let maxTimeStampStart = 0;
    let minTimeStampEnd = duration + 1;
    let maxTimeStampEnd = 0;

    for (const level of levelsStart) {
        const currentTimeStamp = level.value;
        const dBLevel = level.key;
        minTimeStampStart = Math.min(minTimeStampStart, currentTimeStamp);
        maxTimeStampStart = Math.max(maxTimeStampStart, currentTimeStamp);
        mergedAnalyisLevels.push({ dB: dBLevel, timeStamp: currentTimeStamp });
    }

    for (const level of levelsEnd) {
        const currentTimeStamp = level.value;
        const dBLevel = level.key;
        minTimeStampEnd = Math.min(minTimeStampEnd, currentTimeStamp);
        maxTimeStampEnd = Math.max(maxTimeStampEnd, currentTimeStamp);
        mergedAnalyisLevels.push({ dB: dBLevel, timeStamp: currentTimeStamp });
    }

    //Edgecase1: Push value for start (zero) timestamp if needed
    if (minTimeStampStart > 0 && minTimeStampEnd > 0) {
        mergedAnalyisLevels.push({ dB: -1000, timeStamp: 0 });
    }

    //Edgecase2: Push value for last (duration) timestamp if needed
    if (maxTimeStampStart < duration && maxTimeStampEnd < duration) {
        mergedAnalyisLevels.push({ dB: -1000, timeStamp: duration });
    }

    //EdgeCase3: Fill is missing gap of track that was not analyses. Assume full dB.
    //Only put dummy maximum dB points if two analysis files do not overlap!
    if (maxTimeStampStart < minTimeStampEnd) {
        mergedAnalyisLevels.push({ dB: fulldB, timeStamp: maxTimeStampStart + 1 });
        mergedAnalyisLevels.push({ dB: fulldB, timeStamp: minTimeStampEnd - 1 });
    }

    //Sort by timestamp
    mergedAnalyisLevels = sortMergedAnalyisLevels(mergedAnalyisLevels, FadeDirection.IN);
    return mergedAnalyisLevels;
}

function sortMergedAnalyisLevels(mergedAnalyisLevels: IAnalysisLevel[], fadeDirection: FadeDirection) {
    if (fadeDirection == FadeDirection.IN) {
        mergedAnalyisLevels.sort(function (a, b) {
            return a.timeStamp - b.timeStamp;
        });
    } else {
        mergedAnalyisLevels.sort(function (a, b) {
            return b.timeStamp - a.timeStamp;
        });
    }
    return mergedAnalyisLevels;
}

function applyGain(mergedAnalyisLevels: IAnalysisLevel[], gaindB: number) {
    if (gaindB == 0.0) return mergedAnalyisLevels;

    for (const index in mergedAnalyisLevels) {
        mergedAnalyisLevels[index].dB += gaindB;
    }
    return mergedAnalyisLevels;
}

function applyFade(
    mergedAnalyisLevels: IAnalysisLevel[],
    timeStampStart: number,
    fadeType: FadeType,
    fadeDuration: number,
    fadeDirection: FadeDirection,
    duration: number,
    pointFrequency: number
) {
    const updatedMergedAnalyisLevels: IAnalysisLevel[] = [];

    //Important we do not work with fractions here!
    timeStampStart = Math.round(timeStampStart);

    //Copy only the values AFTER the fade-in, or BEFORE fade-out.
    //Note: will also get rid of points that had to be silence trimmed!
    //If fade is not enabled we expect fadeDuration to be zero!
    for (const index in mergedAnalyisLevels) {
        const currentTimestamp = mergedAnalyisLevels[index].timeStamp;
        const mustCopy =
            fadeDirection == FadeDirection.IN
                ? currentTimestamp > timeStampStart + fadeDuration
                : currentTimestamp < timeStampStart;
        if (mustCopy) {
            updatedMergedAnalyisLevels.push(mergedAnalyisLevels[index]);
        }
    }

    mergedAnalyisLevels = sortMergedAnalyisLevels(mergedAnalyisLevels, FadeDirection.IN);

    //Push fade-profile
    for (let i = 0; i < fadeDuration + pointFrequency; i += pointFrequency) {
        const position = Math.min(i, fadeDuration); //We sometimes will overrun - but we want to generate that last point!
        const currentTimeStamp = timeStampStart + position;
        if (currentTimeStamp <= duration) {
            const percentateLevel = getPercentageLevelAtTimestamp(mergedAnalyisLevels, currentTimeStamp); //Always use original merged analysis so pushed values does not interfere
            const fadePositionFraction =
                fadeDirection == FadeDirection.IN ? position / fadeDuration : 1 - position / fadeDuration;
            const fadeFraction = calculateFadeFraction(fadeType, fadePositionFraction);
            const fadedLevel = percentateLevel * fadeFraction;
            updatedMergedAnalyisLevels.push({ dB: getDbLevelFromPercentage(fadedLevel), timeStamp: currentTimeStamp });
        }
    }
    return updatedMergedAnalyisLevels;
}

function calculateFadeFraction(fadeType: FadeType, fadePositionFraction: number) {
    switch (fadeType) {
        case FadeType.None:
            return 1;
        case FadeType.Linear:
            return fadePositionFraction;
        case FadeType.Sinusoidal:
            return (1 + Math.sin((fadePositionFraction - 0.5) * Math.PI)) / 2;
        case FadeType.Logarithmic:
            return Math.log(1 + fadePositionFraction * 10) / Math.log(11);
        case FadeType.Exponential:
            return (Math.exp(2 * fadePositionFraction) - 1) / (Math.exp(2) - 1);
        default:
            return 0;
    }
}

function calculateAnalysis(globalSettings: IAudioSettings, trackSettings: MediaItemAudioSetting, trackAnalysis: IAnalysis) {
    const updatedTrackSettings: MediaItemAudioSetting = {
        MediaItemId: '',
        Gain: 0,
        FadeInType: FadeType.None,
        FadeOutType: FadeType.None,
        CueXFade: undefined
    };
    let updatedMergedAnalyisLevels: IAnalysisLevel[] = [];
    const fullDb = -10;
    const pointFrequency = 50; //ms

    updateGlobalSettings(globalSettings, trackAnalysis);
    updateTrackSettings(updatedTrackSettings, globalSettings, trackSettings);

    updatedMergedAnalyisLevels = mergeAnalysisLevels(
        trackAnalysis.levelsStart,
        trackAnalysis.levelsEnd,
        fullDb,
        trackAnalysis.duration
    );

    updatedMergedAnalyisLevels = applyGain(updatedMergedAnalyisLevels, updatedTrackSettings.Gain);
    updateCueStart(updatedTrackSettings, globalSettings, trackSettings, updatedMergedAnalyisLevels);
    updateCueEnd(updatedTrackSettings, globalSettings, trackSettings, updatedMergedAnalyisLevels, trackAnalysis);

    updatedMergedAnalyisLevels = applyFade(
        updatedMergedAnalyisLevels,
        updatedTrackSettings.CueStart as number,
        updatedTrackSettings.FadeInType,
        updatedTrackSettings.FadeInDuration as number,
        FadeDirection.IN,
        trackAnalysis.duration,
        pointFrequency
    );

    const cueFadeOutStart = Math.max(
        0,
        (updatedTrackSettings.CueEnd as number) - (updatedTrackSettings.FadeOutDuration as number)
    );

    updatedMergedAnalyisLevels = applyFade(
        updatedMergedAnalyisLevels,
        cueFadeOutStart,
        updatedTrackSettings.FadeOutType,
        updatedTrackSettings.FadeOutDuration as number,
        FadeDirection.OUT,
        trackAnalysis.duration,
        pointFrequency
    );

    updateCueCrossStartEnd(updatedTrackSettings, globalSettings, trackSettings, updatedMergedAnalyisLevels);

    return { trackSettings: updatedTrackSettings, mergedAnalyisLevels: updatedMergedAnalyisLevels };
}

/**
 * The point returned is the timestamp on the currently playing track where the next track should start playing so they both cross at the right dB level.
 * IMPORTANT: timestamp assumes that the NEXT track will play immediately at CueStart (i.e. silence trimming applied)
 * MORE IMPORTANT: However, silence trimming NOT applied to CURRENT track - the timestamp provided is the actual timestamp without any adjustments for parts of track that will not play.
 */
function findCrossTimestamp(trackSettingsOut: MediaItemAudioSetting, trackSettingsIn: MediaItemAudioSetting, maxCross: number) {
    let cueXFade = trackSettingsOut.CueEnd;
    if (trackSettingsOut.XFade && trackSettingsIn.XFade) {
        if (trackSettingsOut.CueXFade) {
            cueXFade = trackSettingsOut.CueXFade;
        } else {
            let crossDuration =
                (trackSettingsOut.CueEnd as number) -
                (trackSettingsOut.CueCrossAtEnd as number) +
                ((trackSettingsIn.CueCrossAtStart as number) - (trackSettingsIn.CueStart as number));
            crossDuration = Math.min(maxCross, crossDuration);

            //Point where track should start playing (silence must not play!)
            cueXFade = Math.max(0, (trackSettingsOut.CueEnd as number) - crossDuration);
        }
    }
    return cueXFade;
}

function getPercentageLevelAtTimestamp(mergedAnalyisLevels: IAnalysisLevel[], targetTimeStamp: number) {
    let audioPointBefore: IAnalysisLevel | undefined;
    let audioPointAfter: IAnalysisLevel | undefined;
    const sanityTimeStamp = 0;

    for (const index in mergedAnalyisLevels) {
        const currentAudioPoint = mergedAnalyisLevels[index];

        if (sanityTimeStamp > currentAudioPoint.timeStamp) {
            ConsoleLogError('Analysis levels not sorted as expected. Contact admin if problem persists');
        }

        if (currentAudioPoint.timeStamp == targetTimeStamp) {
            return getPercentageFromDbLevel(currentAudioPoint.dB);
        } else {
            if (
                currentAudioPoint.timeStamp < targetTimeStamp &&
                (!audioPointBefore || audioPointBefore.timeStamp < currentAudioPoint.timeStamp)
            ) {
                audioPointBefore = currentAudioPoint;
            }
            if (
                currentAudioPoint.timeStamp > targetTimeStamp &&
                (!audioPointAfter || audioPointAfter.timeStamp > currentAudioPoint.timeStamp)
            ) {
                audioPointAfter = currentAudioPoint;
                break; //IMPORTANT: We assume mergedAnalyisLevels is sorted by timeStamp in ASC order!
            }
        }
    }

    //Happens when timestamp<0 or timestamp>duration, or when timestamp is within range of points trimmed. (applyFade trims the points)
    //Its safe to assume the percentage level at timestamp is zero (or silence)
    if (!audioPointBefore || !audioPointAfter) {
        ConsoleLog('No audio points found within range.');
        return 0;
    }

    const percentageBefore = getPercentageFromDbLevel(audioPointBefore.dB);
    const percentageAfter = getPercentageFromDbLevel(audioPointAfter.dB);
    if (percentageBefore === percentageAfter) {
        return percentageBefore;
    } else {
        return (
            percentageBefore +
            (percentageAfter - percentageBefore) *
                ((targetTimeStamp - audioPointBefore.timeStamp) / (audioPointAfter.timeStamp - audioPointBefore.timeStamp))
        );
    }
}

function getTimestampFromDbLevel(mergedAnalyisLevels: IAnalysisLevel[], targetDbLevel: number, fadeDirection: FadeDirection) {
    mergedAnalyisLevels = sortMergedAnalyisLevels(mergedAnalyisLevels, fadeDirection);
    let audioPointBefore: IAnalysisLevel | undefined;
    let audioPointAfter: IAnalysisLevel | undefined;
    for (const index in mergedAnalyisLevels) {
        const currentAudioPoint = mergedAnalyisLevels[index];
        if (currentAudioPoint.dB == targetDbLevel) {
            return currentAudioPoint.timeStamp;
        } else {
            if (currentAudioPoint.dB < targetDbLevel && (!audioPointBefore || audioPointBefore.dB < currentAudioPoint.dB)) {
                audioPointBefore = currentAudioPoint;
            }
            if (currentAudioPoint.dB > targetDbLevel && (!audioPointAfter || audioPointAfter.dB > currentAudioPoint.dB)) {
                audioPointAfter = currentAudioPoint;
                break;
            }
        }
    }

    //Can only really happen if targetDbLevel<-100 or targetDbLevel>maxDbLevelInAnalysis
    if (!audioPointBefore || !audioPointAfter) {
        ConsoleLog('No audio points found within range.');
        return;
    }

    if (audioPointBefore.timeStamp === audioPointAfter.timeStamp) {
        return audioPointBefore.timeStamp;
    } else {
        const perBefore = getPercentageFromDbLevel(audioPointBefore.dB);
        const perAfter = getPercentageFromDbLevel(audioPointAfter.dB);
        return (
            audioPointBefore.timeStamp +
            (audioPointAfter.timeStamp - audioPointBefore.timeStamp) *
                ((getPercentageFromDbLevel(targetDbLevel) - perBefore) / (perAfter - perBefore))
        );
    }
}

export function generateFakeTrackSettings(): MediaItemAudioSetting {
    return {
        MediaItemId: '2b9396ad-53f9-47b8-8b8b-b078d580ea2f',
        Gain: 0.0,
        FadeInType: 5,
        FadeOutType: 5
    };
}

export function getPercentageFromDbLevel(dB: number) {
    return Math.pow(10, dB / 20) * 100;
}

export function getDbLevelFromPercentage(perc: number) {
    if (perc == 0) perc = 0.000001; //Sanity check
    return 20 * (Math.log(perc / 100) / Math.log(10));
}

export function generateFakeAnalysis(fullDB: number): IAnalysis {
    const analysis = {
        duration: fakeAnalysisDuration,
        gain: fakeAnalysisGain,
        levelsStart: generateFulldBAnalysisLevels(fakeAnalysisDuration, FadeDirection.IN, fullDB),
        levelsEnd: generateFulldBAnalysisLevels(fakeAnalysisDuration, FadeDirection.OUT, fullDB)
    };
    return analysis;
}

export function calculatePlotSeries(
    globalSettings: IAudioSettings,
    trackSettingsOut: MediaItemAudioSetting,
    trackSettingsIn: MediaItemAudioSetting,
    trackAnalysisOut: IAnalysis,
    trackAnalysisIn: IAnalysis
): IPlotData {
    const seriesIn: ISeries[] = [];
    const seriesOut: ISeries[] = [];
    let dBHigh = -60;
    /**
     * Make copy so we can modify it safely
     * Convert sec to ms for all props
     */
    const globalSettingsCopy: IAudioSettings = getDeepClonedObject(globalSettings);
    const analysisOut = calculateAnalysis(globalSettingsCopy, trackSettingsOut, trackAnalysisOut);
    const analysisIn = calculateAnalysis(globalSettingsCopy, trackSettingsIn, trackAnalysisIn);
    const crossPlayPoint = findCrossTimestamp(
        analysisOut.trackSettings,
        analysisIn.trackSettings,
        !trackSettingsOut.MaxCross ? globalSettingsCopy.MaxCross : trackSettingsOut.MaxCross
    );

    /**
     * Now we need to calculate a series on graph
     * Draw the CrossFade close to middle of graph
     * Both fades should be fully visible
     * Include minimum of 1 second of audio after fade-in or after fade-out.
     */
    const crossDuration = (analysisOut.trackSettings.CueEnd as number) - (crossPlayPoint as number); //Total time both tracks will be playing (EXCLUDING trimmed silence!)
    const biggestPart =
        Math.max(
            crossDuration,
            analysisOut.trackSettings.FadeOutDuration as number,
            analysisOut.trackSettings.FadeInDuration as number
        ) + 1000;
    const trackOutAdjustment = (analysisOut.trackSettings.CueEnd as number) - biggestPart - crossDuration / 2;
    const trackInAdjustment = (crossPlayPoint as number) - trackOutAdjustment - (analysisIn.trackSettings.CueStart as number);

    /**
     * BiggestPart is now effectively the center of the graph we want to draw.
     * So now we have to "move" our plots so they are relative to          */
    for (const index in analysisOut.mergedAnalyisLevels) {
        const currentAudioPoint = analysisOut.mergedAnalyisLevels[index];
        const newTimeStamp = currentAudioPoint.timeStamp - trackOutAdjustment;
        dBHigh = Math.max(dBHigh, currentAudioPoint.dB);
        seriesOut.push({ x: newTimeStamp, y: getPercentageFromDbLevel(currentAudioPoint.dB) });
    }

    for (const index in analysisIn.mergedAnalyisLevels) {
        const currentAudioPoint = analysisIn.mergedAnalyisLevels[index];
        const newTimeStamp = currentAudioPoint.timeStamp + trackInAdjustment;
        dBHigh = Math.max(dBHigh, currentAudioPoint.dB);
        seriesIn.push({ x: newTimeStamp, y: getPercentageFromDbLevel(currentAudioPoint.dB) });
    }

    return {
        totalDuration: biggestPart * 2,
        dBHigh: dBHigh,
        seriesOut: seriesOut,
        seriesIn: seriesIn
    };
}
