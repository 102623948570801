import moment from 'moment';
import { SelectItem } from '../../models/interfaces';
import { AnalyticsInterval, PeriodType, TimezoneType, allMonths, defaultAnalyticsInterval } from './consts';

export function getAnalyticsIntervalSelection(): AnalyticsInterval[] {
    const menuItemList = [defaultAnalyticsInterval, { amount: 30, id: '30-day', interval: 'days', label: '30 day' }];

    const momentDate = moment(new Date());

    const interval = 'months';
    for (let i = 0; i < 12; i++) {
        momentDate.subtract(1, 'months');
        const amount = i + 1;
        const id = `${amount}-${interval}`;
        menuItemList.push({ amount, id, interval, label: momentDate.format('MMM YYYY') });
    }
    return menuItemList as AnalyticsInterval[];
}

/**
 * Used from Dojo's SAM Cloud.
 */
export function calculateTrend(count: number, oldCount: number): number {
    if (count == 0 || oldCount == 0) {
        return 100;
    }
    const trend = 100 - (count > oldCount ? (oldCount / count) * 100 : (count / oldCount) * 100);
    return Math.floor(trend);
}

export function formatDateForInterval(date, analyticsInterval: AnalyticsInterval) {
    const momentLocalDate = moment.utc(date).local();
    if (!momentLocalDate.isValid) {
        return '';
    }
    switch (analyticsInterval.interval) {
        case 'hours': {
            return 'Time: ' + momentLocalDate.format(momentLocalDate.date() == moment().date() ? 'HH:mm' : 'MMM DD, HH:mm');
        }
        case 'days':
        case 'months': {
            return 'Date: ' + momentLocalDate.format('MMM DD');
        }
    }
}

export function buildPeriodStoreMinutes(): PeriodType[] {
    const today = new Date();
    const minuteValues: PeriodType[] = [];
    for (let i = 0; i < 7; i++) {
        const description = today.getDate() + ' ' + allMonths[today.getMonth()] + ' ' + today.getFullYear();
        minuteValues.push({ index: i, description: description, date: new Date(today) });
        today.setDate(today.getDate() - 1);
    }
    return minuteValues;
}

export function buildPeriodStoreHourly() {
    const today = new Date();
    const hourlyValues: PeriodType[] = [];
    for (let i = 0; i < 12; i++) {
        const description = allMonths[today.getMonth()] + ' ' + today.getFullYear();
        hourlyValues.push({ index: i, description: description, date: new Date(today) });
        today.setMonth(today.getMonth() - 1);
    }
    return hourlyValues;
}

export function buildPeriodStoreDaily() {
    const today = new Date();
    const dailyValues: PeriodType[] = [];
    for (let i = 0; i < 5; i++) {
        const description = today.getFullYear() + '';
        dailyValues.push({ index: i, description: description, date: new Date(today) });
        today.setFullYear(today.getFullYear() - 1);
    }
    return dailyValues;
}

export function getPeriodStore(interval: SelectItem<string, string>) {
    switch (interval.id) {
        case '0':
            return buildPeriodStoreMinutes();
        case '1':
            return buildPeriodStoreHourly();
        case '2':
        default:
            return buildPeriodStoreDaily();
    }
}

export function getTimezoneStore(): TimezoneType[] {
    const zoneValues: TimezoneType[] = [];
    let zone = -11;
    for (let i = 0; i < 23; i++) {
        const description = `UTC${zone == 0 ? '' : zone > 0 ? '+' + zone : zone}`;
        zoneValues.push({ index: i.toString(), description: description, offset: zone });
        zone++;
    }

    return zoneValues;
}
