import React from 'react';
import { zIndex1, zIndex5 } from '../models/consts';
import { darkShadowColor, lightShadowColor } from '../utils/colors';
import { getBorderRadiusFromAnchor, getGlobalScrollStyle, getMaxBorderRadius } from '../utils/style';
import { customizeDrawerWidth } from '../utils/themes';
import {
    AccordionProps,
    AccordionSummaryProps,
    ArrowForwardIosSharpIcon,
    Badge,
    BadgeProps,
    Box,
    Button,
    Drawer,
    MuiAccordion,
    MuiAccordionDetails,
    MuiAccordionSummary,
    RadioButtonCheckedIcon,
    Stack,
    Theme,
    Typography,
    alpha,
    lighten,
    styled
} from './mui';

export const BaseContainer = styled(Box)(({ theme }) => {
    return {
        padding: theme.spacing(0),
        margin: 0
    };
});

export const RowContainer = styled(BaseContainer)`
    display: flex;
    flex-direction: row;
`;

export const ColContainer = styled(BaseContainer)`
    display: flex;
    flex-direction: column;
`;

export const MiddleContainer = styled(ColContainer)`
    justify-content: center;
`;

export const FullWidthStack = styled(Stack)`
    flex: 1;
`;

export const Heading = styled(Typography)(({ theme }) => {
    return {
        ...theme.typography.h4,
        padding: theme.spacing(1)
    };
});

export const SubHeading = styled(Heading)(({ theme }) => {
    return {
        ...theme.typography.h6,
        padding: `0px ${theme.spacing(2)}`
    };
});

export const CenteredSubHeading = styled(SubHeading)`
    text-align: center;
`;

export const H6 = styled(Heading)(({ theme }) => {
    return {
        ...theme.typography.h6,
        padding: 0
    };
});

export const Btn = styled(Button)`
    font-weight: bold;
    text-transform: capitalize;
    padding-top: ${({ size }) => (size === 'large' ? '14px' : size === 'small' ? '6px' : '10px')};
    padding-bottom: ${({ size }) => (size === 'large' ? '14px' : size === 'small' ? '5px' : '10px')};
`;

export const HorizontalList = styled(Stack)`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    justify-self: center;
    align-items: center;
`;

export const StyledDrawer = styled(Drawer)(({ anchor, theme }) => {
    return {
        zIndex: zIndex1, // zIndex should be the highest out of all of them, by default, a Styled Drawer's zIndex is 1200 (lower than a popup (1300)).
        ['& .MuiPaper-root']: {
            ...getGlobalScrollStyle(theme),
            overflowX: 'hidden',
            minWidth: customizeDrawerWidth
        },
        ['& > .MuiDrawer-paper']: {},
        ...getBorderRadiusFromAnchor('& .MuiDrawer-paper', theme, anchor)
    };
});

export const Container = styled(RowContainer)<{ theme: Theme }>(({ theme }) => {
    return {
        justifyContent: 'space-between',
        background: lighten(theme.palette.background.default, 0.1),
        borderBottom: `1px solid ${theme.palette.divider}`,
        ['& > div']: {
            padding: theme.spacing(0.5)
        }
    };
});

/**
 * Convenient function to allow props as strings.
 * It's just to prevent warnings in the console.
 * @param props Props as strings that can be passed.
 */
export function allowCustomProps(props: string[]) {
    return { shouldForwardProp: (prop) => !props.includes(prop.toString()) };
}

export const Form = styled('form')(({ theme }) => ({
    boxShadow: `-0.5em -0.5em 4.625em ${theme.palette.mode === 'dark' ? darkShadowColor : lightShadowColor}`,
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    margin: 'auto',
    padding: 10,
    textAlign: 'center',
    position: 'relative'
}));

export const InputOuterContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
});

export const InputInnerContainer = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        width: '100%'
    },
    width: '50%',
    padding: 10
}));

export const StyledLoaderIconContainer = styled(Box)({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: zIndex5,
    fontWeight: 'bolder'
});

export const CustomLink = styled('a')(({ theme }) => ({
    textDecoration: 'underline',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    cursor: 'pointer'
}));

const borderOuterAlpha = 0.2;
export const ListContainer = styled(Box)(({ theme }) => {
    return {
        display: 'flex',
        flex: 1,
        overflow: 'hidden', // Since the border radius looks funny.
        flexDirection: 'column',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: alpha(theme.palette.primary.main, borderOuterAlpha),
        borderRadius: getMaxBorderRadius(theme),
        userSelect: 'none'
    };
});

export const TblContainer = styled(Box)(({ theme }) => {
    return {
        '.ReactVirtualized__Table__Grid': getGlobalScrollStyle(theme)
    };
});

export const BorderedContainer = styled(Stack)(({ theme }) => ({
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: alpha(theme.palette.primary.main, 0.3),
    padding: 10,
    borderRadius: theme.shape.borderRadius,
    overflowY: 'auto',
    ...getGlobalScrollStyle(theme)
}));

export const AudioSettingText = styled(
    Typography,
    allowCustomProps(['largeToSmall'])
)<{ largeToSmall: boolean }>(({ largeToSmall }) => ({
    fontSize: largeToSmall ? 13.5 : 15
}));

export const Body2 = styled(Typography)(({ theme }) => {
    return {
        ...theme.typography.body2
    };
});

export const Body1 = styled(Typography)(({ theme }) => {
    return {
        ...theme.typography.body1
    };
});

export const Caption = styled(Typography)(({ theme }) => {
    return {
        ...theme.typography.caption
    };
});

export const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
    ({ theme }) => ({
        border: `1px solid ${theme.palette.divider}`,
        '&:not(:last-child)': {
            borderBottom: 0
        },
        '&:before': {
            display: 'none'
        }
    })
);

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
    backgroundColor: alpha(theme.palette.action.disabled, 0.05),
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)'
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1)
    }
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(1),
    borderTop: `1px solid ${theme.palette.action.disabled}`
}));

export const RecordingIcon = styled(RadioButtonCheckedIcon)({
    '@keyframes flickerAnimation': {
        '0%': { opacity: 1 },
        '50%': { opacity: 0 },
        '100%': { opacity: 1 }
    },
    '@-o-keyframes flickerAnimation': {
        '0%': { opacity: 1 },
        '50%': { opacity: 0 },
        '100%': { opacity: 1 }
    },
    '@-moz-keyframes flickerAnimation': {
        '0%': { opacity: 1 },
        '50%': { opacity: 0 },
        '100%': { opacity: 1 }
    },
    '@-webkit-keyframes flickerAnimation': {
        '0%': { opacity: 1 },
        '50%': { opacity: 0 },
        '100%': { opacity: 1 }
    },
    '-webkit-animation': 'flickerAnimation 1s infinite',
    '-moz-animation': 'flickerAnimation 1s infinite',
    '-o-animation': 'flickerAnimation 1s infinite',
    animation: 'flickerAnimation 1s infinite'
});

export const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px'
    }
}));

export const StyledImage = styled('img')({
    height: '100%',
    width: 'auto',
    objectFit: 'contain'
});
