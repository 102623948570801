import { ISort } from '../models/dto';

const lblSort = '{sort}';
const lblSortAttr = `sort(${lblSort})`;

/**
 * Use to append sort at the end of a list URL.
 * @param url Url to append to.
 * @param sort Array of sort types to append to url.
 * @returns e.g. https://localhost:44352/api/playlist/14748/search?s=&sort(+Name)
 */
export function addSortToUrl(url: string, sort: ISort[]): string {
    if (sort.length === 0) {
        return url;
    }
    if (url?.indexOf('?') === -1) {
        url += '?';
    } else {
        url += '&';
    }
    return `${url}${lblSortAttr.replace(lblSort, createSortString(sort))}`;
}

export function createSortString(sort: ISort[]): string {
    let sortString = '';
    for (let i = 0; i < sort.length; i++) {
        sortString += `${sortString.length > 0 ? ',' : ''}${sort[i].inc}${sort[i].name}`;
    }
    return sortString;
}
