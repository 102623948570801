import React, { createContext, FC, SyntheticEvent, useContext, useEffect, useRef, useState } from 'react';
import { AudioSettingDto, HistoryItemDto, LibraryItemDto, MediaItemDto, MediaType, PlaylistCategoryRow } from '../../models/dto';
import { SelectItem } from '../../models/interfaces';
import Lang from '../../models/language';
import { DialogStationProps } from '../../models/props';
import { useRoutingData } from '../../pages/routing/provider';
import { useTreeView } from '../../pages/station/library';
import { defaultAudioSettings } from '../../pages/station/settings/audio-settings/models/consts';
import { IAudioSettings, IPlotData } from '../../pages/station/settings/audio-settings/models/interfaces';
import { Notification, useNotification } from '../../providers/notifications';
import useLocalStorage, { LocalStorageType } from '../../utils/local-storage';
import { DialogDraggableTitle, getDialogPaperComponent } from '../draggable-paper';
import LoadingBtn from '../loading-btn';
import MenuBtn from '../menu-btn';
import {
    Alert,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DoneIcon,
    EditIcon,
    FormatListBulletedIcon,
    HistoryIcon,
    MoreVertIcon,
    Paper,
    SaveIcon,
    SearchIcon,
    Stack,
    Tab,
    Tabs,
    Tooltip
} from '../mui';
import TextInputSelect from '../text-input-select';
import { dragTitleId, isLoadingType, selectMediaItemTitle } from './consts';
import { EditMediaPage, IEditMediaContext, ISavedEditMedia, ItemAudioSettingsItem } from './interfaces';
import {
    getDiscardTitle,
    getEditMediaPageItems,
    getSanitizedValues,
    getSaveTitle,
    saveAllChages,
    useFetchMediaItems,
    useProviderValue,
    useStationInfo
} from './utils';
import { Btn } from '../styled-components';
import DialogSearchCoverArt from './components/dialog-search-cover-art';

const initEditMediaContext: Partial<IEditMediaContext> = {
    editMediaState: { activeMediaId: '', activePageName: EditMediaPage.BasicInfo }
};

const EditMediaContext = createContext(initEditMediaContext as IEditMediaContext);

export function useEditMedia() {
    return useContext(EditMediaContext);
}

/**
 * Usage: setSharedDialogs({ editMediaItems: [] });
 */
const DialogEditMediaProvider: FC<DialogStationProps> = ({ draggable, onClose, open, stationId }) => {
    const [isLoading, setIsLoading] = useState<isLoadingType>(false);
    const isDraggable = draggable === undefined || draggable;
    const dialogPaperComponent = useRef(getDialogPaperComponent(dragTitleId)).current;
    const isOpen = useRef(open);
    const { addNotification } = useNotification();
    const { stationData } = useRoutingData();

    const [backupPlaylists, setBackupPlaylists] = useState<SelectItem<string, PlaylistCategoryRow[]>[]>([]);
    const [loadedPlaylists, setLoadedPlaylists] = useState<SelectItem<string, PlaylistCategoryRow[]>[]>([]);
    const [mediaTypes, setMediaTypes] = useState<MediaType[]>([]);
    const [dbAudioSettings, setDbAudioSettings] = useState<IAudioSettings>(defaultAudioSettings);
    const [backupAudioSettingsItems, setBackupAudioSettingsItems] = useState<SelectItem<string, AudioSettingDto>[]>([]);
    const [itemAudioSettingsItems, setItemAudioSettingsItems] = useState<ItemAudioSettingsItem[]>([]);
    const [itemHistoryItems, setItemHistoryItems] = useState<SelectItem<string, HistoryItemDto[]>[]>([]);
    const [leadingTrailingSelectItems, setLeadingTrailingSelectItems] = useState<LibraryItemDto[]>();
    const [backupMediaItems, setBackupMediaItems] = useState<MediaItemDto[]>([]);
    const [mediaItems, setMediaItems] = useState<MediaItemDto[]>([]);
    const [chartData, setChartData] = useState<IPlotData>();
    const { sharedDialogsState } = useTreeView();

    const [dialogSearchCoverArt, setDialogSearchCoverArt] = useState(false);

    const handleChange = (_: SyntheticEvent, newValue: number) => {
        value.setActivePage(editMediaPageItems[newValue].pageName);
    };
    const onDialogSelect = (id: string) => {
        const selected = mediaItems.find((item) => item.MediaItemId === id);
        if (selected) {
            value.setMediaItemId(selected.MediaItemId);
        }
    };

    const [editMediaState, setEditMediaState] = useLocalStorage(
        LocalStorageType.EDITMEDIA,
        initEditMediaContext.editMediaState as ISavedEditMedia
    );

    const saveChanges = async () => {
        await saveAllChages(
            audioItemsChanged,
            itemsChangeCount,
            itemsChanged,
            playlistItemsChanged,
            isOpen,
            mediaItems,
            stationId,
            addNotification,
            setBackupAudioSettingsItems,
            setBackupMediaItems,
            setBackupPlaylists,
            setIsLoading
        );
    };

    const {
        activePage,
        audioItemsChanged,
        currentAudioSettingsItem,
        currentHistoryItem,
        currentItemChanged,
        currentMediaItem,
        currentMediaItemId,
        currentPlaylists,
        discardMenuItems,
        itemsChangeCount,
        itemsChanged,
        playlistItemsChanged,
        okMenuItems
    } = getSanitizedValues(
        backupAudioSettingsItems,
        backupMediaItems,
        backupPlaylists,
        loadedPlaylists,
        editMediaState,
        isLoading,
        itemAudioSettingsItems,
        itemHistoryItems,
        mediaItems,
        setBackupPlaylists,
        setLoadedPlaylists,
        setItemAudioSettingsItems,
        setMediaItems,
        saveChanges,
        onClose
    );

    useFetchMediaItems(
        editMediaState.activeMediaId,
        loadedPlaylists,
        stationId,
        sharedDialogsState.editMediaItems,
        mediaItems,
        mediaTypes,
        setBackupPlaylists,
        setLoadedPlaylists,
        setEditMediaState,
        setIsLoading,
        setBackupMediaItems,
        setMediaItems,
        setMediaTypes
    );

    useStationInfo(setDbAudioSettings);

    useEffect(() => {
        isOpen.current = open;
        if (!open && isLoading === 'SAVING') {
            addNotification(
                new Notification({
                    message: 'Saving media item(s) in the background',
                    severity: 'info'
                })
            );
        }
        // Just to set the initial activeMediaId:
        if (open && !currentMediaItem && mediaItems.length > 0) {
            setEditMediaState((prevState) => {
                return { ...prevState, activeMediaId: mediaItems[0].MediaItemId };
            });
        }
    }, [stationId, open, mediaItems, isLoading]);

    useEffect(() => {
        if (stationId) {
            // Make sure there's no activeMediaId:
            setEditMediaState((prevState) => {
                return { ...prevState, activeMediaId: '' };
            });
        }
    }, [stationId]);

    useEffect(() => {
        if (open && currentMediaItem?.MediaType) {
            const mediaPageItem = editMediaPageItems[activePage];
            if (mediaPageItem.disabled && activePage !== 0) {
                // If the current page is disabled, take it to the first one:
                value.setActivePage(editMediaPageItems[0].pageName);
            }
        }
    }, [open, activePage, currentMediaItem]);

    const editMediaPageItems = getEditMediaPageItems(currentMediaItem, stationData.manageStationData);

    const value = useProviderValue(
        stationId,
        currentAudioSettingsItem,
        currentHistoryItem,
        currentMediaItemId,
        currentPlaylists,
        dbAudioSettings,
        editMediaState,
        leadingTrailingSelectItems,
        mediaItems,
        mediaTypes,
        chartData,
        setBackupAudioSettingsItems,
        setEditMediaState,
        setItemAudioSettingsItems,
        setItemHistoryItems,
        setLeadingTrailingSelectItems,
        setMediaItems,
        setLoadedPlaylists,
        setChartData
    );

    const renderTabs = () => {
        return (
            <Tabs value={activePage} onChange={handleChange} variant="scrollable">
                {editMediaPageItems.map((page) => (
                    <Tab
                        key={page.pageName}
                        icon={<page.icon />}
                        iconPosition="start"
                        label={page.pageName}
                        disabled={page.disabled ?? false}
                    />
                ))}
            </Tabs>
        );
    };

    const renderActivePage = () => {
        const mediaPageItem = editMediaPageItems[activePage];
        return (
            currentMediaItem && (
                <div style={{ paddingTop: '10px' }}>
                    <mediaPageItem.page />
                </div>
            )
        );
    };

    return (
        <EditMediaContext.Provider value={value}>
            <Dialog
                open={open}
                PaperComponent={isDraggable ? dialogPaperComponent : Paper}
                aria-labelledby={dragTitleId}
                maxWidth="lg"
            >
                {open && (
                    <>
                        <DialogDraggableTitle
                            componentId={dragTitleId}
                            dialogTitle={
                                <div style={{ display: 'flex' }}>
                                    <EditIcon sx={{ pt: '4px', mr: '4px' }} />
                                    <div>{Lang.btnEditMediaItems.replace('{plural}', mediaItems.length > 0 ? 's' : '')}</div>
                                </div>
                            }
                            draggable={isDraggable}
                        />
                        <DialogContent>
                            {currentMediaItem && (
                                <TextInputSelect
                                    showArrows
                                    spellCheck={false}
                                    icon={mediaItems.length > 1 ? FormatListBulletedIcon : null}
                                    inputDisabled={isLoading === 'FETCHING' || mediaItems.length === 1}
                                    inputLabel="Current Media Item"
                                    inputTitle={selectMediaItemTitle}
                                    inputValue={currentMediaItem.Title ? currentMediaItem.Title : currentMediaItem.Artist}
                                    selectItems={mediaItems.map((item) => {
                                        return { id: item.MediaItemId, value: item.Title ? item.Title : item.Artist };
                                    })}
                                    selectTitle={selectMediaItemTitle}
                                    selected={currentMediaItem.MediaItemId}
                                    onDialogSelect={onDialogSelect}
                                />
                            )}
                            {renderTabs()}
                            {renderActivePage()}
                        </DialogContent>
                        <DialogActions sx={{ justifyContent: 'space-between', alignItems: 'center', m: 1 }}>
                            <Tooltip title="Populate Album Cover Art and Metadata">
                                <Btn
                                    size="small"
                                    variant={dialogSearchCoverArt ? 'contained' : 'outlined'}
                                    onClick={() => {
                                        setDialogSearchCoverArt(true);
                                    }}
                                    startIcon={<SearchIcon />}
                                >
                                    {Lang.btnSearchMediaItem}
                                </Btn>
                            </Tooltip>
                            {currentMediaItem && (
                                <DialogSearchCoverArt
                                    initSearchText={`${currentMediaItem.Artist ?? ''} ${currentMediaItem.Title ?? ''}`}
                                    open={dialogSearchCoverArt}
                                    closable
                                    onClose={() => setDialogSearchCoverArt(false)}
                                />
                            )}
                            <Stack direction="row" alignItems="center">
                                <Collapse in={!!itemsChangeCount} orientation="vertical" unmountOnExit>
                                    <Alert
                                        variant="outlined"
                                        severity="info"
                                        sx={{ alignItems: 'center' }}
                                        action={
                                            <>
                                                <MenuBtn
                                                    menuItems={discardMenuItems}
                                                    buttonProps={{
                                                        disabled: !!isLoading,
                                                        size: 'small',
                                                        variant: 'outlined',
                                                        type: 'button',
                                                        startIcon: itemsChangeCount > 1 ? <MoreVertIcon /> : <HistoryIcon />
                                                    }}
                                                    loading={false}
                                                >
                                                    {getDiscardTitle(itemsChangeCount, currentItemChanged)}
                                                </MenuBtn>
                                                <LoadingBtn
                                                    buttonProps={{
                                                        size: 'small',
                                                        variant: 'outlined',
                                                        type: 'button',
                                                        onClick: saveChanges,
                                                        startIcon: <SaveIcon />
                                                    }}
                                                    loading={isLoading === 'SAVING'}
                                                >
                                                    {getSaveTitle(itemsChangeCount, currentItemChanged)}
                                                </LoadingBtn>
                                            </>
                                        }
                                    >
                                        {Lang.msgAudioSettingsEdit}
                                    </Alert>
                                </Collapse>
                                <MenuBtn
                                    menuItems={okMenuItems}
                                    buttonProps={{
                                        size: 'small',
                                        variant: 'contained',
                                        type: 'button',
                                        startIcon: okMenuItems.length > 1 ? <MoreVertIcon /> : <DoneIcon />
                                    }}
                                    loading={isLoading === 'FETCHING'}
                                >
                                    {Lang.btnOk}
                                </MenuBtn>
                            </Stack>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </EditMediaContext.Provider>
    );
};

export default DialogEditMediaProvider;
