import React, { PureComponent } from 'react';
import { ColumnCheckListProps } from '../models/props';
import { Checkbox, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ViewColumnIcon } from './mui';

export default class CheckboxList extends PureComponent<ColumnCheckListProps> {
    render() {
        const { colsEnabled, items, onColEnableChange } = this.props;
        return (
            <List>
                {items.map((item) => {
                    const labelId = `checkbox-list-label-${item.dataKey}`;

                    return (
                        <ListItem key={item.dataKey} secondaryAction={<ViewColumnIcon />} disablePadding>
                            <ListItemButton onClick={() => onColEnableChange(item.dataKey)} dense>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={colsEnabled.indexOf(item.dataKey) !== -1}
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={item.label} />
                            </ListItemButton>
                        </ListItem>
                    );
                })}
            </List>
        );
    }
}
