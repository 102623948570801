import React, { FC } from 'react';
import { Area, ComposedChart, Label, Legend, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { Stack, Typography, useTheme } from '../../../../../components/mui';
import { BorderedContainer } from '../../../../../components/styled-components';
import { getDbLevelFromPercentage } from '../audio-calculations';
import { AudioSettingsChartProps } from '../models/interfaces';
import { secondsLabelFunction } from '../utils';

const AudioSettingsChart: FC<AudioSettingsChartProps> = ({ largeToSmall, chartData }) => {
    const theme = useTheme();

    const yAxisDataFormatter = (value: string) => {
        const valueAsNumber = parseFloat(value);
        return getDbLevelFromPercentage(valueAsNumber === 0 ? 0.1 : valueAsNumber).toFixed(1);
    };

    const xAxisDataFormatter = (value: string) => {
        const valueAsNumber = parseFloat(value);
        return secondsLabelFunction(valueAsNumber).toString();
    };

    const seriesOutDataProp = chartData && {
        data: chartData.seriesOut
    };

    const seriesInDataProp = chartData && {
        data: chartData.seriesIn
    };

    return (
        <BorderedContainer spacing={2} sx={{ width: '100%' }}>
            <Stack direction="row" alignItems="center" justifyContent="center">
                <Typography sx={{ textDecoration: 'underline', fontSize: { xs: 11, md: 15 } }}>
                    Tracks normalized to EBU R128 -18LUFS standard
                </Typography>
            </Stack>

            {chartData && (
                <ResponsiveContainer width="99%" height={largeToSmall ? 200 : 220}>
                    <ComposedChart>
                        <XAxis dataKey="x" tick={{ fontSize: 10 }} type="number" tickFormatter={xAxisDataFormatter}>
                            <Label value="Seconds" position="insideBottom" dy={5} />
                        </XAxis>
                        <YAxis yAxisId="left" tick={{ fontSize: 10 }} dataKey="y" tickFormatter={yAxisDataFormatter}>
                            <Label value="Db Level" angle={-90} position="outside" dx={-15} />
                        </YAxis>
                        <Legend />
                        <Area
                            yAxisId="left"
                            type="monotone"
                            dataKey="y"
                            stroke={theme.palette.primary.main}
                            fill={theme.palette.primary.main}
                            opacity={0.5}
                            {...seriesOutDataProp}
                            baseValue="dataMin"
                            name="Fade Out"
                        />
                        <Area
                            yAxisId="left"
                            type="monotone"
                            dataKey="y"
                            stroke={theme.palette.secondary.main}
                            fill={theme.palette.secondary.main}
                            opacity={0.5}
                            baseValue="dataMin"
                            {...seriesInDataProp}
                            name="Fade In"
                        />
                    </ComposedChart>
                </ResponsiveContainer>
            )}
        </BorderedContainer>
    );
};

export default AudioSettingsChart;
