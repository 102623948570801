import {
    BaseResponseDto,
    HistoryMediaItemsDto,
    HistoryMediaItemsRequest,
    MediaItemDto,
    StationRequest,
    SubmitExportRequest
} from '../models/dto';
import { BaseUrl } from '../utils/env';
import { Fetch } from '../utils/middleware';
import { getRequestInitDELETE, getRequestInitGET, getRequestInitPOST } from './headers';

const lblStationId = '{stationId}';
const lblStartDate = '{startDate}';
const lblMediaTypeCodes = '{mediaTypeCodes}';
const lblAggregate = '{aggregate}';
const lblUtcOffset = '{utcOffset}';
const lblEndDate = '{endDate}';
const lblMediaTypes = '{mediaTypes}';

export const apiHistory = `api/history/${lblStationId}/`;

const urlHistoryExportApi = `${apiHistory}report?startDate=${lblStartDate}&mediaTypeCodes=${lblMediaTypeCodes}&aggregate=${lblAggregate}&utcOffset=${lblUtcOffset}`;

const urlHistoryMediaItems = `${apiHistory}playedItems?endDate=${lblEndDate}`;

const urlGetLastPlayedHistoryItem = `${apiHistory}last-played/${lblMediaTypes}`;

const urlClearHistory = `${apiHistory}clear/`;

/**
 * The download is successful with a signal R request.
 */
export async function submitExportRequest({
    stationId,
    aggregate,
    mediaTypeCodes,
    startDate,
    utcOffset
}: SubmitExportRequest): Promise<MediaItemDto> {
    const url = `${BaseUrl()}${urlHistoryExportApi
        .replace(lblStationId, stationId)
        .replace(lblStartDate, startDate)
        .replace(lblMediaTypeCodes, mediaTypeCodes)
        .replace(lblAggregate, aggregate.toString())
        .replace(lblUtcOffset, utcOffset)}`;
    return await Fetch<MediaItemDto>(url, getRequestInitGET());
}

/**
 * Fetch history that has been played.
 */
export async function fetchHistoryMediaItems({ items, stationId }: HistoryMediaItemsRequest): Promise<HistoryMediaItemsDto> {
    const requestDate = new Date().toUTCString();
    const url = `${BaseUrl()}${urlHistoryMediaItems.replace(lblStationId, stationId).replace(lblEndDate, requestDate)}`;

    return await Fetch(url, {
        ...getRequestInitPOST(),
        body: JSON.stringify(items)
    });
}

export async function getLastPlayedHistoryItem(
    stationId: string,
    mediaTypes: string,
    requestInit?: RequestInit
): Promise<MediaItemDto> {
    const url = `${BaseUrl()}${urlGetLastPlayedHistoryItem.replace(lblStationId, stationId).replace(lblMediaTypes, mediaTypes)}`;
    return await Fetch<MediaItemDto>(url, requestInit ?? getRequestInitGET());
}

export async function deleteClearHistory({ stationId }: StationRequest): Promise<BaseResponseDto> {
    const url = `${BaseUrl()}${urlClearHistory.replace(lblStationId, stationId)}`;
    return await Fetch<BaseResponseDto>(url, getRequestInitDELETE());
}
