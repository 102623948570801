import { ChangeEvent, ReactNode } from 'react';
import { SvgIcon, Theme } from '../../../../../components/mui';
import { BaseResponseDto, MediaType, TaskConfig } from '../../../../../models/dto';
import { AllNewType, Severity, Void } from '../../../../../models/interfaces';
import { SignalRMessage } from '../../../../../utils/signalr/models';
import { IntegrationStructureEditorItemKey, IntegrationStructureKey } from './consts';

//SignalR
export interface ISignalThirdPartyIntegration extends SignalRMessage {
    ThirdPartyIntegration?: ThirdPartyIntegration[];
}
//General
export interface IntegrationChangeParams {
    integrationType: ThirdPartyIntegrationType | undefined;
    errorMessage?: IntegrationErrorMessage;
    onErrorMessage?: Void<IntegrationErrorMessage>;
    maxMessageStructureLength?: number;
    maxSampleOutputLength?: number;
    integrationStructureSelect?: IIntegrationStructureSelect;
    isJsonOutput?: boolean;
}

export interface ILoading {
    historyItemData: boolean;
    mediaTypeData: boolean;
    saveData: boolean;
    postNowData: boolean;
    authDeauthData: boolean;
    verifyTuneInData: boolean;
    integrationData: boolean;
}

export interface IIntegrationTab {
    label: string;
    icon: typeof SvgIcon;
    integrationType: ThirdPartyIntegrationType;
}

export interface ITuneInVerify {
    message: string;
    severity: Severity;
}

export interface IntegrationStructureEditor {
    [IntegrationStructureKey.TrackTitle]: string;
    [IntegrationStructureKey.TrackDuration]: string;
    [IntegrationStructureKey.TrackArtist]: string;
    [IntegrationStructureKey.TrackBuyLink]: string;
    [IntegrationStructureKey.TrackAlbum]: string;
    [IntegrationStructureKey.TrackListenUrl]: string;
    [IntegrationStructureKey.DjName]: string;
    [IntegrationStructureKey.Website]: string;
}

export interface IntegrationStructureOutput {
    title?: string;
    duration?: string;
    artist?: string;
    buyLink?: string;
    album?: string;
    listenUrl?: string;
    djName?: string;
    website?: string;
}

export interface SharedIntegration {
    Message?: string;
    MediaTypes?: string;
    CloudEnabled?: boolean;
    LiveEnabled?: boolean;
    ScheduledEventEnabled?: boolean;
    Frequency?: number;
    FrequencyType?: ThirdPartyIntegrationFrequencyType;
    ShareAlbumArt?: boolean;
}

export interface UpdateTwitterRequest extends SharedIntegration {
    OutputNodeSize?: number;
}

export interface UpdateFacebookRequest extends SharedIntegration {
    Page?: FacebookPage;
}

export interface UpdateWebhookRequest extends SharedIntegration {
    Url?: string;
}

export interface FacebookPage {
    access_token: string;
    category: string;
    name: string;
    id: string;
    category_list: CategoryList[];
    perms: string[];
}

export interface CategoryList {
    id: string;
    name: string;
}

export interface ThirdPartyIntegration {
    ThirdPartyIntegrationId: number;
    CloudEnabled: boolean;
    LiveEnabled: boolean;
    ScheduledEventEnabled: boolean;
    StationId: number;
    ThirdPartyIntegrationType: ThirdPartyIntegrationType;
    MediaTypes: string;
    PartnerId?: string;
    PartnerKey?: string;
    TuneInId?: string;
    OAuthToken?: string;
    OAuthTokenSecret?: string;
    ScreenName?: string;
    UserID?: number;
    Message?: string;
    Frequency?: number;
    FrequencyType?: ThirdPartyIntegrationFrequencyType;
    ShareAlbumArt?: boolean;
    Name?: string;
    PageId?: string;
    AccessToken?: string;
    UserId?: string;
    UserName?: string;
    Url?: string;
    SampleOutputSize?: number;
}

export interface DiscordChannel {
    id: string;
    type: number;
    name: string;
    position: number;
    guild_id: string;
}

export interface DiscordServerPayload {
    discordServer: DiscordServer;
    valid: boolean;
    invalidReason: string;
    discordAuthUrl: string;
}

export interface DiscordServer {
    discordServerId: string;
    stationId: number;
    icecastSlot: IcecastSlot;
    liveSourceId: number;
    serverId: string;
    channelId: string;
    serverName: string;
    channelName: string;
    serverIcon: string;
    discordChannels: DiscordChannel[];
    isStreaming: boolean;
}

export interface TuneInPartner {
    CloudEnabled: boolean;
    LiveEnabled: boolean;
    PartnerId: string;
    PartnerKey: string;
    TuneInId: string;
    MediaTypes: string;
}

export type IIntegrationStructureSelect = AllNewType<IntegrationStructureEditor, boolean>;
export type IntegrationErrorMessage = AllNewType<ThirdPartyIntegration, string>;

//Enums
export enum IcecastSlot {
    Slot1 = 1,
    Slot2 = 2
}

export enum ThirdPartyIntegrationType {
    TuneIn,
    Twitter,
    Facebook,
    Discord,
    Webhook
}

export enum ThirdPartyIntegrationFrequencyType {
    MinuteInterval,
    TrackInterval
}

//DTOs
export interface FacebookPagesDto extends BaseResponseDto {
    data: FacebookPage[];
}
export interface ThirdPartyIntegrationsDto extends BaseResponseDto {
    data: ThirdPartyIntegration[];
}
export interface TweetSampleResponseDto extends BaseResponseDto {
    Published: boolean;
    Message?: string;
}

export interface DiscordServerPayloadDto extends DiscordServerPayload, BaseResponseDto {}
export interface DiscordServerDto extends DiscordServer, BaseResponseDto {}

//Props
export interface IntegrationProps {
    integration: ThirdPartyIntegration;
    disabled?: boolean;
}

export type IntegrationEnabledTypesProps = IntegrationProps;
export type IntegrationFrequencyProps = IntegrationProps;

export interface IntegrationMediaTypesProps {
    disabled?: boolean;
    mediaTypes: MediaType[];
    loading: boolean;
    integration?: ThirdPartyIntegration;
    errorMessage: IntegrationErrorMessage;
    selectedMediaTypes?: string[];

    onErrorMessage: Void<IntegrationErrorMessage>;
    onToggleMediaType: (
        integrationType: ThirdPartyIntegrationType | undefined,
        typeCode: string,
        errorMessage: IntegrationErrorMessage,
        onErrorMessage: Void<IntegrationErrorMessage>
    ) => void;
}

export interface SavePostBtnsProps extends IntegrationProps {
    saveDisabled?: boolean;
    postDisabled?: boolean;
}

export interface AuthAndResetBtnsProps {
    isAuthorized?: boolean;
    integrationType: ThirdPartyIntegrationType;
    resetBtnDisabled: boolean;
    authBtnTooltip?: string;
    isTask?: boolean;
    onErrorMessage?: Void<IntegrationErrorMessage>;
}

export interface IntegrationHeadingProps extends AuthAndResetBtnsProps {
    title: string;
}

export interface IntegrationResetBtnProps {
    disabled: boolean;
    onErrorMessage?: Void<IntegrationErrorMessage>;
}

export interface IntegrationMessageStructureProps {
    isJsonOutput?: boolean;
    onErrorMessage: Void<IntegrationErrorMessage>;
    maxSampleOutputLength: number;
    maxMessageStructureLength: number;
    errorMessage: IntegrationErrorMessage;
    disabled?: boolean;

    integrationType?: ThirdPartyIntegrationType;
    message?: string;
    sampleOutputSize?: number;
    shareAlbumArt?: boolean;

    historyItem?: IntegrationStructureEditor;
    loading: boolean;
    onIntegrationPropChange: (itemName: keyof ThirdPartyIntegration, newValue: unknown, params: IntegrationChangeParams) => void;
    onIntegrationStructureSelect: (key: keyof IntegrationStructureEditor, params: IntegrationChangeParams) => void;
    onToggleEnabledType?: (integrationType: ThirdPartyIntegrationType | undefined, itemName: keyof ThirdPartyIntegration) => void;
}

export interface IntegrationProviderProps {
    isTask?: boolean;
    taskConfig?: TaskConfig;
    onPropertyChange?: (integration?: ThirdPartyIntegration, Page?: FacebookPage) => void;
}

export interface MessageStructureSelectProps {
    onSelect: Void<IntegrationStructureEditorItemKey>;
    theme: Theme;
    disabled?: boolean;
    integrationStructureSelect: AllNewType<IntegrationStructureEditor, boolean>;
}

export interface MessageStructureTextAreaProps {
    disabled?: boolean;
    isJsonOutput?: boolean;
    /**
     * To know where to add the selected MessageStructureKey to the value.
     */
    onCursorChanged?: Void<number>;
    onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
    placeholder?: string;
    value: string;
    rows: number;
}

export interface IntegrationItemProps {
    label: string;
    infoTitle?: string;
    loading?: boolean;
    component: ReactNode;
    theme: Theme;
    fixedToRow?: boolean;
}

export interface IntegrationSharedProps {
    integration?: ThirdPartyIntegration;
    initialIntegration?: ThirdPartyIntegration;
    integrationType: ThirdPartyIntegrationType;
    disabled: boolean;
}
