import React, { ChangeEvent, FC, useState } from 'react';
import { fetchPlaylistSelect, postAddToPlaylist } from '../../middleware/playlist';
import { PlaylistCategoryRow, PlaylistResponseDto } from '../../models/dto';
import { AddToPlaylistAction } from '../../models/interfaces';
import Lang from '../../models/language';
import { DialogAddToPlaylistProps } from '../../models/props';
import { getCurrentlySelectedNode } from '../../pages/station/library/utils';
import { useEffectAsync } from '../../utils/react-util';
import BtnIconTooltip from '../btn-icon-tooltip';
import { DialogDraggableTitle, getDialogPaperComponent } from '../draggable-paper';
import { renderAlertPopup } from '../dynamic-table/components';
import LoadingBtn from '../loading-btn';
import {
    AddIcon,
    BorderColorIcon,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
    FormHelperText,
    MenuItem,
    Paper,
    Radio,
    RadioGroup,
    Select,
    SelectChangeEvent,
    Stack,
    SubscriptionsIcon,
    useTheme
} from '../mui';
import { Btn } from '../styled-components';
import TextInputSelect from '../text-input-select';
import { Order, OrderBy, dialogSpacing, initSortOption, sortOptions } from './consts';

const dragTitleId = 'draggable-add-to-playlist-dialog-title';

const DialogAddToPlaylist: FC<DialogAddToPlaylistProps> = ({
    draggable,
    closable,
    open,
    playlistTree,
    addToPlaylistMediaItems,
    onSaveSelectedPlaylist,
    onOpenCreatePlaylist,
    onClose,
    stationId,
    playlist
}) => {
    const isDraggable = draggable === undefined || draggable;
    const isClosable = closable === undefined || closable;

    const theme = useTheme();
    const [isLoading, setIsLoading] = useState(true);
    const [action, setPosition] = useState<AddToPlaylistAction | 'SORT'>('TOP');

    const [sortOption, setSortOption] = useState(initSortOption);
    const [clearChecked, setClearChecked] = useState(false);
    const [playlists, setPlaylists] = useState<Array<PlaylistCategoryRow>>([]);
    const [selectedPlaylist, setSelectedPlaylist] = useState<PlaylistCategoryRow>();
    const [errorMessage, setErrorMessage] = useState<string>();

    const refetchAndSet = async (newPlaylist?: PlaylistResponseDto) => {
        setErrorMessage(undefined);
        setIsLoading(true);
        const playlistSelect = await fetchPlaylistSelect({ stationId, sort: [{ inc: '+', name: 'Name' }] });

        if (newPlaylist) {
            // If a new playlist was created through the dialog, select it directly:
            const selectedPlaylist = playlistSelect.data.find((item) => item.CategoryId === newPlaylist.CategoryId);
            if (selectedPlaylist) {
                setSelectedPlaylist(selectedPlaylist);
            }
        }

        setPlaylists(playlistSelect.data);
        setIsLoading(false);
    };

    useEffectAsync(async () => {
        if (open) {
            await refetchAndSet();
        }
    }, [open]);

    useEffectAsync(async () => {
        if (open) {
            await refetchAndSet(playlist as PlaylistResponseDto);
        }
    }, [playlist]);

    const handleSave = async () => {
        if (!selectedPlaylist) {
            setErrorMessage(Lang.msgPlaylistNotSelected);
            return;
        }

        onSaveSelectedPlaylist && onSaveSelectedPlaylist(selectedPlaylist);

        if (addToPlaylistMediaItems) {
            setIsLoading(true);
            const res = await postAddToPlaylist({
                action: clearChecked ? 'CLEAR' : action === 'SORT' ? 'BOTTOM' : action, // Default to BOTTOM:
                mediaItemIds: addToPlaylistMediaItems,
                stationId,
                playlistId: selectedPlaylist.CategoryId,
                orderBy: action === 'SORT' ? sortOption.orderBy : undefined,
                order: action === 'SORT' ? (sortOption.order === 'Ascending' ? 'ASC' : 'DESC') : undefined
            });
            if (res.success) {
                onClose && onClose();
            } else {
                setErrorMessage(Lang.msgAddItemToPlaylistError);
            }
            setIsLoading(false);
        }
        return selectedPlaylist;
    };
    const openCreateNewPlaylist = (open: boolean) => {
        // Note, callback will emit if a playlist was emitted.
        onOpenCreatePlaylist(createPlaylistCallback, open);
    };
    const createPlaylistCallback = async (newPlaylist: PlaylistResponseDto) => {
        await refetchAndSet(newPlaylist);
    };
    const onDialogSelect = (id: string) => {
        setErrorMessage(undefined);
        const selected = playlists.find((item) => item.CategoryId === id);
        if (selected) {
            setSelectedPlaylist(selected);
        }
    };
    const handleRadioChange = (e: ChangeEvent<HTMLInputElement>) => {
        setPosition((e.target as HTMLInputElement).value as AddToPlaylistAction);
    };
    const onClearChange = (e: ChangeEvent<HTMLInputElement>) => {
        setClearChecked(e.currentTarget.checked);
    };
    const handleOrderByChange = (e: SelectChangeEvent<string>) => {
        setSortOption((prevState) => {
            return { ...prevState, orderBy: e.target.value as OrderBy };
        });
    };
    const handleOrderChange = (e: SelectChangeEvent<string>) => {
        setSortOption((prevState) => {
            return { ...prevState, order: e.target.value as Order };
        });
    };
    const getPlaylistType = () => {
        let name: string | null = null;
        // Type is just a fancy name for the playlist's parent.
        if (selectedPlaylist && playlistTree) {
            const parentNode = getCurrentlySelectedNode(selectedPlaylist.ParentCategoryId, playlistTree);
            name = parentNode?.Name ?? null;
        }

        return name ? `From: ${name}` : 'Select Playlist';
    };

    return (
        <Dialog
            open={open}
            onClose={() => {
                isClosable && onClose && onClose();
            }}
            PaperComponent={isDraggable ? getDialogPaperComponent(dragTitleId) : Paper}
            aria-labelledby={dragTitleId}
        >
            <DialogDraggableTitle
                componentId={dragTitleId}
                dialogTitle={
                    <>
                        <SubscriptionsIcon sx={{ pt: '4px', mr: '4px' }} />
                        Add To Playlist
                    </>
                }
                draggable={isDraggable}
            />
            <DialogContent sx={{ p: 2, minWidth: 300 }}>
                <Stack direction="row" spacing={dialogSpacing}>
                    <TextInputSelect
                        loading={isLoading}
                        icon={BorderColorIcon}
                        inputDisabled={isLoading || playlists.length === 0}
                        inputLabel={getPlaylistType()}
                        inputTitle="Select Playlist"
                        inputValue={selectedPlaylist?.Name ?? '[Nothing Selected]'}
                        selectItems={playlists.map((item) => {
                            return { id: item.CategoryId, value: item.Name };
                        })}
                        selectTitle="Select Playlist"
                        selected={selectedPlaylist?.CategoryId ?? ''}
                        onDialogSelect={onDialogSelect}
                    />
                    <BtnIconTooltip
                        displayMode="tooltip"
                        icon={<AddIcon />}
                        onClick={() => openCreateNewPlaylist(true)}
                        iconButtonProps={{
                            color: 'secondary',
                            size: 'small',
                            sx: { mt: '8px' }
                        }}
                    >
                        {Lang.btnCreatePlaylist}
                    </BtnIconTooltip>
                </Stack>
                {addToPlaylistMediaItems && (
                    <Stack direction="column" spacing={dialogSpacing}>
                        <FormControlLabel
                            sx={{ userSelect: 'none' }}
                            title="Clear playlist before adding new items"
                            control={
                                <Checkbox
                                    color="secondary"
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    checked={clearChecked}
                                    onChange={onClearChange}
                                />
                            }
                            label={Lang.btnClear}
                        />
                        <RadioGroup
                            value={action}
                            sx={{
                                p: 1,
                                pt: 0.1,
                                borderRadius: `${theme.shape.borderRadius}px`,
                                border: '1px solid black'
                            }}
                            onChange={handleRadioChange}
                        >
                            <FormHelperText>Choose Position</FormHelperText>
                            <FormControlLabel value="TOP" control={<Radio size="small" />} label="Top" />
                            <FormControlLabel value="BOTTOM" control={<Radio size="small" />} label="Bottom" />
                            <FormControlLabel value="SORT" control={<Radio size="small" />} label="Sort By" />
                            <Stack direction="row" spacing={1}>
                                <Select
                                    key="by"
                                    disabled={action !== 'SORT'}
                                    sx={{ flex: 1 }}
                                    size="small"
                                    value={sortOption.orderBy}
                                    onChange={handleOrderByChange}
                                >
                                    {sortOptions.orderBy.map((item) => {
                                        return (
                                            <MenuItem key={item} value={item} selected={sortOption.orderBy === item}>
                                                {item}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                <Select
                                    key="order"
                                    disabled={action !== 'SORT'}
                                    size="small"
                                    value={sortOption.order}
                                    onChange={handleOrderChange}
                                >
                                    {sortOptions.order.map((item) => {
                                        return (
                                            <MenuItem key={item} value={item}>
                                                {item}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </Stack>
                        </RadioGroup>
                        {renderAlertPopup(errorMessage ? true : false, 'error', errorMessage as string)}
                    </Stack>
                )}
            </DialogContent>
            <DialogActions>
                <Btn size="small" variant="text" onClick={onClose}>
                    {Lang.btnClose}
                </Btn>
                <LoadingBtn
                    buttonProps={{
                        disabled: !selectedPlaylist,
                        size: 'small',
                        variant: 'contained',
                        type: 'button',
                        onClick: handleSave
                    }}
                    loading={isLoading}
                >
                    {Lang.btnSave}
                </LoadingBtn>
            </DialogActions>
        </Dialog>
    );
};

export default DialogAddToPlaylist;
