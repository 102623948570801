import React, { FC } from 'react';
import { useTreeView } from '..';
import { DialogDraggableTitle, getDialogPaperComponent } from '../../../../components/draggable-paper';
import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    FormControlLabel,
    List,
    ListItem,
    ListItemText,
    Paper,
    useTheme
} from '../../../../components/mui';
import { Btn } from '../../../../components/styled-components';
import Lang from '../../../../models/language';
import { DialogProps } from '../../../../models/props';
import { layoutGridItems } from '../models/interfaces';

const dragTitleId = 'draggable-layout-select-dialog-title';

const DialogLayoutSelectInput: FC<DialogProps> = ({ closable, dialogTextContent, dialogTitle, draggable, open, onClose }) => {
    const theme = useTheme();
    const { libLayout, setLibLayout, setLibLayoutResizable, setLibLayoutGridType } = useTreeView();
    const tryClose = () => onClose && onClose();

    return (
        <Dialog
            open={open}
            onClose={() => {
                closable && tryClose();
            }}
            PaperComponent={draggable ? getDialogPaperComponent(dragTitleId) : Paper}
            aria-labelledby={dragTitleId}
        >
            <DialogDraggableTitle componentId={dragTitleId} dialogTitle={dialogTitle} draggable={draggable} />
            <DialogContent style={{ paddingBottom: theme.spacing(1) }}>
                {dialogTextContent && <DialogContentText>{dialogTextContent}</DialogContentText>}
                <List>
                    {layoutGridItems.map((option) => {
                        const selectedProps = option.layoutGridType === libLayout.layoutGridType && {
                            background: theme.palette.action.selected
                        };
                        return (
                            <ListItem
                                key={option.layoutGridType}
                                onClick={() => {
                                    setLibLayoutGridType(option.layoutGridType);
                                }}
                                divider
                                sx={{
                                    cursor: 'pointer',
                                    transition: theme.transitions.create(['background'], {
                                        easing: theme.transitions.easing.sharp,
                                        duration: theme.transitions.duration.leavingScreen
                                    }),
                                    ...selectedProps,
                                    '&:hover': { background: theme.palette.action.hover }
                                }}
                            >
                                <ListItemText sx={{ mr: 2 }} primary={option.title} secondary={<>{option.description}</>} />
                                <option.icon />
                            </ListItem>
                        );
                    })}
                    <ListItem key="resizable">
                        <ListItemText primary="Resizable" secondary="Makes the Columns Resizable" />
                        <FormControlLabel
                            labelPlacement="start"
                            control={
                                <Checkbox
                                    name="drawer"
                                    color="primary"
                                    checked={libLayout.resizable}
                                    onChange={() => setLibLayoutResizable(!libLayout.resizable)}
                                />
                            }
                            label=""
                        />
                    </ListItem>
                    <ListItem key="reset">
                        <ListItemText primary="Reset" secondary="Reset to default" />
                        <Btn
                            type="submit"
                            variant="outlined"
                            color="primary"
                            size="small"
                            onClick={() => {
                                setLibLayout();
                            }}
                        >
                            {Lang.btnReset}
                        </Btn>
                    </ListItem>
                </List>
            </DialogContent>
            <DialogActions>
                <Btn size="small" variant="text" onClick={tryClose}>
                    {Lang.btnClose}
                </Btn>
            </DialogActions>
        </Dialog>
    );
};

export default DialogLayoutSelectInput;
