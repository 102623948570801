/**
 * Remove key from props.
 * Prevents warning "A props object containing a "key" prop is being spread into JSX":
 * NOTE: When using key, add it at the end, after spreading {...props}.
 * @returns key extracted from props and partialProps
 */
export function removeKeyFromProps(props) {
    const partialProps = { ...props };
    const key = partialProps.key;
    if (partialProps.key) {
        delete partialProps.key;
    }
    return { key, partialProps };
}
