import React, { FC } from 'react';
import { PlayerBtnProps } from '../models/props';
import { IconButton, Tooltip, useTheme } from './mui';
import Wrap from './wrap';

export const PlayerBtn: FC<PlayerBtnProps> = ({ disabled = false, icon, iconProps, onClick, title, tooltipProps }) => {
    const theme = useTheme();
    const { palette, shape, transitions } = theme;
    // The player bar is unique and the buttons should be much more rounded:
    const borderRadius = shape.borderRadius * 4;
    const TheIcon = icon;
    const animTransition = {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.shorter
    };

    const sx = {
        borderRadius: `${borderRadius}px`,
        cursor: 'pointer',
        padding: 1,
        transition: transitions.create(['background'], animTransition),
        background: palette.primary.dark,
        '&:hover': { background: palette.secondary.main }
    };

    const onClickProps = onClick && { onClick };
    return (
        <Wrap
            isWrapped={!disabled}
            wrapper={(child) => (
                <Tooltip title={title} {...tooltipProps}>
                    {child}
                </Tooltip>
            )}
        >
            <IconButton {...onClickProps} sx={sx} disabled={disabled}>
                <TheIcon {...iconProps} />
            </IconButton>
        </Wrap>
    );
};
