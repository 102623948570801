{
    "name": "sam-cloud",
    "version": "1.0.0",
    "license": "MIT",
    "scripts": {
        "start": "cross-env NODE_ENV=development parcel src/index.html --open",
        "start:staging": "cross-env NODE_ENV=staging parcel src/index.html --open",
        "build": "cross-env NODE_ENV=staging parcel build --public-url ./ src/index.html",
        "build:prod": "cross-env NODE_ENV=production parcel build --public-url ./ src/index.html",
        "cp:build": "cd ../MediaLoader.WebUI/ && rm -rf u2 && mkdir u2 && cd ../sam-cloud-ui && cp -R dist/* ../MediaLoader.WebUI/u2",
        "analyze": "yarn clean && parcel build --public-url ./ src/index.html --reporter @parcel/reporter-bundle-analyzer",
        "serve": "serve dist -l 1234 --cors",
        "clean": "rm -rf dist",
        "clean:cache": "rm -rf .parcel-cache dist",
        "clean:all": "rm -rf node_modules yarn.lock .parcel-cache dist",
        "refresh": "yarn clean:all && yarn",
        "prep": "yarn format && yarn lint && yarn test:c",
        "prep:u": "yarn format && yarn lint && yarn test:u",
        "lint": "tsc --noEmit --skipLibCheck && eslint . --fix",
        "format": "prettier --write .",
        "test": "jest --config=jest.config.json",
        "test:c": "yarn test --no-cache --clearCache",
        "test:u": "jest --config=jest.config.json --updateSnapshot --no-cache"
    },
    "devDependencies": {
        "@parcel/reporter-bundle-analyzer": "^2.12.0",
        "@parcel/transformer-sass": "2.12.0",
        "@testing-library/react": "^15.0.0",
        "@types/country-list": "^2.1.1",
        "@types/deep-equal": "^1.0.1",
        "@types/dropbox-chooser": "^1.0.3",
        "@types/geojson": "^7946.0.10",
        "@types/jest": "^29.2.0",
        "@types/leaflet": "^1.9.3",
        "@types/node": "^20.0.0",
        "@types/react": "^18.0.27",
        "@types/react-big-calendar": "^1.6.3",
        "@types/react-dom": "^18.0.6",
        "@types/react-leaflet": "^3.0.0",
        "@types/react-swipeable-views": "^0.13.1",
        "@types/react-virtualized": "^9.21.30",
        "@types/recharts": "^1.8.24",
        "@types/signalr-no-jquery": "^0.1.3",
        "@typescript-eslint/eslint-plugin": "^7.0.0",
        "@typescript-eslint/parser": "^7.0.0",
        "assert": "^2.0.0",
        "cross-env": "^7.0.3",
        "eslint": "^8.20.0",
        "eslint-plugin-react-hooks": "^4.5.0",
        "jest": "^29.2.0",
        "jest-environment-jsdom": "^29.2.1",
        "parcel": "^2.4.1",
        "prettier": "^3.0.0",
        "pretty": "^2.0.0",
        "process": "^0.11.10",
        "serve": "^14.0.0",
        "ts-jest": "^29.0.3",
        "typescript": "^5.4.3"
    },
    "dependencies": {
        "@fontsource/exo": "^5.0.0",
        "@fontsource/roboto": "^5.0.0",
        "@mui/icons-material": "^5.8.4",
        "@mui/material": "^5.13.3",
        "@mui/styled-engine": "^5.11.0",
        "@mui/x-date-pickers": "^7.0.0",
        "country-list": "^2.3.0",
        "deep-equal": "^2.2.0",
        "geojson": "^0.5.0",
        "jsbn": "^1.1.0",
        "leaflet": "^1.9.3",
        "moment": "^2.29.4",
        "react": "^18.2.0",
        "react-audio-voice-recorder": "^2.2.0",
        "react-big-calendar": "^1.6.9",
        "react-dom": "^18.2.0",
        "react-draggable": "^4.4.5",
        "react-google-recaptcha": "^3.0.0",
        "react-helmet": "^6.1.0",
        "react-leaflet": "^4.2.1",
        "react-router-dom": "^6.3.0",
        "react-swipeable-views": "^0.14.0",
        "react-virtualized": "^9.22.5",
        "recharts": "2.13.0-alpha.4",
        "signalr-no-jquery": "^0.2.0",
        "styled-components": "^6.0.0"
    },
    "resolutions": {
        "@mui/styled-engine": "npm:@mui/styled-engine-sc@^5.11.0",
        "@types/react": "npm:@types/react@^18.0.27"
    }
}
