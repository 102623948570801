import React, { FC } from 'react';
import { CenteredCircularLoaderProps } from '../models/props';
import { Backdrop, CircularProgress, Stack, useTheme } from './mui';
import Wrap from './wrap';

const CenteredCircularLoader: FC<CenteredCircularLoaderProps> = ({ loading, hasBackdrop }) => {
    const theme = useTheme();
    return (
        <Wrap
            isWrapped={!!hasBackdrop}
            wrapper={(child) => (
                <Backdrop
                    sx={{
                        color: theme.palette.action.disabled,
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                        position: 'absolute'
                    }}
                    open={!!loading}
                >
                    {child}
                </Backdrop>
            )}
        >
            <Stack justifyContent="center" alignItems="center" sx={{ flex: 1, height: '100%' }}>
                <CircularProgress />
            </Stack>
        </Wrap>
    );
};

export default CenteredCircularLoader;
