import React, { FC, useEffect, useRef, useState } from 'react';
import { LibraryTreeNode } from '../models/dto';
import { Evt } from '../models/interfaces';
import Lang from '../models/language';
import { DialogCreatePlaylistProps } from '../models/props';
import { DialogDraggableTitle, getDialogPaperComponent } from './draggable-paper';
import LoadingBtn from './loading-btn';
import { BorderColorIcon, Dialog, DialogActions, DialogContent, DialogContentText, Paper, TextField } from './mui';
import { Btn } from './styled-components';
import TextInputSelect from './text-input-select';

const dragTitleId = 'draggable-create-playlist-dialog-title';

/**
 * To fire this intent use: sharedDialogsState.createPlaylist(res).
 * That will give a response to whatever is subscribed on it.
 * To subscribe, use:
 * setSharedDialogs({ createPlaylist: (res: PlaylistResponseDto) => {} });
 */
const DialogCreatePlaylistInput: FC<DialogCreatePlaylistProps> = ({
    draggable,
    onClose,
    open,
    onCreatePlaylist,
    playlistTypes
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const isDraggable = draggable === undefined || draggable;
    const dialogPaperComponent = useRef(getDialogPaperComponent(dragTitleId)).current;

    const [playlistName, setPlaylistName] = useState('');
    const [selectedPlaylistType, setSelectedPlaylistType] = useState<LibraryTreeNode>();

    useEffect(() => {
        if (open) {
            if (!selectedPlaylistType && playlistTypes.length > 0) {
                // Try to select the first playlist type if there are any and only if there hasn't been any selection:
                setSelectedPlaylistType(playlistTypes[0]);
            }
        } else {
            setPlaylistName('');
        }
    }, [open, playlistTypes]);

    const positiveEventFunction = async () => {
        setIsLoading(true);
        const newData = { ...(selectedPlaylistType as LibraryTreeNode) };
        const res = await onCreatePlaylist(newData, playlistName);
        if (res.success) {
            onClose && onClose();
        }
        setIsLoading(false);
    };

    const onDialogSelect = (id: string) => {
        const selected = playlistTypes.find((item) => item.Id === id);
        if (selected) {
            setSelectedPlaylistType(selected);
        }
    };
    const onKeyDownCapture = async (evt: Evt) => {
        if (evt.key === 'Enter') {
            await positiveEventFunction();
        }
    };
    const tryValidate = (): boolean => {
        if (playlistName === '') {
            return true;
        }

        return false;
    };

    return (
        <Dialog open={open} PaperComponent={isDraggable ? dialogPaperComponent : Paper} aria-labelledby={dragTitleId}>
            <DialogDraggableTitle componentId={dragTitleId} dialogTitle={Lang.btnCreatePlaylist} draggable={isDraggable} />
            <DialogContent>
                <DialogContentText>Playlists will be resorted after refresh</DialogContentText>
                <TextInputSelect
                    loading={isLoading}
                    icon={BorderColorIcon}
                    inputDisabled={isLoading || playlistTypes.length === 0}
                    inputLabel="Playlist Type"
                    inputTitle="Select Playlist Type"
                    inputValue={selectedPlaylistType?.Name ?? '[Nothing Selected]'}
                    selectItems={playlistTypes.map((item) => {
                        return { id: item.Id, value: item.Name };
                    })}
                    selectTitle="Select Playlist Type"
                    selected={selectedPlaylistType?.Id ?? ''}
                    onDialogSelect={onDialogSelect}
                />
                <TextField
                    autoFocus
                    disabled={isLoading}
                    size="small"
                    fullWidth
                    sx={{ marginTop: 2 }}
                    label="Playlist Name"
                    type="text"
                    value={playlistName}
                    onKeyDownCapture={onKeyDownCapture}
                    onChange={(e) => {
                        setPlaylistName(e.target?.value);
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Btn
                    size="small"
                    variant="text"
                    onClick={() => {
                        onClose && onClose();
                    }}
                >
                    {Lang.btnClose}
                </Btn>

                <LoadingBtn
                    buttonProps={{
                        disabled: tryValidate(),
                        size: 'small',
                        variant: 'contained',
                        type: 'submit',
                        onClick: async () => {
                            await positiveEventFunction();
                        }
                    }}
                    loading={isLoading}
                >
                    {Lang.btnCreatePlaylist}
                </LoadingBtn>
            </DialogActions>
        </Dialog>
    );
};

export default DialogCreatePlaylistInput;
