import React, { createContext, FC, ReactElement, useContext, useState } from 'react';
import { useTheme } from '../components/mui';
import { Severity } from '../models/interfaces';
import { ParentDom } from '../models/props';
import { getRandomId } from '../utils/general';
import { ConsoleLogError } from '../utils/log';
import { getSavedSettings } from './settings';

export class Notification {
    id: string;

    /**
     * Should the message display (if false, the Notification will be removed, it's just present for animation's reason).
     */
    display: boolean;
    /**
     * If there's an error, it shouldn't be displayed to the user unless explicitly opened.
     */
    error?: string;
    /**
     * User-friendly message to display to the user.
     */
    message: ReactElement | string;
    /**
     * Whether the text should be rendered as rich text.
     */
    richText: boolean;
    /**
     * For display purposes.
     */
    severity: Severity;
    /**
     * Time (seconds) this item should be displayed on the screen.
     * Default to {@link notificationDisplayDuration}
     */
    displayTime?: number;

    constructor(notification: Partial<Notification>) {
        this.id = getRandomId('notification-');
        this.display = notification?.display ?? true;
        this.error = notification.error;
        this.message = notification?.message ?? '';
        this.severity = notification.severity ? notification.severity : 'success';
        this.richText = notification.richText ?? false;
        this.displayTime = notification.displayTime;
    }
}

export interface INotificationContext {
    addNotification: (notification: Notification) => void;
    removeNotification: (id: string) => void;
    notifications: Array<Notification>;
}

const defaultNotifications: Array<Notification> = [];

const initNotificationContext: Partial<INotificationContext> = {
    notifications: defaultNotifications
};
const NotificationContext = createContext(initNotificationContext as INotificationContext);

export function useNotification() {
    return useContext(NotificationContext);
}

export const NotificationProvider: FC<ParentDom> = ({ children }) => {
    const [notifications, setNotifications] = useState(defaultNotifications);
    const theme = useTheme();

    const addNotification = (notification: Notification) => {
        setNotifications((prevNotifs) => [...prevNotifs, { ...notification, display: false }]);

        if (notification.severity === 'error') {
            // Convenient way to add an error onto the console:
            ConsoleLogError('Notification-Error', { notification });
        }

        setTimeout(() => {
            setNotifications((prevNotifs) => {
                const item = prevNotifs.find((x) => x.id === notification.id);
                if (item) {
                    item.display = true;
                }
                return [...prevNotifs];
            });
        }, theme.transitions.duration.leavingScreen);

        // Set timer to delete the currently added item in a while:
        setTimeout(
            () => {
                removeNotification(notification.id);
            },
            (notification.displayTime ? notification.displayTime : getSavedSettings().notificationDisplayDuration) * 1000
        );
    };

    const removeNotification = (id: string) => {
        setNotifications((prevNotifs) => {
            const item = prevNotifs.find((x) => x.id === id);
            if (item) {
                item.display = false;
            }
            return [...prevNotifs];
        });

        // This is just to give chance to slide-animate the notification away, then remove it completely out of existence.
        setTimeout(() => {
            setNotifications((prevNotifs) => {
                const notificationIndex = prevNotifs.findIndex((x) => x.id === id);
                if (notificationIndex >= 0) {
                    prevNotifs.splice(notificationIndex, 1);
                }
                return [...prevNotifs];
            });
        }, theme.transitions.duration.leavingScreen);
    };
    const value = {
        addNotification,
        removeNotification,
        notifications
    };

    return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
};
