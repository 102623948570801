import {
    BaseResponseDto,
    BlobInfo,
    MediaItemDto,
    MediaItemLookupResultDto,
    NumberResponseDto,
    StorageUsedInfoDto,
    StringListDto,
    StringResponseDto
} from '../models/dto';
import { generateRandomNumber, isMatchFilenameOnly } from '../pages/station/upload/general';
import { IDestination, Tab } from '../pages/station/upload/models/interfaces';
import { BaseUrl } from '../utils/env';
import { Fetch } from '../utils/middleware';
import { getRequestInitGET, getRequestInitPOST } from './headers';

const userApi = 'api/user';
const stationApi = 'api/station';
const queueCountApi = 'api/queue-count';
const libraryApi = 'api/library';
const libraryImportApi = 'api/library/import';

const lblStationId = '{stationId}';
const lblUserId = '{userId}';
const lblMatchFilenameOnly = '{lblMatchFilenameOnly}';
const lblMediaType = '{mediaType}';
const lblDestinationType = '{destinationType}';
const lblImportMode = '{importMode}';
const lblEntryCount = '{entryCount}';
const lblMediaItemId = '{mediaItemId}';

const urlImportMode = `${userApi}/${lblStationId}/${lblUserId}/importmode`;
const urlStorageInfo = `${stationApi}/${lblStationId}/storage`;
const urlTranscodeQueueStatus = `${queueCountApi}/transcode`;
const urlLookupMediaItems = `${libraryApi}/${lblStationId}/lookup/?filter=FOUND&matchFilenameOnly=${lblMatchFilenameOnly}`;
const urlInitializeBlobUpload = `${libraryImportApi}/${lblStationId}/blob?random=${generateRandomNumber()}`;
const urlUploadToBlob = `${libraryImportApi}/${lblStationId}/blob?async=true`;
const urlUploadUrlToBlob = `${libraryImportApi}/${lblStationId}/url?mediaType=${lblMediaType}&mode=${lblImportMode}&destinationType=${lblDestinationType}&matchFilenameOnly=false`;
const urlUploadRssUrlToBlob = `${libraryImportApi}/${lblStationId}/rss?mediaType=${lblMediaType}&mode=${lblImportMode}&destinationType=${lblDestinationType}&matchFilenameOnly=false&entrycount=${lblEntryCount}`;
const urlGetMediaItem = `${libraryApi}/${lblStationId}/${lblMediaItemId}`;

export async function getImportMode(stationId: string, userId: string): Promise<NumberResponseDto> {
    const url = `${BaseUrl()}${urlImportMode}`.replace(lblStationId, stationId).replace(lblUserId, userId);
    return await Fetch<NumberResponseDto>(url, getRequestInitGET());
}

export async function getStorageInfo(stationId: string): Promise<StorageUsedInfoDto> {
    //Should be done using signalR -Event: storageInfoChanged
    const url = `${BaseUrl()}${urlStorageInfo}`.replace(lblStationId, stationId);
    return await Fetch<StorageUsedInfoDto>(url, getRequestInitGET());
}

export async function getTranscodeQueueCount(): Promise<NumberResponseDto> {
    const url = `${BaseUrl()}${urlTranscodeQueueStatus}`;
    return await Fetch<NumberResponseDto>(url, getRequestInitGET());
}

export async function lookupMediaItems(stationId: string, tab: Tab, filenames: Array<string>): Promise<MediaItemLookupResultDto> {
    const url = `${BaseUrl()}${urlLookupMediaItems}`
        .replace(lblStationId, stationId)
        .replace(lblMatchFilenameOnly, isMatchFilenameOnly(tab).toString());
    return await Fetch<MediaItemLookupResultDto>(url, { ...getRequestInitPOST(), body: JSON.stringify(filenames) });
}

export async function initializeBlobUploadUrl(stationId: string): Promise<StringResponseDto> {
    const url = `${BaseUrl()}${urlInitializeBlobUpload}`.replace(lblStationId, stationId);
    return await Fetch<StringResponseDto>(url, getRequestInitGET());
}

export async function uploadFileBlock(url: string, data: Uint8Array): Promise<BaseResponseDto> {
    const initRequest = {
        headers: { 'x-ms-blob-type': 'BlockBlob' },
        method: 'PUT'
    };
    return await Fetch<BaseResponseDto>(url, { ...initRequest, body: data });
}

export async function commitFileBlock(url: string, data: string, fileType: string): Promise<BaseResponseDto> {
    const initRequest = {
        headers: {
            Accept: 'application/xml',
            'Content-Type': 'application/xml',
            'x-ms-blob-content-type': fileType
        },
        method: 'PUT'
    };
    return await Fetch<BaseResponseDto>(url, { ...initRequest, body: data });
}

export async function uploadToBlob(stationId: string, data: BlobInfo): Promise<BaseResponseDto> {
    const url = `${BaseUrl()}${urlUploadToBlob}`.replace(lblStationId, stationId);
    return await Fetch<BaseResponseDto>(url, { ...getRequestInitPOST(), body: JSON.stringify(data) });
}
export async function uploadUrlToBlob(
    stationId: string,
    mediaType: string,
    importMode: string,
    destination: IDestination,
    data: string
): Promise<StringListDto> {
    let url = `${BaseUrl()}${urlUploadUrlToBlob}`
        .replace(lblStationId, stationId)
        .replace(lblMediaType, mediaType)
        .replace(lblImportMode, importMode)
        .replace(lblDestinationType, destination.type.toString());
    if (destination.id) {
        url = `${url}&destinationId=${destination.id}`;
    }
    return await Fetch<StringListDto>(url, { ...getRequestInitPOST(), body: JSON.stringify([data]) });
}

export async function uploadRssUrlToBlob(
    stationId: string,
    mediaType: string,
    importMode: string,
    destination: IDestination,
    entryCount: number,
    data: string
): Promise<StringListDto> {
    let url = `${BaseUrl()}${urlUploadRssUrlToBlob}`
        .replace(lblStationId, stationId)
        .replace(lblStationId, stationId)
        .replace(lblMediaType, mediaType)
        .replace(lblImportMode, importMode)
        .replace(lblDestinationType, destination.type.toString())
        .replace(lblEntryCount, entryCount.toString());
    if (destination.id) {
        url = `${url}&destinationId=${destination.id}`;
    }
    return await Fetch<StringListDto>(url, { ...getRequestInitPOST(), body: JSON.stringify([data]) });
}

export async function getMediaItem(stationId: string, mediaItemId: string): Promise<MediaItemDto> {
    const url = `${BaseUrl()}${urlGetMediaItem}`.replace(lblStationId, stationId).replace(lblMediaItemId, mediaItemId);
    return await Fetch<MediaItemDto>(url, getRequestInitGET());
}

export function getUploadProgressDetails(
    currentFilePointer: number,
    totalBytesRemaining: number,
    blockSize: number,
    currentFile: File
) {
    currentFilePointer += blockSize;
    totalBytesRemaining -= blockSize;
    if (totalBytesRemaining < blockSize) {
        blockSize = totalBytesRemaining;
    }
    const percentageComplete = ((currentFile.size - totalBytesRemaining) / currentFile.size) * 100;
    return {
        currentFilePointer,
        totalBytesRemaining,
        blockSize,
        percentageComplete
    };
}

export function getCommitRequestDetails(blockIds: Array<string>, uploadUrl: string) {
    const uri = `${uploadUrl}&comp=blocklist`;
    let requestBody = '<?xml version="1.0" encoding="utf-8"?><BlockList>';
    for (const blockId of blockIds) {
        requestBody = `${requestBody}<Latest>${blockId}</Latest>`;
    }
    requestBody = `${requestBody}</BlockList>`;
    return { uri: uri, requestBody: requestBody };
}
