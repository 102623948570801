import { CSSProperties } from 'react';
import { alpha, Theme } from '../components/mui';
import { TableSettings } from '../models/interfaces';

export const containerClassName = 'tableRowDraggableContainer';
export const rowClassName = 'table-row';
export const rowSelector = `.${rowClassName}`;
const borderSelectedStyle = 'solid';
const borderSelectedStyleWidth = 8;
const rowBackgroundAlphaSelected = 0.1;
const rowBackgroundMediaTypeColorAlpha = 0.1;

interface TableRowStylingProps {
    index: number;
    theme: Theme;
    rowChecked: boolean;
    rowDragging: boolean;
    dragAboveItemActive: boolean;
    dragBelowItemActive: boolean;
    mediaTypeColor?: string;
    tableSettings?: TableSettings;
    customRowCss?: CSSProperties;
}

export class TableRowStyling {
    private animDuration: number;
    private animEasing: string;
    private borderColor: string;

    /**
     * Just to emphasize every second item in the list a little so that all the items don't all look the same.
     */
    private even: boolean;
    private theme: Theme;
    private rowChecked: boolean;
    private rowDragging: boolean;
    private dragAboveItemActive: boolean;
    private dragBelowItemActive: boolean;
    private mediaTypeColor?: string;
    private tableSettings?: TableSettings;
    private customRowCss?: CSSProperties;

    constructor({
        index,
        theme,
        rowChecked,
        rowDragging,
        dragAboveItemActive,
        dragBelowItemActive,
        mediaTypeColor,
        tableSettings,
        customRowCss
    }: TableRowStylingProps) {
        this.even = index % 2 === 0;
        this.theme = theme;
        this.animDuration = theme.transitions.duration.shortest;
        this.animEasing = theme.transitions.easing.easeIn;
        this.borderColor = theme.palette.primary.main;

        this.rowChecked = rowChecked;
        this.rowDragging = rowDragging;
        this.dragAboveItemActive = dragAboveItemActive;
        this.dragBelowItemActive = dragBelowItemActive;

        this.mediaTypeColor = mediaTypeColor;
        this.tableSettings = tableSettings;

        this.customRowCss = customRowCss;
    }

    private styledBackground() {
        const { action, common, mode, primary, secondary } = this.theme.palette;
        // Zebra stripes:
        const evenRowColor = alpha(primary.contrastText, 0.06);
        const oddRowColor = alpha(mode === 'light' ? primary.main : common.black, 0.06);

        const backgroundColor = this.rowDragging
            ? secondary.light
            : this.rowChecked
              ? alpha(action.selected, rowBackgroundAlphaSelected)
              : this.even
                ? evenRowColor
                : oddRowColor;

        if (this.tableSettings && this.mediaTypeColor && this.mediaTypeColor.toLowerCase() !== 'none') {
            const { mediaItemMediaTypeColor } = this.tableSettings;
            const mediaTypeDisplayProps =
                mediaItemMediaTypeColor === 'Display 2'
                    ? {
                          backgroundImage: `linear-gradient(180deg, ${backgroundColor} 0%, ${alpha(this.mediaTypeColor, rowBackgroundMediaTypeColorAlpha)} 0%)`
                      }
                    : mediaItemMediaTypeColor === 'Display 3' && {
                          backgroundImage: `linear-gradient(180deg, ${backgroundColor} 0%, ${this.mediaTypeColor} 0%)`
                      };
            return {
                backgroundColor: backgroundColor,
                ...mediaTypeDisplayProps
            };
        }

        return { backgroundColor: backgroundColor };
    }

    private styledBorder() {
        return (
            (this.dragAboveItemActive || this.dragBelowItemActive || this.rowDragging) && {
                borderBottomWidth: 0,
                borderTopWidth: 0,
                borderTopStyle: borderSelectedStyle,
                borderBottomStyle: borderSelectedStyle,
                borderTopColor: 'transparent',
                borderBottomColor: 'transparent',
                transition: `all ${this.animDuration}ms ${this.animEasing}`
            }
        );
    }

    private styledMediaType() {
        if (this.tableSettings && this.mediaTypeColor && this.mediaTypeColor !== 'none') {
            const { mediaItemMediaTypeColor } = this.tableSettings;
            const mediaTypeDisplayProps = (mediaItemMediaTypeColor === 'Display 1' ||
                mediaItemMediaTypeColor === 'Display 2') && {
                borderLeftStyle: borderSelectedStyle,
                borderLeftColor: this.mediaTypeColor && this.mediaTypeColor !== 'none' ? this.mediaTypeColor : 'unset'
            };
            return {
                borderLeftWidth: 6,
                ...mediaTypeDisplayProps
            };
        }
        return {
            borderLeftWidth: 6
        };
    }

    private dragAboveStyle() {
        return (
            this.dragAboveItemActive && {
                borderTopColor: this.borderColor,
                borderTopWidth: borderSelectedStyleWidth
            }
        );
    }

    private dragBelowStyle() {
        return (
            this.dragBelowItemActive && {
                borderBottomColor: this.borderColor,
                borderBottomWidth: borderSelectedStyleWidth
            }
        );
    }

    public getFinalStyling() {
        return {
            ...this.styledMediaType(),
            ...this.styledBorder(),
            ...this.styledBackground(),
            ...this.dragBelowStyle(),
            ...this.dragAboveStyle(),
            ...this.customRowCss
        } as CSSProperties;
    }
}
