/**
 * Copied from SAM-Cloud, create a new guid.
 */
export function generateGuid(): string {
    let d = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c == 'x' ? r : (r & 0x7) | 0x8).toString(16);
    });
    return uuid;
}

/**
 * Extract guid from URL.
 */
export function extractGuid(url: string) {
    const re = /([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/i;
    // the RegEx will match the first occurrence of the pattern
    const match = re.exec(url);
    // result is an array containing:
    // [0] the entire string that was matched by our RegEx
    // [1] the first (only) group within our match, specified by the
    // () within our pattern, which contains the GUID value
    return match ? match[1] : null;
}
