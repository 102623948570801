import { BaseResponseDto, TrackAnalysisDto } from '../models/dto';
import { SelectItem } from '../models/interfaces';

function sortCompare(a, b) {
    return parseInt(b) - parseInt(a);
}

export function mapRawTrackAnalysis(res: Partial<BaseResponseDto>): TrackAnalysisDto {
    if (!res.success) {
        return res as TrackAnalysisDto;
    }
    const { headers, message, range, success } = res as BaseResponseDto;
    const {
        duration,
        gain,
        levelsEnd: levelsEndRaw,
        levelsStart: levelsStartRaw
    } = res as { duration; gain; levelsEnd; levelsStart };

    const levelsEnd: SelectItem<number, number>[] = Object.keys(levelsEndRaw)
        .sort(sortCompare)
        .map((key) => ({ id: parseInt(key), value: parseInt(levelsEndRaw[key]) }));

    const levelsStart: SelectItem<number, number>[] = Object.keys(levelsStartRaw)
        .sort(sortCompare)
        .map((key) => ({ id: parseInt(key), value: parseInt(levelsStartRaw[key]) }));

    const mappedRes: TrackAnalysisDto = {
        headers,
        message,
        range,
        success,
        duration,
        gain,
        levelsEnd,
        levelsStart
    };

    return mappedRes;
}
