import React, { ChangeEvent, FC, useRef, useState } from 'react';
import { postPlaylistUpload } from '../middleware/playlist';
import { BaseResponseDto } from '../models/dto';
import Lang from '../models/language';
import { DialogPlaylistUploadProps } from '../models/props';
import { Notification, useNotification } from '../providers/notifications';
import { getMaxBorderRadius } from '../utils/style';
import { DialogDraggableTitle, getDialogPaperComponent } from './draggable-paper';
import LoadingBtn from './loading-btn';
import {
    Alert,
    Box,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    FormControlLabel,
    Paper,
    UploadIcon,
    styled,
    useTheme
} from './mui';
import { Body1, Btn, H6 } from './styled-components';

export const DragAndDropContainer = styled(Box)(({ theme }) => {
    const { spacing, transitions } = theme;
    return {
        cursor: 'pointer',
        padding: spacing(0.1),
        textAlign: 'center',
        borderRadius: `${getMaxBorderRadius(theme)}px`,
        borderWidth: '3px',
        borderStyle: 'dashed',
        transition: transitions.create(['border-color', 'border-width', 'border-style'], {
            duration: transitions.duration.short,
            easing: transitions.easing.easeIn
        }),
        borderColor: theme.palette.grey[300]
    };
});

const dragTitleId = 'draggable-import-playlist-title';

/**
 * Usage:
 * setSharedDialogs({ importPlaylist: 'playlistCategoryId' });
 */
const DialogImportPlaylist: FC<DialogPlaylistUploadProps> = ({ draggable, onClose, open, playlistId, stationId }) => {
    const { addNotification } = useNotification();
    const theme = useTheme();
    const { palette, spacing, transitions, typography } = theme;
    const [dragActive, setDragActive] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [uploadFile, setUploadFile] = useState<File>();
    const [matchFileName, setMatchFileName] = useState(false);
    const inputFileRef = useRef(null);
    const isDraggable = draggable === undefined || draggable;
    const dialogPaperComponent = useRef(getDialogPaperComponent(dragTitleId)).current;

    const done = () => {
        onClose && onClose();
    };

    const onMatchFileName = (e: ChangeEvent<HTMLInputElement>) => {
        setMatchFileName(e.currentTarget.checked);
    };

    const positiveEventFunction = async () => {
        setIsLoading(true);
        let res: BaseResponseDto | null = null;
        if (uploadFile) {
            res = await postPlaylistUpload(stationId, playlistId, matchFileName, uploadFile);
        }
        setIsLoading(false);
        if (res) {
            if (!res.success) {
                addNotification(
                    new Notification({
                        message: res.message,
                        severity: 'error'
                    })
                );
            } else {
                done();
            }
        }
    };

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (isLoading) {
            return;
        }
        if (e.type === 'dragenter' || e.type === 'dragover') {
            setDragActive(true);
        } else if (e.type === 'dragleave') {
            setDragActive(false);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        setUploadFile(e.dataTransfer.files[0]);
    };

    const handleClick = () => {
        if (inputFileRef?.current) {
            (inputFileRef.current as HTMLInputElement).click();
        }
    };

    const uploadFileClicked = (e) => {
        setUploadFile(e.target.files[0]);
    };

    const dragAndDropSxProps = (dragActive || isLoading) && {
        sx: {
            padding: spacing(0.2),
            borderColor: palette.success.main
        }
    };

    const dragAndDropTransition = transitions.create(['color', 'font-size'], {
        duration: transitions.duration.shorter,
        easing: transitions.easing.sharp
    });

    return (
        <Dialog open={open} PaperComponent={isDraggable ? dialogPaperComponent : Paper} aria-labelledby={dragTitleId}>
            <DialogDraggableTitle componentId={dragTitleId} dialogTitle={Lang.btnUploadPlaylist} draggable={isDraggable} />
            <DialogContent>
                <DialogContentText>Drag and drop a Playlist File or click</DialogContentText>
                <DragAndDropContainer
                    onClick={handleClick}
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                    {...dragAndDropSxProps}
                >
                    <H6
                        sx={{
                            transition: dragAndDropTransition,
                            color: dragActive ? palette.success.main : 'unset'
                        }}
                    >
                        Drag and drop a Playlist File or click
                    </H6>
                    {isLoading ? (
                        <CircularProgress />
                    ) : (
                        <UploadIcon
                            sx={{
                                transition: dragAndDropTransition,
                                fontSize: typography.fontSize * (dragActive ? 2.2 : 1.5),
                                color: dragActive ? palette.success.main : 'unset'
                            }}
                        />
                    )}
                    {uploadFile && <Body1>{uploadFile.name}</Body1>}
                    <input
                        style={{ display: 'none' }}
                        type="file"
                        name="uploadFile"
                        ref={inputFileRef}
                        onChange={uploadFileClicked}
                    />
                </DragAndDropContainer>
                <FormControlLabel
                    sx={{ userSelect: 'none' }}
                    control={
                        <Checkbox
                            color="secondary"
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={matchFileName}
                            onChange={onMatchFileName}
                        />
                    }
                    label="Match on filename only (ignore path)"
                />

                <Alert variant="standard" severity="info">
                    {Lang.msgImportPlaylistInfo}
                </Alert>
            </DialogContent>
            <DialogActions>
                <Btn size="small" variant="text" onClick={done}>
                    {Lang.btnClose}
                </Btn>

                <LoadingBtn
                    buttonProps={{
                        disabled: uploadFile ? false : true,
                        size: 'small',
                        variant: 'contained',
                        type: 'submit',
                        onClick: async () => {
                            await positiveEventFunction();
                        }
                    }}
                    loading={isLoading}
                >
                    {Lang.btnImport}
                </LoadingBtn>
            </DialogActions>
        </Dialog>
    );
};

export default DialogImportPlaylist;
