import React, { FC, useState } from 'react';
import { Evt } from '../models/interfaces';
import Lang from '../models/language';
import { DialogTextInputProps } from '../models/props';
import { DialogDraggableTitle, getDialogPaperComponent } from './draggable-paper';
import LoadingBtn from './loading-btn';
import { Dialog, DialogActions, DialogContent, DialogContentText, Paper, TextField } from './mui';
import { Btn } from './styled-components';

const dragTitleId = 'draggable-text-dialog-title';

const DialogTextInput: FC<DialogTextInputProps> = ({
    defaultText,
    dialogTextContent,
    dialogTitle,
    draggable,
    onNegativeEvent,
    onPositiveEvent,
    onTextChange,
    onValidate,
    open,
    positiveTitle,
    negativeTitle,
    txtFieldLabel,
    txtFieldType
}: DialogTextInputProps) => {
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState(defaultText ? defaultText : '');

    const isDraggable = draggable === undefined || draggable;

    const positiveEventFunction = async () => {
        setLoading(true);
        onPositiveEvent && (await onPositiveEvent(value));
        setLoading(false);
    };

    const tryValidate = (): boolean => {
        if (value === '') {
            return true;
        }

        if (onValidate) {
            return !onValidate(value);
        }

        return false;
    };

    return (
        <Dialog
            open={open}
            PaperComponent={isDraggable ? getDialogPaperComponent(dragTitleId) : Paper}
            aria-labelledby={dragTitleId}
        >
            <DialogDraggableTitle componentId={dragTitleId} dialogTitle={dialogTitle} draggable={isDraggable} />
            <DialogContent>
                {dialogTextContent && <DialogContentText>{dialogTextContent}</DialogContentText>}
                <TextField
                    autoFocus
                    disabled={loading}
                    fullWidth
                    sx={{ marginTop: 2 }}
                    label={txtFieldLabel}
                    type={txtFieldType}
                    value={value}
                    onKeyDownCapture={async (evt: Evt) => {
                        if (evt.key === 'Enter') {
                            await positiveEventFunction();
                        }
                    }}
                    onChange={(e) => {
                        setValue(e.target?.value);
                        onTextChange && onTextChange(e.target?.value);
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Btn
                    size="small"
                    variant="text"
                    onClick={() => {
                        onNegativeEvent && onNegativeEvent();
                    }}
                >
                    {negativeTitle ? negativeTitle : Lang.btnClose}
                </Btn>

                <LoadingBtn
                    buttonProps={{
                        disabled: tryValidate(),
                        size: 'small',
                        variant: 'contained',
                        type: 'submit',
                        onClick: async () => {
                            await positiveEventFunction();
                        }
                    }}
                    loading={loading}
                >
                    {positiveTitle ? positiveTitle : Lang.btnOk}
                </LoadingBtn>
            </DialogActions>
        </Dialog>
    );
};

export default DialogTextInput;
