import { SearchRequest } from './interfaces';

export const defaultSearchItem: SearchRequest = {
    id: 0,
    type: 'all',
    value: '',
    title: 'Search all of the words',
    requestType: 'refresh'
};

export const searchMenuItems: SearchRequest[] = [
    defaultSearchItem,
    {
        id: 1,
        type: 'any',
        value: '',
        title: 'Search any of the words',
        requestType: 'refresh'
    }
];
