import React, { FC } from 'react';
import { Box, TextareaAutosize, useTheme } from '../../../../../../components/mui';
import { getMaxBorderRadius } from '../../../../../../utils/style';
import { MessageStructureTextAreaProps } from '../../models/interfaces';

const MessageStructureTextArea: FC<MessageStructureTextAreaProps> = ({
    value,
    disabled,
    onChange,
    onCursorChanged,
    placeholder,
    rows
}) => {
    const theme = useTheme();
    const borderColor = theme.palette.primary.main;

    const placeholderProps = placeholder && {
        placeholder
    };

    const handleOnClick = (event) => {
        if (onCursorChanged) {
            onCursorChanged(event.target.selectionStart);
        }
    };
    const handleOnChange = (event) => {
        if (onChange) {
            onChange(event);
        }
        if (onCursorChanged) {
            onCursorChanged(event.target.selectionStart);
        }
    };

    return (
        <Box
            sx={{
                width: '100%',
                flex: 7,
                '& :focus': {
                    outline: 'none',
                    border: `1px solid ${borderColor}`,
                    boxShadow: `0 0 4px ${borderColor}`
                }
            }}
        >
            <TextareaAutosize
                minRows={rows}
                maxRows={rows}
                style={{ padding: 1, borderRadius: getMaxBorderRadius(theme), width: '100%', fontSize: 15 }}
                value={value}
                disabled={disabled}
                onChange={handleOnChange}
                onClick={handleOnClick}
                {...placeholderProps}
            />
        </Box>
    );
};

export default MessageStructureTextArea;
