import React, { FC, useState } from 'react';
import { useEditMedia } from '..';
import { MediaItemDto } from '../../../models/dto';
import Lang from '../../../models/language';
import { useEffectAsync } from '../../../utils/react-util';
import CenteredCircularLoader from '../../centered-circular-loader';
import DialogSelectInput from '../../dialog-select-input';
import { Alert, List, ListItem, ListItemText, SkipNextIcon, SkipPreviousIcon as SkipPrevIcon } from '../../mui';
import { Btn } from '../../styled-components';
import { populateDialogLeadingTrailingItems } from '../utils';

export type DialogOpenType = 'LEADING' | 'TRAILING' | false;

export const LeadingTrailingPage: FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [selectDialogOpen, setSelectDialogOpen] = useState<DialogOpenType>(false);
    const { leadingTrailingSelectItems, getCurrentMediaItem, setLeadingTrailingSelectItems, updateItem } = useEditMedia();
    const currentMediaItem = getCurrentMediaItem();

    const handleSelectPlaylist = (id: string) => {
        const key: keyof MediaItemDto = selectDialogOpen === 'LEADING' ? 'LeadingMediaItemId' : 'TrailingMediaItemId';
        updateItem(currentMediaItem.MediaItemId, key, id as never);
    };

    useEffectAsync(async () => {
        if (!leadingTrailingSelectItems) {
            setIsLoading(true);
            await setLeadingTrailingSelectItems();
            setIsLoading(false);
        }
    }, [leadingTrailingSelectItems]);

    const getMediaItemDescription = (mediaItemId: string) => {
        const mediaItem = leadingTrailingSelectItems?.find((item) => item.MediaItemId === mediaItemId);
        let description = '';
        if (mediaItem?.Artist) {
            description = `${mediaItem.Artist}: `;
        }
        return mediaItem && `${description}${mediaItem.Title}`;
    };

    const getLeadingTrailingTextDescription = (dialogType: DialogOpenType) => {
        if (isLoading) {
            return <CenteredCircularLoader />;
        }
        let secondary: string;
        switch (dialogType) {
            case 'LEADING': {
                const description = getMediaItemDescription(currentMediaItem.LeadingMediaItemId);
                secondary = description ? description : 'Choose the leading media item';
                break;
            }
            case 'TRAILING':
            default: {
                const description = getMediaItemDescription(currentMediaItem.TrailingMediaItemId);
                secondary = description ? description : 'Choose the trailing media item';
                break;
            }
        }
        return <ListItemText primary={dialogType === 'LEADING' ? 'Leading' : 'Trailing'} secondary={secondary} />;
    };

    return (!leadingTrailingSelectItems && isLoading) || (leadingTrailingSelectItems?.length ?? 0) > 0 ? (
        <List disablePadding>
            <ListItem>
                <Alert variant="standard" severity="info">
                    {Lang.msgLeadingTrailingSelectTracks}
                </Alert>
            </ListItem>
            <ListItem divider>
                {getLeadingTrailingTextDescription('LEADING')}
                <Btn variant="outlined" size="small" onClick={() => setSelectDialogOpen('LEADING')} startIcon={<SkipPrevIcon />}>
                    Change
                </Btn>
            </ListItem>
            <ListItem divider>
                {getLeadingTrailingTextDescription('TRAILING')}
                <Btn variant="outlined" size="small" onClick={() => setSelectDialogOpen('TRAILING')} startIcon={<SkipNextIcon />}>
                    Change
                </Btn>
            </ListItem>
            <DialogSelectInput
                items={populateDialogLeadingTrailingItems(leadingTrailingSelectItems, currentMediaItem)}
                dialogTitle={`Select ${selectDialogOpen === 'LEADING' ? 'Leading' : 'Trailing'} Media Item`}
                onSelect={handleSelectPlaylist}
                onClose={() => setSelectDialogOpen(false)}
                open={!!selectDialogOpen}
                id={currentMediaItem[selectDialogOpen === 'LEADING' ? 'LeadingMediaItemId' : 'TrailingMediaItemId']}
                loading={isLoading}
                negativeTitle={Lang.btnClose}
            />
        </List>
    ) : (
        <Alert variant="outlined" severity="warning">
            {Lang.msgNoListData}
        </Alert>
    );
};

export default LeadingTrailingPage;
