import React, { FC, useEffect, useRef, useState } from 'react';
import { CountDownTimerProps } from '../models/props';
import { Typography } from './mui';
import { secondsToDHMS, secondsToHMS } from '../utils/time';

const CountDownTimer: FC<CountDownTimerProps> = ({ duration, start, sx, recordingTime, onComplete, includeDays = false }) => {
    const [timer, setTimer] = useState(duration);
    const tick = useRef<ReturnType<typeof setInterval>>();

    useEffect(() => {
        if (timer === 0) {
            onComplete();
        }
    });

    useEffect(() => {
        if (recordingTime && recordingTime > 0) {
            setTimer((prevState) => prevState - recordingTime);
        }
    }, [recordingTime]);

    useEffect(() => {
        if (recordingTime) {
            return;
        }
        if (start) {
            tick.current = setInterval(() => {
                setTimer((timer) => {
                    if (timer === 0) {
                        return duration;
                    }
                    return timer - 1;
                });
            }, 1000);
        } else {
            setTimer(duration);
            clearInterval(tick.current);
        }
        return () => clearInterval(tick.current);
    }, [start]);

    return (
        <Typography variant="h4" mt={1} sx={sx}>
            {includeDays ? secondsToDHMS(timer) : secondsToHMS(timer)}
        </Typography>
    );
};

export default CountDownTimer;
