import React, { FC } from 'react';
import { useEditMedia } from '../..';
import { AudioSettingDto } from '../../../../models/dto';
import Dropdown from '../../../dropdown';
import { Box, List, ListItem, ListItemText, SelectChangeEvent } from '../../../mui';
import NumberInput from '../../../number-input';
import { ActiveToggleState, InputDataProp, activeOptions, minWidthDialog } from './consts';
import { getInputData } from './utils';
import { useRoutingData } from '../../../../pages/routing/provider';

/**
 * InputDataProp only compatible with 'TrimSilence' | 'XFade'.
 */
export const MultiValInputItem: FC<InputDataProp> = ({ toggleKey }) => {
    const { stationData } = useRoutingData();
    const { currentAudioSettingsItem, onSettingChange } = useEditMedia();
    const { input1, input2, input3 } = getInputData(toggleKey, stationData?.stationInfo);

    const audioSettings = currentAudioSettingsItem?.audioSettings;

    if (!audioSettings) {
        return <></>;
    }

    const value1 = audioSettings[input1.key] as boolean | null;
    const value2 = audioSettings[input2.key] as number;
    const value3 = audioSettings[input3.key] as number;

    const toggleState = (value1 ? 'yes' : value1 === false ? 'no' : 'default') as ActiveToggleState;

    const onHandleToggleStateChange = (activeState: ActiveToggleState) => {
        const value = activeState === 'yes' ? true : activeState === 'no' ? false : null;
        if (value === null) {
            // Set to default:
            onSettingChange(input2.default, input2.key);
            onSettingChange(input3.default, input3.key);
        }
        onSettingChange(value, toggleKey);
    };

    const renderNumberInput = (
        defaultVal: number | undefined,
        description: string,
        key: keyof AudioSettingDto,
        min: number | undefined,
        max: number | undefined,
        title: string,
        unit: string | undefined,
        value?: number | null
    ) => {
        const defVal = defaultVal as number;
        const minVal = min as number;
        const maxVal = max as number;
        const unitVal = unit as string;
        return (
            <ListItem disablePadding>
                <ListItemText primary={title} secondary={description} />
                <Box sx={{ mr: 1 }}>
                    <NumberInput
                        disabled={toggleState !== 'yes'}
                        min={minVal}
                        max={maxVal}
                        count={value ? value : defVal}
                        label={unitVal}
                        onChange={(newVal) => onSettingChange(newVal, key)}
                        sx={{ fontWeight: 'normal' }}
                    />
                </Box>
            </ListItem>
        );
    };

    return (
        <List disablePadding sx={{ flex: 1, minWidth: minWidthDialog }}>
            <ListItem disablePadding>
                <ListItemText primary={input1.title} secondary={input1.description} />
                <Dropdown
                    label={input1.title}
                    items={activeOptions}
                    onChange={(event: SelectChangeEvent<string>) =>
                        onHandleToggleStateChange(event.target.value as ActiveToggleState)
                    }
                    value={toggleState}
                />
            </ListItem>
            {renderNumberInput(
                input2.default,
                input2.description,
                input2.key,
                input2.min,
                input2.max,
                input2.title,
                input2.unit,
                value2
            )}
            {renderNumberInput(
                input3.default,
                input3.description,
                input3.key,
                input3.min,
                input3.max,
                input3.title,
                input3.unit,
                value3
            )}
        </List>
    );
};

export default MultiValInputItem;
