import deepEqual from 'deep-equal';
import { CSSProperties } from 'react';
import { Theme } from '../../../../components/mui';
import { SAMCloudRoutes } from '../../../../models/consts';
import { IRoute, UrlSearchParam } from '../../../../models/routes';
import { BaseUrl } from '../../../../utils/env';
import { removeExtraSpacesAndLineBreaks } from '../../../../utils/general';
import { createStationRouteUrl } from '../../../../utils/router-util';
import {
    IntegrationStructureEditorItemKey,
    IntegrationStructureKey,
    defaultFrequency,
    defaultUrlParamValue
} from './models/consts';
import {
    DiscordServerPayload,
    FacebookPage,
    IIntegrationStructureSelect,
    IntegrationStructureEditor,
    IntegrationStructureOutput,
    ThirdPartyIntegration,
    ThirdPartyIntegrationFrequencyType,
    ThirdPartyIntegrationType,
    TuneInPartner,
    UpdateFacebookRequest,
    UpdateTwitterRequest,
    UpdateWebhookRequest
} from './models/interfaces';

export function getSelectedTab(urlQuery: URLSearchParams): ThirdPartyIntegrationType {
    const searchParam: UrlSearchParam = 'tab';
    const tabParam = urlQuery.get(searchParam)?.toString();
    const tuneIn = ThirdPartyIntegrationType.TuneIn;
    const selectedTab = tabParam ? ThirdPartyIntegrationType[tabParam] : defaultUrlParamValue;
    //Special case: TuneIn is not in titleCase
    if (selectedTab === ThirdPartyIntegrationType[tuneIn]) {
        return tuneIn;
    }
    return selectedTab as ThirdPartyIntegrationType;
}

export function getEmptyWebhookIntegration(stationId: string): ThirdPartyIntegration {
    return {
        Url: '',
        Message: '',
        MediaTypes: 'MUS',
        LiveEnabled: false,
        CloudEnabled: false,
        ShareAlbumArt: false,
        ThirdPartyIntegrationId: 0,
        Frequency: defaultFrequency,
        ScheduledEventEnabled: false,
        StationId: parseInt(stationId),
        ThirdPartyIntegrationType: ThirdPartyIntegrationType.Webhook,
        FrequencyType: ThirdPartyIntegrationFrequencyType.MinuteInterval
    };
}

export function getEmptyTuneInIntegration(stationId: string): ThirdPartyIntegration {
    return {
        MediaTypes: 'MUS',
        TuneInId: '',
        PartnerId: '',
        PartnerKey: '',
        LiveEnabled: false,
        CloudEnabled: false,
        ThirdPartyIntegrationId: 0,
        ScheduledEventEnabled: false,
        StationId: parseInt(stationId),
        ThirdPartyIntegrationType: ThirdPartyIntegrationType.TuneIn
    };
}

export function getInfoIconSx(theme: Theme): CSSProperties {
    return { padding: '1px', color: theme.palette.action.disabled, borderColor: theme.palette.action.disabled };
}

export function getIntegrationByType(thirdPartyIntegrationDetails: ThirdPartyIntegration[], type: ThirdPartyIntegrationType) {
    return thirdPartyIntegrationDetails.find((x) => x.ThirdPartyIntegrationType === type);
}

export function isIntegrationEdited(thirdPartyIntegration?: ThirdPartyIntegration, initialIntegration?: ThirdPartyIntegration) {
    if (initialIntegration && thirdPartyIntegration) {
        //Special case: Sample output does not determine if edited as it is only updated for the integration
        initialIntegration.SampleOutputSize = thirdPartyIntegration.SampleOutputSize;
    }
    return !deepEqual(thirdPartyIntegration, initialIntegration);
}

export function isDiscordIntegrationEdited(discordDetails?: DiscordServerPayload, initialDiscordDetails?: DiscordServerPayload) {
    return !deepEqual(discordDetails, initialDiscordDetails);
}

export function getFacebookAuthUrl(stationId: string, fullUrl: string) {
    const redirectUri = `${fullUrl.split('?')[0]}?tab=${ThirdPartyIntegrationType[ThirdPartyIntegrationType.Facebook]}`;
    return `${BaseUrl()}SocialApi/AuthorizeFacebook?stationId=${stationId}&externalRedirectUri=${encodeURIComponent(
        redirectUri
    )}`;
}

export function getTwitterAuthUrl(stationId: string, fullUrl: string) {
    const redirectUri = `${fullUrl.split('?')[0]}?tab=${ThirdPartyIntegrationType[ThirdPartyIntegrationType.Twitter]}`;
    return `${BaseUrl()}SocialApi/AuthorizeTwitter?stationId=${stationId}&externalRedirectUri=${encodeURIComponent(redirectUri)}`;
}

export function isValidWebhookUrl(str?: string) {
    const res = str?.match(
        '(https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,})'
    );
    return !!res;
}

export function getSampleOutputError(sampleOutputSize: number, maxSampleOutputLength: number) {
    return sampleOutputSize > maxSampleOutputLength
        ? `Sample output length cannot exceed '${maxSampleOutputLength}' characters`
        : '';
}

export function getMessageStructureError(maxMessageStructureLength: number, type: ThirdPartyIntegrationType, message?: string) {
    if (!message) {
        return 'Message cannot be empty.';
    }
    let messageError =
        message.length > maxMessageStructureLength
            ? `Message length cannot exceed '${maxMessageStructureLength}' characters`
            : '';
    if (type === ThirdPartyIntegrationType.Twitter && message.replace(/[^#]/g, '').length > 2) {
        messageError = 'You are only allowed two hashtags (#) in your message';
    }
    return messageError;
}

export function getSampleOutput(
    integrationStructureSelect?: IIntegrationStructureSelect,
    message?: string,
    historyItem?: IntegrationStructureEditor,
    isJsonOutput?: boolean
) {
    if (isJsonOutput && integrationStructureSelect) {
        return JSON.stringify(convertStructureEditorToOutputModel(integrationStructureSelect, historyItem));
    }
    let output = message ?? '';
    const integrationStructureEditorItems = Object.entries(historyItem ?? {});
    for (const [item] of integrationStructureEditorItems) {
        const re = new RegExp(item, 'g');
        const historyItemVal = getSampleOutputItem(item as IntegrationStructureEditorItemKey, historyItem);
        output = output.replace(re, historyItemVal ?? '');
    }
    return removeExtraSpacesAndLineBreaks(output);
}

export function getSampleOutputItem(key: IntegrationStructureEditorItemKey, historyItem?: IntegrationStructureEditor) {
    if (!historyItem) {
        return undefined;
    }
    return historyItem[key];
}

export function linkifyUrls(str: string) {
    return str.replace(/(https?:\/\/[^\s]+)/g, '<a href="$1">$1</a>');
}

export function highlightSpecialWords(str: string, theme: Theme) {
    const wordsStartingWithHash = str.match(/(?:^|\s)(#[a-z0-9]\w*)/gi);
    const wordsStartingWithAt = str.match(/(?:^|\s)(@[a-z0-9]\w*)/gi);
    if (wordsStartingWithHash && wordsStartingWithHash.length > 0) {
        const re = new RegExp(wordsStartingWithHash[0], 'g');
        str = str.replace(
            re,
            `<span style="color: ${theme.palette.primary.light}"><strong>${wordsStartingWithHash[0]}</strong></span>`
        );
    }

    if (wordsStartingWithAt && wordsStartingWithAt.length > 0) {
        const re = new RegExp(wordsStartingWithAt[0], 'g');
        str = str.replace(
            re,
            `<span style="color: ${theme.palette.primary.light}"><strong>${wordsStartingWithAt[0]}</strong></span>`
        );
    }
    return str;
}

export function convertStructureEditorToOutputModel(
    integrationStructureSelect: IIntegrationStructureSelect,
    item?: IntegrationStructureEditor
): IntegrationStructureOutput {
    return {
        album: integrationStructureSelect[IntegrationStructureKey.TrackAlbum]
            ? getSampleOutputItem(IntegrationStructureKey.TrackAlbum, item)
            : undefined,
        artist: integrationStructureSelect[IntegrationStructureKey.TrackArtist]
            ? getSampleOutputItem(IntegrationStructureKey.TrackArtist, item)
            : undefined,
        buyLink: integrationStructureSelect[IntegrationStructureKey.TrackBuyLink]
            ? getSampleOutputItem(IntegrationStructureKey.TrackBuyLink, item)
            : undefined,
        djName: integrationStructureSelect[IntegrationStructureKey.DjName]
            ? getSampleOutputItem(IntegrationStructureKey.DjName, item)
            : undefined,
        duration: integrationStructureSelect[IntegrationStructureKey.TrackDuration]
            ? getSampleOutputItem(IntegrationStructureKey.TrackDuration, item)
            : undefined,
        listenUrl: integrationStructureSelect[IntegrationStructureKey.TrackListenUrl]
            ? getSampleOutputItem(IntegrationStructureKey.TrackListenUrl, item)
            : undefined,
        title: integrationStructureSelect[IntegrationStructureKey.TrackTitle]
            ? getSampleOutputItem(IntegrationStructureKey.TrackTitle, item)
            : undefined,
        website: integrationStructureSelect[IntegrationStructureKey.Website]
            ? getSampleOutputItem(IntegrationStructureKey.Website, item)
            : undefined
    };
}

export function getIntegrationRoute() {
    return SAMCloudRoutes.find((x) => x.path === 'settings')?.children?.find((x) => x.path === 'integration') as IRoute;
}

export function getIntegrationNavigationUrl(stationId: string, selectedTab: ThirdPartyIntegrationType) {
    const params: Array<{ name: UrlSearchParam; value: undefined | string | number | boolean }> = [];
    params.push({
        name: 'tab',
        value: ThirdPartyIntegrationType[selectedTab]
    });
    return createStationRouteUrl(getIntegrationRoute(), stationId, params);
}

export function getFacebookDbModel(
    integration: ThirdPartyIntegration,
    availableFacebookPages: FacebookPage[]
): UpdateFacebookRequest {
    return {
        CloudEnabled: integration.CloudEnabled,
        Frequency: integration.Frequency ?? defaultFrequency,
        FrequencyType: integration.FrequencyType,
        LiveEnabled: integration.LiveEnabled,
        MediaTypes: integration.MediaTypes,
        Message: integration.Message,
        Page: availableFacebookPages.find((x) => x.id === integration.PageId),
        ScheduledEventEnabled: integration.ScheduledEventEnabled,
        ShareAlbumArt: integration.ShareAlbumArt
    };
}

export function getTwitterDbModel(integration: ThirdPartyIntegration): UpdateTwitterRequest {
    return {
        CloudEnabled: integration.CloudEnabled,
        Frequency: integration.Frequency ?? defaultFrequency,
        FrequencyType: integration.FrequencyType,
        LiveEnabled: integration.LiveEnabled,
        MediaTypes: integration.MediaTypes,
        Message: integration.Message,
        ScheduledEventEnabled: integration.ScheduledEventEnabled,
        ShareAlbumArt: integration.ShareAlbumArt,
        OutputNodeSize: integration.SampleOutputSize
    };
}

export function getWebhookDbModel(integration: ThirdPartyIntegration): UpdateWebhookRequest {
    return {
        CloudEnabled: integration.CloudEnabled,
        Frequency: integration.Frequency ?? defaultFrequency,
        FrequencyType: integration.FrequencyType,
        LiveEnabled: false,
        MediaTypes: integration.MediaTypes,
        Message: integration.Message,
        ScheduledEventEnabled: false,
        ShareAlbumArt: integration.ShareAlbumArt,
        Url: integration.Url?.replace(/\s/g, '')
    };
}

export function getTuneInDbModel(integration: ThirdPartyIntegration): TuneInPartner {
    return {
        CloudEnabled: integration.CloudEnabled,
        LiveEnabled: integration.LiveEnabled,
        MediaTypes: integration.MediaTypes,
        PartnerId: integration.PartnerId as string,
        PartnerKey: integration.PartnerKey as string,
        TuneInId: integration.TuneInId as string
    };
}
