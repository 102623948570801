import React, { ChangeEvent, FC } from 'react';
import { AnchorPosition } from '../models/interfaces';
import Lang from '../models/language';
import { useSettings } from '../providers/settings';
import { getColor } from '../utils/colors';
import {
    ColorPalettes,
    SavedThemeOptions,
    borderMarks,
    borderRadiusMax,
    borderRadiusMin,
    borderRadiusStep,
    customizeDrawerWidth,
    defaultThemOptions,
    getClosestSpacingRecommended,
    isSupportedSpacing,
    spacingMarks,
    spacingMax,
    spacingMin,
    spacingStep,
    spacingSupportedMax,
    spacingSupportedMin
} from '../utils/themes';
import BtnIconTooltip from './btn-icon-tooltip';
import ColorSpan from './color-span';
import {
    Alert,
    CachedIcon,
    CloseIcon,
    FormControl,
    FormControlLabel,
    InputLabel,
    KeyboardArrowDownIcon,
    KeyboardArrowLeftIcon,
    KeyboardArrowRightIcon,
    KeyboardArrowUpIcon,
    MenuItem,
    Select,
    Slider,
    Stack,
    Switch,
    Typography,
    useTheme
} from './mui';
import { BaseContainer, Btn, RowContainer, StyledDrawer } from './styled-components';

const ThemeSelector: FC = () => {
    const { settings, setThemeDrawer, setTheme } = useSettings();
    const { borderRadius, colorMode, colorPalette, spacing, drawerAnchor } = settings.themeOptions;

    const fullWidth = drawerAnchor === 'top' || drawerAnchor === 'bottom';

    const theme = useTheme();
    const optionInnerSpacing = theme.spacing(1);
    const optionSpacing = theme.spacing(1);

    const setAndSaveTheme = (changedValue: Partial<SavedThemeOptions>) => {
        const newState: SavedThemeOptions = {
            ...settings.themeOptions,
            ...changedValue
        };
        setTheme(newState);
    };

    return (
        <StyledDrawer anchor={drawerAnchor} open={settings.themeDrawer} onClose={() => setThemeDrawer(false)}>
            <BaseContainer style={{ margin: theme.spacing(2) }}>
                <Stack direction="row" justifyContent="space-between">
                    <FormControlLabel
                        sx={{ mb: optionSpacing, ml: 0 }}
                        labelPlacement="start"
                        control={
                            <Switch
                                checked={colorMode === 'dark'}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                    setAndSaveTheme({ colorMode: event.target.checked ? 'dark' : 'light' });
                                }}
                            />
                        }
                        label="Dark Mode"
                    />
                    <RowContainer>
                        <BtnIconTooltip
                            displayMode="expand"
                            icon={<CachedIcon />}
                            iconButtonProps={{
                                onClick: () => setAndSaveTheme(defaultThemOptions),
                                size: 'small',
                                color: 'secondary',
                                type: 'reset'
                            }}
                        >
                            {Lang.btnReset}
                        </BtnIconTooltip>
                        <BtnIconTooltip
                            displayMode="tooltip"
                            icon={<CloseIcon />}
                            iconButtonProps={{
                                onClick: () => setThemeDrawer(false),
                                size: 'small',
                                sx: { ml: theme.spacing(1) }
                            }}
                        >
                            {Lang.btnClose}
                        </BtnIconTooltip>
                    </RowContainer>
                </Stack>
                <FormControl fullWidth sx={{ mb: optionSpacing, mt: optionSpacing }}>
                    <InputLabel id="demo-simple-select-label">PALETTE</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="PALETTE"
                        value={colorPalette}
                        onChange={(event) => {
                            setAndSaveTheme({ colorPalette: event.target.value as number });
                        }}
                    >
                        {ColorPalettes.map((item, index) => {
                            item.paletteOptions.primary;
                            return (
                                <MenuItem key={index} value={index}>
                                    <ColorSpan color={getColor(item.paletteOptions.primary)}></ColorSpan>
                                    {item.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <Typography sx={{ mb: optionInnerSpacing }}>
                    Border Radius: {borderRadius}
                    {borderRadius === borderRadiusMin && ' (min)'}
                    {borderRadius === defaultThemOptions.borderRadius && ' (default)'}
                    {borderRadius === borderRadiusMax && ' (max)'}
                </Typography>
                <Slider
                    value={borderRadius}
                    marks={borderMarks}
                    step={borderRadiusStep}
                    min={borderRadiusMin}
                    max={borderRadiusMax}
                    valueLabelDisplay="auto"
                    onChange={(_, newValue) => {
                        setAndSaveTheme({ borderRadius: newValue as number });
                    }}
                />
                <Typography sx={{ mb: optionInnerSpacing }}>
                    Spacing: {spacing}
                    {spacing === spacingMin && ' (min)'}
                    {spacing === defaultThemOptions.spacing && ' (default)'}
                    {spacing === spacingMax && ' (max)'}
                </Typography>
                <Slider
                    value={spacing}
                    marks={spacingMarks}
                    step={spacingStep}
                    min={spacingMin}
                    max={spacingMax}
                    valueLabelDisplay="auto"
                    onChange={(_, newValue) => {
                        setAndSaveTheme({ spacing: newValue as number });
                    }}
                />
                {!isSupportedSpacing(spacing) && (
                    <Alert
                        variant="outlined"
                        severity="warning"
                        sx={{
                            mb: optionSpacing,
                            ml: 'auto',
                            mr: 'auto',
                            ...(!fullWidth && { maxWidth: customizeDrawerWidth - 50 })
                        }}
                    >
                        Warning - The spacing selected ({spacing}) might not function correctly on all screens. Recommended
                        spacing is between {spacingSupportedMin}-{spacingSupportedMax}.
                        <Btn
                            variant="text"
                            color="warning"
                            size="small"
                            onClick={() => {
                                setAndSaveTheme({ spacing: getClosestSpacingRecommended(spacing) });
                            }}
                        >
                            Recommended ({getClosestSpacingRecommended(spacing)})
                        </Btn>
                        OR
                        <Btn
                            variant="text"
                            color="warning"
                            size="small"
                            onClick={() => {
                                setAndSaveTheme({ spacing: defaultThemOptions.spacing });
                            }}
                        >
                            Reset ({defaultThemOptions.spacing})
                        </Btn>
                    </Alert>
                )}
                <RowContainer style={{ justifyContent: 'center' }}>
                    {[
                        { position: 'top', icon: <KeyboardArrowUpIcon /> },
                        { position: 'bottom', icon: <KeyboardArrowDownIcon /> },
                        { position: 'left', icon: <KeyboardArrowLeftIcon /> },
                        { position: 'right', icon: <KeyboardArrowRightIcon /> }
                    ].map((item) => (
                        <BtnIconTooltip
                            key={`drawerAnchor-${item.position}`}
                            displayMode="tooltip"
                            icon={item.icon}
                            iconButtonProps={{
                                color: 'secondary',
                                onClick: () => setAndSaveTheme({ drawerAnchor: item.position as AnchorPosition }),
                                size: 'small',
                                sx: { m: theme.spacing(1) },
                                disabled: settings.themeOptions.drawerAnchor === item.position ? true : false,
                                type: 'submit'
                            }}
                        >
                            {`customization display (${item.position})`}
                        </BtnIconTooltip>
                    ))}
                </RowContainer>
            </BaseContainer>
        </StyledDrawer>
    );
};

export default ThemeSelector;
