/**
 * Frequently text labels. Mostly for buttons and hints that occur a lot.
 */
const Lang = {
    // Labels (includes labels for inputs):
    lblAffiliateId: 'Affiliate ID',
    lblConfiguration: 'Configuration',
    lblDismissing: 'Dismissing',
    lblEmail: 'Email Adress',
    lblNone: 'None',
    lblPassword: 'Password',
    lblSearch: 'Search Text',
    lblSearchBox: 'Search',
    lblSignOutApp: 'Sign out application',
    lblStarting: 'Starting',
    lblStationSearch: 'Search for station by name',
    lblStationSettings: 'Settings for station',
    lblStationname: 'Station Name',
    lblStopping: 'Stopping',
    lblUserNotifications: 'User Notifications',
    lblUserNotificationsNone: 'No User Notifications',
    lblUsername: 'Username',
    lblAcknowleging: 'Acknowledging',

    // Button names:
    btnCreate: 'Create',
    btnAdd: 'Add',
    btnApply: 'Apply',
    btnAddTask: 'Add task',
    btnEditTask: 'Edit task',
    btnAcknowledge: 'Acknowledge',
    btnAcknowledgeAll: 'Acknowledge All',
    btnAddAllDays: 'Add all days',
    btnAddScheduledEvent: 'Add Scheduled Event',
    btnAddRule: 'Add Rule',
    btnAddStation: 'Add Station',
    btnAltContent: 'Alternate Content',
    btnApiDocumentation: 'Api Documentation',
    btnAndroidApp: 'Android App',
    btnAuthorize: 'Authorize',
    btnBuyMore: 'Buy More',
    btnCancel: 'Cancel',
    btnClear: 'Clear',
    btnClearSearch: 'Clear search',
    btnClone: 'Clone',
    btnClose: 'Close',
    btnCollapse: 'Collapse',
    btnCollapseMenu: 'Collapse',
    btnContactSupport: 'Contact Support',
    btnContinue: 'Continue',
    btnCreateGptMediaItem: 'Create GPT Media Item',
    btnCreatePlaylist: 'Create Playlist',
    btnCreateNewStation: 'Create new Station',
    btnCustomize: 'Customize Theme',
    btnDiscordChooseServer: 'Choose Server',
    btnDiscordRemoveServer: 'Remove Server',
    btnDiscordStartStreaming: 'Start Streaming',
    btnDiscordStopStreaming: 'Stop Streaming',
    btnDiscordSlot1: 'Slot 1',
    btnDiscordSlot2: 'Slot 2',
    btnDelete: 'Delete',
    btnDeselectAll: 'Deselect All',
    btnDeauthorize: 'Deauthorize',
    btnDiscard: 'Discard',
    btnDiscardItems: 'Discard {count} Item{plural}',
    btnDiscardCurrentItem: 'Discard Current Item',
    btnDiscardChanges: 'Discard Changes',
    btnDismiss: 'Dismiss',
    btnDismissAll: 'Dismiss All',
    btnDownload: 'Download',
    btnEdit: 'Edit',
    btnEditMediaItems: 'Edit Media Item{plural}',
    btnEjectPreview: 'Stop Previewing',
    btnExpand: 'Expand',
    btnExpandMenu: 'Menu',
    btnHidePlayerBarMenu: 'Hide Player Bar',
    btnShowPlayerBarMenu: 'Show Player Bar',
    btnExportHistory: 'Export History',
    btnGenerate: 'Generate',
    btnHelp: 'Help',
    btnHide: 'Hide',
    btnImport: 'Import',
    btnIosApp: 'Ios App',
    btnItunesAffiliate: 'Earn commission on sales.',
    btnLessInfo: 'Less Info',
    btnLibLayout: 'Library Layout',
    btnLoop: 'Loop',
    btnMoreMenuOptions: 'More Menu Options',
    btnMinimize: 'Minimize',
    btnMute: 'Mute',
    btnMoreDetails: 'More Details',
    btnMoreInfo: 'More Info',
    btnNext: 'Next',
    btnNextMediaItem: 'Next Media Item',
    btnNotificationSettings: 'Notification Settings',
    btnOffAir: 'Off Air',
    btnOnAir: 'On Air',
    btnOpenPlayerSite: 'Open Player Site',
    btnOpenShareCurrentInfo: 'Share currently playing info',
    btnOptions: 'Options',
    btnOk: 'Ok',
    btnOverwrite: 'Overwrite',
    btnPause: 'Pause',
    btnPlay: 'Play',
    btnSearchMediaItem: 'Search',
    btnSearchAlbumCoverArt: 'Search',
    btnStop: 'Stop',
    btnPreview: 'Preview',
    btnPrev: 'Previous',
    btnPostNow: 'Post Now',
    btnPrevious: 'Previous Media Item',
    btnProvideFeedback: 'Provide Feedback',
    btnReauthorize: 'Re-authorize',
    btnRefresh: 'Refresh',
    btnRemoveAllDays: 'Remove all days',
    btnRemoveStation: 'Remove Station',
    btnReset: 'Reset',
    btnResetToValid: 'Reset to valid',
    btnRestoreDefault: 'Restore default',
    btnRetry: 'Retry',
    btnSave: 'Save',
    btnSaveItems: 'Save {count} Item{plural}',
    btnSaveChanges: 'Save Changes',
    btnSend: 'Send',
    btnSelect: 'Select',
    btnSelectAll: 'Select All',
    btnSelectList: 'Select List',
    btnSelectAnotherStation: 'Select Another Station',
    btnSettings: 'Settings',
    btnGeneralSettings: 'General Settings',
    btnShareFacebook: 'Share',
    btnShareTweet: 'Tweet',
    btnShow: 'Show',
    btnShowHelp: 'Show help',
    btnShowValidationHelp: 'Show validation help',
    btnSignIn: 'Sign In',
    btnSignOut: 'Sign Out',
    btnSignUp: 'Sign Up',
    btnSkip: 'Skip Track',
    btnStationSettings: 'Station Settings',
    btnStart: 'Start',
    btnStartStation: 'Start Station',
    btnStopStation: 'Stop Station',
    btnTableMenu: 'Columns',
    btnTableSettings: 'Table Settings',
    btnUpdateStationDetails: 'Update Station Details',
    btnUpload: 'Upload',
    btnUploadFiles: 'Upload Files',
    btnUploadPlaylist: 'Upload Playlist',
    btnValidate: 'Validate',
    btnVerifySettings: 'Verify Settings',
    btnView: 'View',
    btnAddDj: 'Add DJ',
    btnRemove: 'Remove',
    btnRemovePermission: 'Remove Permission',
    btnReplaceDJ: 'Replace DJ',
    btnStationOnly: ' Station Only',
    btnUserGuide: 'User Guide',
    btnUnmute: 'Unmute',
    btnUserNotifications: 'User Notifications',

    // Actions:
    aAdding: 'Adding',
    aAddTo: 'Add To',
    aAlternateContent: 'Alternate Content',
    aBottom: 'Bottom',
    aBrowsable: 'Browsable',
    aBrowsableDisable: 'Disable Browsing',
    aBrowsableEnable: 'Enable Browsing',
    aClearHistory: 'Clear History',
    aClearHistoryPermanently: 'Permanently Clear History',
    aCreate: 'Create',
    aColor: 'Choose Color',
    aDefault: 'Default',
    aDown: 'Down',
    aEdit: 'Edit',
    aExport: 'Export',
    aExportPlaylistFile: 'Export Playlist File',
    aGptMediaItem: 'GPT Media Item',
    aImport: 'Import',
    aImportPlaylist: 'Import Playlist',
    aIntroTrack: 'Intro Track',
    aMoveTo: 'Move To',
    aNewPlaylist: 'New Playlist',
    aNo: 'No',
    aPreviewInPlayer: 'Preview in Player',
    aRecycleBin: 'Move To Recycle Bin',
    aRefresh: 'Refresh',
    aRemoveDuplicates: 'Remove Duplicates',
    aRemovePlaylist: 'Remove Playlist',
    aRemovePermanently: 'Permanently Remove From Library',
    aRemove: 'Remove',
    aRemoveSelected: 'Remove Selected',
    aRemoveUnSelected: 'Remove UnSelected',
    aRemoving: 'Removing',
    aRenamePlaylist: 'Rename Playlist',
    aRestore: 'Restore',
    aSetAs: 'Set As',
    aTop: 'Top',
    aUp: 'Up',
    aUpdating: 'Updating',
    aUploadFiles: 'Upload Files',
    aYes: 'Yes',

    // Route Titles:
    rAudioSettings: 'Audio',
    rBilling: 'Billing / Services',
    rChat: 'Chat',
    rChatPreview: 'Chat Preview',
    rDJManagement: 'DJ Management',
    rEvents: 'Events',
    rGeoblocking: 'Geoblocking',
    rHealth: 'Health',
    rHome: 'Home',
    rIntegration: 'Integration',
    rLibrary: 'Library',
    rListenLink: 'Listen Link',
    rLiveControl: 'Live Control',
    rListenerStats: 'Stats',
    rLoading: 'Loading',
    rLogin: 'Login',
    rMaintenance: 'Maintenance',
    rPlayblocks: 'Playblocks',
    rProfile: 'Profile',
    rRequestPolicy: 'Request Policy',
    rShowCity: 'Show City',
    rSchedule: 'Schedule',
    rScheduledEvents: 'Scheduled Events',
    rSeparationRules: 'Separation Rules',
    rSelected: 'Select',
    rSettings: 'Settings',
    rStationDetails: 'Station Details',
    rStationSelect: 'Station Select',
    rStationSettings: 'Station Settings',
    rWidgets: 'Widgets',
    rWebWidgets: 'Web Widgets',
    rWebToolsAndWidgets: 'Web Tools',
    rStreaming: 'Streaming',
    rUpload: 'Upload',

    // Titles (Currently from the Library page):
    tFilters: 'Filters',
    tFolder: 'Folder',
    tHistory: 'History',
    tLibrary: 'Library',
    tLibraryTree: 'Library',
    tPlaylist: 'Playlist',
    tPlaylists: 'Playlists',
    tQueue: 'Queue',
    tShuffleQueue: 'Shuffle Queue',
    tShufflePlaylist: 'Shuffle Playlist',
    tUserSelected: 'User Selected Item',
    tWebWidgets: 'Generate Web Widgets',

    // Success/error messages:
    msgCustomColorSelected: 'Make sure the preview matches the custom selected color.',
    msgAcknowledgeNotifications: 'Notification(s) have been acknowledged.',
    msgAddingMovingError: 'There was an error adding/moving items.',
    msgAddItemToPlaylistError: 'Error occurred adding items to playlist',
    msgAddItemsHere: 'Add item(s) here.',
    msgAudioSettingsEdit: 'Apply changes?',
    msgApplyChangesUnAuthorized: 'You are not permitted to apply changes',
    msgBetaFeature: 'This feature is in BETA',
    msgBreakNoteError: "Break Note can't be empty",
    msgConfirmationStationStop: "Are you certain that you want to stop station '{stationName}'?",
    msgDeletePermanentlyQueuePlaylistItems: 'Are you certain that you want to remove the media item{plural} completely?',
    msgErrorCoverPicture: 'There was a problem loading the cover image.',
    msgRecyclePromoJingleMediaItems:
        'Are you certain that you want to recycle the media item? Tracks of the Promo/Jingle media type will also be removed from all Tracks where it is used as a Leading or Trailing track.',
    msgRecycleMediaItems: 'Are you certain that you want to move this track to the recycle bin?',
    msgDismissNotifications: 'Notification(s) have been dismissed.',
    msgDoubleClickInfo: 'Double click to view item.',
    msgEmpty: 'No items',
    msgExportingPlaylistFile: 'We will notify you once the Export is ready to be downloaded.',
    msgGenericRequestError: 'There was an error, please try again later.',
    msgHistoryExported: 'We will notify you once the History Export is ready to be downloaded.',
    msgHistoryItemsInfo:
        'Note: a performance count of -1 indicates that we could not accurately establish the performance count at that time.',
    msgIntroTrackChanged: 'Successfully changed Intro Track.',
    msgAlternateTrackChanged: 'Successfully changed Alternate Content Track.',
    msgLeadingTrailingSelectTracks:
        'Select tracks to play before and after this track. The selected track must be of the Promo/Jingle/Voice Tracking media type.',
    msgLoggedOut: 'You have been logged out.',
    msgLogoutVerificationError: 'Session expired, you have been logged out.',
    msgLoopLimit: 'You cannot enable loop queue if the duration of the items in the queue are < 1 min',
    msgMediaItemNotEligible: 'Only available for tracks with a duration of less than 1 minute.',
    msgNoListData: 'There are no items to display.',
    msgNoListDataFiltered: 'There are no items to display using the current search criteria.',
    msgNotYetImplemented: 'Feature is not yet implemented.',
    msgPermanentlyClearHistory:
        'You are about to clear your entire history. This cannot be undone - are you sure you want to do this?',
    msgPlaylistNotSelected: 'Playlist not selected.',
    msgReplaceExistingAlternateContent:
        'Are you certain that you would like to replace the existing alternate content track? {artist} - {title} ({duration})',
    msgReplaceExistingIntroTrack:
        'Are you certain that you would like to replace the existing intro track? {artist} - {title} ({duration})',
    msgMoveQueuePlaylistItemsToRecycleBin: 'Are you certain that you want to move the track{plural} to the recycle bin?',
    msgRecreateFSM: 'Re-create Free Spacial Media in your Library?',
    msgRemoveFSM: 'Are you sure you would like to remove ALL Free Spacial Media from your library?',
    msgRequestPasswordError: 'There was an error requesting the password reset.',
    msgRequestPasswordSuccess: 'Password reset successfully requested.',
    msgStationInfoNotLoaded: "Station information wasn't loaded yet",
    msgInvalidOverlapDurationEvents:
        'The show is already inside the overlap duration. Please decrease the overlap duration or change the show time to a later date.',
    msgInvalidRepeatMinutes: 'End time earlier than start time.',
    msgInvalidWeekdays: 'Please select at least one repeat weekday.',
    msgInvalidRepeatHours: 'Please select at least one repeat hour.',
    msgImportPlaylistInfo:
        'Importing playlist via browser will only add items found in library. No missing files will be imported. Use Library Import Utility if you wish to physically import tracks.',
    msgStationStartedStopped: "Station '{stationName}' {stationStatus}.",
    msgSkipDelay: 'The track will skip in a couple of seconds.'
};

export default Lang;
