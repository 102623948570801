import { Dispatch, SetStateAction } from 'react';
import { submitExportRequest } from '../../middleware/history';
import { getMediaTypes } from '../../middleware/media-item';
import { MediaType } from '../../models/dto';
import { FnVoid } from '../../models/interfaces';
import Lang from '../../models/language';
import { Notification } from '../../providers/notifications';
import {
    filterInputIndex,
    initInputValues,
    ItemInputs,
    periodIndex,
    reportTypeIndex,
    SelectItemDataType,
    timeZoneIndex
} from './consts';

export function buildReportPeriods(): SelectItemDataType[] {
    const date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth();
    const monthNames: SelectItemDataType[] = [];

    for (let i = 0; i < 12; i++) {
        const dateString = mapMonthIndexToName(month) + ' ' + year;
        monthNames.push({ id: i.toString(), value: { value: dateString } });

        month--;
        if (month === -1) {
            month = 11;
            year -= 1;
        }
    }
    return monthNames;
}

function mapMonthIndexToName(index) {
    if (index >= 0 && index <= 12) {
        return [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ][index];
    }
    return '';
}

export function buildTimeZone(): SelectItemDataType[] {
    const zoneValues: SelectItemDataType[] = [];
    let zone = -11;
    for (let i = 0; i < 23; i++) {
        const title = 'UTC' + (zone == 0 ? '' : zone > 0 ? '+' + zone : zone);
        zoneValues.push({ id: i.toString(), value: { title, value: zone } });
        zone++;
    }
    return zoneValues;
}

export async function fetchMediaTypes(
    stationId: string,
    addNotification: (notification: Notification) => void,
    setInputs: Dispatch<SetStateAction<ItemInputs>>,
    setIsLoading: Dispatch<SetStateAction<boolean>>
) {
    setIsLoading(true);
    const res = await getMediaTypes({ stationId });
    const initFilterInput = initInputValues[filterInputIndex];
    if (res.success && res.data) {
        // Option for all appended:
        res.data = [
            {
                MediaTypeId: '00000000-0000-0000-0000-000000000000',
                TypeCode: 'ALL',
                TypeName: 'All'
            } as MediaType,
            ...res.data
        ];
        setInputs((prevState) => {
            // Merge the data:
            const newData: SelectItemDataType[] = res.data.map((item) => {
                return { id: item.MediaTypeId, value: { title: item.TypeName, value: item.TypeCode } };
            });
            const curSelected = initFilterInput.value.selected;
            prevState[filterInputIndex] = {
                ...initFilterInput,
                value: {
                    ...initFilterInput.value,
                    data: newData,
                    selected: curSelected ? curSelected : newData[0] // Select first item by default
                }
            };
            return [...prevState];
        });
    } else {
        addNotification(
            new Notification({
                message: res.message,
                severity: 'error'
            })
        );
    }
    setIsLoading(false);
}

export function exportRequest(
    stationId: string,
    inputs: ItemInputs,
    addNotification: (notification: Notification) => void,
    onClose: FnVoid | undefined,
    setIsLoading: Dispatch<SetStateAction<boolean>>
) {
    setIsLoading(true);

    const aggregate = inputs[reportTypeIndex].value.selected?.id === '1' ? true : false; // Assuming '1' is "Aggregate Report".
    const mediaTypeCodeValue = inputs[filterInputIndex].value.selected?.value.value;
    const mediaTypeCodes = mediaTypeCodeValue === 'ALL' ? 'null' : (mediaTypeCodeValue as string);
    const startDate = inputs[periodIndex].value.selected?.value.value as string;
    const utcOffset = inputs[timeZoneIndex].value.selected?.value.value as string;

    onClose && onClose();
    addNotification(
        new Notification({
            message: Lang.msgHistoryExported,
            severity: 'info'
        })
    );
    submitExportRequest({ stationId, startDate, mediaTypeCodes, aggregate, utcOffset }).catch((msg) => {
        addNotification(
            new Notification({
                message: msg,
                severity: 'error'
            })
        );
    });

    setIsLoading(false);
}
