import React, { FC, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { HashRouter } from 'react-router-dom';
import MainAppBar from '../../components/main-app-bar';
import MenuDrawer from '../../components/menu-drawer';
import { styled, useMediaQuery, useTheme } from '../../components/mui';
import NotificationContainer from '../../components/notification-container';
import PlayerBar from '../../components/player-bar';
import { ColContainer, RowContainer, allowCustomProps } from '../../components/styled-components';
import { getIcon } from '../../images/image';
import { verifySessionInterval } from '../../models/time';
import { useAccount } from '../../providers/account';
import { useSettings } from '../../providers/settings';
import { useEffectAsync } from '../../utils/react-util';
import { EntityMessageType, TableEntity } from '../../utils/signalr/models';
import { useSignalRMultipleEntities } from '../../utils/signalr/utils';
import { getGlobalScrollStyle } from '../../utils/style';
import { mainDrawerWidth, mainDrawerWidthSmall } from '../../utils/themes';
import { getIsStarted } from '../station/live-control/general';
import { ISignalRLiveControlMessage } from '../station/live-control/models/interfaces';
import { RoutingProvider, StationData } from './provider';

const Main = styled(
    'main',
    allowCustomProps(['open'])
)<{
    open: boolean;
}>(({ open, theme }) => {
    return {
        position: 'relative',
        flex: 1,
        overflowY: 'auto',
        padding: theme.spacing(1),
        width: mainDrawerWidthSmall,
        ...(open && {
            width: mainDrawerWidth
        }),
        ...getGlobalScrollStyle(theme)
    };
});

export const signalREntities: Array<TableEntity> = ['SourceStateRefreshMessage'];

export const Router: FC = () => {
    const theme = useTheme();
    const account = useAccount();
    const { settings, setMainDrawer } = useSettings();
    const [stationData, setStationData] = useState<StationData>();
    const stationIsStarted = useMemo(() => Boolean(stationData && stationData.stationInfo?.IsStarted), [stationData]);

    const xs = useMediaQuery(theme.breakpoints.down('sm'));
    useEffect(() => {
        // When screen becomes extra small, close the drawer.
        xs && setMenuDrawerOpen(false);
    }, [xs]);

    useEffectAsync(async () => {
        let intervalObj;
        if (account.accountState.loggedIn) {
            intervalObj = setInterval(async () => {
                await account.verifySession();
            }, verifySessionInterval) as unknown;
        } else {
            if (intervalObj) {
                clearInterval(intervalObj);
            }
            // Verify once (Fire and Forget, if signed out, the state will change accordingly):
            await account.loginSession();
        }

        return () => {
            if (intervalObj) {
                clearInterval(intervalObj);
            }
        };
    }, [account.accountState.loggedIn]);

    const onSignalRMessageReceived = (messageType: EntityMessageType, message: ISignalRLiveControlMessage) => {
        if (messageType == 'SourceStateRefreshMessage' && message.SourceState) {
            setStationData((prevState) => {
                const isStarted = message.SourceState?.Sources
                    ? message.SourceState.Sources.some((item) => getIsStarted(item))
                    : false;
                if (prevState?.stationInfo) {
                    prevState.stationInfo.IsStarted = isStarted;
                }
                return { ...prevState };
            });
        }
    };

    useSignalRMultipleEntities(
        stationData?.stationId,
        signalREntities,
        async (messageType: EntityMessageType, message: ISignalRLiveControlMessage) => {
            await onSignalRMessageReceived(messageType, message);
        }
    );

    const setMenuDrawerOpen = (value: boolean) => {
        setMainDrawer(value);
    };

    const onSetStationData = (stationData: StationData) => {
        setStationData(stationData);
    };

    return (
        <HashRouter>
            {account.accountState.brand && (
                <Helmet>
                    <link rel="shortcut icon" href={getIcon(account.accountState.brand.FavIconName).src} />
                </Helmet>
            )}
            <NotificationContainer />
            <ColContainer className="fullHeight" sx={{ overflow: 'hidden' }}>
                {account.accountState.loggedIn && <MainAppBar stationIsStarted={stationIsStarted} stationData={stationData} />}
                <RowContainer className="fullHeight" sx={{ overflow: 'hidden', flex: 1 }}>
                    {account.accountState.loggedIn && (
                        <MenuDrawer
                            stationIsStarted={stationIsStarted}
                            open={settings.mainDrawer}
                            setMenuDrawerOpen={setMenuDrawerOpen}
                            xs={xs}
                        />
                    )}
                    <Main open={settings.mainDrawer} className="main">
                        <RoutingProvider onSetStationData={onSetStationData} />
                    </Main>
                </RowContainer>
                {stationData?.stationId && <PlayerBar stationIsStarted={stationIsStarted} stationData={stationData} />}
            </ColContainer>
        </HashRouter>
    );
};

export default Router;
