import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { exportListenerStats } from '../../middleware/listener-stats';
import { SelectItem, Void } from '../../models/interfaces';
import Lang from '../../models/language';
import { DialogProps } from '../../models/props';
import { Notification, useNotification } from '../../providers/notifications';
import { useStationId } from '../../utils/router-util';
import { minWidthInputSize } from '../dialog-edit-media-item/consts';
import { DialogDraggableTitle, getDialogPaperComponent } from '../draggable-paper';
import Dropdown from '../dropdown';
import { Dialog, DialogActions, DialogContent, List, ListItem, ListItemText, Paper, SelectChangeEvent } from '../mui';
import { Btn } from '../styled-components';
import { PeriodType, TimezoneType, intervalStore } from './consts';
import { getPeriodStore, getTimezoneStore } from './utils';
import LoadingBtn from '../loading-btn';

const dragTitleId = 'draggable-export-statistics-dialog-title';

const DialogExportStatistics: FC<DialogProps> = ({ closable, draggable, open, onClose }) => {
    const { addNotification } = useNotification();
    const [isLoading, setIsLoading] = useState(false);

    const isDraggable = draggable === undefined || draggable;
    const isClosable = closable === undefined || closable;
    const dialogPaperComponent = useRef(getDialogPaperComponent(dragTitleId)).current;

    const [interval, setInterval] = useState<SelectItem<string, string>>(intervalStore[0]);
    const periodStore = useMemo(() => getPeriodStore(interval), [interval]);
    const timezoneStore = useMemo(() => getTimezoneStore(), []);
    const [period, setPeriod] = useState<PeriodType>(periodStore[0]); // Dependent on interval
    const [timezone, setTimezone] = useState<TimezoneType>(timezoneStore[11]); // Initial is 11 (UTC without offset)

    const location = useLocation();
    const stationId = useStationId(location);

    useEffect(() => {
        // Current period gets irrelevant when interval changes:
        setPeriod(periodStore[0]);
    }, [interval]);

    const onExport = async () => {
        setIsLoading(true);
        if (stationId) {
            const res = await exportListenerStats(stationId, period, timezone.offset, interval);
            if (res.success) {
                onClose && onClose();
            } else {
                addNotification(
                    new Notification({
                        message: res.message,
                        severity: 'error'
                    })
                );
            }
        }
        setIsLoading(false);
    };

    const renderListItem = (
        label: string,
        store: SelectItem<string, string>[],
        onChangeCallback: Void<SelectChangeEvent>,
        id: string
    ) => {
        return (
            <ListItem divider>
                <ListItemText primary={label} />
                <Dropdown sx={{ width: minWidthInputSize }} label={label} items={store} onChange={onChangeCallback} value={id} />
            </ListItem>
        );
    };

    return (
        <Dialog
            open={open}
            onClose={() => {
                isClosable && onClose && onClose();
            }}
            PaperComponent={isDraggable ? dialogPaperComponent : Paper}
            aria-labelledby={dragTitleId}
        >
            <DialogDraggableTitle componentId={dragTitleId} dialogTitle="Statistics Export" draggable={isDraggable} />
            <DialogContent sx={{ p: 2, overflow: 'hidden', minWidth: 385, maxWidth: 500 }}>
                <List>
                    {renderListItem(
                        'Interval',
                        intervalStore,
                        (event: SelectChangeEvent) => {
                            const item = intervalStore.find((item) => {
                                return item.id === event.target.value;
                            });
                            if (item) {
                                setInterval(item);
                            }
                        },
                        interval.id
                    )}
                    {renderListItem(
                        'Period',
                        periodStore.map(
                            (item) => ({ id: item.index.toString(), value: item.description }) as SelectItem<string, string>
                        ),
                        (event: SelectChangeEvent) => {
                            const item = periodStore.find((item) => {
                                return item.index.toString() === event.target.value;
                            });
                            if (item) {
                                setPeriod(item);
                            }
                        },
                        period.index.toString()
                    )}
                    {renderListItem(
                        'Time Zone',
                        timezoneStore.map((item) => ({ id: item.index, value: item.description }) as SelectItem<string, string>),
                        (event: SelectChangeEvent) => {
                            const item = timezoneStore.find((item) => {
                                return item.index.toString() === event.target.value;
                            });
                            if (item) {
                                setTimezone(item);
                            }
                        },
                        timezone.index
                    )}
                </List>
            </DialogContent>
            <DialogActions>
                <Btn variant="text" size="small" onClick={onClose} disabled={isLoading}>
                    {Lang.btnClose}
                </Btn>
                <LoadingBtn
                    buttonProps={{
                        size: 'small',
                        variant: 'contained',
                        type: 'submit',
                        onClick: onExport
                    }}
                    loading={isLoading}
                >
                    {Lang.aExport}
                </LoadingBtn>
            </DialogActions>
        </Dialog>
    );
};

export default DialogExportStatistics;
