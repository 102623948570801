import { BigInteger } from 'jsbn';

export const ResourcePermissions = {
    none: new BigInteger('0'),
    owner: new BigInteger('1'),
    editMetaData: new BigInteger('16'),
    importMedia: new BigInteger('32'),
    removeMedia: new BigInteger('64'),
    editPlaylists: new BigInteger('128'),
    mobileLiveStreaming: new BigInteger('512'),
    adminPlaylists: new BigInteger('256'),
    adminPlaylistsImplicit: new BigInteger('384'),
    startStopStation: new BigInteger('65536'),
    editStationInfo: new BigInteger('131072'),
    historyReport: new BigInteger('262144'),
    samBroadcasterWebIntegration: new BigInteger('524288'),
    sourceSwitching: new BigInteger('1048576'),
    scheduledStreamingDJ: new BigInteger('67108864'),
    listenerStatistics: new BigInteger('134217728'),
    skipTrack: new BigInteger('2097152'),
    editScheduledEvents: new BigInteger('4194304'),
    editRotationRules: new BigInteger('8388608'),
    editQueue: new BigInteger('16777216'),
    editScheduledEventsImplicit: new BigInteger('31522944'),
    access: new BigInteger('33554432'),
    all: new BigInteger('9223372036854775807'),
    allServices: new BigInteger('1008'), // front end only
    allStationsWithLiveStreaming: new BigInteger('200605696'), // front end only
    allStationsWithScheduledStreaming: new BigInteger('266665984') // front end only
};

export default class Security {
    userRights: BigInteger;
    hasStreamingRights: boolean;
    rightsCache = {};

    constructor(dbPermissions: string, hasStreamingRights = false) {
        dbPermissions = dbPermissions ?? '0';
        this.userRights = new BigInteger(dbPermissions.toString());
        this.hasStreamingRights = hasStreamingRights;
    }

    hasPermissions(userPermission) {
        let bigintUserPermission: BigInteger;
        if (typeof userPermission == 'number' || typeof userPermission == 'string') {
            bigintUserPermission = new BigInteger('' + userPermission, 10);
        } else {
            bigintUserPermission = userPermission;
        }
        if (!this.rightsCache[bigintUserPermission.toString()]) {
            this.rightsCache[bigintUserPermission.toString()] =
                this.userRights.toString() == bigintUserPermission.toString() ||
                this.userRights.and(bigintUserPermission).toString() == bigintUserPermission.toString();
        }
        return userPermission == ResourcePermissions.sourceSwitching
            ? this.hasStreamingRights
            : this.rightsCache[bigintUserPermission.toString()];
    }
}
