import React, { createContext, FC, useContext, useState } from 'react';
import { MediaItemDto } from '../models/dto';
import { Void } from '../models/interfaces';
import { ParentDom } from '../models/props';

export interface IStationContext {
    previewMediaItem?: MediaItemDto;
    /**
     * Set and play preview media item directly.
     */
    togglePlayPreviewMediaItem: Void<MediaItemDto | undefined>;
}

const initAccountContext: Partial<IStationContext> = {};

const StationContext = createContext<IStationContext>(initAccountContext as IStationContext);

export function useStation() {
    return useContext(StationContext);
}

/**
 * TODO: Investigate moving Station Related items (such as station details and stationId) functionality into this provider.
 */
export const StationProvider: FC<ParentDom> = ({ children }) => {
    const [previewMediaItem, setPreviewMediaItem] = useState<MediaItemDto>();

    const value: IStationContext = {
        previewMediaItem,
        togglePlayPreviewMediaItem: (mediaItem?: MediaItemDto) => {
            setPreviewMediaItem(mediaItem);
        }
    };

    return <StationContext.Provider value={value}>{children}</StationContext.Provider>;
};
