import React, { FC } from 'react';
import { TableRowProps } from 'react-virtualized/dist/es/Table';
import { PlaceholderItem } from '../../models/interfaces';
import Lang from '../../models/language';
import { Box, LinearProgress, useTheme } from '../mui';
import { TableRowStyling } from '../../utils/table-row-styling';
import { ColContainer } from '../styled-components';

export const DynamicPlaceholderTableRow: FC<TableRowProps> = ({ className, index, rowData, style }) => {
    const theme = useTheme();
    const rowCSS = new TableRowStyling({
        index,
        theme,
        rowChecked: true,
        rowDragging: false,
        dragAboveItemActive: false,
        dragBelowItemActive: false
    });

    const placeholderItem = rowData as PlaceholderItem;

    let placeholderSummary;
    let color;
    let textAction = 'Adding';
    const action = placeholderItem && placeholderItem.action;

    if (placeholderItem && placeholderItem.description) {
        if (placeholderItem.description) {
            if (action === 'add') {
                color = 'secondary';
                textAction = Lang.aAdding;
            } else if (action === 'remove') {
                color = 'error';
                textAction = Lang.aRemoving;
            } else {
                color = 'warning';
                textAction = Lang.aUpdating;
            }
            placeholderSummary = `<strong>${textAction}</strong> - ${placeholderItem.description}`;
        }
        if (action !== 'remove' && placeholderItem.status === 'pending') {
            color = 'info';
        }
    }

    const containerProps = {
        'aria-rowindex': index + 1,
        className,
        role: 'row',
        style: {
            ...style,
            ...rowCSS.getFinalStyling()
        }
    };

    return (
        <ColContainer {...containerProps}>
            {placeholderSummary && (
                <div
                    style={{ width: '90%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                    dangerouslySetInnerHTML={{ __html: placeholderSummary }}
                ></div>
            )}
            <Box sx={{ width: '100%' }}>
                <LinearProgress color={color} />
            </Box>
        </ColContainer>
    );
};

export default DynamicPlaceholderTableRow;
