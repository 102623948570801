import { Connection } from 'signalr-no-jquery';
import { DiscordServerPayload } from '../../pages/station/settings/integration/models/interfaces';

export interface SignalRSetup {
    stationId?: string;
    stationHubActive: boolean;
    userHubActive: boolean;
    libraryHubActive: boolean;
}

export interface SignalRItem<T> {
    Id: string;
    Data: T;
}

export interface SignalRMessage {
    MessageType: EntityMessageType;
    Table: TableEntity;

    // When MessageType = EntityDeletedMessage
    DeletedItems?: SignalRItem<unknown>[];
    // When MessageType = EntityInsertedMessage
    InsertedItems?: SignalRItem<unknown>[];
    // When MessageType = EntityUpdatedMessage
    UpdatedItems?: SignalRItem<unknown>[];
    // When MessageType = DiscordServerIntegrationUpdateMessage
    discordServerPayload?: DiscordServerPayload;
}

/**
 * When SharedSetting for SignalR message of Loop Queue changes.
 */
export interface SignalRLoopQueueData {
    SharedSettingId: string;
    Name: string;
    Value: string;
    StationReferenceId: number;
}

/**
 * Special Signal R Message Definition
 */
export declare type OnSignalRMessage<T> = (messageType: EntityMessageType, message: T) => Promise<void> | void;

/**
 * Object that
 */
export interface TableEntityCallbacks {
    // Library:
    HistoryItem: OnSignalRMessage<never>[];
    PlaylistCategoryItem: OnSignalRMessage<never>[];
    PlaylistCategoryItemRow: OnSignalRMessage<never>[];
    PlaylistItem: OnSignalRMessage<never>[];
    QueueItem: OnSignalRMessage<never>[];
    RequestItem: OnSignalRMessage<never>[];
    RequestAggregateItem: OnSignalRMessage<never>[];
    MediaItem: OnSignalRMessage<never>[];
    NotificationItem: OnSignalRMessage<never>[];
    // Live Control:
    FileProcessingCompletedMessage: OnSignalRMessage<never>[];
    RssUrlProcessingMessage: OnSignalRMessage<never>[];
    RssUrlCreateFileMessage: OnSignalRMessage<never>[];
    RssUrlDeleteMessage: OnSignalRMessage<never>[];
    SharedSetting: OnSignalRMessage<never>[];
    HistoryTouchMessage: OnSignalRMessage<never>[];
    SourceStateRefreshMessage: OnSignalRMessage<never>[];
    Clockwheel: OnSignalRMessage<never>[];
    ClockwheelLineItem: OnSignalRMessage<never>[];
    RelayStatusUpdateMessage: OnSignalRMessage<never>[];
    RefreshStationRelaysMessage: OnSignalRMessage<never>[];
    SeparationRule: OnSignalRMessage<never>[];
    StationDetailsUpdatedMessage: OnSignalRMessage<never>[];
    MaxDjLoginsUpdatedMessage: OnSignalRMessage<never>[];
    UserPermissionsUpdatedMessage: OnSignalRMessage<never>[];
    ThirdPartyIntegrationUpdateMessage: OnSignalRMessage<never>[];
    RequestPolicyRefreshMessage: OnSignalRMessage<never>[];
    // HealthNotification:
    ServerLogEntry: OnSignalRMessage<SignalRMessage>[];
    // DiscordIntegration:
    DiscordServerIntegrationUpdateMessage: OnSignalRMessage<never>[];
    // Listener Stats Events:
    ListenerStatsEvent: OnSignalRMessage<never>[];
    // Listener Stats Status
    ListenerStats: OnSignalRMessage<never>[];
    // Recycle Bin Events:
    RecycledMediaItem: OnSignalRMessage<never>[];
    // Chat Events:
    BlockedMessage: OnSignalRMessage<never>[];
    UpdateChatUserListMessage: OnSignalRMessage<never>[];
    ChatUpdateChatDetailsMessage: OnSignalRMessage<never>[];
    // Library table colours
    MediaTypeColorUpdated: OnSignalRMessage<never>[];
}

export const initTableEntityCallbacks: TableEntityCallbacks = {
    // Library:
    HistoryItem: [],
    PlaylistCategoryItem: [],
    PlaylistCategoryItemRow: [],
    PlaylistItem: [],
    QueueItem: [],
    RequestItem: [],
    RequestAggregateItem: [],
    MediaItem: [],
    NotificationItem: [], // E.g. For Export History, notify popup.
    // Live Control:
    FileProcessingCompletedMessage: [],
    RssUrlProcessingMessage: [],
    RssUrlCreateFileMessage: [],
    RssUrlDeleteMessage: [],
    SharedSetting: [],
    HistoryTouchMessage: [],
    SourceStateRefreshMessage: [],
    // Playblocks
    Clockwheel: [],
    ClockwheelLineItem: [],
    // Streaming
    RelayStatusUpdateMessage: [],
    RefreshStationRelaysMessage: [],
    // Separation rules
    SeparationRule: [],
    // Station details
    StationDetailsUpdatedMessage: [],
    MaxDjLoginsUpdatedMessage: [],
    UserPermissionsUpdatedMessage: [],
    ThirdPartyIntegrationUpdateMessage: [],
    RequestPolicyRefreshMessage: [],
    // HealthNotification:
    ServerLogEntry: [],
    // DiscordIntegration:
    DiscordServerIntegrationUpdateMessage: [],
    // Listener Stats Events:
    ListenerStatsEvent: [],
    // Listener Stats Status
    ListenerStats: [],
    // Recycle Bin Events:
    RecycledMediaItem: [],
    // Chat Events:
    BlockedMessage: [],
    UpdateChatUserListMessage: [],
    ChatUpdateChatDetailsMessage: [],
    // Library table colours
    MediaTypeColorUpdated: []
};

/**
 * The third party package types doesn't contain all of the functions and options.
 * This is the extended version of the javascript Signal R 1.6.4 interface.
 */
export interface ExtendedConnection extends Connection {
    /**
     * Last error from signal R, e.g. disconnect reason.
     */
    lastError: unknown;
    /**
     * State options: { 0: 'connecting', 1: 'connected', 2: 'reconnecting', 4: 'disconnected' }
     */
    state: number;
    /**
     * Set Signal R error callback.
     */
    error: (callback: () => void) => void;
    /**
     * Query string to send additional information.
     */
    qs?: string;
}

export declare type EntityMessageType =
    | 'DiscordServerIntegrationUpdateMessage'
    | 'EntityDeletedMessage'
    | 'EntityInsertedMessage'
    | 'EntityUpdatedMessage'
    | 'RefreshItemsMessage'
    | 'FileProcessingCompletedMessage'
    | 'RssUrlProcessingMessage'
    | 'RssUrlCreateFileMessage'
    | 'RssUrlDeleteMessage'
    | 'SharedSetting'
    | 'HistoryTouchMessage'
    | 'SourceStateRefreshMessage'
    | 'RelayStatusUpdateMessage'
    | 'RefreshStationRelaysMessage'
    | 'StationDetailsUpdatedMessage'
    | 'MaxDjLoginsUpdatedMessage'
    | 'UserPermissionsUpdatedMessage'
    | 'ThirdPartyIntegrationUpdateMessage'
    | 'RequestPolicyRefreshMessage'
    | 'UpdateChatUserListMessage'
    | 'ChatUpdateChatDetailsMessage'
    | 'BlockedMessage'
    | 'MediaTypeColorUpdated';

/**
 * Items that can be updated by signal R (sent from server).
 */
export declare type TableEntity =
    // Library:
    | 'HistoryItem'
    | 'LibraryItem'
    | 'PlaylistItem'
    | 'QueueItem'
    | 'RequestItem'
    | 'NotificationItem'
    | 'Playlist'
    // Live Control:
    | 'MediaItem'
    | 'FileProcessingCompletedMessage'
    | 'RssUrlProcessingMessage'
    | 'RssUrlCreateFileMessage'
    | 'RssUrlDeleteMessage'
    | 'SharedSetting'
    | 'HistoryTouchMessage'
    | 'SourceStateRefreshMessage'
    | 'Clockwheel'
    | 'ClockwheelLineItem'
    | 'RelayStatusUpdateMessage'
    | 'RefreshStationRelaysMessage'
    | 'SeparationRule'
    | 'StationDetailsUpdatedMessage'
    | 'MaxDjLoginsUpdatedMessage'
    | 'UserPermissionsUpdatedMessage'
    | 'ThirdPartyIntegrationUpdateMessage'
    | 'RequestPolicyRefreshMessage'

    // HealthNotification:
    | 'ServerLogEntry'
    // DiscordIntegration:
    | 'DiscordServerIntegrationUpdateMessage'
    // Listener Stats Events
    | 'ListenerStatsEvent'
    // Listener Stats Status
    | 'ListenerStats'
    // Playlist Tree View (on Library Page)
    | 'PlaylistCategoryItem'
    // Recycle Bin
    | 'RecycledMediaItem'

    //Chat
    | 'BlockedMessage'
    | 'UpdateChatUserListMessage'
    | 'ChatUpdateChatDetailsMessage'

    // Library Tables
    | 'MediaTypeColorUpdated';

/**
 * Query String attribute to tell the backend what the client's intention is.
 * INTERNAL - LibraryHub, StationHub, UserHub - Communication inside of SAM Cloud e.g. Library or queue updates.
 * EXTERNAL - WidgetHub - Used internally and externally e.g. SAM-Widgets.
 * EXTERNAL_CHAT - WidgetHub - Used to chat internally or externally.
 */
export declare type SignalRIntent = 'INTERNAL' | 'EXTERNAL' | 'EXTERNAL_CHAT';

export declare type ConnectionState = 'connecting' | 'connected' | 'disconnected';

export const restartAttempts = 25;

/**
 * Signal R takes a while to connect.
 * Just wait for it before doing anything.
 */
export const signalRConnectDelay = 2000;

export interface SubscriptionNamePair {
    table: keyof TableEntityCallbacks;
    subscriptions: number;
}

/**
 * Contains relevant information to know if a message was processed and what type of message it was.
 */
export interface MessagesReceived {
    hubRef: HubRef;
    id: string;
    messageType?: EntityMessageType;
    messageProcessed: boolean;
    message?: unknown;
    table: keyof TableEntityCallbacks;
    time: string;
}

/**
 * From which hub does it come from.
 */
export type HubRef = 'LibraryHub' | 'StationHub' | 'UserHub';
