import React from 'react';
import { Navigate } from 'react-router-dom';
import { SAMCloudRoutes } from '../models/consts';
import ScheduledMaintenanceWindow from '../pages/maintenance/scheduled-maintenance-window';
import { useAccount } from '../providers/account';
import { createBaseUrl, createLinkUrl, useStationId } from '../utils/router-util';

/**
 * This navigates to whatever the main page should be.
 */
export const RootNavigation = () => <Navigate to={createBaseUrl('stations')} replace />;

/**
 * Navigates to the first station's link. Dependent on the station ID.
 * Route: /station/:stationId
 */
export const RootStationNavigation = () => {
    const stationId = useStationId();
    if (stationId) {
        // This assumes that SAMCloudRoutes contains 'library'
        const libraryRoute = SAMCloudRoutes.find((item) => item.path === 'library');
        const virtualPath = libraryRoute
            ? `${libraryRoute?.parentPath}${libraryRoute?.path}${libraryRoute?.defaultUrlParams}`
            : '/station/:stationId/library';
        const linkPath = createLinkUrl(virtualPath, stationId);
        return <Navigate to={linkPath} replace />;
    } else {
        // If there's no station ID, allow the user to pick a station ID:
        return <RootNavigation />;
    }
};

/**
 * Navigates to the first station settings's link. Dependent on the station ID.
 * Route: /station/:stationId/settings
 */
export const RootStationSettingsNavigation = () => {
    const stationId = useStationId();

    if (stationId) {
        return <Navigate to={createLinkUrl(`/station/:stationId/settings/station-details`, stationId)} replace />;
    } else {
        // If there's no station ID, allow the user to pick a station ID:
        return <RootNavigation />;
    }
};

export const RootStationScheduleNavigation = () => {
    const stationId = useStationId();
    const linkPath = createLinkUrl(`/station/:stationId/schedule/playblocks`, stationId);
    return <Navigate to={linkPath} replace />;
};

export const RootStationEventsNavigation = () => {
    const stationId = useStationId();
    const linkPath = createLinkUrl(`/station/:stationId/events/health`, stationId);
    return <Navigate to={linkPath} replace />;
};

export const RootStationWidgetsNavigation = () => {
    const stationId = useStationId();
    const linkPath = createLinkUrl(`/station/:stationId/widgets/web-tools-and-widgets`, stationId);
    return <Navigate to={linkPath} replace />;
};

export const RootMaintenanceNavigation = () => {
    const { accountState } = useAccount();

    if (accountState.maintenanceMode && accountState.maintenanceMode.isDue) {
        return <ScheduledMaintenanceWindow />;
    }
    return <RootNavigation />;
};

export const MaintenanceNavigation = () => <Navigate to={createBaseUrl('maintenance')} replace />;
