import React, { PureComponent } from 'react';
import { KeyboardDef, getKeyDisplayName } from '../utils/key-press-handler';
import { Chip, Stack } from './mui';

/**
 * Displays a chip for a shortcut.
 * The shortcut functionality is rigged up using useKeyPress.
 */
export default class Shortcut extends PureComponent<{ shortcut?: KeyboardDef }> {
    render() {
        const { shortcut } = this.props;
        const keySx = { alignSelf: 'center', fontSize: '8pt' };
        return (
            shortcut && (
                <Chip
                    sx={{ ml: 1 }}
                    color="primary"
                    size="small"
                    label={
                        <Stack direction="row" spacing={0.35} style={{ display: 'flex', alignContent: 'center' }}>
                            {shortcut.ctrlKey && <div style={keySx}>Ctrl</div>}
                            {shortcut.altKey && <div style={keySx}>Alt</div>}
                            {shortcut.shiftKey && <div style={keySx}>Shft</div>}
                            <div style={{ ...keySx, fontSize: '10pt', fontWeight: 'bold' }}>{getKeyDisplayName(shortcut)}</div>
                        </Stack>
                    }
                />
            )
        );
    }
}
