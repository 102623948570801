import React, { CSSProperties, FC, memo, MouseEvent, useEffect, useMemo, useState } from 'react';
import { useTreeView } from '..';
import DialogConfirmation from '../../../../components/dialog-confirmation';
import DialogMediaTypeColor from '../../../../components/dialog-media-type-color';
import DialogTextInput from '../../../../components/dialog-text-input';
import MenuOptions from '../../../../components/dynamic-table/menu';
import { getTableColumns } from '../../../../components/dynamic-table/table-util';
import {
    AddCircleIcon,
    alpha,
    Box,
    Collapse,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    RemoveCircleIcon,
    styled,
    Tooltip,
    useTheme
} from '../../../../components/mui';
import { allowCustomProps, Body1 } from '../../../../components/styled-components';
import { addMediaItemsToType, postLibraryRecycle, updateMediaItemWeights } from '../../../../middleware/library';
import { lookupMediaTypeByCode } from '../../../../middleware/media-item';
import { postAddToPlaylist } from '../../../../middleware/playlist';
import { LibraryTreeNode } from '../../../../models/dto';
import { DataMouseEvent, DropPosition, MenuAnchorPosition, MenuItemData, TblColType } from '../../../../models/interfaces';
import Lang from '../../../../models/language';
import { TreeNodeProps, TreePlainItemProps, TreeViewMainProps } from '../../../../models/props';
import { useDrag } from '../../../../providers/drag';
import { Notification, useNotification } from '../../../../providers/notifications';
import { Color, getColorFromPalette } from '../../../../utils/colors';
import { TableEntity } from '../../../../utils/signalr/models';
import { getGlobalScrollStyle, getMaxBorderRadius } from '../../../../utils/style';
import { millisecondsToTime } from '../../../../utils/time';
import { useRoutingData } from '../../../routing/provider';
import { libraryTreeMenuItems, playlistTreeMenuItems, TreeDefinition } from '../models/interfaces';
import {
    categoryConstants,
    getActivePlaylistMenuItems,
    getCollapseState,
    getIcon,
    getMediaItemIds,
    getNodeMediaTypeCode,
    hasChildren,
    isSelectedBranch
} from '../utils';

const iconOpacity = 0.5;
const rowBackgroundAlphaSelected = 0.05;
const tableEntity: TableEntity = 'MediaItem';

const ViewContainer = styled(List)(({ theme }) => {
    return {
        overflowY: 'auto',
        borderRadius: getMaxBorderRadius(theme),
        '& .MuiListItemIcon-root': {
            minWidth: 'unset'
        },
        '& .MuiSvgIcon-root': {
            fontSize: 20
        },
        ...getGlobalScrollStyle(theme)
    };
});

const ListButtonContainer = styled(
    ListItemButton,
    allowCustomProps(['main', 'open'])
)<{ main: string; open: boolean }>(({ main, open }) => {
    return {
        color: open ? main : 'unset',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 1
    };
});

const renderChildren = ({
    color,
    nodes,
    selectedNode,
    treeDef
}: {
    color: Color;
    nodes: Array<LibraryTreeNode>;
    selectedNode: LibraryTreeNode | undefined;
    treeDef: TreeDefinition;
}) => {
    const isUncle = nodes.some((node) => hasChildren(node));
    return nodes.map((node) => {
        if (hasChildren(node)) {
            const open = selectedNode ? isSelectedBranch(node, selectedNode) : false;
            return <TreeItem key={`container-${node.Id}`} color={color} node={node} open={open} treeDef={treeDef} />;
        } else {
            return <TreePlainItem key={node.Id} isUncle={isUncle} color={color} node={node} treeDef={treeDef} />;
        }
    });
};

const TreeItem: FC<TreeNodeProps> = ({ color, node, open: expanded, treeDef }) => {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const { allCollapseState, setCollapseState, treeViewData } = useTreeView();
    const collapseState = getCollapseState(allCollapseState, treeDef);

    useEffect(() => {
        if (!open && expanded) {
            setOpen(true);
            setCollapseState(1, treeDef);
        }
    }, [expanded]);

    useEffect(() => {
        if (collapseState === 'collapse') {
            setOpen(false);
        } else if (collapseState === 'expand') {
            setOpen(true);
        }
    }, [collapseState]);

    const onClick = () => {
        setOpen((prevState) => {
            // Either add or subtract the amount that is collapsed already:
            setCollapseState(!prevState ? 1 : -1, treeDef);
            return !prevState;
        });
    };

    return (
        <Box sx={{ background: open ? alpha(theme.palette.action.active, rowBackgroundAlphaSelected) : 'transparent' }}>
            <TreePlainItem isUncle={true} color={color} node={node} open={open} onClick={onClick} treeDef={treeDef} />
            <Collapse in={open} unmountOnExit sx={{ pl: '16px' }}>
                {renderChildren({ color, nodes: node.Children, selectedNode: treeViewData.selectedNode, treeDef })}
            </Collapse>
        </Box>
    );
};

const TreePlainItem: FC<TreePlainItemProps> = ({ isUncle, color, node, onClick, open, treeDef }) => {
    const [isDragActive, setIsDragActive] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [mediaItemsToDb, setMediaItemsToDb] = useState<{ ids: string[]; isJinOrPro: boolean }>({
        ids: [],
        isJinOrPro: false
    });
    const { dragState, setTableEntityTo, onItemsDrop } = useDrag();
    const theme = useTheme();
    const { stationData } = useRoutingData();
    const { addNotification } = useNotification();
    const stationId = stationData.stationId as string;
    const { treeViewData, trySelectedNode, playlistSummaries } = useTreeView();
    const iconMarginStyling = { marginRight: 4 };
    const { main } = getColorFromPalette(color, theme.palette);
    const icon = getIcon(color, open === true, node);
    const children = hasChildren(node);
    // Only show expand button if there are children to expand:
    const expandVisibility = children ? 'unset' : 'hidden';
    const childCountProps = children && { secondary: `${node.Children?.length} items` };
    const currentlySelected = treeViewData.resolvedNode?.categoryId === node.Id;

    const isPlaylistCategory = node.NodeType === 'Playlist';
    const isMediaTypeCategory = node.ParentId?.toLowerCase() === categoryConstants.MEDIA_TYPES.toLowerCase();
    const isWeightedRotationCategory = node.ParentId?.toLowerCase() === categoryConstants.WEIGHTED_ROTATION.toLowerCase();
    const isRecycleCategory = node.Id.toLowerCase() === categoryConstants.RECYCLE_BIN.toLowerCase();

    const isDroppableNode =
        !dragState.isTableSwap && (isPlaylistCategory || isRecycleCategory || isWeightedRotationCategory || isMediaTypeCategory);

    let tooltipTitle = '';
    const summaryItem = playlistSummaries?.find((item) => item.CategoryId === node.Id);
    if (summaryItem) {
        tooltipTitle =
            summaryItem.ItemCount > 0
                ? `${summaryItem.ItemCount} tracks (${millisecondsToTime(summaryItem.TotalDuration)})`
                : Lang.msgEmpty;
    }

    const contextMenuProp = {
        onContextMenu: (event: MouseEvent<HTMLElement>) => {
            const e = event as DataMouseEvent<{ childTarget: HTMLElement; node: LibraryTreeNode }>;
            // Data will be propagated to the parent:
            e.data = { childTarget: e.currentTarget, node };
            e.preventDefault();
        }
    };

    const handleSelectNode = () => {
        onClick && onClick(node);
        if (!children) {
            trySelectedNode(node, treeDef);
        }
    };

    const getRecycleOrMediaTypeDialogMessage = () => {
        if (isRecycleCategory) {
            return mediaItemsToDb.isJinOrPro ? Lang.msgRecyclePromoJingleMediaItems : Lang.msgRecycleMediaItems;
        }

        if (isMediaTypeCategory) {
            return 'When you change the media type of a Promo/Jingle all occurrences of this track, where used as a Leading or Trailing track, will be removed.';
        }
    };

    const handleRecycleMediaItems = async () => {
        const res = await postLibraryRecycle(stationId, mediaItemsToDb.ids);
        if (!res.success) {
            addNotification(
                new Notification({
                    message: res.message,
                    severity: 'error'
                })
            );
        }
        handleCloseDialog();
    };

    const handleDialogConfirmation = async () => {
        if (isRecycleCategory) {
            await handleRecycleMediaItems();
        } else if (isMediaTypeCategory) {
            await handleUpdateMediaItemsType();
        }
    };

    const handleUpdateMediaItemsType = async () => {
        await addMediaItemsToMediaType(mediaItemsToDb.ids, getNodeMediaTypeCode(node.Name));
        handleCloseDialog();
    };

    const handleCloseDialog = () => {
        setOpenConfirmDialog(false);
        setMediaItemsToDb({ ids: [], isJinOrPro: false });
    };

    const onDropToRecycleBin = (_dropPosition: DropPosition, _: unknown, sourceTableItems: TblColType[]) => {
        setMediaItemsToDb(getMediaItemIds(sourceTableItems));
        setOpenConfirmDialog(true);
    };
    const onDropToMediaType = async (_dropPosition: DropPosition, _: unknown, sourceTableItems: TblColType[]) => {
        const items = getMediaItemIds(sourceTableItems);
        const mediaTypeCode = getNodeMediaTypeCode(node.Name);
        setMediaItemsToDb(items);

        if (items.isJinOrPro && !(mediaTypeCode == 'JIN' || mediaTypeCode == 'PRO')) {
            setOpenConfirmDialog(true);
        } else {
            await addMediaItemsToMediaType(items.ids, mediaTypeCode);
        }
    };

    const addMediaItemsToMediaType = async (mediaItemIds: string[], mediaTypeCode?: string) => {
        if (mediaItemIds.length === 0 || !mediaTypeCode) {
            return;
        }

        let errorMessage = '';
        const lookupResponse = await lookupMediaTypeByCode(stationId, mediaTypeCode);
        if (lookupResponse.success) {
            const addMediaItemsResponse = await addMediaItemsToType(stationId, lookupResponse.MediaTypeId, mediaItemIds);
            if (addMediaItemsResponse.success) {
                return;
            }
            errorMessage = addMediaItemsResponse.message;
        } else {
            errorMessage = lookupResponse.message;
        }
        addNotification(
            new Notification({
                message: errorMessage,
                severity: 'error'
            })
        );
    };

    const onDropItemOntoPlaylist = async (dropPosition: DropPosition, _: unknown, sourceTableItems: TblColType[]) => {
        const res = await postAddToPlaylist({
            action: dropPosition,
            mediaItemIds: getMediaItemIds(sourceTableItems).ids,
            stationId,
            playlistId: node.Id
        });

        if (!res.success) {
            addNotification(
                new Notification({
                    message: Lang.msgAddItemToPlaylistError,
                    severity: 'error'
                })
            );
        }
    };

    const onDropToWeightedRotation = async (_dropPosition: DropPosition, _: unknown, sourceTableItems: TblColType[]) => {
        const res = await updateMediaItemWeights(stationId, node.Name, getMediaItemIds(sourceTableItems).ids);

        if (!res.success) {
            addNotification(
                new Notification({
                    message: Lang.msgAddItemToPlaylistError,
                    severity: 'error'
                })
            );
        }
    };

    const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();

        if (isDroppableNode) {
            setTableEntityTo(tableEntity);
            setIsDragActive(true);
        }
    };

    const onDrop = () => {
        setIsDragActive(false);
        if (dragState.tableEntityTo && dragState.tableEntityFrom) {
            const { tableData } = getTableColumns<TblColType>(dragState.tableEntityFrom);
            if (isPlaylistCategory) {
                onItemsDrop(tableEntity, tableData, undefined, 'BOTTOM', onDropItemOntoPlaylist);
            } else if (isRecycleCategory) {
                onItemsDrop(tableEntity, tableData, undefined, 'BOTTOM', onDropToRecycleBin);
            } else if (isWeightedRotationCategory) {
                onItemsDrop(tableEntity, tableData, undefined, 'BOTTOM', onDropToWeightedRotation);
            } else if (isMediaTypeCategory) {
                onItemsDrop(tableEntity, tableData, undefined, 'BOTTOM', onDropToMediaType);
            }
        }
    };

    const dragOverCss = (): CSSProperties => {
        if (isDragActive) {
            return {
                transition: 'all .2s ease',
                border: `7px solid ${theme.palette.secondary.light}`,
                borderRadius: `${theme.shape.borderRadius}px`
            };
        }
        return {};
    };

    const onDragLeave = () => {
        setTableEntityTo(false);
        setIsDragActive(false);
    };

    const dragNDropProps = isDroppableNode && { onDragOver: onDragOver, onDragLeave: onDragLeave, onDrop: onDrop };

    return (
        <>
            <ListButtonContainer
                {...contextMenuProp}
                {...dragNDropProps}
                draggable
                main={main}
                open={!!open}
                onClick={handleSelectNode}
                sx={{
                    pl: 1.5,
                    pr: 0,
                    pb: 0.5,
                    pt: 0.5,
                    color: currentlySelected ? theme.palette.primary.main : 'unset',
                    background: currentlySelected ? theme.palette.action.selected : 'transparent',
                    ...dragOverCss()
                }}
            >
                {isUncle && (
                    <ListItemIcon style={iconMarginStyling}>
                        {open ? (
                            <RemoveCircleIcon color={color as never} />
                        ) : (
                            <AddCircleIcon sx={{ color: 'text.primary', visibility: expandVisibility }} />
                        )}
                    </ListItemIcon>
                )}
                <ListItemIcon
                    style={{ ...iconMarginStyling, marginTop: '6px', opacity: open || currentlySelected ? 1 : iconOpacity }}
                >
                    {icon}
                </ListItemIcon>
                {tooltipTitle ? (
                    <Tooltip title={tooltipTitle} placement="right">
                        <Body1>
                            {node.Name} {summaryItem && `(${summaryItem.ItemCount})`}
                        </Body1>
                    </Tooltip>
                ) : (
                    <ListItemText primary={node.Name} {...childCountProps} title={node.Name} />
                )}
            </ListButtonContainer>
            <DialogConfirmation
                closable
                draggable
                open={openConfirmDialog}
                dialogTitle={isRecycleCategory ? 'Recycle media items?' : 'Remove as Leading or Trailing?'}
                onPositiveEvent={handleDialogConfirmation}
                theme={theme}
                onClose={handleCloseDialog}
                onNegativeEvent={handleCloseDialog}
            >
                {getRecycleOrMediaTypeDialogMessage()}
            </DialogConfirmation>
        </>
    );
};

export const TreeView: FC<TreeViewMainProps> = memo(({ treeDef }) => {
    const { libraryTree, playlistTree, setPlaylistTree, treeViewData } = useTreeView();

    const curTree = treeDef.treeEntity === 'library-tree' ? libraryTree : playlistTree;
    const [menuAnchorPosition, setSelectedElement] = useState<MenuAnchorPosition | null>(null);
    const [selectedNode, setSelectedNode] = useState<LibraryTreeNode | null>(null);
    // Only used if playlist:
    const [openRenameDialog, setOpenRenameDialog] = useState(false);
    // Only used if library-tree:
    const [openMediaTypeColor, setOpenMediaTypeColor] = useState(false);

    const activePlaylistTreeMenuItems = useMemo(
        () => getActivePlaylistMenuItems(playlistTreeMenuItems, selectedNode),
        [selectedNode]
    );

    const contextMenuProp = {
        onContextMenu: (event: MouseEvent<HTMLElement>) => {
            event.preventDefault();
            const e = event as DataMouseEvent<{ childTarget: HTMLElement; node: LibraryTreeNode }>;
            // Prevents right click if there is no data coming through:
            if (e.data) {
                const { childTarget, node } = e.data;
                const _setSelectedElement = () => {
                    setSelectedElement({
                        data: node,
                        mouseX: childTarget.clientLeft,
                        selectedElement: childTarget,
                        selectedEvent: e
                    });
                    setSelectedNode(node);
                };

                if (treeDef.treeEntity === 'library-tree') {
                    // Context Menu specific to Media Types:
                    if (node.ParentId && categoryConstants.MEDIA_TYPES === node.ParentId.toUpperCase()) {
                        _setSelectedElement();
                    }
                } else {
                    _setSelectedElement();
                }
            }
        }
    };

    const menuItemClickedPlaylistProp = treeDef.treeEntity === 'playlist' && {
        menuItemClicked: async (item: MenuItemData) => {
            const newData = { ...(selectedNode as LibraryTreeNode) };
            if (item.action === 'rename') {
                // Before saving, rename needs a dialog to know what's going to be saved.
                setOpenRenameDialog(true);
            } else {
                await setPlaylistTree(newData, item.action);
            }
        }
    };

    const menuItemClickedLibraryProp = treeDef.treeEntity === 'library-tree' && {
        menuItemClicked: (item: MenuItemData) => {
            if (item.action === 'color') {
                setOpenMediaTypeColor(true);
            }
        }
    };

    const onRenamePositiveEvent = async (value: string) => {
        // Make copy of the node, don't change the node directly because that will affect rendering:
        const newData = { ...(selectedNode as LibraryTreeNode) };
        newData.Name = value;
        await setPlaylistTree(newData, 'rename');
        setOpenRenameDialog(false);
    };

    if (curTree) {
        return (
            <ViewContainer disablePadding {...contextMenuProp}>
                {renderChildren({
                    color: 'primary',
                    nodes: curTree.Children,
                    selectedNode: treeViewData.selectedNode,
                    treeDef
                })}
                {menuItemClickedPlaylistProp && (
                    <MenuOptions
                        menuAnchorPosition={menuAnchorPosition}
                        menuItems={activePlaylistTreeMenuItems}
                        actionTitle=""
                        {...menuItemClickedPlaylistProp}
                        setSelectedElement={setSelectedElement}
                    />
                )}
                {menuItemClickedLibraryProp && (
                    <MenuOptions
                        menuAnchorPosition={menuAnchorPosition}
                        menuItems={libraryTreeMenuItems}
                        actionTitle=""
                        {...menuItemClickedLibraryProp}
                        setSelectedElement={setSelectedElement}
                    />
                )}
                {selectedNode && (
                    <DialogTextInput
                        closable={true}
                        key={`${selectedNode.Id}-${selectedNode.Name}`}
                        defaultText={selectedNode.Name}
                        dialogTextContent="Playlists will be resorted after refresh"
                        dialogTitle={Lang.aRenamePlaylist}
                        open={openRenameDialog}
                        positiveTitle="Change Playlist Name"
                        onNegativeEvent={() => setOpenRenameDialog(false)}
                        onPositiveEvent={onRenamePositiveEvent}
                        onValidate={(value: string) => {
                            // If the value is still the same, fail validation:
                            return value !== selectedNode.Name;
                        }}
                        txtFieldType="text"
                        txtFieldLabel="Playlist Name"
                    ></DialogTextInput>
                )}
                {selectedNode && (
                    <DialogMediaTypeColor
                        closable
                        onClose={() => setOpenMediaTypeColor(false)}
                        open={openMediaTypeColor}
                        mediaType={selectedNode}
                    />
                )}
            </ViewContainer>
        );
    }
    return null;
});
