import { TableEntity } from '../../../../utils/signalr/models';
import { IBroadcastModeChipCss, ILoading, ILiveControlSettings, SourceState } from './interfaces';

export const stationSelectBroadcastModeChipCss: IBroadcastModeChipCss = { fontSize: 20, p: 3.8, minHeight: 50 };
export const liveControlBroadcastModeChipCss: IBroadcastModeChipCss = { fontSize: 20, p: 2.4, minHeight: 50 };

export const quickStartGuideLink = '//spacial.com/cloud-live-help';
export const bufferSelectDuration = ['5', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55', '60'];
export const signalREntities: Array<TableEntity> = ['HistoryItem', 'SourceStateRefreshMessage', 'HistoryTouchMessage'];
export const changeModeOptions: Array<string> = [
    'SAM Broadcaster Cloud only (No live streaming)',
    'SAM Broadcaster Cloud with Live streaming',
    'Live streaming with SAM Broadcaster Cloud as backup (recommended)'
];

export const stateBit = {
    LIVETYPE: 128,
    CLOUDBROADCASTMODE: 64,
    CLOUDLIVEBROADCASTMODE: 32,
    LIVEBACKUPBROADCASTMODE: 16,
    STARTED: 8,
    READY: 4,
    ACTIVELIVESOURCE: 2,
    NEXTLIVESOURCE: 1
};

export const defaultSourceState: SourceState = {
    activeLiveSourceId: 0,
    broadcastMode: 0,
    nextLiveSourceId: 0,
    sources: []
};

export const defaultLoading: ILoading = {
    mainDisplay: true,
    broadcastModeSelectDialog: false,
    iceCast2Dialog: false,
    liveDjDialog: false,
    samProDialog: false,
    streamBufferDuration: false,
    refresh: false,
    moveBtn: false
};

export const defaultLiveControlSettings: ILiveControlSettings = {
    kickNodeHidden: false,
    switchNodeHidden: false,
    switchTrackEndNodeHidden: false,
    offAirDetailsHidden: true,
    offAirWarningHidden: true,
    changeBroadcastModeLinkHidden: false,
    startNodeHidden: true,
    stopNodeHidden: true,
    skipNodeHidden: true,
    sourceNotificationNodeHidden: true,
    stateMessageHidden: true,
    backupSourceHeadingNodeHidden: true,
    externalUrlNodeHidden: false,
    externalUrlDetailsHidden: false,
    externalUrlStartStopNodeHidden: false,
    moveupNodeHidden: true,
    movedownNodeHidden: true,
    kickNodeDisabled: false,
    switchNodeDisabled: false,
    skipNodeDisabled: false,
    moveDownNodeDisabled: false,
    moveUpNodeDisabled: false,
    switchTrackEndNodeDisabled: false,
    statusNodeIcon: undefined,
    statusNodeColor: '',
    streamingStatusNodeText: '',
    connectedText: '',
    stateMessageText: 'Stopping...',
    externalUrlText: '',
    externalUrlStartStopNodeText: 'Start/Stop',
    streamingDetailsHidden: true,
    offBackupAirWarningHidden: true,
    availableDetailsHidden: true,
    toConnectHidden: false,
    externalUrlBtnLoading: false,
    startBtnLoading: false,
    stopBtnLoading: false,
    skipBtnLoading: false,
    kickBtnLoading: false,
    switchOnTrackEndBtnLoading: false,
    cancelBtnLoading: false,
    externalUrlStartStopBtnLoading: false,
    switchNowBtnLoading: false
};

export declare type SourceStatus = 'INACTIVE' | 'STOPPED' | 'STARTING' | 'ACTIVE' | 'READY';
export declare type ProductName = 'SAM Broadcaster PRO' | 'SAM Broadcaster Live-DJ';
export declare type SourceStreamingStatus = 'ON AIR' | 'OPEN' | 'STREAMING' | 'STOPPED' | 'STARTING' | 'STARTED';
export declare type OptionsValue = 'Change Broadcast Mode' | 'Change Stream Buffer';
export declare type SourceBtn = 'Start' | 'Stop' | 'Start/Stop' | 'Kick' | 'Switch Now' | 'Switch on Track-End' | 'External Url';
export declare type SourceBtnType =
    | 'ExternalUrl'
    | 'ExternalUrlStartStop'
    | 'Start'
    | 'Stop'
    | 'Kick'
    | 'SwitchNow'
    | 'SwitchTrackEnd'
    | 'Cancel';
