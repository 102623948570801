import { useEffect } from 'react';
import SignalR from '.';
import { SearchRequest } from '../../components/search-bar/interfaces';
import { FilterName } from '../../middleware/library';
import { ResolvedTreeNode } from '../../pages/station/library/models/interfaces';
import { consoleLogAllowed } from '../env';
import { ConsoleLogError, ConsoleLog as log } from '../log';
import { OnSignalRMessage, SignalRMessage, SignalRSetup, TableEntity, TableEntityCallbacks } from './models';

/**
 * Some table entities need to subscribe to different tables.
 */
export function getTableEntityCallbacks(
    tableEntity: TableEntity,
    filterName: FilterName = FilterName.ALL
): (keyof TableEntityCallbacks)[] {
    switch (tableEntity) {
        case 'LibraryItem':
            switch (filterName) {
                case FilterName.REQUESTS:
                    return ['MediaItem', 'RequestItem', 'RequestAggregateItem'];
                case FilterName.RECYCLEBIN:
                    // Needs MediaItem.EntityDeletedMessage for deleting permanently:
                    return ['MediaItem', 'RecycledMediaItem'];
                case FilterName.ALL:
                case FilterName.DATEADDED:
                case FilterName.MEDIATYPE:
                case FilterName.DURATION:
                case FilterName.WEIGHT:
                case FilterName.GROUPEDFILTER:
                default:
                    return ['MediaItem'];
            }
        case 'PlaylistItem':
            return ['PlaylistCategoryItem', 'PlaylistCategoryItemRow', 'PlaylistItem'];
        case 'QueueItem':
            // Also subscribe to MediaItem.EntityUpdatedMessage:
            return ['MediaItem', 'QueueItem'];
        case 'HistoryItem':
        case 'MediaItem':
        default:
            return [tableEntity as keyof TableEntityCallbacks];
    }
}

/**
 * Should only be called from {@link connectSignalR}
 */
export function getOrCreateSignalR(setup: SignalRSetup): SignalR {
    if (window.signalR) {
        if (!window.signalR.reconstructNeeded(setup)) {
            return window.signalR;
        } else {
            /** Should disconnect because it's going to reconnect in {@link connectSignalR} */
            disconnectSignalR();
        }
    }
    if (!window.signalR) {
        // Only use one single signalR object to avoid duplications:
        window.signalR = new SignalR(setup, 'INTERNAL', (message) => {
            log('SignalR client connected.', message);
        });
    } else {
        window.signalR.copy(
            new SignalR(setup, 'INTERNAL', (message) => {
                log('SignalR client connected COPIED.', message);
            })
        );
    }

    return window.signalR;
}

/**
 * Get or create the Signal R Client. Connect the client if it's not already connected.
 */
export function connectSignalR(setup: SignalRSetup, from = ''): SignalR {
    const signalR = getOrCreateSignalR(setup);
    log(`SignalR State${from && ` ${from} `}`, signalR.isConnected);
    // If 'connecting' then it's not going to try to connect:
    if (signalR.isConnected === 'disconnected') {
        signalR.connectDebounced();
    }
    return signalR;
}

/**
 * Usage: subscribeMessage(1234, 'HistoryItem', () => {});
 * Warning: Omitting stationId might cause signalR to be recreated without the station hub. This can be fatal.
 */
function subscribeMessage<T extends SignalRMessage>(
    stationId: string | undefined,
    subscriptionKey: keyof TableEntityCallbacks,
    signalRMessageReceived: OnSignalRMessage<T>
) {
    let { signalR } = window;
    if (!signalR) {
        // This only tries desperately to recreate the signal R object, but this won't happen that easily since the signal R object has already been created.
        signalR = connectSignalR({
            stationId,
            stationHubActive: stationId ? true : false,
            userHubActive: true,
            libraryHubActive: true
        });
    }
    signalR.subscribeMessage(subscriptionKey, signalRMessageReceived);
}

function unsubscribeMessage<T extends SignalRMessage>(
    stationId: string | undefined,
    subscriptionKey: keyof TableEntityCallbacks,
    signalRMessageReceived: OnSignalRMessage<T>
) {
    let signalR = window.signalR;
    if (!signalR) {
        signalR = connectSignalR({
            stationId,
            stationHubActive: stationId ? true : false,
            userHubActive: true,
            libraryHubActive: true
        });
    }
    signalR.unsubscribeMessage(subscriptionKey, signalRMessageReceived);
}

/**
 * Disconnecting can be remade once connect runs.
 */
export function disconnectSignalR() {
    if (window.signalR) {
        window.signalR.disconnect();
    }
}

/**
 * Special request from Testers to not log on Staging, so for now, only log Signal R on Development.
 */
export function logSignalRError(msg: unknown, params: unknown = null) {
    if (consoleLogAllowed()) {
        ConsoleLogError(msg, params);
    }
}

/**
 * @param tableEntities *HAS TO BE A* Global Const otherwise it refreshes the whole time.
 */
export function useSignalRMultipleEntities(
    stationId: string | undefined,
    tableEntities: TableEntity[],
    messageReceived: OnSignalRMessage<SignalRMessage>,
    dependencyProp?: unknown
) {
    useEffect(() => {
        if (stationId) {
            for (const tableEntity of tableEntities) {
                const subscriptionKeys = getTableEntityCallbacks(tableEntity);
                for (const subscriptionKey of subscriptionKeys) {
                    subscribeMessage(stationId, subscriptionKey, messageReceived);
                }
            }
        }

        return () => {
            if (stationId) {
                for (const tableEntity of tableEntities) {
                    const subscriptionKeys = getTableEntityCallbacks(tableEntity);
                    for (const subscriptionKey of subscriptionKeys) {
                        unsubscribeMessage(stationId, subscriptionKey, messageReceived);
                    }
                }
            }
        };
    }, [stationId, dependencyProp, tableEntities]);
}

/**
 * For User Hub only to Connect.
 */
export function useSignalROnMessageUserHub(
    tableEntities: TableEntity[],
    loggedIn: boolean,
    messageReceived: OnSignalRMessage<SignalRMessage>
) {
    useEffect(() => {
        if (loggedIn) {
            for (const tableEntity of tableEntities) {
                const subscriptionKeys = getTableEntityCallbacks(tableEntity);
                for (const subscriptionKey of subscriptionKeys) {
                    subscribeMessage(undefined, subscriptionKey, messageReceived);
                }
            }
        }

        return () => {
            for (const tableEntity of tableEntities) {
                const subscriptionKeys = getTableEntityCallbacks(tableEntity);
                for (const subscriptionKey of subscriptionKeys) {
                    unsubscribeMessage(undefined, subscriptionKey, messageReceived);
                }
            }
        };
    }, [tableEntities, loggedIn]);
}

/**
 * Use a subscription key if you only want to subscribe to a table callback directly.
 */
export function useSignalRCallback(
    stationId: string,
    subscriptionKey: keyof TableEntityCallbacks,
    messageReceived: OnSignalRMessage<SignalRMessage>,
    dependencyProp?: unknown
) {
    useEffect(() => {
        subscribeMessage(stationId, subscriptionKey, messageReceived);

        return () => {
            unsubscribeMessage(stationId, subscriptionKey, messageReceived);
        };
    }, [stationId, subscriptionKey, dependencyProp]);
}

/**
 * A single Table Entity can subscribe to multiple callback events.
 */
export function useSignalRSingleEntity(
    stationId: string,
    tableEntity: TableEntity,
    messageReceived: OnSignalRMessage<SignalRMessage>,
    resolvedNode?: ResolvedTreeNode | undefined,
    searchRequest?: SearchRequest
) {
    useEffect(() => {
        const subscriptionKeys = getTableEntityCallbacks(tableEntity, resolvedNode?.filterName ?? FilterName.ALL);
        for (const subscriptionKey of subscriptionKeys) {
            subscribeMessage(stationId, subscriptionKey, messageReceived);
        }

        return () => {
            for (const subscriptionKey of subscriptionKeys) {
                unsubscribeMessage(stationId, subscriptionKey, messageReceived);
            }
        };
    }, [stationId, tableEntity, resolvedNode?.filterName, searchRequest]);
}
