import { SamVibeStationDto } from '../../../../../models/dto';
import { SignalRMessage } from '../../../../../utils/signalr/models';
import { AudioSettingInputName } from './consts';

//Props

export interface SharedProps {
    largeToSmall: boolean;
}

export interface AudioSettingsChartProps extends SharedProps {
    chartData?: IPlotData;
}

export type AudioSettingsBtnsProps = SharedProps;
export type FadeSettingsProps = SharedProps;
export type TrimSettingsProps = SharedProps;
export type CrossSettingsProps = SharedProps;

export interface NumberInputProps {
    value: number;
    min: number;
    max: number;
    unit: string;
    disabled: boolean;
    name: AudioSettingInputName;
    onChange: (value: number, name: AudioSettingInputName) => void;
}

export interface LabelSliderProps<T> {
    min: number;
    max: number;
    unit?: string;
    step: number;
    disabled?: boolean;
    value: number;
    largeToSmall?: boolean;
    name: T;
    round?: number;
    onChange: (value: number, name: T) => void;
}

export interface Fade {
    FadeType: FadeType;
    Duration: number;
}

export interface MediaItemAudioSetting {
    MediaItemId: string;
    Gain: number;
    TrimSilence?: boolean;
    XFade?: boolean;
    LevelStart?: number;
    LevelEnd?: number;
    LevelXfade?: number;
    MaxCross?: number;
    CueStart?: number;
    CueEnd?: number;
    CueXFade?: number;
    FadeInType: FadeType;
    FadeInDuration?: number;
    FadeOutType: FadeType;
    FadeOutDuration?: number;
    //Custom settings
    CueCrossAtEnd?: number;
    CueCrossAtStart?: number;
}

export interface IAudioSettings {
    TrimSilence: boolean;
    Xfade: boolean;
    StationGain: number;
    XfadeSkipShortDuration: number;
    LevelStart: number;
    LevelEnd: number;
    LevelXfade: number;
    MaxCross: number;
    FadeIn: Fade;
    FadeOut: Fade;
}

export interface SamVibeStationSignalRMessage extends SignalRMessage {
    Station?: SamVibeStationDto;
    StationId?: number;
}

export interface Gain {
    REPLAYGAIN_REFERENCE_LOUDNESS: string;
    REPLAYGAIN_TRACK_GAIN: string;
    REPLAYGAIN_ALGORITHM: string;
    REPLAYGAIN_ALBUM_GAIN: string;
}

export interface ILevel {
    key: number;
    value: number;
}

export interface IAnalysis {
    duration: number;
    gain: Gain;
    levelsStart: ILevel[];
    levelsEnd: ILevel[];
}

export interface IAnalysisLevel {
    dB: number;
    timeStamp: number;
}

export interface ISeries {
    x: number;
    y: number;
    name?: string;
}

export interface ILoading {
    mainDisplayData: boolean;
    saveBtnData: boolean;
}

export interface IPlotData {
    totalDuration: number;
    dBHigh: number;
    seriesOut: ISeries[];
    seriesIn: ISeries[];
}

//Enums
export enum FadeType {
    None,
    Linear,
    Sinusoidal,
    Logarithmic,
    Exponential,
    Default
}
export enum FadeDirection {
    IN,
    OUT
}

export enum DummySeries {
    NONE,
    IN,
    OUT
}
