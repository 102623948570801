import React, { FC } from 'react';
import { MenuItemData } from '../models/interfaces';
import { SelectItemMenuProps } from '../models/props';
import { ListItemIcon, Menu, MenuItem, useTheme } from './mui';

const menuIconSize = 'small';

export const SelectItemMenu: FC<SelectItemMenuProps> = ({
    menuAnchorPosition,
    menuItems,
    selectedItemId,
    onSelectedItem,
    setSelectedElement
}) => {
    const theme = useTheme();
    const open = Boolean(menuAnchorPosition?.selectedElement);

    const handleClose = () => {
        setSelectedElement(null);
    };
    const actionMenuItemClicked = (item: MenuItemData) => {
        onSelectedItem && onSelectedItem(item);
        handleClose();
    };

    const renderMenuItems = () => {
        return menuItems.map((item) => (
            <MenuItem
                selected={selectedItemId === item.action}
                key={item.title.toLowerCase()}
                onClick={() => {
                    actionMenuItemClicked(item);
                }}
            >
                <ListItemIcon>
                    <item.icon fontSize={menuIconSize} />
                </ListItemIcon>
                {item.title}
            </MenuItem>
        ));
    };

    return (
        <Menu
            anchorEl={menuAnchorPosition?.selectedElement}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
            }}
            transformOrigin={{
                vertical: menuAnchorPosition?.mouseY ? -menuAnchorPosition?.mouseY : 'top',
                horizontal: -(menuAnchorPosition?.mouseX ?? 0)
            }}
            sx={{
                '&.MuiPaper-root': { borderTopLeftRadius: '0px' },
                '& .MuiMenu-list': { pt: 0, pb: 0 },
                '& .Mui-selected svg': {
                    color: theme.palette.primary.main
                }
            }}
        >
            {renderMenuItems()}
        </Menu>
    );
};

export default SelectItemMenu;
