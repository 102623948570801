/**
 * This was compiled from SAM-Widgets' TD Player.
 * If this file changes, please update SAM-Widgets as well.
 */

import { getNowPlayingInformation } from '../../middleware/livecontrol';
import { getNowPlayingInformation as getNPI, getStationRelays } from '../../middleware/stations';
import { PlayerDataDto } from '../../models/dto';
import { GetEnv } from '../env';

const lblStationId = '{stationId}';
export const apiLibrary = `api/history/${lblStationId}`;
/**
 * Get the correct Triton SDK from environment.
 * Use localhost for debugging locally from the web-sdk repo.
 * DO NOT COMMIT debugLocally = true.
 */
export function getTDSdk(): string {
    const debugLocally = false; // Should stay false.

    if (debugLocally && GetEnv().name !== 'production') {
        return 'http://localhost:3000/td-sdk.js';
    }
    return '//sdk.listenlive.co/web/2.9/td-sdk.min.js';
}

export async function getNowPlaying(stationId: string, isPlaying: boolean, playerData?: PlayerDataDto): Promise<PlayerDataDto> {
    let resPlayerData = playerData;

    if (!playerData?.data || (playerData?.data && !playerData.data.find((x) => x.StationId.toString() === stationId))) {
        // Only fetch relays once unless the station ID changed
        resPlayerData = await getStationRelays(stationId);
    }

    const resMediaItem = await (isPlaying ? getNowPlayingInformation(stationId) : getNPI(stationId));

    const mergedValue = {
        ...resPlayerData,
        ...(isPlaying ? { mediaItem: resMediaItem, historyItem: undefined } : { historyItem: resMediaItem, mediaItem: undefined })
    };

    return mergedValue as PlayerDataDto;
}
