import React, { FC, useState } from 'react';
import { useListenerStatus } from '.';
import { ListenerStatusProps } from '../../models/props';
import { bytesToGB, formatListenerStatCount } from '../../utils/general';
import { Collapse, Divider, HeadphonesIcon, Stack, StorageIcon, Tooltip, alpha, debounce, useMediaQuery, useTheme } from '../mui';
import { Body2 } from '../styled-components';
import DialogListenerStatus from './dialog-listener-status';

const debounceDelayTime = 1500;
const ListenerStatus: FC<ListenerStatusProps> = () => {
    const theme = useTheme();
    const { palette, shape } = theme;
    const grayedOut = palette.grey[300];
    const { isDialogOpen, listenerStatCount, peakListenerStatCount, storageInfo, setIsDialogOpen } = useListenerStatus();
    const listenerStatCountFormatted = formatListenerStatCount(listenerStatCount);
    const storageUsedRounded = bytesToGB(storageInfo.storageUsed);
    const storageLimitRounded = bytesToGB(storageInfo.storageLimit);

    const sm = useMediaQuery(theme.breakpoints.down('md'));
    const [hoverOver, setHoverOver] = useState(false);
    const smallerDisplay = sm || !hoverOver;
    const dialogActive = isDialogOpen && !sm;
    const showSmallerDisplay = !dialogActive && smallerDisplay;
    const showBiggerDisplay = dialogActive || !smallerDisplay;

    // Too hold the expanded state for a bit longer:
    const debouncedOnHover = debounce((hover: boolean) => {
        setHoverOver(hover);
    }, debounceDelayTime);

    const backgroundProps = (dialogActive || !smallerDisplay) && { background: alpha(palette.primary.main, 0.1) };
    const usageAmount = `${storageUsedRounded}GB/${storageLimitRounded}GB`;

    return (
        <>
            <Stack
                direction="row"
                spacing={1}
                sx={{
                    borderColor: alpha(grayedOut, 0.5),
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    p: 0.6,
                    borderRadius: `${shape.borderRadius}px`,
                    ...backgroundProps
                }}
                onMouseEnter={() => {
                    setHoverOver(true);
                    debouncedOnHover(true);
                }}
                onMouseLeave={() => debouncedOnHover(false)}
                onClick={() => {
                    // OnClick gets invoked again with this click if the dialog closes. This is a bit dangerous.
                    !isDialogOpen && setIsDialogOpen(true);
                }}
            >
                <Tooltip title={`Listeners: ${listenerStatCountFormatted} | Peak: ${peakListenerStatCount}`}>
                    <Stack direction="column" alignItems="center" sx={{ cursor: 'pointer', height: '44px' }}>
                        <HeadphonesIcon />
                        {showSmallerDisplay && <Body2>{listenerStatCountFormatted}</Body2>}
                        <Collapse in={showBiggerDisplay} orientation="horizontal" unmountOnExit>
                            <Body2
                                sx={{ whiteSpace: 'nowrap', overflow: 'hidden' }}
                            >{`Listeners: ${listenerStatCountFormatted} | Peak: ${peakListenerStatCount}`}</Body2>
                        </Collapse>
                    </Stack>
                </Tooltip>
                <Divider orientation="vertical" flexItem />
                <Tooltip title={`${usageAmount} used`}>
                    <Stack direction="column" alignItems="center" sx={{ cursor: 'pointer', height: '44px' }}>
                        <StorageIcon />
                        {showSmallerDisplay && <Body2>{storageUsedRounded}GB</Body2>}
                        <Collapse in={showBiggerDisplay} orientation="horizontal" unmountOnExit>
                            <Body2 sx={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>{usageAmount}</Body2>
                        </Collapse>
                    </Stack>
                </Tooltip>
            </Stack>
            <DialogListenerStatus
                closable
                onClose={() => {
                    setIsDialogOpen(false);
                }}
                open={isDialogOpen}
            />
        </>
    );
};

export default ListenerStatus;
