/**
 * Names of colours matched with https://colors.artyclick.com/color-name-finder/.
 */

import { Palette, PaletteColorOptions, SimplePaletteColorOptions } from '../components/mui';

/**
 * SAM Cloud orange. Complementary of {@link oliveDrabCl}.
 */
export const samOrangeCl: SimplePaletteColorOptions = {
    main: '#f18d28',
    light: '#ffbe59',
    dark: '#b95f00',
    contrastText: '#fff'
};
/**
 * Complementary of {@link samOrangeCl}.
 */
export const oliveDrabCl: SimplePaletteColorOptions = {
    main: '#599620',
    light: '#8ac751',
    dark: '#266700'
};
/**
 * Complementary of {@link indigoCl}.
 */
export const napierGreenCl: SimplePaletteColorOptions = {
    main: '#2f8700',
    light: '#64b83c',
    dark: '#005900'
};
/**
 * Complementary of {@link napierGreenCl}.
 */
export const indigoCl: SimplePaletteColorOptions = {
    main: '#580087',
    light: '#8a3bb8',
    dark: '#260059'
};
/**
 * Analogous of {@link citronCl}.
 */
export const leafCl: SimplePaletteColorOptions = {
    main: '#65ab24',
    light: '#98dd57',
    dark: '#317b00',
    contrastText: '#fff'
};
/**
 * Analogous of {@link leafCl}.
 */
export const citronCl: SimplePaletteColorOptions = {
    main: '#a9ab24',
    light: '#dddc58',
    dark: '#767c00',
    contrastText: '#fff'
};
/**
 * Complementary of {@link chelseaGemCl}.
 */
export const funBlueCl: SimplePaletteColorOptions = {
    main: '#0c5c9b',
    light: '#5289cc',
    dark: '#00336c'
};
/**
 * Complementary of {@link funBlueCl}.
 */
export const chelseaGemCl: SimplePaletteColorOptions = {
    main: '#9b4a0c',
    light: '#d1773a',
    dark: '#681f00'
};
/**
 * Complementary of {@link aquaMarineCl}.
 */
export const raspberryCl: SimplePaletteColorOptions = {
    main: '#e91e63',
    light: '#ff6090',
    dark: '#b0003a'
};
/**
 * Complementary of {@link raspberryCl}.
 */
export const aquaMarineCl: SimplePaletteColorOptions = {
    main: '#1ee9a5',
    light: '#6dffd7',
    dark: '#00b676',
    contrastText: '#393c3c'
};
/**
 * Complementary of {@link skyBlueCl}.
 */
export const warmRedCl: SimplePaletteColorOptions = {
    main: '#ff3d00',
    light: '#ff7539',
    dark: '#c30000'
};
/**
 * Complementary of {@link warmRedCl}.
 */
export const skyBlueCl: SimplePaletteColorOptions = {
    main: '#00c3ff',
    light: '#6bf6ff',
    dark: '#0093cc',
    contrastText: '#fff'
};

export function getColor(color: PaletteColorOptions | undefined): string {
    if (!color) {
        return samOrangeCl.main; // Default
    }
    const cl = color as SimplePaletteColorOptions;

    return cl.main;
}

export function getColorFromPalette(color: Color, palette: Palette) {
    switch (color) {
        case 'primary':
        case 'secondary':
        case 'error':
        case 'info':
        case 'success':
        case 'warning':
            return palette[color];
        case 'default':
        case 'inherit':
        default:
            return palette.primary;
    }
}

export declare type Color =
    | 'inherit'
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | undefined;

export const darkShadowColor = '#1A2027';
export const lightShadowColor = '#e3e8f8f7';
