import React, { createContext, FC, useContext, useEffect, useRef, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import DeveloperConsole from '../../components/developer-console';
import MoreMenuOptionsDrawer from '../../components/more-menu-options-drawer';
import {
    MaintenanceNavigation,
    RootMaintenanceNavigation,
    RootNavigation,
    RootStationEventsNavigation,
    RootStationNavigation,
    RootStationScheduleNavigation,
    RootStationSettingsNavigation,
    RootStationWidgetsNavigation
} from '../../components/root-navigation';
import { getManageStation, getStation } from '../../middleware/stations';
import { SAMCloudRoutes } from '../../models/consts';
import { ISamvibeStationSignalRMessage, ManageStationDto, SamVibeStationDto } from '../../models/dto';
import { FnAsync, Void } from '../../models/interfaces';
import { RoutingProviderProps } from '../../models/props';
import { IRoute } from '../../models/routes';
import { AccountState, RequireAccount, useAccount } from '../../providers/account';
import { getRandomId, shldHideRouteFromTritonUser } from '../../utils/general';
import { useEffectAsync } from '../../utils/react-util';
import { checkStationDataRequirement, updateWebsiteTitle, useStationId } from '../../utils/router-util';
import { EntityMessageType, TableEntity } from '../../utils/signalr/models';
import { connectSignalR, useSignalRMultipleEntities } from '../../utils/signalr/utils';
import StationDataLoader from './station-data-loader';

const stationDetailsEntities: TableEntity[] = ['StationDetailsUpdatedMessage'];

export interface StationData {
    stationId?: string;
    manageStationData?: ManageStationDto;
    stationInfo?: SamVibeStationDto;
}

interface IRoutingContext {
    setStationId: Void<string>;
    refetchStationData: FnAsync<void>;
    stationData: StationData;
}

const initRoutingContext: Partial<IRoutingContext> = {
    stationData: {}
};
const RoutingContext = createContext(initRoutingContext as IRoutingContext);

export function useRoutingData() {
    return useContext(RoutingContext);
}

const renderRoutes = (routes: Array<IRoute>, accountState: AccountState, stationData?: StationData) => {
    return routes.map((route) => {
        if (shldHideRouteFromTritonUser(route, accountState.useTritonSso, stationData?.manageStationData?.showBillingMenuItem)) {
            return <></>;
        }
        if (route.path === 'maintenance') {
            return <Route key={getRandomId(`route-${route.path}`)} path={route.path} element={<RootMaintenanceNavigation />} />;
        }
        if (route.children) {
            return (
                <Route key={getRandomId(`route-${route.path}`)} path={route.path}>
                    {renderRoutes(route.children, accountState)}
                    {route.path === 'settings' && <Route path="" element={<RootStationSettingsNavigation />} />}
                    {route.path === 'schedule' && <Route path="" element={<RootStationScheduleNavigation />} />}
                    {route.path === 'events' && <Route path="" element={<RootStationEventsNavigation />} />}
                    {route.path === 'widgets' && <Route path="" element={<RootStationWidgetsNavigation />} />}
                </Route>
            );
        } else {
            const page = checkStationDataRequirement(route, stationData) ? (
                <route.page />
            ) : (
                <StationDataLoader>
                    <route.page />
                </StationDataLoader>
            );

            const element = route.protected ? <RequireAccount>{page}</RequireAccount> : page;
            return (
                <Route
                    key={getRandomId(`route-${route.path}`)}
                    path={route.path}
                    element={
                        accountState.maintenanceMode && accountState.maintenanceMode.isDue ? <MaintenanceNavigation /> : element
                    }
                />
            );
        }
    });
};

export const RoutingProvider: FC<RoutingProviderProps> = ({ onSetStationData }) => {
    const location = useLocation();

    const stationId = useStationId(location);
    const [stationData, setStationData] = useState<StationData>({});
    const account = useAccount();
    const fetchingStationData = useRef(false);
    const [developerConsoleOpen, setDeveloperConsoleOpen] = useState(false);

    const defaultRoutes = SAMCloudRoutes.filter((item) => item.parentPath === '/');
    const stationRoutes = SAMCloudRoutes.filter((item) => item.parentPath === '/station/:stationId/');

    useEffect(() => {
        updateWebsiteTitle(location, stationId);
    }, [location]);

    useEffect(() => {
        onSetStationData(stationData);
    }, [stationData]);

    useSignalRMultipleEntities(
        stationId,
        stationDetailsEntities,
        (messageType: EntityMessageType, message: ISamvibeStationSignalRMessage) => {
            if (messageType === 'StationDetailsUpdatedMessage') {
                const stationInfo = message?.Station;
                setStationData((prevState) => ({
                    ...prevState,
                    stationInfo: stationInfo
                }));
            }
        }
    );

    const value = {
        setStationId: (stationId: string) => {
            setStationData((prevState) => ({ ...prevState, stationId }));
        },
        refetchStationData: async () => {
            if (stationId) {
                setStationData((prevState) => ({
                    ...prevState,
                    stationId,
                    manageStationData: undefined,
                    stationInfo: undefined
                }));
                const [manageStationData, stationInfo] = await Promise.all([getManageStation(stationId), getStation(stationId)]);
                setStationData((prevState) => ({
                    ...prevState,
                    stationId,
                    manageStationData,
                    stationInfo
                }));
            }
        },
        stationData
    };

    const clearStationData = () => {
        setStationData((prevState) => {
            const { manageStationData, stationId, stationInfo } = prevState;
            // Only set state if there are anything to set:
            if (manageStationData || stationId || stationInfo) {
                return {
                    ...prevState,
                    stationId: undefined,
                    manageStationData: undefined,
                    stationInfo: undefined
                };
            }
            return prevState;
        });
    };

    useEffectAsync(async () => {
        if (stationId) {
            // If the station changes, it will automatically disconnect and reconnect.
            // This is just to prepare the signal R for message events, use `subscribeMessage` to use:
            connectSignalR(
                { stationId, stationHubActive: true, userHubActive: true, libraryHubActive: true },
                RoutingProvider.name
            );
        }
        if (stationId && stationId !== stationData.stationId && !fetchingStationData.current) {
            fetchingStationData.current = true;
            await value.refetchStationData();
            fetchingStationData.current = false;
        } else if (!stationId) {
            clearStationData();
        }
    }, [stationId]);

    useEffect(() => {
        // If the user logs out, invalidate the stationId:
        clearStationData();
    }, [account.accountState.loggedIn]);

    return (
        <RoutingContext.Provider value={value}>
            <DeveloperConsole
                onClose={() => {
                    setDeveloperConsoleOpen(false);
                }}
                open={developerConsoleOpen}
            />
            <MoreMenuOptionsDrawer setDeveloperConsoleOpen={setDeveloperConsoleOpen}></MoreMenuOptionsDrawer>
            <Routes>
                <Route path="/">
                    <Route path="station/:stationId/">
                        {renderRoutes(stationRoutes, account.accountState, stationData)}
                        <Route path="" element={<RootStationNavigation />} />
                    </Route>
                    {renderRoutes(defaultRoutes, account.accountState)}
                </Route>
                <Route path="*" element={<RootNavigation />} />
            </Routes>
        </RoutingContext.Provider>
    );
};
