import { BaseResponseDto, MediaTypeColorsResult, MediaTypeDto, MediaTypeItemDto, StationRequest } from '../models/dto';
import { BaseUrl } from '../utils/env';
import { Fetch } from '../utils/middleware';
import { getRequestInitGET, getRequestInitPOST } from './headers';

const lblStationId = '{stationId}';
const lblMediaTypeCode = '{mediaTypeCode}';
const lblUserId = '{loggedInUser}';
const lblColor = '{color}';

const urlMediaTypes = 'api/mediatype';
const urlMediaTypesStation = `${urlMediaTypes}/${lblStationId}`;
const urlLookupMediaTypeByCode = `${urlMediaTypesStation}/${lblMediaTypeCode}`;
const urlMediaTypeColors = `${urlMediaTypes}/${lblUserId}/mediatype/colors`;
const urlMediaTypeColor = `${urlMediaTypes}/${lblUserId}/mediatype/${lblMediaTypeCode}/color/${lblColor}`;

export async function getMediaTypes({ stationId }: StationRequest): Promise<MediaTypeDto> {
    const url = `${BaseUrl()}${urlMediaTypesStation}`.replace(lblStationId, stationId);
    return await Fetch<MediaTypeDto>(url, getRequestInitGET());
}

export async function lookupMediaTypeByCode(stationId: string, mediaTypeCode: string): Promise<MediaTypeItemDto> {
    const url = `${BaseUrl()}${urlLookupMediaTypeByCode}`
        .replace(lblStationId, stationId)
        .replace(lblMediaTypeCode, mediaTypeCode);
    return await Fetch<MediaTypeItemDto>(url, getRequestInitGET());
}

export async function getMediaTypeColor(userId: string): Promise<MediaTypeColorsResult> {
    const url = `${BaseUrl()}${urlMediaTypeColors.replace(lblUserId, userId.toString())}`;
    return await Fetch(url, getRequestInitPOST());
}

/**
 * Use getNodeMediaTypeCode on mediaTypeCode.
 */
export async function postMediaTypeColor(userId: string, mediaTypeCode: string, color: string): Promise<BaseResponseDto> {
    const url = `${BaseUrl()}${urlMediaTypeColor.replace(lblUserId, userId.toString()).replace(lblMediaTypeCode, mediaTypeCode).replace(lblColor, encodeURIComponent(color))}`;
    return await Fetch(url, getRequestInitPOST());
}
