import React, { DragEvent, FC, useMemo, useState } from 'react';
import { TableRowProps, defaultRowRenderer } from 'react-virtualized/dist/es/Table';
import { TblColType } from '../../models/interfaces';
import { DynamicTableRowProps } from '../../models/props';
import { useTreeView } from '../../pages/station/library';
import { matchMediaTypeColor } from '../../pages/station/library/utils';
import { useDrag } from '../../providers/drag';
import { TableEntity } from '../../utils/signalr/models';
import { TableRowStyling, containerClassName, rowSelector } from '../../utils/table-row-styling';
import { useTheme } from '../mui';
import { getTableSettingsItems } from './table-util';
import { getItemDragPosition, isRowDragging } from './utils';

/**
 * Dependencies: useTreeView (Must run under provider).
 */
export const DynamicTableRow: FC<DynamicTableRowProps> = (props) => {
    const theme = useTheme();
    const { libLayout, mediaTypeColors, tableSettingsControl } = useTreeView();
    const { dragState, setDragActive, setTableEntityTo, onItemsDrop } = useDrag();
    const [dragAboveItemActive, setDragAboveItemActive] = useState(false);
    const [dragBelowItemActive, setDragBelowItemActive] = useState(false);

    const { index, rowData, tableData, tableEntity, triggerOnDrag, triggerOnDrop, triggerTableSwap, rowChecked } = props;
    const rowDragging =
        dragState.tableEntityFrom && dragState.tableEntityFrom === tableEntity && isRowDragging(tableEntity, rowData, dragState);
    const isMovable = tableData.movable.indexOf(tableEntity) > -1;

    const { tableSettings } = getTableSettingsItems(tableData.tableSettingsStorageKey, tableSettingsControl);

    const getTableRowStyling = () => {
        let mediaTypeColor: string | undefined;
        const mediaTypeColorDto = matchMediaTypeColor(rowData, mediaTypeColors);
        if (mediaTypeColorDto) {
            mediaTypeColor = mediaTypeColorDto.Value;
        }
        return new TableRowStyling({
            index,
            theme,
            rowChecked,
            rowDragging,
            dragAboveItemActive,
            dragBelowItemActive,
            mediaTypeColor,
            tableSettings
        });
    };

    const rowCSS = useMemo(
        () => getTableRowStyling(),
        [index, theme, rowChecked, rowDragging, dragAboveItemActive, dragBelowItemActive, tableSettings, mediaTypeColors]
    );

    const toggleDragBorders = (droppable: boolean, above: boolean, below: boolean, leaveOnly = false) => {
        if (isMovable) {
            if (!leaveOnly) {
                setTableEntityTo(droppable ? tableEntity : false);
            }
            setDragAboveItemActive(above);
            setDragBelowItemActive(below);
        } else {
            // Only droppable if movable:
            setTableEntityTo(false);
        }
    };

    const onDragStart = () => {
        setDragActive(tableEntity, tableData, true);
        triggerOnDrag(props.index, rowData as TblColType);
    };

    const onDragOver = (e: DragEvent<HTMLDivElement>) => {
        if (!libLayout.resizable) {
            // Needed otherwise "drop" events don't fire:
            e.stopPropagation();
        }
        e.preventDefault();

        if (rowDragging || dragState.isTableSwap) {
            return;
        }

        if (getItemDragPosition(e, containerClassName, rowSelector) === 'TOP') {
            //Add below
            toggleDragBorders(true, true, false);
        } else {
            //Add above
            toggleDragBorders(true, false, true);
        }
    };

    const onDragEnd = () => {
        setDragActive(tableEntity, tableData, false);
        toggleDragBorders(false, false, false);
    };

    const onDragLeave = () => {
        toggleDragBorders(false, false, false, true);
    };

    const onDrop = (e: DragEvent<HTMLDivElement>) => {
        if (dragState.tableEntityTo) {
            if (dragState.isTableSwap) {
                if (dragState.tableEntityTo != dragState.tableEntityFrom) {
                    triggerTableSwap &&
                        triggerTableSwap(dragState.tableEntityFrom as TableEntity, dragState.tableEntityTo as TableEntity);
                }
            } else {
                const dropPosition = getItemDragPosition(e, containerClassName, rowSelector);
                onItemsDrop(tableEntity, tableData, rowData, dropPosition, triggerOnDrop);
            }
        }
        setDragActive(tableEntity, tableData, false);
        toggleDragBorders(false, false, false);
        // Needed otherwise DynamicTable::onDrop will also trigger:
        e.stopPropagation();
    };

    return (
        <div
            draggable
            className={containerClassName}
            onDragStart={onDragStart}
            onDragOver={onDragOver}
            onDragEnd={onDragEnd}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
        >
            {defaultRowRenderer({
                ...(props as TableRowProps),
                style: {
                    ...props.style,
                    ...rowCSS.getFinalStyling()
                }
            })}
        </div>
    );
};

export default DynamicTableRow;
