import React, { PureComponent } from 'react';
import { alpha, Button, Stack } from '../../../../../../components/mui';
import { IntegrationStructureEditorItemKey, IntegrationStructureKey } from '../../models/consts';
import { MessageStructureSelectProps } from '../../models/interfaces';

export default class MessageStructureSelect extends PureComponent<MessageStructureSelectProps> {
    render() {
        const { onSelect, integrationStructureSelect, theme, disabled } = this.props;

        const renderSelectItemBtn = (key: IntegrationStructureEditorItemKey, label: string) => {
            const isSelected = integrationStructureSelect[key] as boolean;
            return (
                <Button
                    sx={{
                        borderRadius: 0,
                        background: isSelected ? alpha(theme.palette.primary.main, 0.75) : 'none',
                        flex: 1,
                        height: 43
                    }}
                    disabled={disabled}
                    variant={isSelected ? 'contained' : 'outlined'}
                    onClick={() => onSelect(key)}
                    size="small"
                >
                    {label}
                </Button>
            );
        };

        return (
            <Stack flex={3}>
                <Stack direction="row">
                    {renderSelectItemBtn(IntegrationStructureKey.TrackTitle, 'Title')}
                    {renderSelectItemBtn(IntegrationStructureKey.TrackDuration, 'Duration')}
                </Stack>
                <Stack direction="row">
                    {renderSelectItemBtn(IntegrationStructureKey.TrackArtist, 'Artist')}
                    {renderSelectItemBtn(IntegrationStructureKey.TrackBuyLink, 'Buy link')}
                </Stack>
                <Stack direction="row">
                    {renderSelectItemBtn(IntegrationStructureKey.TrackAlbum, 'Album')}
                    {renderSelectItemBtn(IntegrationStructureKey.TrackListenUrl, 'Listen url')}
                </Stack>
                <Stack direction="row">
                    {renderSelectItemBtn(IntegrationStructureKey.DjName, 'DJ name')}
                    {renderSelectItemBtn(IntegrationStructureKey.Website, 'Website')}
                </Stack>
            </Stack>
        );
    }
}
